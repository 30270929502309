
import { throwError as observableThrowError, Observable } from 'rxjs';

import { catchError, map } from 'rxjs/operators';
import { Injectable } from "@angular/core";
import { Http, Response, RequestOptions, Headers } from "@angular/http";

@Injectable()
export class UploadService {

    constructor(private http: Http) {
    }

    uploadFile(url: string, formData: FormData): Observable<any> {
        let options = this.getHeaders();

        return this.http
            .post(url, formData, options).pipe(
                map((response: Response) => {
                    return <any>response.json();
                }),
                catchError(this.handleError));
    }

    private handleError(error: any) {
        return observableThrowError(error);
    }

    private getHeaders() {
        let headers = new Headers();
        headers.append('Authorization', 'Bearer ' + localStorage.token);
        headers.append('IXS', localStorage.IXS_id);
        let options = new RequestOptions({ headers: headers });
        return options;
    }
}