import {
  Component,
  Input,
  OnInit,
  ViewChild,
  AfterViewInit
} from "@angular/core";
import {
  MatSort,
  MatTableDataSource,
  MatPaginator,
  PageEvent
} from "@angular/material";
import {
  animate,
  state,
  style,
  transition,
  trigger
} from "@angular/animations";
import { PageService } from "./../../foundations-webct-robot/robot/pageComponent/page.service";
import { Utils } from "./../../foundations-webct-robot/robot/utils/utils.service";
import {
  JsonParams,
  newEvent
} from "./../../foundations-webct-robot/robot/classes/jsonParams.class";
import { GlobalVarsService } from "./../../foundations-webct-robot/robot/utils/global-vars.service";
import { HttpClient, HttpHeaders } from "@angular/common/http";
import { SelectionModel } from "@angular/cdk/collections";
import { OperationsService } from "./../../foundations-webct-robot/robot/utils/operations.service";
import { ConfigurationService } from "./../../foundations-webct-robot/robot/utils/configuration.service";
import { UtilsCustomLocalService } from "../custom-utils.service";

import * as $ from "jquery";
import { PagingSettings } from "../custom-pagination/custom-pagination.component";
import { ItemsPerPagePagination } from "../custom-pagination/custom-pagination.component";

@Component({
  selector: "app-custom-tests-table",
  templateUrl: "./custom-tests-table.component.html",
  styleUrls: ["./custom-tests-table.component.scss"],
  animations: [
    trigger("detailExpand", [
      state("collapsed", style({ height: "0px", minHeight: "0" })),
      state("expanded", style({ height: "*" })),
      transition(
        "expanded <=> collapsed",
        animate("225ms cubic-bezier(0.4, 0.0, 0.2, 1)")
      )
    ])
  ]
})
export class CustomTestsTableComponent implements OnInit {
  @Input() dataRecs;
  @Input() inputDataRecs;
  @ViewChild(MatSort) sort: MatSort;
  @ViewChild(MatPaginator) paginator: MatPaginator;

  private static BE_PATH = "SchoolBE";
  private static BASE_PATH = "SchoolBOT";

  columnsToDisplay = [
    "select",
    "expand",
    "name",
    "program",
    "lastExecution",
    "state",
    "result",
    "action"
  ];
  columnsToDisplay2 = ["expandTest", "name", "state"];
  columnsToDisplay3 = ["saying", "expectedResponse", "botResponse", "state"];
  //"botResponse",
  // "intent"
  // "expectedResponse",
  // "accuracy",
  // "parameters",

  expandedElement: object | null;
  expandedElement2: object | null;
  progressBar;
  dataSource;
  selection;
  testSets;
  loading;
  programs;

  private requestEndpointObj: JsonParams;
  private testsURL;
  private baseUrl;
  private beURL;
  private data;
  private testsData = [];
  private loaded = false;
  private testsDataCompleted = [];
  public pageSizeOptions: number[] = [
    ItemsPerPagePagination.Five,
    ItemsPerPagePagination.Ten,
    ItemsPerPagePagination.TwentyFive,
    ItemsPerPagePagination.Hundred
  ];
  public pageSize = 5;
  public pagingSettings: PagingSettings;

  /**
   * @description Validate if Student is 'live' , if is 'live' can use tests
   */
  public canUseTests: boolean;

  constructor(
    public pageService: PageService,
    public utils: Utils,
    private _global: GlobalVarsService,
    private _http: HttpClient,
    private _operations: OperationsService,
    private _config: ConfigurationService,
    private _customUtils: UtilsCustomLocalService
  ) {
    this.loading = true;
  }

  ngOnInit() {
    this.baseUrl = this._config.getEndpoint(
      CustomTestsTableComponent.BASE_PATH
    );
    this.beURL = this._config.getEndpoint(CustomTestsTableComponent.BE_PATH);

    this.dataRecs.status === "live"
      ? (this.canUseTests = true)
      : (this.canUseTests = false);

    // Get TestSets and respective tests
    this.buildURL();
    this.selection = new SelectionModel(true, []);
  }

  public buildURL() {

    const superheroProgramsUrl = `${this.baseUrl}/superheros/${this._global.urlParams['id']}?keys={"programs":1}`;

    this.getHttpRequest(superheroProgramsUrl).subscribe(result => {
      const superheroPrograms = result['programs'];


      const filteredPrograms = this._customUtils.getStudentProgramsByStatusWithoutId(
        superheroPrograms,
        'L',
        'status'
      );
      if (!filteredPrograms || filteredPrograms == null) {
        this.loading = false;
        this.loaded = true;
        return;
      }

      const testSetsUrl = `${this.baseUrl}/testSets?pagesize=101&count&filter={'programId': { '$in':
     ${filteredPrograms}}}&sort=-lastUpdate&keys={'name':1,'description':1,'belongsTo':1,
     'status':1,'lastUpdate':1,'version':1, 'programId':1}`;

      this.getHttpRequest(testSetsUrl).subscribe(res => {
        this.testSets = res["_embedded"];

        // @ts-ignore
        const testSetsIds = [];

        this.testSets.map(testSet => testSetsIds.push(`"${testSet._id.$oid}"`));

        this.testsURL = `${this.baseUrl}/tests?filter=
      {'testSetId':{'$in':[${testSetsIds.join()}]},
    'superheroId':'${this.dataRecs._id.$oid}'}}
    &keys={
      'testSetId':1,
      'superheroId':1,
      'tests.lastExecution':1,
      'tests.state':1,
      'tests.totalTests':1,
      'tests.totalPassed':1,
      'tests.tests.name':1,
      'tests.tests.state':1,
      'tests.tests.steps.saying':1,
      'tests.tests.steps.expectedResponse':1,
      'tests.tests.steps.botResponse':1,
      'tests.tests.steps.state':1
    }
    `;

        const filteredProgramsByID = this._customUtils.getStudentProgramsByStatus(
          superheroPrograms,
          'L',
          'status'
        );

        if (!filteredProgramsByID || filteredProgramsByID == null) {
          this.loading = false;
          this.loaded = true;
          return;
        }

        const programsUrl = `${this.baseUrl}/programs?filter={'_id': { '$in':${filteredProgramsByID}}}&keys={'_id':1,'name':1}`;

        this.getHttpRequest(programsUrl).subscribe(prg => {
          this.programs = prg['_embedded'];
          this.getTestsData();
        });
      });
    });
  }

  /**
   * @param url endpoint where we get data from
   * @description GET data from Endpoint (url)
   */
  public getHttpRequest(url) {
    const options = this.getHeaders();
    return this._http.get(url, options);
  }

  /**
   * @param url endpoint where we post data to
   * @description POST data to Endpoint (url)
   */
  public postHttpRequest(url, body) {
    const options = this.getHeaders();
    return this._http.post(url, body, options);
  }

  public openElement(element) {
    if (element.state === "failed" || element.state === "passed") {
      this.expandedElement = this.expandedElement === element ? null : element;
    }
  }

  public getTestsData() {
    const temp = this.getHttpRequest(this.testsURL).subscribe(
      val => {
        const notExecuted = this.testSets.filter(x => !val['_embedded'].map(i => i.testSetId).includes(x._id.$oid));
        this.transformData(val, notExecuted);
      },
      error => console.error("err", error)
    );
  }

  public transformData(data, notExecuted?) {
    this.data = data;
    for (const value of data._embedded) {
      value.tests[0].expandable = true;
      value.tests[0].testSetId = value.testSetId;
      value.tests[0].superheroId = value.superheroId;
      value.tests[0].lastExecution = new Date(
        value.tests[0].lastExecution
      ).toLocaleString();
      value.tests[0].name = this.testSets.find(
        el => el._id.$oid === value.testSetId
      ).name;

      const auxProgram = this.testSets.find(
        el => el._id.$oid === value.testSetId
      ).programId;
      value.tests[0].programName = this.programs.find(
        el => el._id.$oid === auxProgram
      ).name;

      value.tests[0].description = this.testSets.find(
        el => el._id.$oid === value.testSetId
      ).description;
      value.tests[0].progressBar = this.FormatData_ProgressBar_TestSets(
        value.tests[0]
      );
      if (value.tests[0].hasOwnProperty("totalTests")) {
        var testSetID = value.tests[0].testSetId;
        // Add to Test row de value expand
        value.tests[0].tests.map((test, index) => {
          test.id = `${testSetID}___${index}`;
          test.expandTest = false;
        });
      }

      this.testsDataCompleted.push(value.tests[0]);
      const auxTestSetData = JSON.parse(JSON.stringify(value.tests[0]));
      auxTestSetData.tests = [];
      this.testsData.push(auxTestSetData);

    }

    if (notExecuted) {
      // tslint:disable-next-line: forin
      for (const testSet of notExecuted) {
        let testSetAux = {
          tests: [{
            expandable: true,
            testSetId: '',
            superheroId: '',
            lastExecution: '',
            name: '',
            programName: '',
            description: '',
            progressBar: [],
            state: 'notExecuted'
          }]
        };
        testSetAux.tests[0].expandable = false;
        testSetAux.tests[0].testSetId = testSet._id.$oid;
        testSetAux.tests[0].superheroId = this._global.urlParams['id'];
        testSetAux.tests[0].lastExecution = '--';
        testSetAux.tests[0].name = testSet.name;
        const auxProgram = this.testSets.find(
          el => el._id.$oid === testSet._id.$oid
        ).programId;
        testSetAux.tests[0].programName = this.programs.find(
          el => el._id.$oid === auxProgram
        ).name;
        testSetAux.tests[0].description = testSet.description;
        testSetAux.tests[0].progressBar = [
          {
            option: this.pageService.i18n('application___passed'),
            description: '',
            value: this.pageService.i18n('application___passed'),
            next: 1,
            count: 0
          },
          {
            option: this.pageService.i18n('application____failed'),
            description: '',
            value: this.pageService.i18n('application____failed'),
            next: 2,
            count: 0
          },
          {
            option: this.pageService.i18n('application___notExecuted'),
            description: '',
            value: this.pageService.i18n('application___notExecuted'),
            next: 3,
            count: 1
          }
        ];
        testSetAux.tests[0].state = 'not executed';
        this.testsDataCompleted.push(testSetAux.tests[0]);
        const auxTestSetData = JSON.parse(JSON.stringify(testSetAux.tests[0]));
        auxTestSetData.tests = [];
        this.testsData.unshift(auxTestSetData);
      }
    }

    if (this.loaded == true) {
      this._operations._showPopupRequestStatusMessage(
        "info",
        [],
        `${this.pageService.i18n("application___testsSetDataReloaded")}`
      );
    }

    this.dataSource = new MatTableDataSource<object>(this.testsData);
    this.dataSource.sort = this.sort;
    this.dataSource.paginator = this.paginator;
    this.pagingSettings = {
      id: "testSetStudentPaginator",
      totalItems: this.testsData.length,
      paging: true,
      currentPage: 1,
      itemsPerPage: this.pageSize
    };
    this.loading = false;
    this.loaded = true;
  }

  public reloadTests() {
    this.loading = true;

    // RESET TABLE DATA
    delete this.dataSource;
    delete this.expandedElement;
    delete this.expandedElement2;
    this.testsDataCompleted = [];
    this.testsData = [];

    this.buildURL();
    this.paginator.firstPage();
  }

  public runSelectedTests(singleTest: any) {
    let calls = [];

    if (singleTest) {
      calls.push(
        this.runTest(
          singleTest.testSetId,
          singleTest.superheroId,
          singleTest.name
        )
          .then(res => res)
          .catch(err => err)
      );
    }
    for (let selected of this.selection.selected) {
      calls.push(
        this.runTest(selected.testSetId, selected.superheroId, selected.name)
          .then(res => res)
          .catch(err => err)
      );
    }

    // waits until all tests are executed before concluding
    Promise.all(calls)
      .then(val => {
        const testSetNumber = val.length;
        const errorsNumber = val.filter(runners => runners.res === false)
          .length;
        let messageType = "error";
        let messageContent = this.pageService.i18n("errors___runMultiTestSet");

        if (errorsNumber === 0) {
          messageType = "success";
          messageContent = this.pageService.i18n("success___runMultiTestSet");
        } else if (errorsNumber > 0 && errorsNumber < testSetNumber) {
          messageType = "warning";
          messageContent = `${this.pageService.i18n(
            "warnings___runMultiTestSet"
          )} ${errorsNumber}/${testSetNumber}`;
        }

        this._operations._showPopupRequestStatusMessage(
          messageType,
          [],
          `${messageContent}`
        );
      })
      .catch(err => {
        this._operations._showPopupRequestStatusMessage(
          "error",
          [],
          `${this.pageService.i18n("errors___runMultiTestSet")}`
        );

        // return "error";
      });
  }

  public runTest(testID, superheroID, testName, oneTest = false): any {
    let runURL = `${this.beURL}/testset/${testID}/execute/${superheroID}`;
    const body = {
      requestedBy: localStorage.getItem("logged-user")
    };
    return new Promise((resolve, reject) => {
      this.postHttpRequest(runURL, body).subscribe(
        res => resolve({ test: testName, res: true }),
        err => reject({ test: testName, res: false })
      );
    });
  }

  private getHeaders() {
    const headers = new HttpHeaders({
      "Content-Type": "application/json",
      'Cache-Control': 'no-cache',
      Authorization: "Bearer " + localStorage.token,
      IXS: localStorage.IXS_id
    });

    return { headers: headers };
  }

  public FormatData_ProgressBar_TestSets(data: any): any {
    let finalData = data ? data : {};

    let obj: any[] = [
      {
        option:
          this.pageService.i18n("application___passedOn") +
          finalData["totalPassed"] +
          this.pageService.i18n("application____tests"),
        description: "",
        value: this.pageService.i18n("application___passed"),
        next: 1,
        count: finalData["totalPassed"] || 0
      },
      {
        option:
          this.pageService.i18n("application___failedOn") +
          (finalData["totalTests"] - finalData["totalPassed"]) +
          this.pageService.i18n("application____tests"),
        description: "",
        value: this.pageService.i18n("application____failed"),
        next: 2,
        count: !isNaN(finalData["totalTests"])
          ? finalData["totalTests"] - finalData["totalPassed"]
          : 0
      },
      {
        option: this.pageService.i18n("application___notExecuted"),
        description: "",
        value: this.pageService.i18n("application___notExecuted"),
        next: 3,
        count: !isNaN(finalData["totalTests"])
          ? finalData["totalTests"] == 0
            ? 1
            : 0
          : 1
      }
      // apply only when status running is added
      /*, {
        option: 'running',
        description: '',
        value: 'still running',
        next: r,
        count: finalData['totalRunning'] || 0
      }*/
    ];
    return obj;
  }

  // ***** FUNCTIONS DEFAULT FROM MATERIAL TABLE *****//

  /** The label for the checkbox on the passed row */
  checkboxLabel(row?): string {
    if (!row) {
      return `${this.isAllSelected() ? "select" : "deselect"} all`;
    }
    return `${
      this.selection.isSelected(row) ? "deselect" : "select"
      } row ${row.position + 1}`;
  }

  /** Whether the number of selected elements matches the total number of rows. */
  isAllSelected() {
    const numSelected = this.selection.selected.length;
    // const numRows = this.dataSource ? this.dataSource.data.length : 0;
    const numRows = this.dataSource ? this.dataSource.data.length : 0;
    return numSelected == numRows;
  }

  /** Selects all rows if they are not all selected; otherwise clear selection. */
  masterToggle() {
    this.isAllSelected()
      ? this.selection.clear()
      : this.dataSource.data.forEach(row => this.selection.select(row));
  }
  toggleFloat(element, toggleField) {
    //  let t0 = performance.now();
    switch (toggleField) {
      case "expand":
        // this.testsData.map(testSet => {
        //   if (testSet.testSetId !== element.testSetId) {
        //     testSet["expand"] = false;
        //     testSet["tests"] = [];
        //   } else {
        //     const testCompleteData = this.testsDataCompleted.find(
        //       testComplete => testComplete.testSetId === element.testSetId
        //     );
        //     // console.log("DENTRO ", testCompleteData);
        //     testSet["tests"] = testCompleteData.tests;
        //   }
        // });
        this.testsData.map(testSet => {
          if (testSet.testSetId !== element.testSetId) {
            testSet['expand'] = false;
            testSet['tests'] = [];
          }
        });

        const testCompleteData = this.testsDataCompleted.find(
          testComplete => testComplete.testSetId === element.testSetId
        );
        if (testCompleteData && testCompleteData.tests)
          element["tests"] = testCompleteData.tests;
        break;

      case "expandTest":
        const testSetID = element && element.id ? element.id.substr(0, element.id.indexOf("___")) : '';
        const testSet = this.testsData.find(
          testSets => testSets.testSetId == testSetID
        );
        if (testSet && testSet.tests && testSet.tests.length > 0) {
          testSet.tests.map(test => {
            if (test.id !== element.id) {
              test.expandTest = false;
            }
          });
        }
    }
    //  let t1 = performance.now();
    //  console.log("time ", t1 - t0);
    element[toggleField] = !element[toggleField];
  }

  public goAccountTab() {
    let acountButton_nav: JsonParams = this._global.getPageParameter(
      'entityInfoButtonAreasAccount_navBar'
    );
    let acountButton: JsonParams = this._global.getPageParameter(
      "entityInfoButtonAreasAccount"
    );

    let exerciseButton_nav: JsonParams = this._global.getPageParameter(
      "entityInfoButtonAreasExercises_navBar"
    );
    let exerciseButton: JsonParams = this._global.getPageParameter(
      "entityInfoButtonAreasExercises"
    );

    acountButton_nav.class = "active entityInfoButton";
    acountButton.class = "active entityInfoButton";
    exerciseButton_nav.class = "entityInfoButton";
    exerciseButton.class = "entityInfoButton";

    const accountTab: JsonParams = this._global.getPageParameter(
      "superheroPropertiesViewTabAccount"
    );

    //       this.paginator.nextPage
    // this.paginator.previousPage
    // this.paginator.lastPage
    // this.paginator.firstPage
    // this.paginator.pageSize

    accountTab.newEvent().next([
      {
        key: "selectTab",
        param: accountTab
      }
    ]);
  }

  public paginationListener(event: any) {
    switch (event.name) {
      case "paginator-first-page":
        this.paginator.firstPage();
        break;
      case "paginator-previous-page":
        this.paginator.previousPage();
        break;
      case "paginator-next-page":
        this.paginator.nextPage();
        break;
      case "paginator-last-page":
        this.paginator.lastPage();
        break;
      case "paginator-itemsPerPage":
        this.pageSize = parseInt(event.value);
        this.paginator._changePageSize(event.value);
        break;
    }
  }
}
