// import { ModalService } from './../../foundations-webct2-palette/components/modalComponent/modal.service';
import { ModalService} from '../../foundations-webct-robot/robot/services/modal.service';

import { CustomEventManagerService } from '../../customComponents/custom-event-manager.service';
import { TestService } from '../test.service';
import { Component, Input, OnInit, OnDestroy, AfterViewInit } from '@angular/core';
import { PageService } from '../../foundations-webct-robot/robot/pageComponent/page.service';


@Component({
  selector: 'tests-manager',
  templateUrl: './main-test.component.html',
  styleUrls: ['./main-test.component.css']
})
export class MainTestComponent implements OnInit, OnDestroy, AfterViewInit {



  /* @Input()  */public program;

  public editMode: any;
  public filterArgument;

  public viewModeEdit:string="VER";


  public programVariables;
  public intentVariables = [];
  public currentIntent;
  public autoCompleteLists;

  public currentIntentVariables;
  public questions:any;
  public answers:any;


  private genericActionSubscription;
  private newIntentCreatedSubscription;

  public warningMessage: any;
  public warningMessageA: any;


  constructor(
    public pageService: PageService,
    public testsService: TestService,
    private eventManager: CustomEventManagerService,
    private modalService: ModalService) {
  }

  public ngOnInit() {

    this.editMode = "VIEW";

    this.warningMessage="OK";
    this.warningMessageA="OK";

    //load program to model
    //this.testsService.setProgram(this.program);
/*     this.testsService.reloadProgram().then(response => {
      this.program = this.testsService.program;
    }); */

    this.genericActionSubscription = this.eventManager.genericAction.subscribe(
      (action) => {
        if (action == "INTENT_REMOVE_CONFIRMATION") {
          this.removeCurrentIntent();
        } else if (action == "INTENT_REMOVE_CANCELATION") {
          this.modalService.closeModal();
        } else if (action == "INTENT_CREATE_CANCELATION") {
          this.modalService.closeModal();
        }
      }
    );

    this.newIntentCreatedSubscription = this.eventManager.newIntentCreated.subscribe(
      () => {
        let last = this.testsService.getProgramIntents().length - 1;
        this.currentIntent = this.testsService.getProgramIntents()[last];
      }
    );

  }

  ngAfterViewInit(): void {

    /*     this.testsService.reloadProgram().then(response => {
      this.program = this.testsService.program;
      if (this.program.lessons && this.program.intents.length > 0) {
        this.openIntent(0);
      }

    }); */

    this.testsService.reloadProgram().then(
      response => {
        if (this.testsService.program.intents && this.testsService.program.intents.length > 0) {
          this.currentIntent = this.testsService.program.intents[0];
        }
      }
    );


  }
  cancelEdit() {
    this.warningMessage="OK";
    this.warningMessageA="OK";
    this.setEditMode("VIEW");
  }


  private removeCurrentIntent() {

    let currentIndex = this.testsService.getIntentIndex(this.currentIntent);
    this.testsService.removeIntent(this.currentIntent).then((resp) => {
      this.testsService.reloadProgram().then(response => {
        this.modalService.closeModal();
        if (this.testsService.getProgramIntents().length > 0) {
          this.currentIntent = this.testsService.getProgramIntents()[Math.min(currentIndex, this.testsService.getProgramIntents().length - 1)];
        }

      });

    });

  }



  /**
   *
   * @param index @deprecated
   */
/*   public openIntent(index: number) {
    this.currentIntent = this.program.lessons[index];
  } */

  public intentSelected(data) {
    this.currentIntent = data[0];
  }

  public setEditMode(mode) {
    this.editMode = mode;
  }

  public viewMode(mode){
    console.log("viewMode;" + mode)
   //if (this.newIntentCreatedSubscription) {
      this.newIntentCreatedSubscription.unsubscribe();
    //}

    if (this.genericActionSubscription) {
      this.genericActionSubscription.unsubscribe();
    }
  
    this.viewModeEdit=mode;

    this.setEditMode("VIEW");
  }

 public questionsUpdate(data){
  this.questions=data
 }

 public answersUpdate(data){
   this.answers = data;
 }


  public save(){
    this.warningMessage="OK";
    this.warningMessageA="OK";

    console.log("public usedVariables;" + JSON.stringify(this.questions))
    console.log("public answers;" + JSON.stringify(this.answers))

    var arrQ = this.testsService.cleanEmptyLines(this.questions);
    console.log("public arrQ: " + JSON.stringify(arrQ))

    var arrA = this.testsService.cleanEmptyLines(this.answers);
    console.log("arrA: " + JSON.stringify(arrA))

    if(arrQ.length === arrA.length){

      var arr = [];

      for(let i=0; i<arrQ.length; i++){
        
        var o={"q":"", "a":""};
        var obj = arrQ[i][0];
        o.q=obj.value;
        o.a="";
        arr.push(o);

        if(arrA){
          if (arrA[i]){
            if (arrA[i].length > 0){
              var obj = arrA[i][0];
              o.a=obj.value;
            }
          }
        }

      }
      
      this.testsService.addDialogsStep(this.currentIntent.name, arr);
      this.setEditMode("VIEW");
    }else if(arrQ.length > arrA.length){
      //alert("The number of dialogs between User and Virtual assistant must be the same! \nYou have more User's dialogs than Virtual assistants answers.");
      this.warningMessage="You have more User's dialogs than Virtual assistants answers. Must be the same.";
    } else{
      //alert("The number of dialogs between User and Virtual assistant must be the same! \nYou have more Virtual assistants answers than User's dialogs!");
      this.warningMessageA="You have more Virtual assistants answers than User's dialogs. Must be the same.";
    } 
  }

  ngOnDestroy(): void {
    if (this.newIntentCreatedSubscription) {
      this.newIntentCreatedSubscription.unsubscribe();
    }

    if (this.genericActionSubscription) {
      this.genericActionSubscription.unsubscribe();
    }
  }
}
