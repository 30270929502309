import { Component, Input, OnInit, DoCheck, KeyValueDiffers } from '@angular/core';
import { FormControl } from '@angular/forms';

import { JsonParams } from './../../../foundations-webct-robot/robot/classes/jsonParams.class';
import { Utils } from './../../../foundations-webct-robot/robot/utils/utils.service';
import { GlobalVarsService } from './../../../foundations-webct-robot/robot/utils/global-vars.service';
import { PageService } from './../../../foundations-webct-robot/robot/pageComponent/page.service';

@Component({
  selector: 'selected-items-container',
  templateUrl: 'selected-items-container.component.html'
})

export class SelectedItemsContainerComponent implements OnInit, DoCheck {

  @Input() public inputParameters: JsonParams;
  @Input() public control: FormControl;

  public differ: any;
  public valueControl: string = null;

  private param: JsonParams;

  constructor(
    private _differs: KeyValueDiffers,
    private _utils: Utils,
    private _globalVars: GlobalVarsService,
    private _pageService: PageService
  ) {
    this.differ = _differs.find({}).create();
  }

  public ngOnInit() {

    if (!this.control)
      this.control = new FormControl();

    this.inputParameters.valueList = [];
    this.param = this._utils.findObjectInArray(this._globalVars.getPageParametersAsArray(), 'removeValue');
  }

  public ngDoCheck() {
    try {
      const changes = this.differ.diff(this.inputParameters.value);
      if (changes) {
        if (this.param.subType === 'card-container-radio') {
          this.inputParameters.valueList = [];
        }
        if (Object.keys(this.inputParameters.value).length > 0) {
          this.inputParameters.valueList.push(this.inputParameters.value);
        }
      }
      this.setValueControl();
    } catch (err) {
      // console.error('this.inputParameters.value => ', this.inputParameters.value, ' = ', typeof this.inputParameters.value)
    }
  }

  public sliceUniqueSuffix(item, pathToValue): string {
    let rtn = item[pathToValue];
    Object.keys(item).forEach((param) => {
      const genericPathToValue = param.replace(this._utils.uniqIdRegex, '');
      if (pathToValue === genericPathToValue)
        rtn = item[param];
    });
    return rtn;
  }

  public removeValue(idx) {

    if (this.param.subType === 'card-container-radio') {

      this.param.value = null;
      this.inputParameters.value = {};
      this.inputParameters.valueList = [];

    } else if (this.param.subType === 'card-container-checkBox') {

      this.param.value.splice(idx, 1);
      this.inputParameters.valueList.splice(idx, 1);
    }
    this.setValueControl();
  }

  public setValueControl() {
    this.valueControl = this.inputParameters.valueList.length > 0 ? '*'.repeat(this.inputParameters.valueList.length) : null;

    this.control.markAsUntouched();
    this.control.markAsPristine();
    this.control.updateValueAndValidity();
  }

}
