import { JsonParams } from './jsonParams.class';

export class JsonGroups {
    public header: JsonParams = undefined;
    public navigation: JsonParams = undefined;
    public sidebar: JsonParams = undefined;
    public menus: JsonParams = undefined;
    public tabs: JsonParams = undefined;
    public wizard: JsonParams = undefined;
    public urlResource: JsonParams = undefined;
    public axis: JsonParams = undefined;
    public rowsConfig: JsonParams = undefined;
    public columns: JsonParams = undefined;
    public rows: JsonParams = undefined;
    public details: JsonParams = undefined;
    public operations: JsonParams = undefined;
    public components: JsonParams = undefined;
    public buttons: JsonParams = undefined;
    public seo: JsonParams = undefined;
}
