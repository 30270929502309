import { Injectable } from '@angular/core';
import { Observable } from 'rxjs/Rx';
import { Utils } from './utils/utils.service';
import { ApiService } from './services/api.service';
import { GlobalVarsService } from './utils/global-vars.service';
import { JsonParams } from './classes/jsonParams.class';
import { JsonGroups } from './classes/jsonGroups.class';
import { Validator, ValidatorDefs } from './classes/validator.class';

import * as moment from 'moment/moment';

@Injectable()
export class RobotEngineModel {

  public view: JsonParams;
  public paramsToValidate: JsonParams[] = [];

  private _rolesAsObj: Object = {};
  private _asRoles = false;

  // dynamicMenu CTG
  // dynamicMenuOperation = 'dynamicMenu';
  // dynamicMenuOperationAdapter = 'dynamicMenuAdapter';
  // dynamicMenuOperationKey = 'dynamicMenuParamKey';
  // headerDynamicMenuInUse: boolean = false;
  // headerDynamicMenuAdapter: string;
  // headerDynamicMenuParamKey: string;
  // headerDynamicMenuParamValue: string;

  constructor(
    private _utils: Utils,
    private _globalVars: GlobalVarsService,
    private _apiService: ApiService) {

    this._setUserRoles();
  }

  public loadPageParams(json, startLevelId?: string, cloneAs?: string): Promise<JsonParams> {

    return new Promise((resolve) => {

      if (!json) resolve(undefined);
      if (!this.validateRoles(json.role)) resolve(undefined);

      if (!startLevelId) {
        let existingGroup: string[];
        do {
          startLevelId = 'ROBOT-' + this._utils.guid(4, '');
          existingGroup = this._globalVars.getPageParametersIdsByGroup(startLevelId);
        } while (existingGroup);
        console.error(`RobotEngineModel.loadPageParams(): Page parameters are being created without GroupID (WebCT AutoID: ${startLevelId}).`);
      }
      // json = this._preventGroupParametersDetails(json);

      let object: JsonParams = new JsonParams();

      object.mockJson = json;
      object.key = json.key;
      object.id = json.id !== undefined ? json.id + (cloneAs ? cloneAs : '') : undefined;
      object.internalId = this._utils.guid(2, '');
      object.text = json.text;
      object.title = json.title;
      object.description = json.description;
      object.value = json.value;
      object.originalValue = json.originalValue || json.value;
      object.valueList = json.valueList;
      object.refData = json.refData;
      object.icon = json.icon;
      object.html = json.html;
      object.type = json.type;
      object.subType = json.subType;
      object.class = json.class;
      object.style = json.style;
      object.navigateTo = json.navigateTo;
      object.showComponentById = json.showComponentById;
      object.pathToValue = json.pathToValue;
      object.templateOfValue = json.templateOfValue;
      object.pathToValueList = json.pathToValueList;
      object.templateOfValueList = json.templateOfValueList;
      object.mockToLoad = json.mockToLoad;
      object.hidden = json.hidden;
      object.disabled = json.disabled;
      object.readonly = json.readonly;
      object.checked = json.checked;
      object.filter = json.filter;
      object.filterTemplate = json.filterTemplate;
      object.apiFieldName = json.apiFieldName;
      object.placeholder = json.placeholder;
      object.position = json.position;
      object.role = json.role;
      object.mappingId = json.mappingId;
      object.sort = typeof json.sort == 'string' ? json.sort.toUpperCase() : json.sort;
      object.sortTemplate = json.sortTemplate;
      object.size = json.size;
      object.min = json.min;
      object.max = json.max;
      object.lazyLoading = this._evaluateJsonAttribute(json.lazyLoading, json.lazyLoading || false);
      object.global = json.global || false;
      object.dynamicProps = json.dynamicProps;

      if (typeof json.filter == 'object')
        this.loadPageParams(json.filter, startLevelId, cloneAs).then(res => object.filter = res);

      if (object.key == 'mappingProps') {
        object.validator = json.validator;
      } else {
        object.validator = this.loadParameterValidators(json.validator);
        if (json.validator)
          this.paramsToValidate.push(object);
      }
      object.parameters = [];
      this._findIncludesInJson(json.parameters, startLevelId).then(totalParams => {
        this._loadJsonParameters(totalParams, startLevelId, cloneAs).then(params => {
          object.parameters = params;
          object.groups = new JsonGroups();
          this._loadJsonGroupParameters(object.groups, json, startLevelId, cloneAs).then(res => {
            if (object.id || object.dynamicProps) {
              if (!object.id)
                object.id = object.internalId;
              this._globalVars.setPageParameters(object, startLevelId);
            }
            resolve(object);
          });
        });
      });
    });
  }

  public loadParameterValidators(validators: Object): Validator {

    if (!validators || typeof validators != 'object' || Object.keys(validators).length == 0)
      return new Validator();

    let fnName: string[] = [];
    let fnMessage: string[] = [];

    if (validators['fn']) {
      for (let i in validators['fn']) {
        fnName.push(validators['fn'][i][0]);
        fnMessage.push(validators['fn'][i][1]);
      }
    }

    return new Validator(
      validators['required'] && typeof validators['required'][0] == 'boolean' ? new ValidatorDefs<boolean, string>(validators['required'][0], validators['required'][1]) : null,
      validators['minlength'] && validators['minlength'][0] ? new ValidatorDefs<number, string>(validators['minlength'][0], validators['minlength'][1]) : null,
      validators['maxlength'] && validators['maxlength'][0] ? new ValidatorDefs<number, string>(validators['maxlength'][0], validators['maxlength'][1]) : null,
      validators['regex'] && validators['regex'][0] ? new ValidatorDefs<string, string>(validators['regex'][0], validators['regex'][1]) : null,
      fnName.length > 0 && fnMessage.length > 0 ? new ValidatorDefs<string[], string[]>(fnName, fnMessage) : null,
      validators['autocomplete'] ? new ValidatorDefs<Object, string>(validators['autocomplete'][0], validators['autocomplete'][1]) : null,
      validators['unique'] ? new ValidatorDefs<Object, string>(validators['unique'][0], validators['unique'][1]) : null
    );
  }

  public updatePageWithData(params: JsonParams, data: any = {}, dataParameters: Object = null, contextData: Object = null, forkJoin: number = -1, forkJoinData: Object = null) {

    if (!params) {
      console.error('Update page parameters not found.');
      return;
    }

    if (typeof data == 'object' || typeof data == 'string') {

      let contextInfoSequence = [data, forkJoinData, contextData, this._apiService.getApiHistory()];

      this.evaluateParamValues(params, contextInfoSequence, forkJoin);

      let updateGroupNames = {
        columns: 'rows',
        axis: 'rows',
        rowsConfig: 'rows',
        details: 'details'
      };

      for (let i in updateGroupNames) {
        if (!params.groups[i])
          continue;

        let originalValue: any;
        let formatedValue: any;
        let currParameter: JsonParams;
        let originalDataToGroup: Object[] = [];
        let formatedDataToGroup: Object[] = [];
        let updateGroup: boolean = updateGroupNames[i] != i;

        if (updateGroup) {
          if (!params.groups[updateGroupNames[i]].value || Object.prototype.toString.call(params.groups[updateGroupNames[i]].value[0]) !== '[object Array]') {
            params.groups[updateGroupNames[i]].value = [];
            params.groups[updateGroupNames[i]].originalValue = [];
          }
          if (forkJoin >= 0) {
            params.groups[updateGroupNames[i]].value.push([]);
            params.groups[updateGroupNames[i]].originalValue.push([]);
          }
        }

        for (let p in params.groups[i].parameters) {
          currParameter = params.groups[i].parameters[p]; // Ex: Cada coluna da tabela

          if (updateGroup)
            currParameter.idPrefix = i;
          if (currParameter.type == 'groupParameters' || currParameter.type == 'wrapper') {
            let paramsArray = currParameter.type == 'groupParameters' ? currParameter.parameters : currParameter.groups.details.parameters;
            for (let gp in paramsArray) {
              if (!paramsArray[gp].groups.urlResource)
                this.evaluateParamValues(paramsArray[gp], contextInfoSequence, forkJoin);
            }
          } else {
            if (updateGroup) {
              if (currParameter.pathToValue)
                currParameter.value = [];
              let listOfData: any[] = Object.prototype.toString.call(data) === '[object Array]' ? data : [data];
              for (let l in listOfData) {
                let returnedObject = {};
                if (!currParameter.groups.urlResource)
                  returnedObject = this.evaluateParamValues(currParameter, [listOfData[l], forkJoinData, contextData], forkJoin, updateGroup);

                if (currParameter.oid) {
                  originalDataToGroup[l] = originalDataToGroup[l] || {};
                  formatedDataToGroup[l] = formatedDataToGroup[l] || {};
                  originalDataToGroup[l][currParameter.oid] = returnedObject ? returnedObject['original'] : null;
                  formatedDataToGroup[l][currParameter.oid] = returnedObject ? returnedObject['formated'] : null;
                }

                // Guarda as configurações de cada Row, caso exista
                if (listOfData[l] && listOfData[l][updateGroupNames[i]])
                  formatedDataToGroup[l]['wct_' + updateGroupNames[i]] = listOfData[l][updateGroupNames[i]];

                if (currParameter.pathToValue && returnedObject['original'])
                  currParameter.value.push(returnedObject['original'].toString());
              }
              if (currParameter.pathToValue && currParameter.value.length == 0)
                currParameter.value = null;
            } else if (!currParameter.groups.urlResource)
              this.evaluateParamValues(currParameter, contextInfoSequence, forkJoin, (updateGroupNames[i] != i));
          }
        }
        if (formatedDataToGroup && Object.keys(formatedDataToGroup).length > 0) {
          if (forkJoin >= 0) {
            params.groups[updateGroupNames[i]].value[forkJoin] = formatedDataToGroup;
            params.groups[updateGroupNames[i]].originalValue[forkJoin] = originalDataToGroup;
          } else {
            params.groups[updateGroupNames[i]].value = formatedDataToGroup;
            params.groups[updateGroupNames[i]].originalValue = originalDataToGroup;
          }
        }
      }
      this.updateDynamicPropsOnPageParameters(data);
    }
    this.view = params;
    return;
  }

  public evaluateParamValues(parameter: JsonParams, data: Object[], forkJoin: number = -1, returnAsObject: boolean = false) {

    if (!parameter || (parameter.pathToValue === undefined && parameter.pathToValueList === undefined && parameter.value === undefined))
      return;

    if (parameter.pathToValueList !== undefined) {
      let tempValueList: any;
      for (let i in data) {
        if (!data[i])
          continue;
        tempValueList = this._utils.getValueFromDataForThisKey(data[i], parameter.pathToValueList);
        if (tempValueList) break;
      }
      parameter.valueList = forkJoin <= 0 ? [] : (parameter.valueList || []);
      if (tempValueList !== undefined)
        parameter.valueList = parameter.valueList.concat(tempValueList);

      parameter.valueList = this._setListTemplate(parameter.templateOfValueList, parameter.valueList);
    }

    let tempOriginalValue: any;
    let tempFormatedValue: any;
    if (parameter.pathToValue !== undefined) {
      for (let i in data) {
        if (!data[i])
          continue;
        tempOriginalValue = this._utils.getValueFromDataForThisKey(data[i], parameter.pathToValue);
        if (tempOriginalValue !== undefined) break;
      }
      tempOriginalValue = tempOriginalValue || parameter.originalValue;
    } else {
      tempOriginalValue = parameter.originalValue || parameter.value;
    }

    tempFormatedValue = this._utils.formatValues(parameter, tempOriginalValue);

    if (returnAsObject) {
      return {
        original: tempOriginalValue,
        formated: tempFormatedValue
      }
    }

    parameter.originalValue = tempOriginalValue;
    parameter.value = tempFormatedValue;

    if (parameter.global && parameter.originalValue) {
      let globalName = typeof parameter.global == 'string' ? parameter.global : parameter.id;
      this._globalVars.setGlobalVars(globalName, parameter.originalValue, true);
    }

    return;
  }

  public updateDynamicPropsOnPageParameters(data: Object = null) {

    let parameters = this._globalVars.getPageParametersAsArray();

    this._globalVars.setDynamicPropsData(this._utils.arrToObj(parameters));
    if (data)
      this._globalVars.setDynamicPropsData(data);

    let dynamicPropsData: Object = this._globalVars.getDynamicPropsData();
    for (let param of parameters)
      this.changeParameterByDynamicProps(param, dynamicPropsData);
  }

  public findDynamicPropsDependencies(id: string, dataRecs: Object = {}, suffix?: string) {
    if (!id) return;

    let pageParams = this._globalVars.getPageParametersAsArray();

    this._globalVars.setDynamicPropsData(this._utils.arrToObj(pageParams));
    let dependencies = pageParams.filter(obj => {

      if (obj.dynamicProps && JSON.stringify(obj.dynamicProps).match(new RegExp('({){2}([^{}]?)+(' + id.replace(this._utils.uniqIdRegex, '') + ')+([^{}]?)+(}){2}'))) {
        if (suffix && obj.id.indexOf(suffix) >= 0)
          return obj;
        else if (!suffix)
          return obj;
      }

    });
    for (let param of dependencies) {
      this.changeParameterByDynamicProps(param, dataRecs);
    }
  }

  public changeParameterByDynamicProps(param: JsonParams, data: Object) {
    if (!param.dynamicProps)
      return;

    this._globalVars.setDynamicPropsData(data);

    let dynamicPropsData: Object = this._globalVars.getDynamicPropsData();
    let idSuffix: string = param.id.match(this._utils.uniqIdRegex) ? param.id.match(this._utils.uniqIdRegex)[0] : null;

    for (let i in param.dynamicProps) {
      if (!param.dynamicProps[i])
        continue;

      let isObject: boolean = typeof param.dynamicProps[i] == 'object';
      let evalStr = isObject ? JSON.stringify(param.dynamicProps[i]) : param.dynamicProps[i];

      evalStr = this._utils.prepareStringForEval(this._utils.replaceTagVars(evalStr, dynamicPropsData, idSuffix));

      if (isObject)
        evalStr = JSON.parse(evalStr);

      try {
        let evalResult = eval(evalStr);

        if (i == 'log') {
          console.log(`${evalStr} --> ${evalResult}`);
          continue;
        }

        if (i == 'lazyLoading' && param[i] === false && evalResult === false && this._globalVars.getDynamiPropsHistory(param.id) != evalStr) {
          this._utils.cleanApiGetHistory(param);
          param.refresh();
        } else if (Object.keys(new Validator()).indexOf(`_${i}`) >= 0 && param.validator && param.validator[i]) {
          param.validator[i].value = evalResult;
        } else {
          param.setPropertyValue(i, evalResult);
        }

        this._globalVars.setDynamiPropsHistory(param.id, evalStr);

      } catch (e) {
        if (i == 'log')
          console.error('Error trying to eval(' + evalStr + ') on changeParameterByDynamicProps()', e);
      }
    }
  }

  public validateRoles(roles: string): boolean {
    if (!roles)
      return true;

    this._setUserRoles();

    let asDecorator = this._utils.findDecorator(roles);
    if (asDecorator)
      return !!eval(this._utils.prepareStringForEval(this._utils.replaceTagVars(asDecorator, { user_roles: this._rolesAsObj })));

    if (roles.indexOf('{{user_roles}}') >= 0) {
      try {
        return eval(this._utils.replaceTagVars(roles, { user_roles: this._rolesAsObj }));
      } catch (e) {
        console.error('Error trying to eval() on validateRoles()', e);
        return false;
      }
    }
    let user_roles = this._globalVars.getLocalStorage('user_roles') || '';
    if (user_roles && user_roles.indexOf('"' + roles + '"') >= 0) {
      // Valor da Role no MOCK deverá ser a string exacta do nome da mesma
      return true;
    }
    return false;
  }

  private _loadJsonParameters(parameters: any[], startLevelId?: string, cloneAs?: string): Promise<JsonParams[]> {

    return new Promise((resolve) => {
      if (parameters && parameters.length > 0) {
        let promises = [];
        for (let i in parameters)
          promises.push(this.loadPageParams(parameters[i], startLevelId, cloneAs));

        if (promises.length > 0)
          Promise.all(promises).then(res => resolve(res.filter(obj => obj instanceof JsonParams)));
        else
          resolve([]);
      } else
        resolve([]);
    });
  }

  private _loadJsonGroupParameters(group: JsonGroups, json, startLevelId?: string, cloneAs?: string): Promise<boolean> {

    return new Promise((resolve) => {

      let groups = new JsonGroups();
      let myParameters: JsonParams;

      let arrayPromises = [];
      let objectPromises = [];
      for (let i in groups) {
        if (!json[i])
          continue;

        group[i] = new JsonParams();
        arrayPromises.push(this._findIncludesInJson(json[i], startLevelId));
      }

      if (arrayPromises.length > 0) {
        Promise.all(arrayPromises).then(arrayRes => {
          let paramsPromise = [];
          for (let i in arrayRes) {
            if (this._utils.isObjectType(arrayRes[i], 'Array'))
              paramsPromise.push(this._loadJsonParameters(arrayRes[i], startLevelId, cloneAs));
            else
              paramsPromise.push(this.loadPageParams(arrayRes[i], startLevelId, cloneAs));
          }
          Promise.all(paramsPromise).then(paramsRes => {
            let count = 0;
            for (let i in groups) {
              if (!json[i])
                continue;

              if (this._utils.isObjectType(paramsRes[count], 'Array'))
                group[i].parameters = paramsRes[count];
              else
                group[i] = paramsRes[count];

              count++;
            }
            resolve(true);
          });
        });
      } else
        resolve(true);
    });
  }

  private _findIncludesInJson(json: any | any[], startLevelId: string = null): Promise<any[]> {

    return new Promise((resolve) => {
      if (!json)
        resolve(json);
      else {
        let returnAsArray: boolean = this._utils.isObjectType(json, 'Array');
        let includeFiles: any[] = returnAsArray ? json.filter(obj => obj.include && this.validateRoles(obj.role)) : json.include ? [json] : [];

        if (includeFiles.length > 0) {
          let myJson: any[] = returnAsArray ? json : [json];
          let forkJoinRequests: Observable<any>[] = [];
          for (let i in includeFiles)
            forkJoinRequests.push(this._utils.GetAll(this._utils.getJson(includeFiles[i].include)).catch(res => Observable.of(null)));

          Observable.forkJoin(forkJoinRequests)
            .subscribe(response => {
              let finalJson: any[] = [];
              let responsePos = 0;
              for (let i in myJson) {

                if (myJson[i].include === undefined) {
                  finalJson.push(myJson[i]);
                  continue;
                } else if (!this.validateRoles(myJson[i].role))
                  continue;

                let positionResponse = this._utils.cloneObject(response[responsePos]);
                if (positionResponse !== null && positionResponse['body'] === undefined) {

                  if (myJson[i].id)
                    this._globalVars.setPageParameters(new JsonParams(myJson[i].id, response), startLevelId);
                  if (!this._utils.isObjectType(positionResponse, 'Array'))
                    positionResponse = [positionResponse];
                  if (myJson[i].max !== undefined)
                    positionResponse.splice(myJson[i].max);

                  finalJson = finalJson.concat(positionResponse);
                }
                responsePos++;
              }
              resolve(returnAsArray ? finalJson : finalJson[0]);
            });
        } else
          resolve(json);
      }
    });
  }

  private _setListTemplate(template: string, valueList: any[] = []) {

    if (!template || typeof valueList[0] != 'object')
      return valueList;

    let parsedTemplate = JSON.parse(template);
    let formatedList: any[] = [];

    for (let l in valueList) {
      let formated = true;
      for (let t in parsedTemplate) {
        if (valueList[l][t] === undefined) {
          formated = false;
          break;
        }
      }
      formatedList.push(formated ? valueList[l] : JSON.parse(this._utils.replaceTagVars(template, valueList[l]).replace(this._utils.mustacheReg, '')));
    }

    return formatedList;
  }

  private _setUserRoles() {

    if (this._asRoles || !this._globalVars.getLocalStorage('user_roles'))
      return;

    this._rolesAsObj = JSON.parse(this._globalVars.getLocalStorage('user_roles')).reduce((result, item) => {
      result[item] = true;
      return result;
    }, {});
    this._asRoles = Object.keys(this._rolesAsObj).length > 0;
  }

  private _evaluateJsonAttribute(condition: any, value: any) {

    if (!condition || typeof condition !== 'string')
      return value;

    let hasDecorator = this._utils.findDecorator(condition);
    if (hasDecorator)
      return eval(this._utils.prepareStringForEval(this._utils.replaceTagVars(hasDecorator)));

    return value;
  }

  /**
   * Verifica se o objecto é do tipo "groupParameters" e, caso se verifique, troca a informação colocada nos "parameters" para os "details"
   * @param json corresponde à configuração vinda do mock
   */
  private _preventGroupParametersDetails(json: any): any {

    if (json.type != 'groupParameters' || !json.parameters || json.parameters.length == 0)
      return json;

    json.details = this._utils.cloneJsonParse(json.parameters);
    delete json.parameters;
  }
}
