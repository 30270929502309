import { JsonParams } from './classes/jsonParams.class';
import { JsonGroups } from './classes/jsonGroups.class';

export class PageObjectsService {

  private _finalPageObject = '';
  private _imports: string[] = [];
  private _attributes: string[] = [];
  private _methods: string[] = [];
  private _info: string[] = [];
  private _componentConfigMap: any[];
  private _url: string;

  set url(value: string) {
    this._url = value;
  }

  public init(object: JsonParams) {

    // this._componentConfigMap = componentConfigMap ? <any>componentConfigMap.default : [];
    this._componentConfigMap = [];

    this._loadParameter(object);
    this._implodePageObject(object);
  }

  public _loadParameter(object: JsonParams): void {

    if (!object || !(object instanceof JsonParams))
      return;

    this._pageObjectByComponentType(object);

    for (let i in object) {
      if (!object[i])
        continue;

      if (object[i] instanceof JsonParams)
        this._loadParameter(object[i]);
      else if (object[i] instanceof JsonGroups)
        for (let g in object[i])
          this._loadParameter(object[i][g]);
      else if (object[i] instanceof Array && object[i].length > 0)
        for (let param of object[i])
          this._loadParameter(param);
    }
  }

  private _pageObjectByComponentType(object: JsonParams) {

    if (!object.id || Object.keys(this._componentConfigMap).length == 0)
      return;

    let objectConfig = this._componentConfigMap.find(obj => obj.componentType.indexOf(object.type) >= 0);
    if (!objectConfig)
      return;

    let attrName: string = <string>this._getVarNames(object, 'attrName');

    if (this._imports.findIndex(str => str.indexOf(objectConfig.importAs) > 0) < 0)
      this._imports.push(this._importStr(objectConfig.importAs, objectConfig.classFileName));

    this._attributes.push(this._newInstanceStr(objectConfig.importAs, attrName, object.id));
    this._methods.push(this._newMethodStr(attrName));

    this._info.push(this._newInfoObject(object.text, attrName))
  }

  private _getVarNames(object: JsonParams, attr?: 'className' | 'varName' | 'attrName'): VarNames | string {

    let obj = {
      className: object.id[0].toUpperCase() + object.id.slice(1),
      varName: object.text || object.id,
      attrName: object.id
      //attrName: object.type + '_' + object.id (TODO: Regex que apanhe todos os caracteres diferentes de a-zA-Z0-9 e coloca primeira letra seguinte a capital) */
    };

    return attr ? obj[attr] : obj;
  }

  private _implodePageObject(object: JsonParams) {
    this._finalPageObject = `
${this._imports.join('\n')}

export class ${this._getVarNames(object, 'className')} {

  constructor(){
    ${this._attributes.join('\n')}
  }

  ${this._methods.join('\n')}

  getInfo(){

    return {
      ${this._info.join(',')}
    }

  }


}`;

    console.log('**********************');
    console.log(this._finalPageObject);
    console.log('**********************');

  }

  private _importStr = (name: string, file: string) => `import * as ${name} from './classes/${file}.class.js';`;
  private _newInstanceStr = (className: string, attrName: string, elementId: string) => `this._${attrName} = new ${className}('${elementId}');`;
  private _newMethodStr = (attrName: string) => `${attrName}(){ return this._${attrName}; }`;
  private _newInfoObject = (label: string, attrName: string) => `"${label/* TODO: Necessita de ser traduzido pelo i18n */}": this._${attrName}.getValue()`;

}

interface VarNames {
  className: string;
  varName: string;
  attrName: string;
}
