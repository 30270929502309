import { Component, OnInit, Input, OnChanges } from '@angular/core';
import { GlobalVarsService } from './../../foundations-webct-robot/robot/utils/global-vars.service';
import { JsonParams } from './../../foundations-webct-robot/robot/classes/jsonParams.class';

@Component({
  selector: 'app-answers-preview',
  templateUrl: './answers-preview.component.html',
  styleUrls: ['./answers-preview.component.scss']
})
export class AnswersPreviewComponent implements OnInit, OnChanges {

  @Input() inputParameters;
  @Input() inputDataRecs;
  @Input() dataRecs;



  public valores: any;
  public sufix: string;

  constructor(
    private globalVars: GlobalVarsService,

  ) {
  }

  ngOnInit() {

    let sufix = this.inputParameters.id;
    console.log('this.sufix -----> ', this.sufix);
  }

  ngOnChanges() {


  }

  /**
   * getValue
   */
  public getValue = (prop: string) => {

    let id: string = this.inputParameters.id;
    let sufix: string = (id).substring(id.indexOf("$"));
    let jsonParam: JsonParams = this.globalVars.getPageParameter(this.inputParameters.value + sufix);


    if (!jsonParam) return;


    let arrayValues: any[] = jsonParam.value;

    if (!arrayValues) return;

    let position: number = (arrayValues.length - jsonParam.position) - 1;

    return arrayValues[position][prop];


  }

}
