import { CustomValidator } from "../customValidators.service";
import { ProgramsService } from "./../programs/programs.service";
import { CustomEventManagerService } from "./custom-event-manager.service";
import { NgModule } from "@angular/core";
import { FormsModule, ReactiveFormsModule } from "@angular/forms";

import { SharedModule } from "./../foundations-webct-robot/robot/shared.module";
import { CustomBridgeComponent } from "./custom-bridge.component";

import { CustomCSVUpload } from "./csvUpload/custom-csv-upload.component";
import { CustomTableOperationComponent } from "./customTableOperation/custom-table-operation.component";
import { MessageBodyEditorComponent } from "./text-area-variables/text-area-variables.component";
import { CustomPieChartComponent } from "./custom-pie-chart/custom-pie-chart.component";
import { SurveyHorizontalChartComponent } from "./custom-survey-horizontal-chart/custom-survey-horizontal-chart.component";
import { CustomTabValidator } from "./custom-tab-activator/custom-tab-activator.component";
import { CustomLinkOpenModalComponent } from "./custom-link-open-modal/custom-link-open-modal.component";
import { CustomLabelInlineEdition } from "./label-inline-edition/label-inline-edition.component";

import { BrowserAnimationsModule } from "@angular/platform-browser/animations"; // this is needed!

import { TrainingPhrasesEditorComponent } from "../intentManager/training-phrases-editor/training-phrases-editor.component";
import { TestAnswersEditorComponent } from "../testManager/test-answers-editor/test-answers-editor.component";
import { MockPhrasesEditorComponent } from "../testManager/mock-phrases-editor/mock-phrases-editor.component";
import { TestPhrasesEditorComponent } from "../testManager/test-phrases-editor/test-phrases-editor.component";

import { TestSetExplainTest } from "../testManager/testSet-ExplainTest/testSet-ExplainTest.component";
import { TestSetErrorExplainTest } from "../testManager/testSet-Error-ExplainTest/testSet-Error-ExplainTest.component";



import { IntentsService } from "../intentManager/intent.service";
import { CustomTestsTableComponent } from "./custom-tests-table/custom-tests-table.component";
import { MatTableModule } from "@angular/material/table";

import { TestService } from "../testManager/test.service";

// import { MatSortModule } from '@angular/material/sort';
import {
  MatButtonModule,
  MatCheckboxModule,
  MatProgressSpinnerModule,
  MatSortModule,
  MatPaginatorModule
} from "@angular/material";
import { CustomPaginationComponent } from "./custom-pagination/custom-pagination.component";

@NgModule({
  imports: [
    SharedModule,
    BrowserAnimationsModule,
    FormsModule,
    ReactiveFormsModule,
    MatTableModule,
    MatSortModule,
    MatPaginatorModule,
    MatButtonModule,
    MatCheckboxModule,
    MatProgressSpinnerModule
  ],
  declarations: [
    CustomBridgeComponent,
    CustomCSVUpload,
    CustomTableOperationComponent,
    MessageBodyEditorComponent,
    CustomPieChartComponent,
    SurveyHorizontalChartComponent,
    CustomTabValidator,
    CustomLinkOpenModalComponent,
    CustomLabelInlineEdition,
    TrainingPhrasesEditorComponent,
    CustomTestsTableComponent,
    CustomPaginationComponent,
    TestPhrasesEditorComponent,
    TestAnswersEditorComponent,
    TestSetExplainTest,
    TestSetErrorExplainTest,
    MockPhrasesEditorComponent
  ],
  exports: [
    CustomBridgeComponent,
    CustomCSVUpload,
    CustomTableOperationComponent,
    MessageBodyEditorComponent,
    CustomPieChartComponent,
    SurveyHorizontalChartComponent,
    CustomTabValidator,
    CustomLinkOpenModalComponent,
    CustomLabelInlineEdition,
    TrainingPhrasesEditorComponent,
    CustomTestsTableComponent,
    CustomPaginationComponent,
    MatPaginatorModule,
    TestPhrasesEditorComponent,
    TestAnswersEditorComponent,
    TestSetExplainTest,
    TestSetErrorExplainTest,
    MockPhrasesEditorComponent
  ],
  providers: [
    CustomEventManagerService,
    IntentsService,
    TestService,
    ProgramsService,
    CustomValidator
  ]
})
export class CustomComponentsModule {}
