import { Component, Input, OnInit, OnDestroy } from '@angular/core';
import { FormControl } from '@angular/forms';
import { Utils } from './../../../foundations-webct-robot/robot/utils/utils.service';
import { JsonParams } from './../../../foundations-webct-robot/robot/classes/jsonParams.class';
import { InputService } from './../../../foundations-webct-robot/robot/services/input.service';
import { ComponentsService } from './../../../foundations-webct-robot/robot/services/components.service';
import { RobotEngineModel } from './../../../foundations-webct-robot/robot/robotEngineModel';

@Component({
  selector: 'input-checkbox',
  templateUrl: 'checkbox.component.html',
  styles: [`
      p{ margin: 0; padding-left: 10px; }
      p.align-on-top{ padding-top: 6px; }
      p label{ display: inline; }
  `]
})

export class InputCheckboxComponent implements OnInit, OnDestroy {

  @Input() public inputParameters: JsonParams;
  @Input() public inputDataRecs: Object;
  @Input() public dataRecs: Object;
  @Input() public control: FormControl;

  public valueControl: string = null;
  public auxElementsOn = 10;
  public elementsIds = {
    check: '',
    search: ''
  };

  private _componentId: string;
  private _subscription: any;

  constructor(
    private _utils: Utils,
    private _components: ComponentsService,
    private _robot: RobotEngineModel,
    private _inputService: InputService) {
    this._componentId = 'CHECK-' + this._utils.guid(4, '');
  }

  public optionKey = (option: any) => option.id ? (typeof option.value != 'object' ? (option.value || option.id) : option.id) : option.key;

  public ngOnInit() {
    this._startComponent();
    this._subscription = this.inputParameters.observe().subscribe(param => this._startComponent());
  }

  public ngOnDestroy() {
    if (this._subscription)
      this._subscription.unsubscribe();
  }

  public updateParameters(option: any = null, setInitValue: boolean = false) {

    if (!setInitValue && (this.inputParameters.readonly || this.inputParameters.disabled))
      return;

    if (this.inputParameters.type == 'boolean') {
      this.inputParameters.value = !this.inputParameters.value;
    } else {

      this._preventEmptyValue();

      let compareValue = option.id ? (option.value || option.id) : option.key;
      if (this.inputParameters.value.indexOf(compareValue) >= 0) {
        this.inputParameters.value.splice(this.inputParameters.value.indexOf(compareValue), 1);
      } else {
        this.inputParameters.value.push(compareValue);
      }
    }

    // this._pageService.evaluatePageComponents();
    this._robot.findDynamicPropsDependencies(this.inputParameters.id, this.dataRecs);
    this._components.updatePageParameters(this.inputParameters.parameters);

    this.setValueControl();
    this.control.markAsTouched();
    this.control.markAsDirty();
    this.control.updateValueAndValidity();
  }

  public setValueControl() {
    if (this.inputParameters.type == 'boolean')
      this.valueControl = this.inputParameters.value || this.inputParameters.value === false ? this._utils.convertToString(this.inputParameters.value) : null;
    else
      this.valueControl = this.inputParameters.value.length > 0 ? '*'.repeat(this.inputParameters.value.length) : null;
  }

  public searchOnList(e, list) {
    for (let i in list)
      list[i].hidden = list[i].value.toLowerCase().indexOf(e.target.value.toLowerCase()) < 0;
  }

  private _startComponent() {
    this._setElementsIds();
    this.auxElementsOn = this.inputParameters.size >= 0 ? this.inputParameters.size : this.auxElementsOn;

    if (!this.control)
      this.control = new FormControl();

    if (this.inputParameters.type != 'boolean') {

      this._preventEmptyValue(true);
      this.setValueControl();

      this._inputService.loadValueListFromDataRecs(this.inputParameters, this.dataRecs, this.inputParameters.valueList, this._componentId);

      if (this.inputParameters.checked && this.inputParameters.valueList) {
        for (let i in this.inputParameters.valueList)
          this.updateParameters(this.inputParameters.valueList[i], true);
      }

    } else if (!this.inputParameters.readonly) {
      this.inputParameters.value = this._utils.evaluateBoolean(this.inputParameters.value);
      // this._pageService.evaluatePageComponents();
      this._robot.findDynamicPropsDependencies(this.inputParameters.id, this.dataRecs);
      this.setValueControl();
    }
    this.control.markAsUntouched();
    this.control.markAsPristine();
    this.control.updateValueAndValidity();
  }

  private _preventEmptyValue(initValue?: boolean) {
    this.inputParameters.value = this.inputParameters.value || [];
    this.inputParameters.value = typeof this.inputParameters.value == 'object' ? this.inputParameters.value : [this.inputParameters.value];

    if (initValue)
      this._setOriginalValue();
  }

  private _setElementsIds() {
    this.elementsIds.check = this._components.getHtmlId(this.inputParameters);
    this.elementsIds.search = this._components.getHtmlId(this.inputParameters, 'checkbox-search');
  }

  private _setOriginalValue() {
    if (this.inputParameters.value) {
      console.log('this.inputParameters.value ---> ', this._utils.cloneJsonParse(this.inputParameters.value));
      this.inputParameters.originalValue = this._utils.cloneJsonParse(this.inputParameters.value);
    }
  }
}
