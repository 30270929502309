import { Component, Input, OnInit } from '@angular/core';
import { FormControl } from '@angular/forms';
import { JsonParams } from './../../../foundations-webct-robot/robot/classes/jsonParams.class';

@Component({
  selector: 'slider-component',
  templateUrl: 'slider.component.html'
})

export class SliderComponent implements OnInit {

  // Parametros Mock do teu Componente
  @Input() public inputParameters: JsonParams;
  // Dados de contexto no qual o teu Componente é instanciado
  @Input() public inputDataRecs: Object[];
  // Formulário activo onde o teu Componente é instanciado
  @Input() public control: FormControl;

  public rangeSelectorCfg: RangeSelectorCfg;

  public ngOnInit () {

    if (!this.control)
      this.control = new FormControl();

    // defaults:
    this.rangeSelectorCfg = {
      range: this.inputParameters.value instanceof Array,
      minRange: this.inputParameters.min || 0,
      maxRange: this.inputParameters.max || 10,
      theme: 'bootstrap'
    };

    this.inputParameters.parameters.forEach((param) => {
      this.rangeSelectorCfg[param.key] = param.value;
    });

    if (this.rangeSelectorCfg.range) {
      this.inputParameters.value = this.inputParameters.value || [0, 0];
      this.refreshRangeValues(0);
      this.refreshRangeValues(1);
    } else {
      this.inputParameters.value = this.inputParameters.value || 0;
      this.refreshRangeValues();
    }
  }

  public refreshRangeValues (idx= -1) {

    let tmp = idx >= 0 ? this.inputParameters.value[idx] : this.inputParameters.value;

    tmp = this.findClosestValue(this.rangeSelectorCfg.minRange, this.rangeSelectorCfg.maxRange, this.rangeSelectorCfg.step, tmp);

    if (idx < 0) {
      if (tmp < this.rangeSelectorCfg.minRange) {
        tmp = this.rangeSelectorCfg.minRange;
      }
      if (tmp > this.rangeSelectorCfg.maxRange) {
        tmp = this.rangeSelectorCfg.maxRange;
      }
      this.inputParameters.value = tmp;
    } else {
      if (idx === 0 && (tmp < this.rangeSelectorCfg.minRange || tmp > this.inputParameters.value[1])) {
        tmp = this.rangeSelectorCfg.minRange;
      }

      if (idx === 1 && (tmp > this.rangeSelectorCfg.maxRange || tmp < this.inputParameters.value[0])) {
        tmp = this.rangeSelectorCfg.maxRange;
      }
      this.inputParameters.value[idx] = tmp;
      this.inputParameters.value = JSON.parse(JSON.stringify(this.inputParameters.value))
    }
  }

  private findClosestValue (min: number, max: number, step: number, valueToSearch: number): number {
    let arr: number[] = [];
    arr.push(min);

    do {
      arr.push(arr[arr.length - 1 ] + step);
    } while(arr[arr.length - 1] < max);

    let roundedVal: number = arr[0];

    arr.forEach((val) => {
      if (Math.abs(valueToSearch - val) < Math.abs(valueToSearch - roundedVal)) {
        roundedVal = val;
      }
    });
    return roundedVal;
  }
}

interface RangeSelectorCfg {
  range: boolean;
  minRange: number;
  maxRange: number;
  step?: number;
  style?: Object;
  animate?: boolean;
  orientation?: string;
  theme: string;
}
