import { CustomValidator } from '../../customValidators.service';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { WctNotificationService } from '../../foundations-webct2-palette/components/wct-notification/wct-notification.service';
import { TestService, Intent } from '../test.service';
// import { HttpClient, HttpHeaders } from '@angular/common/http';
// import { GlobalVarsService } from './../../foundations-webct-robot/robot/utils/global-vars.service';
// import { ModalService } from './../../foundations-webct2-palette/components/modalComponent/modal.service';
// import { ModalService} from './../../foundations-webct-robot/robot/services/modal.service';
declare var require: any;
import '../../../assets/mode-traningPhrases';
import '../../../assets/theme-traningPhrases';
import { Component, Input, AfterViewInit, ViewChild, OnInit, OnDestroy, EventEmitter, Output, ElementRef } from '@angular/core';
import { JsonParams, newEvent } from '../../foundations-webct-robot/robot/classes/jsonParams.class';
// import { PageService } from './../../foundations-webct-robot/robot/pageComponent/page.service';
import { AceEditorComponent } from 'ng2-ace-editor';
import { CustomEventManagerService } from '../../customComponents/custom-event-manager.service';

import 'brace';
import 'brace/ext/searchbox';
import 'brace/ext/language_tools';

var UndoManager = require('brace').acequire("ace/undomanager").UndoManager;


@Component({
  selector: 'app-mock-phrases-editor',
  templateUrl: 'mock-phrases-editor.component.html',
  styleUrls: ['mock-phrases-editor.component.css']
})
export class MockPhrasesEditorComponent implements OnInit, AfterViewInit, OnDestroy {


  @Input() public inputParameters: JsonParams;
  @Input() public dataRecs;


  private _filterArgument


  @Input() editMode: any;
  @Output() editModeChange = new EventEmitter<string>();

  @Input() usedVariables: any;
  @Output() usedVariablesChange = new EventEmitter<string>();
  @Output() questionsUpdate = new EventEmitter<string>();

  @Output() currentIntentChange = new EventEmitter<Intent>();

  @ViewChild('editor') aceEditor: AceEditorComponent;
  @Output() textChanged = new EventEmitter();

  //@Input() public currentIntent;

  private editor;

  public showInfo: boolean = true;

  public _currentIntent;


  //public newIntentNameValue: string = "";

  public newIntentDescriptionValue: string = "";
  private newVariableCreatedSubscription;

  private intentVariables;
  private programVariables;
  private newIntentCreatedSubscription;

  private variableColorsArray = [];

  /*   public intentName = "";
    public intentDescription = ""; */
  public isEditable = false;
  //public currentIntent;

  public intentNameForm: FormGroup;
  public duplicatedNameError: Boolean = false;

  value: string = "";
  objectToSend = [];

  options: any = {
    //theme: "ace/theme/eclipse",
    theme: "ace/theme/traningPhrases",
    mode: "ace/mode/traningPhrases",
    animatedScroll: false,
    enableBasicAutocompletion: true,
    showPrintMargin: false,
    tabSize: 2,
    indentedSoftWrap: true,
    //maxLines: Infinity,
    maxLines: 15,
    useSoftTabs: false,
    wrap: true,
    enableLiveAutocompletion: false
  };


  /*   httpOptions = {
      headers: new HttpHeaders({
        'Accept': 'application/json; charset=utf-8',
        'Authorization': 'Bearer ' + localStorage.token,
        'IXS': localStorage.IXS_id
      })
    }; */

  constructor(
    //private _modalService: ModalService,
    private eventManager: CustomEventManagerService,
    public testsService: TestService,
    //private globalVars: GlobalVarsService,
    private formBuilder: FormBuilder,
    public customValidator: CustomValidator,
    public notificationService: WctNotificationService) {
  }


  public ngOnInit() {

    this.intentNameForm = this.formBuilder.group({
      newIntentNameValue: ['', [Validators.required, this.customValidator.preventSpecialCharacters]]
    });



    this.editor = this.aceEditor.getEditor();

    this.newVariableCreatedSubscription = this.eventManager.newVariableCreated.subscribe(
      (teste) => {
        this._updateValue();
      }
    );


    if (!this.inputParameters) {
      this.inputParameters = new JsonParams();
      return;
    }

    this.value = this.inputParameters.value;

    this.value = "";
    this.inputParameters.checked = true;

  }

  ngOnDestroy(): void {
    if (this.newVariableCreatedSubscription) {
      this.newVariableCreatedSubscription.unsubscribe();
    }
  }


  @Input()
  set currentIntent(intent) {
    if (!intent)
      return;

    this.variableColorsArray = [];
    this._currentIntent = intent;
    if (intent) {
      this.setEditMode("VIEW");
      this.intentNameForm.get('newIntentNameValue').setValue(intent.name);
      this.newIntentDescriptionValue = intent.description;

      this.value = this.testsService.formatMockConversation(intent.step,"");
      //this.reloadVariablesAutocompleter([intent.parameters, []]);
      this.editor.setValue(this.value, -1);
      this.inputParameters.checked = true;
      this.editor.session.setUndoManager(new UndoManager());

      //this.reloadVariablesAutocompleter([this.testsService.program.variables, []]);

    }

  }

  @Input()
  set filterArgument(token) {
    this._filterArgument = token;
    /*     console.info("FILTER ARGUMENT: ", this._filterArgument);
        if(this._filterArgument){
    
          this.editor.execCommand("find");
          this.editor.searchBox.hide();
          this.editor.searchBox.searchInput.value = this._filterArgument;
          this.editor.searchBox.$onChange();
    
        } */
  }

  /*   @Input()
    set autoCompleteLists(vars){
      this.reloadVariablesAutocompleter(vars);
    } */

  /**
   * @deprecated ainda esta a ser usado no cancel
   * @param intent
   */
  private loadTranigPhrases(intent) {

    this._currentIntent = intent.intent;

    this.intentNameForm.get('newIntentNameValue').setValue(this._currentIntent.name);
    //this.newIntentDescriptionValue = this._currentIntent.description;

    console.log("loadTranigPhrases: "+ intent)

    this.value = this.testsService.formatMockConversation(this._currentIntent.step,"");
    //    this.reloadVariablesAutocompleter(intent.parameters);
    this.editor.setValue(this.value, -1);
    this.inputParameters.checked = true;
    this.editor.session.setUndoManager(new UndoManager());

    if (this.filterArgument) {
      this.editor.execCommand("find");
      this.editor.searchBox.searchInput.value = intent.filterArgument;
      this.editor.searchBox.$onChange();
      //this.editor.searchBox.hide();
    }

    //this._updateValue();
  }

  public onTextChange(e) {
    this._updateValue();
  }



  public setSize() {
/*     const newHeight = this.editor.getSession().getScreenLength() * (this.editor.renderer.lineHeight);
    this.editor.container.style.height = newHeight+"px";
    this.editor.resize();
 */  }

  ngAfterViewInit() {

    //this.editor.on('focus', () => this.setVariables());
    this.editor.setOptions(this.options);
    this.editor.setFontSize(13);
    this.editor.container.style.lineHeight = 2;
    this.editor.completers = [];
    this.setReadOnly(true);

    //this._updateValue();

    this.editor.session.getDocument().on('change', function (delta) {

      //debugger;
      switch (delta.action) {
        case "insert":
          if (delta.start.row == 0 && delta.lines.length == 2 && delta.start.column != 0) {
            if (delta.start.row == delta.end.row - 1) {
              this.editor.session.getDocument().removeNewLine(delta.start.row);
              this.editor.session.getDocument().insertNewLine({ row: 0, column: 0 });
              this.editor.moveCursorToPosition({ row: 0, column: 0 });
            }

            this._updateValue();
            this.setSize();
          }
          break;

        default:
          break;
      }

    }.bind(this));

    this.editor.commands.addCommand({
      name: "showKeyboardShortcuts",
      bindKey: { win: "Ctrl-Shift-space", mac: "Command-Shift-Space" },
      exec: function (editor) {
        this.editor.completers = [
          this.programVariables
        ];

        this.editor.execCommand("startAutocomplete");
      }.bind(this)
    });

    this.eventManager.editorIsLoaded();

  }




  reloadVariablesAutocompleter(variableList) {

    this.editor.completers = [];

    this.intentVariables = {
      getCompletions(editor, session, pos, prefix, callback) {
        if (session.$mode.completer) {
          return session.$mode.completer.getCompletions(editor, session, pos, prefix, callback);
        }

        callback(null, variableList[1].map(function (entry) {
          if (!entry || !entry.entityTypeDisplayName)
            return;

          let removePrefix = function (entityType) {
            let index = entityType.indexOf("_");
            return index > 0 ? entityType.substring(index + 1) : entityType;
          }

          let entityType = entry.entityTypeDisplayName.startsWith("@sys.") ? entry.entityTypeDisplayName : removePrefix(entry.entityTypeDisplayName)


          /**     value: string;
                  score: number;
                  meta?: string;
                  name?: string;
                  caption?: string; */
          return {
            caption: entry.displayName,
            value: "`" + entry.displayName + " | " + entityType + '`',
            meta: entityType,
            className: "intentVar",
            description: entry.description
          };
        }));
      },
      getDocTooltip: function (item) {
        item.docHTML = [
          "<b>", item.caption, "</b>", "<hr></hr>",
          item.description
        ].join("");
      }
    };

    this.programVariables = {
      getCompletions(editor, session, pos, prefix, callback) {
        if (session.$mode.completer) {
          return session.$mode.completer.getCompletions(editor, session, pos, prefix, callback);
        }

        callback(null, variableList[0].map(function (entry) {
          if (!entry || !entry.entityTypeDisplayName)
            return;

          let removePrefix = function (entityType) {
            let index = entityType.indexOf("_");
            return index > 0 ? entityType.substring(index + 1) : entityType;
          }

          let entityType = entry.entityTypeDisplayName.startsWith("@sys.") ? entry.entityTypeDisplayName : removePrefix(entry.entityTypeDisplayName)

          return {
            caption: entry.displayName,
            value: "`" + entry.displayName + " | " + entityType + '`',
            meta: entityType,
            className: "programVar_",
            description: entry.description
          };
        }));
      },
      getDocTooltip: function (item) {
        item.docHTML = [
          "<b>", item.caption, "</b>", "<hr></hr>",
          item.description
        ].join("");
      }
    };

    this.editor.completers = [
      this.intentVariables
    ];


  }


  private _updateValue() {

    //force Tokenizer
    // var TokenIterator = require('brace').acequire("ace/token_iterator").TokenIterator;
    // var stream = new TokenIterator(this.editor.session, 0, 0);
    // var usedVariablesTmpList = [];
    // let tok = stream.getCurrentToken();

    // while (tok != null || stream.getCurrentTokenRow() < this.editor.session.getLength() - 1) {
    //   if (tok && tok.type.includes('variable')) {
    //     usedVariablesTmpList.push(tok.value);
    //   }
    //   tok = stream.stepForward();
    // }

    // let tmpVars = [];
    // usedVariablesTmpList.forEach(variable => {
    //   tmpVars.push(this.testsService.variableStrToObj(variable));
    // });
    // tmpVars = tmpVars.map(v => v.alias);

    //get matched list from program variables (variable objects)
    // this.usedVariables = this.testsService.getProgramVariables().filter(
    //   variable => tmpVars.includes(variable.displayName)
    // );

    // this.usedVariablesChange.emit(this.usedVariables);

    // //eliminar variaveis que não estão a ser usadas
    // this.variableColorsArray = this.variableColorsArray.map(function (v) {
    //   return (<Array<any>>this.usedVariables).includes(v) ? v : null;
    // }.bind(this));

    // //adicionar variaveis que ainda não existem
    // this.usedVariables.forEach(function (v) {
    //   if (!this.variableColorsArray.includes(v)) {
    //     if (this.variableColorsArray.indexOf(null) != -1) {
    //       this.variableColorsArray[this.variableColorsArray.indexOf(null)] = v;
    //     } else {
    //       this.variableColorsArray.push(v);
    //     }
    //   }
    // }.bind(this));

    // //reload auto complete list
    // this.reloadVariablesAutocompleter(this.testsService.getVariablesToAutoCompleter(this.usedVariables));

    let lines = this.editor.session.bgTokenizer.lines;
    //setValue to webCT
    this.inputParameters.value = lines;
    this.questionsUpdate.emit(this.editor.session.bgTokenizer.lines);
    console.log("this.inputParameters: " + JSON.stringify(this.editor.session.bgTokenizer.lines))

    //count valid training phrases
    // var trainingPhrasesInputAsObj = typeof lines === 'string' ? JSON.parse(lines) : lines;
    // trainingPhrasesInputAsObj = trainingPhrasesInputAsObj.filter(function (line) {
    //   return line != null && line.length > 0;
    // });
    // this.showInfo = trainingPhrasesInputAsObj.length < 2;




    //set variables color
    // var teste = this.variableColorsArray.map(
    //   function (v) {
    //     if (v) {
    //       if (v.nature === "system")
    //         return v.displayName + " | " + v.entity;
    //       else
    //         return v.displayName + " | " + v.entity.slice(4);
    //     }
    //     return null
    //   });
    // this.editor.session.$mode.$highlightRules.setVariablePossitionArray(teste);
    this.editor.session.bgTokenizer.start(0);

    //_________________________________________________





  }


  private setReadOnly(notEditable: boolean) {
    this.isEditable = !notEditable;
    if (this.isEditable) {
      this.editor.setOptions({ readOnly: false, highlightActiveLine: true, highlightGutterLine: true });
      this.editor.renderer.$cursorLayer.element.style.display = "";
    } else {
      this.editor.setOptions({ readOnly: true, highlightActiveLine: false, highlightGutterLine: false });
      this.editor.renderer.$cursorLayer.element.style.display = "none";
    }
  }

  public validateName() {
    console.info(this._currentIntent.displayName);
    if (this.intentNameForm.get('newIntentNameValue').value.trim() === this._currentIntent.name) {
      this.duplicatedNameError = false;
    } else {
      this.duplicatedNameError = this.testsService.intentExists(this.intentNameForm.get('newIntentNameValue').value.trim());
    }

  }

  setEditMode(mode) {
    //this.editMode = mode;
    this.loadTranigPhrases({ intent: this._currentIntent });
    this.editModeChange.emit(mode);
  }


  startEdit() {
    //this._updateValue();
    //this.setReadOnly(false);
    this.setEditMode("EDIT_TRAINING_PHRASES");
  }

  cancelEdit() {
    this.loadTranigPhrases({ intent: this._currentIntent });
    this.setReadOnly(true);
    this.setEditMode("VIEW");
  }

  saveEdit() {
    console.log("this.inputParameters: " + JSON.stringify(this.editor.session.bgTokenizer.lines))
    //this.usedVariablesChange = this.editor.session.bgTokenizer.lines;
    this.questionsUpdate.emit(this.editor.session.bgTokenizer.lines);
    // this.testsService.saveTrainingPhrases(this.inputParameters.value, this._currentIntent.displayName).then(
    //   (resp) => {
    //     this.testsService.saveTrainingPhrasesVariableList(this.usedVariables, this._currentIntent.index, this._currentIntent.displayName).then(
    //       (resp) => {
    //         this.notificationService.showSuccess(
    //           "Save training phrases",
    //           "Training phrases successfully saved!",
    //           { timeout: { success: 700 }, positionClass: { success: 'top-right' }, limit: { success: 1 } }
    //         );

    //         if (this.testsService.program.model && this.testsService.program.model.status != "new") {
    //           this.testsService.setProgramDeprecated().then(
    //             (resp) => {
    //               this.testsService.reloadProgram().then(
    //                 (resp) => {
    //                   this.setReadOnly(true);
    //                   this.setEditMode("VIEW");
    //                 }
    //               );
    //             }
    //           );
    //         } else {
    //           this.testsService.reloadProgram().then(
    //             (resp) => {
    //               this.setReadOnly(true);
    //               this.setEditMode("VIEW");
    //             }
    //           );
    //         }
    //       }
    //     );
    //   },
    //   (resp) => {
    //     this.notificationService.showError(
    //       "Save training phrases",
    //       "An error occurred while saving training phrases!",
    //       { timeout: { error: 700 }, positionClass: { error: 'top-right' }, limit: { error: 1 } }
    //     );
    //     console.log(resp)
    //   }
    // );
  }


  /**
   * createFirstIntent
   */
  public createFirstIntent() {
    this.eventManager.sendCreateNewIntent();
  }


  @ViewChild("intentNameEditField") intentNameEditField: ElementRef;
  public renameIntent() {
    if (this.editMode === "VIEW" && !this._currentIntent.isUsed) {
      this.setEditMode("EDIT_TF_NAME");
    }

    //this.intentNameEditField.nativeElement.focus();
  }


  public renameIntentSave() {
    this.testsService.changeIntentName(this.intentNameForm.get('newIntentNameValue').value, this._currentIntent).then(
      (resp) => {
        this.notificationService.showSuccess(
          "Intent name",
          "Intent name successfully changed!",
          { timeout: { success: 700 }, positionClass: { success: 'top-right' }, limit: { success: 1 } }
        );
        this.testsService.reloadProgram().then(
          response => {
            this.setEditMode("VIEW");
            this._currentIntent.name = this.intentNameForm.get('newIntentNameValue').value;
            this._currentIntent.displayName = this.testsService.program.code + "_" + this._currentIntent.name;
          }
        );
      },
      (resp) => {
        this.notificationService.showError(
          "Intent name",
          "Change intent name error!",
          { timeout: { error: 700 }, positionClass: { error: 'top-right' }, limit: { error: 1 } }
        );
        console.log(resp)
      }
    );
  }

  public renameIntentCancel() {
    this.setEditMode("VIEW");
    this.intentNameForm.get('newIntentNameValue').setValue(this._currentIntent.name);
    this.validateName();
  }



  public changeIntentDescription() {
    if (this.editMode === "VIEW") {
      this.setEditMode("EDIT_TF_DESCRIPTION");
    }
    //this.intentDescriptionEditField.nativeElement.focus();
  }


  public changeIntentDescriptionSave() {
    this.testsService.changeIntentDescription(this.newIntentDescriptionValue, this._currentIntent).then(
      (resp) => {
        this.notificationService.showSuccess(
          "Intent description",
          "Intent description successfully changed!",
          { timeout: { success: 700 }, positionClass: { success: 'top-right' }, limit: { success: 1 } }
        );
        this.testsService.reloadProgram().then(
          response => {
            this.setEditMode("VIEW");
            this._currentIntent.description = this.newIntentDescriptionValue;
          }
        );
      },
      (resp) => {
        this.notificationService.showError(
          "Intent description",
          "Change intent description error!",
          { timeout: { error: 700 }, positionClass: { error: 'top-right' }, limit: { error: 1 } }
        );
        console.log(resp)
      }
    );
  }

  public changeIntentDescriptionCancel() {
    this.setEditMode("VIEW");
    this.newIntentDescriptionValue = this._currentIntent.description;
  }
}