import { CustomValidator } from '../../customValidators.service';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { WctNotificationService } from '../../foundations-webct2-palette/components/wct-notification/wct-notification.service';
import { TestService, Intent } from '../test.service';
// import { HttpClient, HttpHeaders } from '@angular/common/http';
// import { GlobalVarsService } from './../../foundations-webct-robot/robot/utils/global-vars.service';
// import { ModalService } from './../../foundations-webct2-palette/components/modalComponent/modal.service';
// import { ModalService} from './../../foundations-webct-robot/robot/services/modal.service';
declare var require: any;
import '../../../assets/mode-traningPhrases';
import '../../../assets/theme-traningPhrases';
import { Component, Input, AfterViewInit, ViewChild, OnInit, OnDestroy, EventEmitter, Output, ElementRef } from '@angular/core';
import { JsonParams, newEvent } from '../../foundations-webct-robot/robot/classes/jsonParams.class';
// import { PageService } from './../../foundations-webct-robot/robot/pageComponent/page.service';
import { AceEditorComponent } from 'ng2-ace-editor';
import { CustomEventManagerService } from '../../customComponents/custom-event-manager.service';

import 'brace';
import 'brace/ext/searchbox';
import 'brace/ext/language_tools';

var UndoManager = require('brace').acequire("ace/undomanager").UndoManager;


@Component({
  selector: 'app-test-phrases-editor',
  templateUrl: 'test-phrases-editor.component.html',
  styleUrls: ['test-phrases-editor.component.css']
})
export class TestPhrasesEditorComponent implements OnInit, AfterViewInit, OnDestroy {


  @Input() public inputParameters: JsonParams;
  @Input() public dataRecs;


  private _filterArgument


  @Input() editMode: any;
  @Input() warningMessage:any;
  @Output() editModeChange = new EventEmitter<string>();

  @Input() usedVariables: any;
  @Output() usedVariablesChange = new EventEmitter<string>();
  @Output() questionsUpdate = new EventEmitter<string>();

  @Output() currentIntentChange = new EventEmitter<Intent>();

  @ViewChild('editor') aceEditor: AceEditorComponent;
  @Output() textChanged = new EventEmitter();

  //@Input() public currentIntent;

  private editor;

  public showInfo: boolean = true;

  public _currentIntent;


  //public newIntentNameValue: string = "";

  public newIntentDescriptionValue: string = "";
  private newVariableCreatedSubscription;

  private intentVariables;
  private programVariables;
  private newIntentCreatedSubscription;

  private variableColorsArray = [];

  /*   public intentName = "";
    public intentDescription = ""; */
  public isEditable = false;
  //public currentIntent;

  public intentNameForm: FormGroup;
  public duplicatedNameError: Boolean = false;

  value: string = "";
  objectToSend = [];

  options: any = {
    //theme: "ace/theme/eclipse",
    theme: "ace/theme/traningPhrases",
    mode: "ace/mode/traningPhrases",
    animatedScroll: false,
    enableBasicAutocompletion: true,
    showPrintMargin: false,
    tabSize: 2,
    indentedSoftWrap: false,
    //maxLines: Infinity,
    maxLines: 35,
    useSoftTabs: false,
    wrap: true,
    enableLiveAutocompletion: false
  };


  /*   httpOptions = {
      headers: new HttpHeaders({
        'Accept': 'application/json; charset=utf-8',
        'Authorization': 'Bearer ' + localStorage.token,
        'IXS': localStorage.IXS_id
      })
    }; */

  constructor(
    //private _modalService: ModalService,
    private eventManager: CustomEventManagerService,
    public testsService: TestService,
    //private globalVars: GlobalVarsService,
    private formBuilder: FormBuilder,
    public customValidator: CustomValidator,
    public notificationService: WctNotificationService) {
  }


  public ngOnInit() {

    this.intentNameForm = this.formBuilder.group({
      newIntentNameValue: ['', [Validators.required, this.customValidator.preventSpecialCharacters]]
    });



    this.editor = this.aceEditor.getEditor();

    this.newVariableCreatedSubscription = this.eventManager.newVariableCreated.subscribe(
      (teste) => {
        this._updateValue();
      }
    );


    if (!this.inputParameters) {
      this.inputParameters = new JsonParams();
      return;
    }

    this.value = this.inputParameters.value;

    this.value = "";
    this.inputParameters.checked = true;

  }

  ngOnDestroy(): void {
    if (this.newVariableCreatedSubscription) {
      this.newVariableCreatedSubscription.unsubscribe();
    }
  }


  @Input()
  set currentIntent(intent) {
    if (!intent)
      return;

    this.variableColorsArray = [];
    this._currentIntent = intent;
    if (intent) {
      this.setEditMode("VIEW");
      this.intentNameForm.get('newIntentNameValue').setValue(intent.name);
      this.newIntentDescriptionValue = intent.description;

      this.value = this.testsService.formatTrainingPhrases(intent.step);
      //this.reloadVariablesAutocompleter([intent.parameters, []]);
      this.editor.setValue(this.value, -1);
      this.inputParameters.checked = true;
      this.editor.session.setUndoManager(new UndoManager());

      //this.reloadVariablesAutocompleter([this.testsService.program.variables, []]);

    }

  }

  @Input()
  set filterArgument(token) {
    this._filterArgument = token;
    /*     console.info("FILTER ARGUMENT: ", this._filterArgument);
        if(this._filterArgument){
    
          this.editor.execCommand("find");
          this.editor.searchBox.hide();
          this.editor.searchBox.searchInput.value = this._filterArgument;
          this.editor.searchBox.$onChange();
    
        } */
  }

  /*   @Input()
    set autoCompleteLists(vars){
      this.reloadVariablesAutocompleter(vars);
    } */

  /**
   * @deprecated ainda esta a ser usado no cancel
   * @param intent
   */
  private loadTranigPhrases(intent) {

    this._currentIntent = intent.intent;

    this.intentNameForm.get('newIntentNameValue').setValue(this._currentIntent.name);
    //this.newIntentDescriptionValue = this._currentIntent.description;

    console.log("loadTranigPhrases: "+ intent)

    this.value = this.testsService.formatTrainingPhrases(this._currentIntent.step);
    //    this.reloadVariablesAutocompleter(intent.parameters);
    this.editor.setValue(this.value, -1);
    this.inputParameters.checked = true;
    this.editor.session.setUndoManager(new UndoManager());

    if (this.filterArgument) {
      this.editor.execCommand("find");
      this.editor.searchBox.searchInput.value = intent.filterArgument;
      this.editor.searchBox.$onChange();
      //this.editor.searchBox.hide();
    }

    //this._updateValue();
  }

  public onTextChange(e) {
    this._updateValue();
  }



  public setSize() {
/*     const newHeight = this.editor.getSession().getScreenLength() * (this.editor.renderer.lineHeight);
    this.editor.container.style.height = newHeight+"px";
    this.editor.resize();
 */  }

  ngAfterViewInit() {

    //this.editor.on('focus', () => this.setVariables());
    this.editor.setOptions(this.options);
    this.editor.setFontSize(13);
    this.editor.container.style.lineHeight = 2;
    this.editor.completers = [];
    this.setReadOnly(true);

    //this._updateValue();

    this.editor.session.getDocument().on('change', function (delta) {

      //debugger;
      switch (delta.action) {
        case "insert":
          if (delta.start.row == 0 && delta.lines.length == 2 && delta.start.column != 0) {
            if (delta.start.row == delta.end.row - 1) {
              this.editor.session.getDocument().removeNewLine(delta.start.row);
              this.editor.session.getDocument().insertNewLine({ row: 0, column: 0 });
              this.editor.moveCursorToPosition({ row: 0, column: 0 });
            }

            this._updateValue();
            this.setSize();
          }
          break;

        default:
          break;
      }

    }.bind(this));

    this.editor.commands.addCommand({
      name: "showKeyboardShortcuts",
      bindKey: { win: "Ctrl-Shift-space", mac: "Command-Shift-Space" },
      exec: function (editor) {
        this.editor.completers = [
          this.programVariables
        ];

        this.editor.execCommand("startAutocomplete");
      }.bind(this)
    });

    this.eventManager.editorIsLoaded();

  }






  private _updateValue() {

    let lines = this.editor.session.bgTokenizer.lines;
    
    this.inputParameters.value = lines;
    this.questionsUpdate.emit(this.editor.session.bgTokenizer.lines);
    console.log("this.inputParameters: " + JSON.stringify(this.editor.session.bgTokenizer.lines))

    this.editor.session.bgTokenizer.start(0);

    //_________________________________________________





  }


  private setReadOnly(notEditable: boolean) {
    this.isEditable = !notEditable;
    if (this.isEditable) {
      this.editor.setOptions({ readOnly: false, highlightActiveLine: true, highlightGutterLine: true });
      this.editor.renderer.$cursorLayer.element.style.display = "";
    } else {
      this.editor.setOptions({ readOnly: true, highlightActiveLine: false, highlightGutterLine: false });
      this.editor.renderer.$cursorLayer.element.style.display = "none";
    }
  }

  

  setEditMode(mode) {
    this.editMode = mode;
    this.editModeChange.emit(mode);
  }


  startEdit() {
    this._updateValue();
    this.setReadOnly(false);
    this.setEditMode("EDIT_TRAINING_PHRASES");
  }

  cancelEdit() {
    this.loadTranigPhrases({ intent: this._currentIntent });
    this.setReadOnly(true);
    this.setEditMode("VIEW");
  }

  

  saveEdit() {
    console.log("this.inputParameters: " + JSON.stringify(this.editor.session.bgTokenizer.lines))
    //this.usedVariablesChange = this.editor.session.bgTokenizer.lines;
    var arr:any = this.testsService.cleanEmptyLines(this.editor.session.bgTokenizer.lines);

    console.log("cleanEmptyLines: " + JSON.stringify(arr))

    this.questionsUpdate.emit(arr);
    
  }


  /**
   * createFirstIntent
   */
  public createFirstIntent() {
    this.eventManager.sendCreateNewIntent();
  }


  @ViewChild("intentNameEditField") intentNameEditField: ElementRef;
  public renameIntent() {
    if (this.editMode === "VIEW" && !this._currentIntent.isUsed) {
      this.setEditMode("EDIT_TF_NAME");
    }

    //this.intentNameEditField.nativeElement.focus();
  }


  
}