import { Component, ContentChildren, Input, QueryList, AfterContentInit } from '@angular/core';
import { Ng2WizardStepComponent } from './ng2-wizard-step.component';

@Component({
  selector: 'ng2-wizard-tab',
  templateUrl: 'ng2-wizard-tab.component.html'
})

export class Ng2WizardTabComponent implements AfterContentInit {

  @Input() public title: string;

  @ContentChildren(Ng2WizardStepComponent) public steps: QueryList<Ng2WizardStepComponent>;

  public active = false;
  public visited = false;

  public ngAfterContentInit(): void {
    if (this.steps && this.steps.length > 0) {
      this.steps.first.active = true;
    }
  }
}
