import { Utils } from './../../foundations-webct-robot/robot/utils/utils.service';
import { HttpClient } from '@angular/common/http';
import { PageService } from './../../foundations-webct-robot/robot/pageComponent/page.service';
import { ModalService } from "./../../foundations-webct-robot/robot/services/modal.service";
import { Component, OnInit, Input } from '@angular/core';
import * as moment from 'moment/moment';
import { RequestManagerService } from '../../requests/requestManager.service';
import { SuperherosService } from '../superheros.service';
import { JsonParams } from './../../foundations-webct-robot/robot/classes/jsonParams.class';

@Component({
  selector: 'superheros-list',
  templateUrl: './superheros-list.component.html',
  styleUrls: ['./superheros-list.component.scss']
})

export class SuperherosListComponent implements OnInit {
  public superherosList: any = [];
  @Input() inputParameters;
  @Input() inputDataRecs;
  @Input() dataRecs;

  imageURL: any;

  constructor(
    private pageService: PageService,
    private httpClient: HttpClient,
    private utils: Utils,
    private _modal: ModalService,
    private superheroService: SuperherosService) {
  }

  ngOnInit() {
    this.listSuperheros();
  }

  public listSuperheros() {
    return this.superheroService.listSuperherosModel()
      .then(response => {
        this.superherosList = response;
        this.superherosList.map(superhero => {
          superhero.lastUpdate = this.timestampToDateFormat(superhero.lastUpdate);
          superhero.img = this.pageService.getUrlFromConfig(`{{ImageService}}/superheros/${superhero._id.$oid}`);
          return superhero;
        });
      });
  }

  public openStudent(superheroId) {
    this.utils.navigate(`superheros/${superheroId.$oid}`);
  }

  public openModal(mock: string) {
    this._modal.openModal(mock, null, new Object(), new JsonParams());
  }

  public timestampToDateFormat(date: any, mask: string = 'DD-MM-YYYY HH:mm:ss'): string {
    try {
      return moment(new Date(date)).format(mask);
    } catch (e) {
      return date;
    }
  }
}
