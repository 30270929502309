import { CustomEventManagerService } from './../custom-event-manager.service';
import { Component, OnInit, Input } from '@angular/core';

@Component({
  selector: 'generic-action-button',
  templateUrl: './generic-action-button.component.html',
  styleUrls: ['./generic-action-button.component.scss']
})
export class GenericActionButtonComponent implements OnInit {

  @Input() inputParameters;
  @Input() inputDataRecs;
  @Input() dataRecs;

  constructor(private eventManager: CustomEventManagerService ) { }

  ngOnInit() {

  }

  buttonClick(){
    this.eventManager.sendGenericAction(this.inputParameters.value);
  }

}
