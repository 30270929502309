import { Component, Input, Output, OnInit, EventEmitter } from '@angular/core';
import { FormControl } from '@angular/forms';

import { JsonParams, newEvent } from './../../../foundations-webct-robot/robot/classes/jsonParams.class';
import { InputService } from './../../../foundations-webct-robot/robot/services/input.service';
import { Utils } from './../../../foundations-webct-robot/robot/utils/utils.service';
import { RobotEngineModel } from './../../../foundations-webct-robot/robot/robotEngineModel';
import { GlobalVarsService } from './../../../foundations-webct-robot/robot/utils/global-vars.service';
import { ComponentsService } from './../../../foundations-webct-robot/robot/services/components.service';

@Component({
  selector: 'app-wct-ng-select',
  templateUrl: './wct-ng-select.component.html',
  styleUrls: ['./wct-ng-select.component.css']
})
export class WctNgSelectComponent implements OnInit {

  @Input() public inputParameters: JsonParams;
  @Input() public dataRecs: any;
  @Input() public control: FormControl;

  @Output() public newRequest: EventEmitter<any> = new EventEmitter();

  public items: Item[] = new Array();
  public fullItems: Item[];
  public multiple: boolean = false;
  public virtualScroll: boolean = true;
  public loading: boolean = false;
  public clearable: boolean = true;

  public ngSelectValue: any;

  private _componentId: string;

  private _page: number = 0;
  private _configParam: JsonParams;

  private _subscription: EventEmitter<JsonParams>;
  private _newEvent: EventEmitter<newEvent[]>;

  constructor(
    private _utils: Utils,
    private _robot: RobotEngineModel,
    private _globalVars: GlobalVarsService,
    private _components: ComponentsService,
    private _inputService: InputService) {
    this._componentId = 'NGSELECT-' + this._utils.guid(4, '');
  }

  public ngOnInit() {

    this.multiple = this.inputParameters.subType == 'multiple' || this._utils.isObjectType(this.inputParameters.value, 'Array');
    if (this.inputParameters.value)
      this.ngSelectValue = this._utils.cloneJsonParse(this.inputParameters.value);

    if (this.inputParameters.parameters) {
      let param_clearable = this._utils.findObjectInArray(this.inputParameters.parameters, 'clearable');
      if (param_clearable.key) {
        this.clearable = param_clearable.value;
      }
    }

    this._createConfigparam();
    this._formatValueList();
    this._findInitValue();

    this._subscription = this.inputParameters.observe().subscribe(param => this._formatValueList());
    this._newEvent = this.inputParameters.newEvent().subscribe(data => this._handleEvent(data));

  }

  public ngOnDestroy() {
    this._globalVars.deletePageParametersByGroup(this._componentId);
    if (this._subscription)
      this._subscription.unsubscribe();
  }

  public fetchMore() {

    if (!this.virtualScroll || !this.inputParameters.groups.urlResource)
      return;

    this.loading = true;
    this._updatePage();
    this.newRequest.emit(event);
  }

  public onValueChange() {
    this._updateValue();
    this._robot.findDynamicPropsDependencies(this.inputParameters.id);
    this._components.updatePageParameters(this.inputParameters.parameters);
  }

  private _itemsObject = (obj: JsonParams) => new Object({ id: obj.id, label: this._utils.i18n(obj.text), icon: obj.icon, disabled: obj.disabled, group: this._utils.i18n(obj.data['groupBy']) });

  private _formatValueList() {

    if (!this.inputParameters.valueList) {
      this.loading = false;
      return;
    }

    this._inputService.loadValueListFromDataRecs(this.inputParameters, this.dataRecs, this.inputParameters.valueList, this._componentId);
    if(!this.inputParameters.valueList)
      return;
    
    this.items = this.items.concat(<Item[]>this.inputParameters.valueList.map(obj => this._itemsObject(obj)));
    this.inputParameters.valueList.length = 0;

    this._updateValue();

    this.loading = false;
  }

  private _findInitValue() {

    if (this.inputParameters.value === undefined)
      return;

    if (this.multiple) {
      this.inputParameters.value = typeof this.inputParameters.value == 'string' ? [this.inputParameters.value] : this.inputParameters.value;
      let findValue = this.items.filter(obj => this.inputParameters.value.indexOf(obj.id) >= 0);
      if (findValue.length > 0)
        this.inputParameters.value = findValue;
    } else {
      let findValue = this.items.find(obj => this.inputParameters.value == obj.id);
      if (findValue)
        this.inputParameters.value = findValue;
    }

    this.ngSelectValue = this.inputParameters.value;
  }

  private _createConfigparam() {
    this._configParam = new JsonParams('config::' + this.inputParameters.id, new Object());
    this._globalVars.setPageParameters(this._configParam, this._componentId);
    this._updatePage();
  }

  private _updatePage() {
    this._configParam.value.page = ++this._page;
  }

  private _updateValue() {
    this.inputParameters.value = this.ngSelectValue ? this._utils.cloneJsonParse(this.ngSelectValue) : undefined;
  }

  private _handleEvent(data: newEvent[]) {

    data.forEach(event => {
      if (event.key == 'infiniteScrollRequest') {
        this._updateValue();
        this._formatValueList();
      }
    });

  }

}

interface Item {
  id: any;
  label: any;
  group?: string;
  disabled?: boolean;
}

