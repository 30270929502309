import { Injectable } from '@angular/core';

@Injectable()
export class VersionControlService {

    private _version: string = Math.random().toString();

    public preventJsonCache = (char: string = '?') => char + 'v=' + this.version;

    get version(): string {
        return this._version;
    }
    set version(value: string) {
        if (value)
            this._version = value;
    }

}
