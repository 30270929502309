import { Component, Input } from '@angular/core';
import { JsonParams } from './../../../foundations-webct-robot/robot/classes/jsonParams.class';

@Component({
    selector: 'image-component',
    templateUrl: 'image.component.html'
})

export class ImageComponent {

    @Input() public inputParameters: JsonParams;

}
