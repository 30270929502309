import { Component, Input, OnInit, OnDestroy } from '@angular/core';
import { FormControl } from '@angular/forms';
import { Utils } from './../../../foundations-webct-robot/robot/utils/utils.service';
import { PageService } from './../../../foundations-webct-robot/robot/pageComponent/page.service';
import { JsonParams } from './../../../foundations-webct-robot/robot/classes/jsonParams.class';
import { InputService } from './../../../foundations-webct-robot/robot/services/input.service';
import { ComponentsService } from './../../../foundations-webct-robot/robot/services/components.service';
import { RobotEngineModel } from './../../../foundations-webct-robot/robot/robotEngineModel';

@Component({
  selector: 'input-radio',
  templateUrl: 'radio.component.html',
  styles: [`
      p{ margin: 0; padding-left: 10px; }
      p label{ display: inline; }
  `]
})

export class InputRadioComponent implements OnInit, OnDestroy {

  @Input() public inputParameters: JsonParams;
  @Input() public inputDataRecs: Object;
  @Input() public dataRecs: Object;
  @Input() public control;

  public valueControl: string = null;
  public elementId: string;

  private _componentId: string;
  private _subscription: any;

  constructor(
    private _robot: RobotEngineModel,
    private _utils: Utils,
    private _components: ComponentsService,
    private _pageService: PageService,
    private _inputService: InputService) {

    this._componentId = 'RADIO-' + this._utils.guid(4, '');

  }

  public optionKey = (option: any) => option.id ? (typeof option.value != 'object' ? (option.value || option.id) : option.id) : option.key;

  public ngOnInit() {
    this._startComponent();
    this._subscription = this.inputParameters.observe().subscribe(param => this._startComponent());
  }

  public ngOnDestroy() {
    if (this._subscription)
      this._subscription.unsubscribe();
  }

  public updateParameters(option: any) {

    if (this.inputParameters.disabled || option.disabled)
      return;

    this.inputParameters.value = option.id ? (option.value || option.id) : option.key;
    if (option && option['updateParameter']) {
      for (let i in option['updateParameter']) {
        this.inputParameters[i] = !option['updateParameter'][i].match(/{{\s*[\w\.\-\_]+\s*}}/g) ? option['updateParameter'][i] : '';
      }
    }

    this.setValueControl();
    this._robot.findDynamicPropsDependencies(this.inputParameters.id, this.dataRecs);
    this._components.updatePageParameters(this.inputParameters.parameters);
  }

  public setValueControl() {
    this.valueControl = this.inputParameters.value;
  }

  private _startComponent() {

    this.elementId = this._components.getHtmlId(this.inputParameters);

    if (!this.control)
      this.control = new FormControl();

    this.setValueControl();
    this._inputService.loadValueListFromDataRecs(this.inputParameters, this.dataRecs, this.inputParameters.valueList, this._componentId);
  }
}
