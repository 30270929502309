import { Injectable, EventEmitter } from '@angular/core';

import { PageComponent } from './../../../foundations-webct-robot/robot/pageComponent/page.component';
import { Utils } from './../../../foundations-webct-robot/robot/utils/utils.service';
import { NavigationService } from './../../../foundations-webct-robot/robot/services/navigation.service';
import { ToggleElementsService } from './../../../foundations-webct-robot/robot/services/toggle-elements.service';
import { Router, Route } from '@angular/router';
import { JsonParams } from './../../../foundations-webct-robot/robot/classes/jsonParams.class';
import { RobotEngineModel } from './../../../foundations-webct-robot/robot/robotEngineModel';
import { GlobalVarsService } from './../../../foundations-webct-robot/robot/utils/global-vars.service';


@Injectable()
export class AppHeaderService {

  private _mainMenuViewStructure: JsonParams;
  private _sideMenuViewStructure: JsonParams;

  // private _currentMenuFile: string;
  private _matchActive: Object = {
    top: {},
    side: {}
  };

  private _baseRoutes: Route[] = [];
  private _newRoutesEmitter: EventEmitter<any> = new EventEmitter();
  private _showResponsiveMenu = false;
  private _isSideMenuExpanded = false;

  constructor(
    private _utils: Utils,
    private _robot: RobotEngineModel,
    private _navigation: NavigationService,
    private _toggle: ToggleElementsService,
    private _globalVars: GlobalVarsService,
    private _router: Router) {

  }

  public get showResponsiveMenu(): boolean {
    return this._showResponsiveMenu;
  }
  public set showResponsiveMenu(value: boolean) {
    this._showResponsiveMenu = value;
  }

  public get mainMenuViewStructure(): JsonParams {
    return this._mainMenuViewStructure;
  }
  public set mainMenuViewStructure(value: JsonParams) {
    this._mainMenuViewStructure = value;
  }

  public get sideMenuViewStructure(): JsonParams {
    return this._sideMenuViewStructure;
  }
  public set sideMenuViewStructure(value: JsonParams) {
    this._sideMenuViewStructure = value;
  }

  public get isSideMenuExpanded(): boolean {
    return this._isSideMenuExpanded;
  }
  public set isSideMenuExpanded(value: boolean) {
    this._isSideMenuExpanded = value;
  }

  public loadMenuMock(mockToLoad: string, componentId: string): Promise<JsonParams> {

    return new Promise((resolve, reject) => {

      this._utils
        .GetAll(this._utils.getJson(mockToLoad))
        .subscribe(
          response => this._robot.loadPageParams(response, componentId).then(res => resolve(res)),
          error => reject(null));
    });
  }

  public navigateTo(e, menu: JsonParams) {

    this._showResponsiveMenu = false;

    if (menu.groups.details && menu.groups.details.parameters && menu.groups.details.parameters.length > 0)
      this._toggle.toggleSubMenuStatus(menu);
    else
      this._toggle.hideSubMenu();

    menu.type = menu.type || 'navigate';
    this._navigation.navigateByType(e, menu);
  }
  public toggleSubMenuStatus(menu: JsonParams) {
    this._toggle.toggleSubMenuStatus(menu);
  }
  public getSubMenuStatus(menu: JsonParams): boolean {
    return this._toggle.getSubMenuStatus(menu);
  }

  public checkActivatedRoute(menu: JsonParams, position: 'top' | 'side'): boolean {
    if (!menu.navigateTo || !this._router.url)
      return false;
    if (menu.navigateTo == '/') // HOME ROUTE
      return menu.navigateTo == this._router.url;

    this._matchActive[position][menu.internalId] = -1;

    let checkIfMatch = (str: string): boolean => this._router.url.indexOf(this._applyRouteSlash(str)) === 0;
    let replaceRouteParams = str => str.replace(/^(\/)/, '').replace(/\:[a-zA-z0-9]+/g, '***').replace(this._utils.mustacheReg, '***').replace(this._utils.urlMustacheReg, '***');

    let routeConfig = this._router.config.find(obj => replaceRouteParams(obj.path) == replaceRouteParams(menu.navigateTo));
    if (routeConfig) {

      let matchStr = routeConfig.path.replace(/\:[a-zA-z]+/g, str => this._globalVars.urlParams[str.slice(1)]);
      if (checkIfMatch(matchStr)) {
        this._matchActive[position][menu.internalId] = this._router.url.length - matchStr.length;
        return this._checkActiveMenu(this._matchActive[position][menu.internalId], position);
      }

      return false;
    }

    if (checkIfMatch(menu.navigateTo)) {
      this._matchActive[position][menu.internalId] = this._router.url.length - menu.navigateTo.length;
      return this._checkActiveMenu(this._matchActive[position][menu.internalId], position);
    }

    return false;
  }

  public setRouterParameters(menu: JsonParams) {
    let routes: JsonParams = this._utils.findObjectInArray(menu.parameters, 'routes');
    if (routes.value && routes.value.length > 0) {

      this._baseRoutes = this._baseRoutes.length > 0 ? this._baseRoutes : this._utils.cloneObject(this._router.config);

      let newRoutesList = routes.value;
      let newRoutesConfig: Route[] = this._utils.cloneObject(this._baseRoutes);

      for (let i in newRoutesList) {

        let duplicatedRouteIndex = newRoutesConfig.findIndex(obj => obj.path == newRoutesList[i]['path']);
        if (duplicatedRouteIndex >= 0)
          newRoutesConfig.splice(duplicatedRouteIndex, 1);

        newRoutesConfig.unshift({
          path: newRoutesList[i]['path'],
          data: newRoutesList[i]['data'],
          component: PageComponent
        });
      }

      this._router.resetConfig(newRoutesConfig);
      this._newRoutesEmitter.next(routes.value);
    }
  }

  public observeNewRoutes = (): EventEmitter<any> => this._newRoutesEmitter;

  private _applyRouteSlash = (str: string) => (str.indexOf('/') === 0 ? '' : '/') + str;
  private _checkActiveMenu(number: number, position: 'top' | 'side') {
    return !(Object.keys(this._matchActive[position]).map(key => this._matchActive[position][key]).find(nbr => nbr >= 0 && nbr < number) >= 0);
  }

}
