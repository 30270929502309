import { Component, ContentChildren, Input, Output, EventEmitter, QueryList, OnInit, AfterContentInit } from '@angular/core';
import { Router } from '@angular/router';

import { Ng2WizardTabComponent } from './ng2-wizard-tab.component';
import { Ng2WizardStepComponent } from './ng2-wizard-step.component';
import { INg2WizardConfig } from './ng2-wizard.config';

import { PageService } from './../../../foundations-webct-robot/robot/pageComponent/page.service';
import { Utils } from './../../../foundations-webct-robot/robot/utils/utils.service';
import { GlobalVarsService } from './../../../foundations-webct-robot/robot/utils/global-vars.service';
import { OperationsService } from './../../../foundations-webct-robot/robot/utils/operations.service';
import { JsonParams } from './../../../foundations-webct-robot/robot/classes/jsonParams.class';
import { ModalService } from './../../../foundations-webct-robot/robot/services/modal.service';
import { RobotEngineModel } from './../../../foundations-webct-robot/robot/robotEngineModel';

@Component({
  selector: 'ng2-wizard',
  templateUrl: 'ng2-wizard.component.html',
  styles: [`
      .no-click{ cursor: default; }
  `]
})

export class Ng2WizardComponent implements OnInit, AfterContentInit {

  @Input() public viewStructure: JsonParams;
  @Input() public loadingFromModal = false;

  public wizardTabs: JsonParams[] = null;
  public wizardLocation = 'wizardFormSteps';

  // represents the user's config values
  @Input() private config: INg2WizardConfig;

  private defaultConfig: INg2WizardConfig = {
    showNavigationButtons: true,
    navigationButtonLocation: 'top',
    preventUnvisitedTabNavigation: false
  };
  private combinedConfig: INg2WizardConfig = this.defaultConfig;
  private _componentId: string;
  private _configParam: JsonParams = new JsonParams();

  @Output() private onNext: EventEmitter<any> = new EventEmitter<any>();
  @Output() private onPrevious: EventEmitter<any> = new EventEmitter<any>();
  @Output() private onFinish: EventEmitter<any> = new EventEmitter<any>();
  @Output() private onTabChange: EventEmitter<any> = new EventEmitter<any>();

  @ContentChildren(Ng2WizardTabComponent)
  private tabs: QueryList<Ng2WizardTabComponent>;

  private get steps(): Ng2WizardStepComponent[] {
    let steps: Ng2WizardStepComponent[] = new Array<Ng2WizardStepComponent>();

    if (this.tabs) {
      this.tabs.forEach((tab) => {
        steps = steps.concat(tab.steps.toArray());
      });
    }

    return steps;
  }

  // Allow usage of enum in template
  private NavigationDirection = NavigationDirection;
  

  constructor(
    public pageService: PageService,
    public modalService: ModalService,
    private _operations: OperationsService,
    private _utils: Utils,
    private _robot: RobotEngineModel,
    private _globalVars: GlobalVarsService,
    private _router: Router) {

    this._componentId = 'WIZARD-' + this._utils.guid(4, '');

  }

  private get activeStep(): Ng2WizardStepComponent {
    return this.steps.find((step) => step.active);
  }

  public checkFormValidation(index: number = -1) {
    if (index >= 0 && this.pageService[this.wizardLocation][index])
      return this.pageService[this.wizardLocation][index].invalid;
    return !!this.pageService[this.wizardLocation].find(obj => obj ? obj.invalid : false);
  }

  public printForm(index: number = -1) {
    if (index >= 0)
      console.log('this.pageService[' + this.wizardLocation + '][' + index + '] ---> ', this.pageService[this.wizardLocation][index]);
    else
      console.log('this.pageService[' + this.wizardLocation + '] ---> ', this.pageService[this.wizardLocation]);
  }

  public get currentStepIndex(): number {
    return this.steps.indexOf(this.activeStep);
  }

  private get currentTab(): Ng2WizardTabComponent {
    return this.tabs.toArray().find((tab) => tab.active);
  }

  public get hasNextStep(): boolean {
    return this.currentStepIndex < this.steps.length - 1;
  }

  public get hasPreviousStep(): boolean {
    return this.currentStepIndex > 0;
  }

  public ngOnInit(): void {

    this.wizardLocation += this.loadingFromModal ? 'Modal' : '';

    if (this.viewStructure && this.viewStructure.groups) {
      this.pageService.setButtonsConfig(this.viewStructure.groups.buttons, 'page');
      this.wizardTabs = this.viewStructure.groups.wizard.parameters;
    }

    this.verifyConfig();
    this.combineConfig();
    this._createConfigParam();
  }

  public ngOnDestroy() {
    this._globalVars.deletePageParametersByGroup(this._componentId);
  }

  public ngAfterContentInit(): void {
    // TODO: Verificar o erro quando este setTimeout() não existe (Expression has changed after it was checked.)
    // setTimeout(() => {
    this.tabs.first.active = true;
    this._saveCurrentStep();
    // }, 100);
  }

  public onButtonClick(direction: NavigationDirection): void {
    switch (direction) {
      case NavigationDirection.NEXT:
        this.next();
        break;
      case NavigationDirection.PREVIOUS:
        this.previous();
        break;
      case NavigationDirection.FINISH:
        this.finish();
        break;
      case NavigationDirection.CANCEL:
        this.cancel();
        break;
      default:
        throw new Error(direction + ' is not a valid NavigationDirection');
    }
  }

  private get showTopNavigationButtons(): boolean {
    return this.combinedConfig.showNavigationButtons &&
      (this.combinedConfig.navigationButtonLocation === 'top' || this.combinedConfig.navigationButtonLocation === 'both');
  }

  private get showBottomNavigationButtons(): boolean {
    return this.combinedConfig.showNavigationButtons &&
      (this.combinedConfig.navigationButtonLocation === 'bottom' || this.combinedConfig.navigationButtonLocation === 'both');
  }

  private onTabClick(param: JsonParams, index: number): void {

    let selectedTab = this.tabs.toArray()[index];
    if (param.hidden || param.lazyLoading || !selectedTab)
      return;

    this.deactivateAllTabs();
    selectedTab.active = true;
    this.deactivateAllSteps();
    selectedTab.steps.first.active = true;
    this._saveCurrentStep();
  }

  private previous(): void {
    if (this.hasPreviousStep) {
      let step: Ng2WizardStepComponent = this.steps[this.currentStepIndex - 1];
      this.deactivateAllSteps();
      step.active = true;
      this.onPrevious.emit(this.currentStepIndex);
      this.selectTab(step);
    }
  }

  private next(): void {
    if (this.hasNextStep) {
      this.wizardTabs[this.currentStepIndex + 1].lazyLoading = false;
      let step: Ng2WizardStepComponent = this.steps[this.currentStepIndex + 1];
      this.deactivateAllSteps();
      step.active = true;
      this.onNext.emit(this.currentStepIndex);
      this.selectTab(step);
    }
  }

  private cancel(): void {
    if (!this.pageService.buttonsConfig.page['cancel']) {
      console.error('Missing operation parameters.');
      return;
    }
    this.modalService.closeModal();
    this._utils.navigate(this.pageService.buttonsConfig.page['cancel'].navigateTo);
  }

  private finish(): void {
    if (!this.pageService.buttonsConfig.page['submit']) {
      console.error('Missing operation parameters.');
      return;
    }
    this.pageService.operationLoading = true;
    this.modalService.closeModal();
    this._operations.execute(this._globalVars.getPageParametersAsArray(), this.pageService.buttonsConfig.page['submit'].parameters);
  }

  private selectTab(newStep: Ng2WizardStepComponent): void {

    let previousTab: Ng2WizardTabComponent = this.currentTab;
    this.deactivateAllTabs();

    this.tabs.forEach((tab) => {
      tab.steps.forEach((step) => {
        if (newStep === step) {
          tab.active = true;

          if (previousTab !== tab) {
            this.onTabChange.emit(null);
          }

          return;
        }
      });
    });
    this._saveCurrentStep();
  }

  private deactivateAllTabs(): void {
    this.tabs.forEach((tab) => {
      tab.active = false;
    });
  }

  private deactivateAllSteps(): void {
    this.steps.forEach((step) => {
      step.active = false;
    });
  }

  // check configuration rules and warn user when they have conficting config values
  private verifyConfig(): void {
    if (this.combinedConfig.navigationButtonLocation && !this.combinedConfig.showNavigationButtons) {
      console.warn('ng2-wizard: config value "navigationButtonLocation" ignored because "showNavigationButtons" is false.');
    }
  }

  // loop through all configuation settings in user input config and over write the defaults
  private combineConfig(): void {
    for (let key in this.config) {
      if (this.config.hasOwnProperty(key)) {
        this.combinedConfig[key] = this.config[key];
      }
    }
  }

  private _createConfigParam() {
    this._configParam.id = 'config::' + this.viewStructure.id;
    this._configParam.value = {
      numberOfSteps: this.wizardTabs.length,
      currentStep: 0
    };
    this._saveCurrentStep();
    this._globalVars.setPageParameters(this._configParam, this._componentId);
  }

  private _saveCurrentStep() {
    this._configParam.value.currentStep = this.currentStepIndex + 1;
    this._robot.findDynamicPropsDependencies(this._configParam.id);
  }
}

enum NavigationDirection {
  PREVIOUS,
  NEXT,
  FINISH,
  CANCEL
}
