import { Component, Input, ViewChild } from '@angular/core';
import { ReCaptchaComponent } from 'angular2-recaptcha';
import { JsonParams } from './../../../foundations-webct-robot/robot/classes/jsonParams.class';
import { Utils } from './../../../foundations-webct-robot/robot/utils/utils.service';
import { FormControl } from '@angular/forms';

@Component({
  selector: 'captcha-component',
  templateUrl: 'captcha.component.html'
})

export class CaptchaComponent {

  @ViewChild(ReCaptchaComponent) public captcha: ReCaptchaComponent;

  @Input() public inputParameters: JsonParams;
  @Input() public control: FormControl;

  public captchaCfg: Object | null;

  constructor(private _utils: Utils) {}

  public ngOnInit() {
    if (!this.control)
      this.control = new FormControl();

    this.captchaCfg = this._utils.arrToObj(this.inputParameters.parameters);
    if (Object.keys(this.captchaCfg).length === 0 && this.captchaCfg.constructor === Object) {
      // defaults:
      this.captchaCfg = {
        siteKey: '6LeIxAcTAAAAAJcZVRqyHh71UMIEGNQ_MXjiZKhI'
      };
    }
  }

  public handleCorrectCaptcha(ev) {
    this.inputParameters.value = this.captcha.getResponse();
  }

  public handleExpiredCaptcha() {
    this.inputParameters.value = this.captcha.getResponse();
  }

  public resetCaptcha() {
    this.captcha.reset();
  }
}
