import { Utils } from './../../foundations-webct-robot/robot/utils/utils.service';
import { Component, OnInit, Input } from '@angular/core';
import { OnBoardingService } from '../../OnBoarding.service';

@Component({
  selector: 'app-tour-start',
  templateUrl: './tour-start.component.html',
  styleUrls: ['./tour-start.component.scss']
})
export class TourStartComponent implements OnInit {

  @Input() inputParameters;
  @Input() inputDataRecs;
  @Input() dataRecs;
  public displayName;

  constructor(private _utils: Utils, private _onboarding: OnBoardingService) { }

  ngOnInit() {
    this.displayName = this.inputParameters.value['routeName'];
  }

  buttonClick() {
    if (this.inputParameters && this.inputParameters.value.dynamicRouteStart) {
       this._onboarding.endTour();
      this._onboarding.manualTrigger(this.inputParameters.value['_id']['$oid']);
      this._utils.navigate(`/${this.inputParameters.value.dynamicRouteStart}`);
    }
  }
}
