declare var require: any;
import { ComponentsService } from './../../../foundations-webct-robot/robot/services/components.service';
//import 'brace';
//import 'brace/theme/eclipse';
import '../../../../../src/assets/mode-traningPhrases';
import '../../../../../src/assets/theme-traningPhrases';

import {TokenTooltip} from '../../../../../src/assets/mode-traningPhrases';

//import * as langTools from 'brace/ext/language_tools.js';
//import 'brace/ext/searchbox.js';
//import 'brace/ext/statusbar.js';
import { Component, Input, AfterViewInit, ViewChild, OnInit, OnDestroy } from '@angular/core';
import { JsonParams} from './../../../foundations-webct-robot/robot/classes/jsonParams.class';
import { Utils } from './../../../foundations-webct-robot/robot/utils/utils.service';
// import { PageService } from './../../../foundations-webct-robot/robot/pageComponent/page.service';

import { AceEditorComponent } from 'ng2-ace-editor';
import { RobotEngineModel } from './../../../foundations-webct-robot/robot/robotEngineModel';

// var TokenTooltip = require("../../../../assets/mode-traningPhrases").TokenTooltip;


@Component({
  selector: 'app-wct-highlight-editor',
  templateUrl: 'wct-highlight-editor.component.html',
  styleUrls: ['wct-highlight-editor.component.css']
})
export class WctHighlightEditorComponent implements OnInit, AfterViewInit {
  
  @Input() public inputParameters: JsonParams;
  @Input() public dataRecs;
  @ViewChild('editor') aceEditor: AceEditorComponent;

  private editor;
  private _componentId: string;

  public elementId: string;
  public showInfo = true;

  value = '';
  objectToSend = [];

  options: any = {
    // theme: "ace/theme/eclipse",
    theme: 'ace/theme/traningPhrases',
    mode: 'ace/mode/traningPhrases',
    animatedScroll: false,
    enableBasicAutocompletion: true,
    showPrintMargin: false,
    tabSize: 2,
    indentedSoftWrap: false,
    maxLines: Infinity,
    // maxLines: 20,
    useSoftTabs: false,
    wrap: true,
    enableLiveAutocompletion: false
  };

  constructor(private _robot: RobotEngineModel, private _components: ComponentsService, private _utils: Utils) {
    this._componentId = 'HIGHLIGHT-' + this._utils.guid(4, '');
  }

  public ngOnInit() {
    this.elementId = this._components.getHtmlId(this.inputParameters);

    this.editor = this.aceEditor.getEditor();

    if (!this.inputParameters) {
      this.inputParameters = new JsonParams();
      return;
    }

    this.value = this.inputParameters.value;
    this.inputParameters.checked = true;
  }


  public onChange(e) {

    var changed = this.inputParameters.checked;
    this.inputParameters.checked = false;
    if (changed)
      this._robot.findDynamicPropsDependencies(this.inputParameters.id);

    this._updateValue();

    // let Range = require('brace').acequire('ace/range').Range;
    // this.editor.getEditor().session.addMarker(new Range(2, 5, 2, 15), 'myMarker', null, true);

    /*     var row = this.editor.selection.getCursor().row
        this.editor.session.getTokens(row).filter(function(t) {
          console.info(t);
        }); */
  }

  ngAfterViewInit() {
    this.editor.on('focus', () => this.setVariables());
    this.editor.setOptions(this.options);
    this.editor.setFontSize(13);
    this.editor.container.style.lineHeight = 2;
    this.editor.completers = [];

    this._updateValue();
    //this.countTrainingPhrases();

    this.editor.session.getDocument().on('change', function(delta) {
      //console.info(delta);
      //debugger;
      switch (delta.action) {
        case "insert":

          if(delta.start.row == 0 && delta.lines.length == 2 && delta.start.column != 0){

            if (delta.start.row == delta.end.row-1){
              this.editor.session.getDocument().removeNewLine(delta.start.row);
              this.editor.session.getDocument().insertNewLine({row: 0, column: 0 });
              this.editor.moveCursorToPosition({row: 0, column: 0 });
            }
          }
          break;
        default:
          break;
      }

      this._updateValue();
      this.countTrainingPhrases();

    }.bind(this));

    /*     this.editor.session.bgTokenizer.on('update', function() {
          console.info("bgTokenizer update");
          this._updateValue();

        }.bind(this)); */

  }

  setVariables() {
    //set variables for highlight
    let variableList = this.inputParameters.valueList;
    let variables = this.inputParameters.valueList.map(x => x.displayName).join('|');
    this.editor.session.$mode.$highlightRules.setKeywords({ 'keyword': variables });
    this.editor.session.bgTokenizer.start(0);

    //reload autocomplete list
    this.editor.completers = [];

    const customKeyWordCompleter = {
      getCompletions(editor, session, pos, prefix, callback) {
        if (session.$mode.completer) {
          return session.$mode.completer.getCompletions(editor, session, pos, prefix, callback);
        }
        const state = editor.session.getState(pos.row);

        let keywordCompletions = session.$mode.getCompletions(state, session, pos, prefix);

        //return callback(null, keywordCompletions);
        callback(null, variableList.map(function (entry) {

          if(!entry || !entry.entityTypeDisplayName)
            return;

          let removePrefix = function (entityType) {
            let index = entityType.indexOf("_");
            return index > 0 ? entityType.substring(index + 1) : entityType;
          }

          let entityType = entry.entityTypeDisplayName.startsWith("@sys.") ? entry.entityTypeDisplayName : removePrefix(entry.entityTypeDisplayName)

          return {
            caption: entry.displayName,
            value: "`" + entry.displayName + " | " + entityType + '`',
            meta: entityType
          };
        }));
      },
    };

    this.editor.completers = [
      customKeyWordCompleter
    ];
  }

  public countTrainingPhrases() {

    var TokenIterator = require('brace').acequire('ace/token_iterator').TokenIterator;
    var stream = new TokenIterator(this.editor.session, 0, 0);
    do {
      var tok = stream.stepForward();
    } while (tok != null);

    let lines = this.editor.session.bgTokenizer.lines;

    var trainingPhrasesInputAsObj = typeof lines === 'string' ? JSON.parse(lines) : lines;

    trainingPhrasesInputAsObj = trainingPhrasesInputAsObj.filter(function(line) {
      return line != null && line.length > 0;
    });

    this.showInfo =  trainingPhrasesInputAsObj.length <2;

  }

  private _updateValue() {

    //force Tokenizer
    var TokenIterator = require('brace').acequire("ace/token_iterator").TokenIterator;
    var stream = new TokenIterator(this.editor.session, 0, 0);
    do {
      var tok = stream.stepForward();
    } while(tok != null);

    let lines = this.editor.session.bgTokenizer.lines;
    this.inputParameters.value = lines;

    //count
    var trainingPhrasesInputAsObj = typeof lines === 'string' ? JSON.parse(lines) : lines;
    trainingPhrasesInputAsObj = trainingPhrasesInputAsObj.filter(function(line) {
      return line != null && line.length > 0;
    });
    this.showInfo =  trainingPhrasesInputAsObj.length <2;

  }
}
