import { Injectable } from '@angular/core';

import { PageService } from './../../../foundations-webct-robot/robot/pageComponent/page.service';
import { JsonParams } from './../../../foundations-webct-robot/robot/classes/jsonParams.class';
import { Utils } from './../../../foundations-webct-robot/robot/utils/utils.service';
import { ConfigurationService } from './../../../foundations-webct-robot/robot/utils/configuration.service';

@Injectable()
export class ExportService {

  constructor(
    private _utils: Utils,
    private _config: ConfigurationService,
    private _pageService: PageService) {
    // Blank Constructor for Demo Purpose
  }

  // Download CSV
  public generateCSV(columns: JsonParams[], rows: Object[], filename: string) {

    let csvData = '';
    csvData += this.convertColumnsToCSV(columns);
    csvData += this.convertRowsToCSV(columns, rows);

    filename += '.csv';

    let a: any = document.createElement('a');
    a.setAttribute('style', 'display:none;');
    document.body.appendChild(a);
    let blob = new Blob([csvData], { type: 'text/csv' });
    a.href = window.URL.createObjectURL(blob);

    let isIE = false || !!(<any>document).documentMode;

    if (isIE) {
      let retVal = navigator.msSaveBlob(blob, filename);
    } else {
      a.download = filename;
    }
    // If you will any error in a.download then dont worry about this.
    a.click();
  }

  private convertColumnsToCSV(columns: JsonParams[]): string {

    if (typeof columns != 'object')
      return;

    let line: string[] = [];
    for (let c in columns) {
      if (columns[c].type == 'actions' || columns[c].type == 'context' || columns[c].type == 'expand' || columns[c].hidden === true)
        continue;
      line.push(this._pageService.i18n(columns[c].id));
    }
    return line.join(';') + '\r\n';
  }

  private convertRowsToCSV(columns: JsonParams[], rows: Object[]) {

    if (typeof rows != 'object')
      return;

    let line: string[];
    let lines = '';
    let columnIndex: number;

    for (let l in rows) {
      line = [];
      columnIndex = -1;
      for (let c in rows[l]) {
        columnIndex++;
        if (columns[columnIndex].type == 'actions' || columns[columnIndex].type == 'context' || columns[columnIndex].type == 'expand' || columns[columnIndex].hidden === true)
          continue;
        line.push(this.cleanString(this._utils.replaceTagVars(rows[l][c])));
      }
      lines += line.join(this._config.getConfig(['default','csvSeparator']) || ';') + '\r\n';
    }
    return lines;
  }

  private cleanString(str: any): string {
    if (!str)
      return '-';
    if (typeof str == 'object') {
      try {
        str = str.toString();
      } catch (e) {
        str = JSON.stringify(str);
      }
    }
    return typeof str == 'string' ?  str.replace(/\<i class=\".*"><\/i>/g, '').replace(/&nbsp;/g, '') : str;
  }

}
