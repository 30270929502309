import { Injectable } from '@angular/core';
import * as introJs from '../../node_modules/intro.js/intro.js';

import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable } from 'rxjs';
import { ConfigurationService } from './foundations-webct-robot/robot/utils/configuration.service';

import * as $ from 'jquery';

// Max number of tries
export let MAX_TRY_DOM_FIND = 12;
// Milliseconds for each interval
export let TIME_TO_TRY = 500;
// Html to show on skip button
export let SKIP_HTML = '<i class="fa fa-times" aria-hidden="true"></i>';
export let NEXT_HTML = '>';
export let PREV_HTML = '<';


@Injectable({
  providedIn: 'root'
})
export class OnBoardingService {
  public introJS: introJs;
  public currentStepService: number;
  private tour: any;
  private interval: any;
  private findElementTry = 0;
  private _BTN_PREV = 'fx-wizard-buttons-previous';
  private _BTN_NEXT = 'fx-wizard-buttons-next';

  constructor(public http: HttpClient, private _config: ConfigurationService) {
    this.introJS = introJs();
  }

  // Get current tour
  public getTour = () => this.tour;
  // Finish the tour
  public endTour = () => { this.introJS.exit(); delete this.tour; };
  // Get pathname of URL
  public getPathname = (url: Location): string => url.pathname.replace(/^\/([^\/]*).*$/, '$1');
  // Get all steps ids of the tour
  private getStepsElements = () => {
    if (this.tour) {
      return this.tour.steps.map((step: any) => step.element);
    }
  }
  // Get info of current step
  private getCurrentStep = (index: number) => this.tour.steps[index];
  // Escape chars of element's id
  private escapeChars = (id: string) => id.replace('$', '\\$').replace('{', '\\{').replace('}', '\\}');
  // Check if step's id exists
  private stepIdFound = (stepId: string) => this.getStepsElements().find(id => id === stepId);
  // Change position style
  private changePosition = (position: string) => $('body .introjs-fixedTooltip').css('position', position);
  // Get HTTP Headers to BD request
  private getHttpHeaders = (): any => new Object({
    headers: new HttpHeaders(Object.assign({ 'Authorization': `Bearer ${localStorage.token}` , 'IXS' : `${localStorage.getItem('IXS_id')}`}))
  })
  // Get tour, filtered by id
  private getTourById = (id: string): Observable<any> => {
    if (this._config.getEndpoint('SchoolBE')) {
      return this.http.get(`${this._config.getEndpoint('SchoolBE')}/onboarding/${id}`, this.getHttpHeaders());
    }
  }
  // Get tour, filtered by route's name
  private getTourByRoute(route: string): Observable<any> {
    if (this._config.getEndpoint('SchoolBE') && route && localStorage.token) {
      return this.http.get(
        `${this._config.getEndpoint('SchoolBE')}/onboarding?filter={"dynamicRouteStart":"${route}"}`,
        this.getHttpHeaders()
      );
    }
  }
  // Check if is not a click from a button
  private isNotAButton = (event: any, id: string) => {
    return ($(id).is('select')
      || $(id).is('input')
      || $(id).is('textarea')
      || $(event.path.find(obj => obj.localName === 'input')).length > 0)
      || $(id).find('.selectable-cards').length > 0
      || $(id).find('ng-select').length;
  }
  // Go to next step
  public goToStep(event: any, stepDirection: number, isNextButton = false) {
    let currentId: string;
    const stepsSize = this.getStepsElements() ? this.getStepsElements().length : 0;
    // If exists steps
    if (stepsSize > 0) {
      // Get id of event
      if (event.target.id || event.target.offsetParent) {
        currentId = event.target.id ? `#${event.target.id}` : `#${event.target.offsetParent.id}`;
      }
      // Check from child's id
      if (!this.stepIdFound(currentId)) {
        let id: any;
        try {
          const parentId = event.path.find(element => this.escapeChars(element.id) ===
            this.getCurrentStep(this.currentStepService)['element'].replace('#', ''));
          if (parentId) {
            id = $(parentId).attr('id');
          }
          id !== undefined ? id = `#${id}` : id = '';
        } catch (error) {
          id = '';
        }
        currentId = this.escapeChars(id);
      }
      // Check if is a Next Button in a Wizzard
      if (currentId.length > 0 && this.stepIdFound(currentId) && this.currentStepService !== undefined) {
        isNextButton = true;
        if (this.isNotAButton(event, currentId)) {
          isNextButton = false;
        }
      }
      // Update current step
      if (this.introJS._currentStep !== this.currentStepService) {
        this.introJS._currentStep = this.currentStepService;
      }

      if (isNextButton && this.currentStepService < stepsSize - 1) {
        this.checkTour(this.currentStepService, stepDirection, this.getPathname(window.location))
          .then(() => {
            const btnNextExists = $(this.tour.steps[this.currentStepService]['element']);
            let btnPrevExists = false;

            if ($(this._BTN_PREV)[0]) {
              btnPrevExists = ($(this._BTN_PREV)[0] as HTMLButtonElement).disabled;
            } if (btnNextExists.hasClass(this._BTN_NEXT) && btnPrevExists) {
              this.changePosition('fixed');
            } else {
              this.changePosition('absolute');
            }
            this.validateDOMElement();
          });
        // Check if is the last step of the tour
      } else if (this.currentStepService === stepsSize - 1) {
        this.endTour();
      }
    }
  }

  public checkTour(currentStep: number, stepDirection: number, route?: string): Promise<any> {
    // Updates current step
    this.currentStepService = currentStep;
    return new Promise((resolve) => {
      /*
      ** New Tour
      **
      ** In a new tour, the current step is undefined
      */
      if (route && (isNaN(this.currentStepService) || this.currentStepService === undefined)) {
        // Get tour from DB
        this.getTourByRoute(route).subscribe((res) => {
          const result = res;
          if (result) {
            this.tour = result;
            // Manipulate Ids with special chars
            this.tour.steps.map((obj) => obj.element = this.escapeChars(obj.element));
            // Set HTML on intro's tooltip
            this.tour.steps.map((step, index) => step.intro = this.innerHtmlIntro(index + 1, step.title, step.description));
            this.innerHtmlButtons(this.tour);

            this.loadTour(result._id.$oid, result);
            resolve(true);
          }
        });
        // Same Tour
      } else {
        /*
        ** Update current step
        ** Step Direction: 0 -> Same; 1 -> Next; -1 -> Back
        */
        this.currentStepService = this.currentStepService + stepDirection;
        resolve(true);
      }
    });
  }

  // Validate if element exists
  public validateDOMElement() {
    this.interval = setInterval(() => {
      // Maximum tries is N. If the number tries is higher, exit the tour
      if (this.findElementTry > MAX_TRY_DOM_FIND) {
        this.endTour();
        clearInterval(this.interval);
      } else {
        this.findElementTry++;
      }
      // In the first time, the current step is undefined
      if (!this.currentStepService) {
        this.currentStepService = 0;
      }
      // If element is in DOM
      if ($(this.tour.steps[this.currentStepService]['element']).length !== 0) {
        this.findElementTry = 0;
        // If is a modal
        if (document.body.className.includes('modal-open')) {
          setTimeout(() => {
            if (this.currentStepService === 0) {
              this.introJS.start();
            } else {
              this.introJS.goToStep(this.currentStepService).start();
            }
          }, TIME_TO_TRY);
        } else {
          if (this.currentStepService === 0) {
            this.introJS.start();
          } else {
            this.introJS.goToStep(this.currentStepService).start();
          }
        }
        // Update Next and Prev buttons visibility
        this.updateButtonVisibility('.introjs-prevbutton', this.tour.steps[this.currentStepService].btnPrev);
        this.updateButtonVisibility('.introjs-nextbutton', this.tour.steps[this.currentStepService].btnNext);
        clearInterval(this.interval);
      }
    }, TIME_TO_TRY);
  }

  // Verifies if is needed to get the tour, and then, sets its options
  public loadTour(routeId: string, tourData?: string): Promise<any> {
    return new Promise((resolve) => {
      // If tour is already loaded
      if (tourData) {
        this.introJS.setOptions(tourData);
        resolve(true);
      } else if (routeId) {
        // Otherwise, it loads from DB
        this.getTourById(routeId).subscribe((res) => {
          if (res) {
            this.tour = res;
            this.tour.steps.map((obj) => obj.element = this.escapeChars(obj.element));
            // Set HTML on intro's tooltip
            this.tour.steps.map((step, index) => step.intro = this.innerHtmlIntro(index + 1, step.title, step.description));
            this.innerHtmlButtons(this.tour);
          }
          this.introJS.setOptions(res);
          resolve(true);
        });
      }
    });
  }

  // Update the visibility status of Next and Prev buttons
  private updateButtonVisibility(selector: string, visibilityStatus: boolean) {
    const element = document.querySelector(selector);
    const visibility = visibilityStatus ? 'visible' : 'hidden';
    if (getComputedStyle(element).visibility !== visibility) {
      element.setAttribute('style', `visibility: ${visibility}`);
    }
  }

  // Set HMTL for OnBoarding's tooltip
  private innerHtmlIntro(index: number, title = 'Tour Title', description = 'Tour Description') {
    return `<div class="row">
              <div class="col-sm-8 onboarding-info">
                <div class="col-sm-12 onboarding-title">
                  <span class="onboarding-number">${index}</span>${title}
                </div>
                <div class="col-sm-12 onboarding-description">${description}</div>
              </div>
              <div class="col-sm-4 onboarding-img-bot">
                <img src="/assets/images/platform/logo.svg" alt="">
              </div>
            </div>`;
  }

  //
  private innerHtmlButtons(tour: any) {

    this.tour['skipLabel'] = SKIP_HTML;
    this.tour['prevLabel'] = PREV_HTML;
    this.tour['nextLabel'] = NEXT_HTML;
    this.tour['doneLabel'] = SKIP_HTML;


    return this.tour;
  }

  // Manually start tour
  public manualTrigger(tourID: string) {
    this.loadTour(tourID).then(() => {
      this.checkTour(undefined, 0)
        .then(() => {
          this.validateDOMElement();
        });
    });
  }
}