import { ChangeDetectionStrategy, Component, NgModule, Directive, EventEmitter, ElementRef, Renderer, Output, Input, SimpleChange, OnInit, OnChanges } from '@angular/core';

import { ExportService } from './table-export.service';
import { PageService } from './../../../foundations-webct-robot/robot/pageComponent/page.service';
import { Utils } from './../../../foundations-webct-robot/robot/utils/utils.service';
import { JsonParams } from './../../../foundations-webct-robot/robot/classes/jsonParams.class';
import { ToggleElementsService } from './../../../foundations-webct-robot/robot/services/toggle-elements.service';

import * as moment from 'moment/moment';
import { ComponentsService } from './../../../foundations-webct-robot/robot/services/components.service';
import { RobotEngineModel } from './../../../foundations-webct-robot/robot/robotEngineModel';
import { GlobalVarsService } from './../../../foundations-webct-robot/robot/utils/global-vars.service';
import { FormControl } from '@angular/forms';

@Component({
  selector: 'table-options-component',
  templateUrl: 'table-options.component.html',
  styleUrls: ['table-options.component.css']
})

export class TableOptionsComponent implements OnInit {

  @Input() public viewStructure: JsonParams;
  @Input() public allowReload = true;
  @Input() public rows: JsonParams;
  @Input() public columns: JsonParams[] = null;
  @Input() public isHorizontalFilter: boolean;
  @Input() public viewStructureContext: JsonParams[];

  @Input() public tableSearchHidden: number[] = [];

  @Output() public tableSearchHiddenChange: EventEmitter<any> = new EventEmitter();
  @Output() public pageChangeEmit: EventEmitter<any> = new EventEmitter();
  @Output() public newBeSearch: EventEmitter<any> = new EventEmitter();

  public tableActions: TableOptions = new TableOptions();
  public componentId: string;

  public clientSearch: JsonParams;
  public clientSearchControl: FormControl;

  private _columns: any[] = [];

  constructor(
    private _pageService: PageService,
    private _utils: Utils,
    private _robot: RobotEngineModel,
    private _globalVars: GlobalVarsService,
    private _component: ComponentsService,
    private _toggle: ToggleElementsService,
    private _exportService: ExportService) {

  }

  public getHtmlId = (param: JsonParams, type?: string, wct: boolean = true) => this._component.getHtmlId(param, type, wct);

  public ngOnInit() {
    this.componentId = this._utils.guid(4, '');

    if (this.viewStructure.id) {

      this.clientSearchControl = new FormControl();
      this.clientSearch = new JsonParams('search::' + this.viewStructure.id, '');
      this._globalVars.setPageParameters(this.clientSearch, this.componentId);

      // this.tableActions.search['beSearch'] = this._utils.findObjectInArray(this.viewStructure.parameters, 'backendSearch').value;

      // this.clientSearchControl.valueChanges.debounceTime(500).subscribe((val: string) => {
      //   this._robot.findDynamicPropsDependencies(this.clientSearch.id);
      //   if (this.clientSearchControl.dirty && this.tableActions.search['beSearch'])
      //     this.newBeSearch.emit(true);
      // });

    }

    this._evaluateTableActions();
  }

  public ngOnDestroy() {
    this._globalVars.deletePageParametersByGroup(this.componentId);
  }

  public exportTable() {
    this._exportService.generateCSV(this.columns, (this.rows.originalValue || this.rows), 'exportname');
  }

  public showColumns(column) {
    column.hidden = !column.hidden;
  }

  public pageChange(paginationSettings, loading: boolean = false, pageNumber: number = null) {
    this.pageChangeEmit.emit(true);
  }

  public toggleView(component: JsonParams) {
    this._utils.findObjectInArray(this.viewStructureContext, false, 'lazyLoading').lazyLoading = true;
    component.lazyLoading = false;
  }

  public searchOnTable(event: KeyboardEvent) {

    if (this.tableActions.search['beSearch']) {
      if (event.code == 'Enter')
        this.searchOnBe();
      return;
    }

    this.tableSearchHidden = [];
    let objValues = null;
    let rows = this.rows.value || this.rows;
    for (let i in rows) {
      objValues = Object.keys(rows[i]).map((key) => { return rows[i][key] }).join();
      if (objValues.indexOf(this.clientSearch.value) < 0)
        this.tableSearchHidden.push(+i);
    }

    this.tableSearchHiddenChange.emit(this.tableSearchHidden);
  }

  public searchOnBe() {

    if (!this.tableActions.search['beSearch'])
      return;

    this._robot.findDynamicPropsDependencies(this.clientSearch.id);
    if (this.clientSearchControl.dirty && this.tableActions.search['beSearch'])
      this.newBeSearch.emit(true);
  }

  public detach(id: string) {

    let buttonClick = document.getElementById(id + '-open');
    if (!buttonClick) return;
    let buttonClickPosition = buttonClick.getBoundingClientRect();

    let detachElement = document.getElementById(id);
    if (!detachElement) return;
    let detachElementPosition = detachElement.getBoundingClientRect();

    detachElement.classList.remove('hidden-from-user');
    detachElement.style.top = (buttonClickPosition.top + buttonClickPosition.height) + 'px';
    detachElement.style.left = (buttonClickPosition.left + buttonClickPosition.width - detachElementPosition.width) + 'px';
  }

  public getTooltipStatus = (id: string) => this._toggle.getTooltipStatus(id);
  public toggleTooltip = (id: string) => this._toggle.toggleTooltipStatus(id);

  private _evaluateTableActions() {

    let tableManualActions = this._utils.findObjectInArray(this.viewStructure.parameters, 'tableActions');
    tableManualActions.value = tableManualActions.value || {};

    this.tableActions.filter = this.isHorizontalFilter;
    this.tableActions.reload = this.allowReload;
    this.tableActions.switchViews = !(this._pageService.pageSize <= this._pageService.devices.desktop) && this.viewStructure.showComponentById && this.viewStructureContext.length > 1;
    this.tableActions.columnManager = tableManualActions.value['columnManager'] === false ? false : (this.columns && this.viewStructure.type !== "initCards" ? !!this.columns.find((obj) => { return obj.type != 'context' && obj.hidden !== undefined; }) : false);
    this.tableActions.export = tableManualActions.value['export'] !== undefined ? tableManualActions.value['export'] : true;
    this.tableActions.search = tableManualActions.value['search'] !== undefined ? tableManualActions.value['search'] : new Object();

    if (this.tableActions.search && typeof this.tableActions.search == 'boolean')
      this.tableActions.search = new Object();
  }

}

class TableOptions {
  filter: boolean;
  reload: boolean;
  switchViews: boolean;
  columnManager: boolean;
  export: boolean;
  search: boolean | Object;
}
