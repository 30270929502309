import { Component, Input, Output, EventEmitter, OnInit, OnDestroy } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { RobotEngineModel } from './../../../foundations-webct-robot/robot/robotEngineModel';
import { Utils } from './../../../foundations-webct-robot/robot/utils/utils.service';
import { GlobalVarsService } from './../../../foundations-webct-robot/robot/utils/global-vars.service';
import { PageService } from './../../../foundations-webct-robot/robot/pageComponent/page.service';
import { JsonParams } from './../../../foundations-webct-robot/robot/classes/jsonParams.class';

import { AutoCompleteDetailsService } from './auto-complete-details.service';
import { InputService } from './../../../foundations-webct-robot/robot/services/input.service';
import { ModalService } from './../../../foundations-webct-robot/robot/services/modal.service';

@Component({
  selector: 'auto-complete-details',
  template: ``
})

export class AutoCompleteDetailsComponent implements OnInit, OnDestroy {

  @Input() public inputParameters: JsonParams;
  @Input() public details: JsonParams[];
  @Input() public dataRecs: Object[];
  @Input() public baseInfoForm: FormGroup;
  @Input() public componentIndex = -1;
  @Input() public loadingFromModal = false;

  @Output() public baseInfoFormChange: EventEmitter<FormGroup> = new EventEmitter();

  private _dataParameters: Object;
  private _paramsToValidate: JsonParams[] = [];
  private _componentId: string;
  private _unsubscribeParams: any[] = [];
  private _mappingProps: JsonParams;

  constructor(
    private _robotEngineModel: RobotEngineModel,
    private _utils: Utils,
    private _globalVars: GlobalVarsService,
    private _inputService: InputService,
    private _modalService: ModalService,
    private _pageService: PageService,
    private _autoCompleteService: AutoCompleteDetailsService) {

    this._componentId = 'AUTODETAILS-' + this._utils.guid(4, '');

  }

  public ngOnInit() {

    this.details.splice(1);

    this._mappingProps = this._utils.findObjectInArray(this.inputParameters.parameters, 'mappingProps');
    this._dataParameters = this._utils.arrToObj(this._globalVars.getPageParametersAsArray());
    // this._autoCompleteService.autoCompleteValue = this._autoCompleteService.autoCompleteTotalFields || {};
    // this.inputParameters.value = this.inputParameters.mappingId ? (this._autoCompleteService.autoCompleteTotalFields || this.inputParameters.value) : this.inputParameters.value;

    if (this.dataRecs && Object.keys(this.dataRecs).length) {

      let newDetailParameter: JsonParams;
      this.dataRecs = this.dataRecs[0] ? this.dataRecs : [this.dataRecs];
      for (let i in this.dataRecs) {

        /**
         * PROPRIEDADES BASE
         */
        newDetailParameter = new JsonParams();

        this.mappingProps(newDetailParameter, this.dataRecs[i]);

        if (this.dataRecs[i]['conditions'] && this.dataRecs[i]['conditions']['condition']) {
          this._evaluateDynamicProps(newDetailParameter, this.dataRecs[i]['conditions']['condition'], this.dataRecs[i]);
        }

        if (newDetailParameter.templateOfValueList && newDetailParameter.valueList)
          this._inputService.loadValueListFromDataRecs(newDetailParameter, this.dataRecs, newDetailParameter.valueList);
        if (newDetailParameter.pathToValue) {
          newDetailParameter.pathToValue = this._utils.replaceTagVars(newDetailParameter.pathToValue, this.dataRecs[i]);
          newDetailParameter.originalValue = this._utils.getValueFromDataForThisKey(this._dataParameters, newDetailParameter.pathToValue);
          newDetailParameter.value = this._utils.formatValues(newDetailParameter, newDetailParameter.originalValue);
        }

        /**
         * DEFINE NOVOS "paramsToValidate" PARA CRIAR AS VALIDAÇÕES NECESSÁRIAS
         * TODO: ACTUALIZAR NO "page.component" AS MENSAGENS DE ERRO
         */
        if (newDetailParameter.validator)
          this._robotEngineModel.paramsToValidate.push(newDetailParameter);

        this.mappingIds(newDetailParameter);

        if (this.evaluateBoolean(newDetailParameter.mappingId) && !newDetailParameter.value && !newDetailParameter.valueList)
          continue;

        let dataForEvaluation = Object.assign(this._utils.arrToObj(this._globalVars.getPageParametersAsArray()), this.dataRecs[i]);

        this._robotEngineModel.changeParameterByDynamicProps(newDetailParameter, dataForEvaluation);

        newDetailParameter.observe().subscribe(obj => {
          this._evaluateRequestBody();
        });

        this.details.push(newDetailParameter);

        this._autoCompleteService.autoCompleteTotalFields[newDetailParameter.id] = '{{' + newDetailParameter.id + '}}';
      }

      // if (this.inputParameters.originalValue)
      //   Object.assign(this.inputParameters.value, this.inputParameters.originalValue);

      if (this.baseInfoForm && this._paramsToValidate.length > 0) {
        this.baseInfoForm = this._pageService.configValidators(this._paramsToValidate, this.loadingFromModal, this.componentIndex, true, this.inputParameters.id);
      }
      this.mappingValues();
    }

    for (let param of this.details)
      this._globalVars.setPageParameters(param, this._componentId);

    /**
     * TRABALHA O VALOR OBTIDO PELO COMPONENTE NA ESTRUTURA BASE, CONSOANTE AS INDICAÇÕES NO MOCK
     */
    this._evaluateRequestBody();

    if (this.baseInfoForm) {
      this.baseInfoFormChange.emit(this.baseInfoForm);
    }
  }

  public ngOnDestroy() {
    this._autoCompleteService.restartService();
    this._globalVars.deletePageParametersByGroup(this._componentId);

    this._unsubscribeParams.forEach(obj => {
      obj.unsubscribe();
    });

    if (this.inputParameters)
      this._pageService.deleteActiveForm(this.inputParameters.id);
  }

  private _evaluateAutoCompleteValue() {
    this._autoCompleteService.autoCompleteValue = this.inputParameters.originalValue || {};
    for (let i in this._autoCompleteService.autoCompleteTotalFields) {

      let param = this._globalVars.getPageParameter(i);
      if (!param)
        continue;

      let hiddenWithNoValue = param.hidden && (!param.value || (typeof param.value == 'object' && Object.keys(param.value).length == 0));
      hiddenWithNoValue = param.dynamicProps && param.dynamicProps['hidden'] !== undefined ? param.hidden : hiddenWithNoValue;

      if (!hiddenWithNoValue)
        this._autoCompleteService.autoCompleteValue[param.id] = this._autoCompleteService.autoCompleteTotalFields[i];

      if (this.baseInfoForm.controls[param.id] && (hiddenWithNoValue || param.type == 'label' || param.readonly))
        this.baseInfoForm.controls[param.id].disable();
    }
  }

  private _evaluateRequestBody() {

    this._evaluateAutoCompleteValue();
    this.inputParameters.value = this._autoCompleteService.autoCompleteValue;
    /**
     *
     * ATENÇÃO: DEVE SER POSSÍVEL ACEDER NO MOCK AO OBJECTO GERADO POR ESTE COMPONENTE (WEBCT-842)
     *
     */

    if (this._autoCompleteService.autoCompleteValue) {
      let componentLocation = '_' + (this.loadingFromModal ? 'modal' : 'page') + 'Service';
      if (this[componentLocation].viewStructure.groups && this[componentLocation].viewStructure.groups.buttons) {
        let btnSubmit: JsonParams = this._utils.findObjectInArray(this[componentLocation].viewStructure.groups.buttons.parameters, 'submit');
        let requestBody: JsonParams = this._utils.findObjectInArray(btnSubmit.parameters, 'requestBody');
        if (requestBody.key == 'requestBody') {

          // Fica à escuta de alterações ao requestBody. Caso este mude, é actualizado o novo com os parâmetros do formulário
          let subscription = requestBody.observe().subscribe((param) => {
            if (param.key == 'requestBody')
              return;
            this._evaluateRequestBody();
            param.observe().unsubscribe();
          });
          this._unsubscribeParams.push(subscription);

          let requestBodyString: string = typeof requestBody.originalValue == 'object' ? JSON.stringify(requestBody.originalValue) : requestBody.originalValue;
          if (requestBodyString.indexOf('{{' + (this._autoCompleteService.autoCompleteMainId || this.inputParameters.id) + '}}') >= 0) {

            this._autoCompleteService.autoCompleteMainId = this._autoCompleteService.autoCompleteMainId || this.inputParameters.id;

            // COMENTADO NO ÂMBITO DO ISSUE: WEBCT-902
            // let mainComponent = this._globalVars.getPageParameter(this._autoCompleteService.autoCompleteMainId);
            // console.log('mainComponent ---> ', mainComponent);
            // if (mainComponent.originalValue && typeof mainComponent.originalValue == 'object' && Object.keys(mainComponent.originalValue).length > 0) {
            //   console.log('mainComponent.originalValue ---> ', mainComponent.originalValue);
            //   Object.assign(this._autoCompleteService.autoCompleteValue, mainComponent.originalValue);
            // }

            requestBodyString = requestBodyString.replace('"{{' + this._autoCompleteService.autoCompleteMainId + '}}"', JSON.stringify(this._autoCompleteService.autoCompleteValue));
            requestBody.value = typeof requestBody.originalValue == 'object' ? JSON.parse(requestBodyString) : requestBodyString;
          }
        } else
          console.error('AutoCompleteDetailsComponent: "requestBody" not found.');
      }
    }
    // console.log('this.inputParameters.value ---> ', this.inputParameters.value);
  }

  /**
   * MAPEIA PROPRIEDADES ATRAVÉS DO PARÂMETRO "mappingProps"
   */

  private mappingProps(newDetailParameter: JsonParams, dataRecs: Object, createControler: boolean = true) {

    // let mappingProps = this._utils.findObjectInArray(this.inputParameters.parameters, 'mappingProps');
    if (!this._mappingProps || Object.keys(this._mappingProps).length <= 0)
      return;

    // this._mappingProps = this._mappingProps.value[0] ? this._mappingProps.value : [this._mappingProps.value];

    for (let i in this._mappingProps) {

      if (i == 'key' || newDetailParameter[i])
        continue;

      /**
       * COMPORTAMENTO ESPECÍFICO NO CASO DAS VALIDAÇÕES
       * TODO: VERIFICAR RESTANTES PARÂMETROS QUE DEFINEM O COMPORTAMENTO DO FORMULÁRIO DO OFFERBROWSING
       */
      if (i == 'validator' && this._mappingProps[i] && this._utils.isObjectType(this._mappingProps[i], 'Object')) {
        let validators = {};
        let currValidation: any = null;
        // for (let v in this._mappingProps[i]) {
        //   let mappingProp;
        //   if (Object.prototype.toString.call(this._mappingProps[i][v][0]) === '[object Array]') {
        //     const tmp = [];
        //     this._mappingProps[i][v].forEach((entry) => {
        //       mappingProp = this._evalueateMappingProps(entry, dataRecs);
        //       if (mappingProp) {
        //         tmp.push(mappingProp);
        //       }
        //     });
        //     if (tmp.length > 0)
        //       validators[v] = tmp;
        //   } else {
        //     mappingProp = this._evalueateMappingProps(this._mappingProps[i][v], dataRecs);
        //     if (mappingProp)
        //       validators[v] = mappingProp;
        //   }
        // }

        let validatorsTemplate = this._utils.replaceTagVars(JSON.stringify(this._mappingProps.validator), dataRecs);
        validatorsTemplate = validatorsTemplate.replace(/"[^"]*"/g, key => {

          try {
            // Boolean
            return JSON.parse(key.slice(1, -1));
          } catch (e) {
            let number = +key.slice(1, -1);
            if (!isNaN(number))
              return number;
          }
          return key;
        });

        validatorsTemplate = this._removeMustache(newDetailParameter, validatorsTemplate);
        try {
          validators = JSON.parse(validatorsTemplate);
        } catch (e) {
          validators = JSON.parse(validatorsTemplate.replace(/\\/g, '\\\\'));
        }
        // console.log('validators ---> ', validators);

        newDetailParameter[i] = this._robotEngineModel.loadParameterValidators(validators);

        if (createControler)
          this._paramsToValidate.push(newDetailParameter);

        this._autoCompleteService.autoCompleteParametersToValidate.push(newDetailParameter);
        continue;
      }
      // if (i == 'validator')
      //   continue;

      /**
       * COMPORTAMENTO ESPECÍFICO NO CASO DOS PARÂMETROS
       * É APENAS MAPEADO UM CONJUNTO DE PARÂMETROS - AQUELE QUE CONSEGUIR VALOR PARA TODAS AS PROPRIEDADES
       */
      if (i == 'parameters' && this._mappingProps[i]) {
        let mappingSuccess: boolean;
        let properties: JsonParams;
        for (let p in this._mappingProps[i]) { // CADA PARÂMETRO
          properties = new JsonParams();
          mappingSuccess = true;
          for (let pp in this._mappingProps[i][p]) { // CADA PROPRIEDADE DO PARÂMETRO

            if (!this._mappingProps[i][p][pp] || typeof this._mappingProps[i][p][pp] != 'string') {
              delete properties[pp];
              continue;
            }

            properties[pp] = this.evaluatePropsValue(this._mappingProps[i][p][pp], dataRecs);
            if (!properties[pp]) {
              // CASO UMA DAS PROPRIEDADES NÃO TENHA VALOR, PASSA PARA O PARÂMETRO SEGUINTE
              mappingSuccess = false;
              break;
            }
          }
          if (!mappingSuccess) {
            properties = null;
            continue;
          }
          break;
        }
        if (properties) {
          if (properties.key == 'innerParameter') {

            let innerParameter = new JsonParams();
            let innerParameters: JsonParams[] = [];

            properties['valueList'] = properties['valueList'] instanceof Array ? properties['valueList'] : [properties['valueList']];
            if (properties['valueList'].length > 0) {
              newDetailParameter.valueList = [];
              for (let p in properties['valueList']) {
                innerParameter = new JsonParams();
                this.mappingProps(innerParameter, properties['valueList'][p], false);
                newDetailParameter.valueList.push(innerParameter);
              }
            }
          } else {
            // MAPEIA AS PROPRIEDADES QUANDO TODAS AS DEFINIDAS NO MOCK TÊM VALOR
            for (let p in properties)
              newDetailParameter[p] = properties[p];
          }
        }
        continue;
      }

      if (typeof this._mappingProps[i] == 'string' && this._mappingProps[i].match(/^eval\(.*\)$/g)) {
        try {
          let evalStr = this._utils.prepareStringForEval(this._utils.replaceTagVars(this._mappingProps[i].slice(5, -1), dataRecs));

          let evalResult = eval(evalStr);
          newDetailParameter[i] = this.evaluatePropsValue(evalResult, dataRecs);
          continue;

        } catch (e) {
        }
      }

      /**
       * COMPORTAMENTO POR DEFEITO
       */
      newDetailParameter[i] = this.evaluatePropsValue(this._mappingProps[i], dataRecs);
    }
  }

  private _evalueateMappingProps(mappingProp, dataRecs) {
    if (!mappingProp || !mappingProp[0]) return false;

    let currValidation: any = this._utils.replaceTagVars(mappingProp[0], dataRecs);
    if (currValidation === undefined || currValidation.match(this._utils.mustacheReg))
      return false;

    if (mappingProp[0] == '{{optional}}' && currValidation === 'true')
      return false;
    if (mappingProp[0] == '{{optional}}' && currValidation === 'false')
      currValidation = true;

    try {
      currValidation = JSON.parse(currValidation);
    } catch (e) { }

    return [currValidation, mappingProp[1]];
  }

  /**
   * MAPEIA VALOR DOS NOVOS CAMPOS ATRAVÉS DO PARÂMETRO "mappingValues", PROCURANDO NA RESPOSTA DA API O SEU VALOR
   */
  private mappingValues() {

    let mappingConfiguration = this._utils.findObjectInArray(this.inputParameters.parameters, 'mappingValues');
    if (!mappingConfiguration || !mappingConfiguration.key)
      return;

    let parameterConfiguration: any;
    let updateDetailParameter: JsonParams;
    let parametersToMap = this._utils.getValueFromDataForThisKey(this._pageService.dataRecs, mappingConfiguration.pathToValue);
    for (let i in parametersToMap) {
      parameterConfiguration = JSON.parse(this._utils.replaceTagVars(JSON.stringify(mappingConfiguration.value), parametersToMap[i]));
      updateDetailParameter = this._utils.findObjectInArray(this.details, parameterConfiguration.id, 'id');

      if (!updateDetailParameter || !updateDetailParameter.id)
        continue;

      updateDetailParameter.value = this._utils.getValueFromDataForThisKey(this._dataParameters, parameterConfiguration.pathToValue);
      updateDetailParameter.hidden = !(!!updateDetailParameter.value);
      updateDetailParameter.readonly = this.evaluateBoolean(parameterConfiguration.readonly);

      // if (this._autoCompleteService.autoCompleteValue && updateDetailParameter.hidden)
      //   delete this._autoCompleteService.autoCompleteValue[updateDetailParameter.id];
    }
  }

  /**
   * MAPEIA VALOR DO PARÂMETRO COM OUTRO QUE LHE ESTEJA ASSOCIADO ATRAVÉS DO "mappingId"
   */
  private mappingIds(parameter: JsonParams) {

    if (!this.evaluateBoolean(parameter.mappingId))
      return;

    let mappingParameter = this._utils.findObjectInArray(this._globalVars.getPageParametersAsArray(), parameter.id, 'mappingId');
    if (!mappingParameter || !mappingParameter.value) {
      /**
       * CASO O CAMPO EM QUESTÃO TENHA UM "mappingId" MAS NÃO ENCONTRE O SEU VALOR, ESTE É ESCONDIDO
       */
      parameter.hidden = true;
      return;
    }
    /**
     * ACTUALIZA O VALOR DO CAMPO EM QUESTÃO E DESABILTA A POSSIBILIDADE DE EDIÇÃO
     */
    parameter.value = mappingParameter.value;
    parameter.readonly = true;
  }

  private evaluateBoolean(val: any): boolean {
    if (!val) return false;
    if (typeof val == 'boolean') return val;
    if (val.indexOf('true') >= 0) return !(val.indexOf('!') >= 0);
    if (val.indexOf('false') >= 0) return val.indexOf('!') >= 0;
    return false;
  }

  private evaluatePropsValue(str: any, dataRecs: Object) {
    if (typeof str == 'string' && str.match(/^{{.*}}$/g))
      return this._utils.getValueFromDataForThisKey(dataRecs, str.slice(2, -2));
    return str;
  }

  private _evaluateDynamicProps(newDetailParameter: JsonParams, dynamicProps: any, dataRecs: any) {

    let getCondition = (and: any, conditionRes: any) => {
      if (operatorsTemplate[and['operator']] === undefined)
        return `'${and['parameterName']}' ${and['operator']} '${and['value']}'`;
      else
        return this._utils.replaceTagVars(operatorsTemplate[and['operator']], and);
    };

    let formatConditionRule = (attr: string, rule: string, data: any) => {
      if (!rule)
        return data[attr];

      return this._utils.replaceTagVars(rule, data);
    };

    newDetailParameter.dynamicProps = newDetailParameter.dynamicProps || {};
    let operatorsTemplate = {
      "equal": "'{{{{parameterName}}}}' == '{{value}}'",
      "not_equal": "'{{{{parameterName}}}}' != '{{value}}'",
      "include": "'{{{{parameterName}}}}'.indexOf('{{value}}') >= 0",
      "not_include": "'{{{{parameterName}}}}'.indexOf('{{value}}') < 0"
    };

    let conditionTypeMap = {
      "visible": "hidden",
      "readonly": "disabled",
      "enumValues": "valueList"
    };
    let conditionTypeRule = {
      "visible": "!{{visible}}"
    };

    if (Object.prototype.toString.call(dynamicProps) === '[object Object]')
      dynamicProps = [dynamicProps];

    for (let param of dynamicProps) {

      let conditionType = param['conditionType'];
      let conditionTypeOnWct = conditionTypeMap[param['conditionType']] || param['conditionType'];

      // let conditionTypeRule = this._mappingProps[conditionTypeOnWct];

      let joinOrStatments: string[] = [];
      let conditionCustomValue: boolean;

      if (Object.prototype.toString.call(param['rules']) === '[object Object]')
        param['rules'] = [param['rules']];

      for (let or of param['rules']) {

        if (Object.prototype.toString.call(or['rule']) === '[object Object]')
          or['rule'] = [or['rule']];

        let joinAndStatments: string[] = [];
        let joinAndStatmentsValue = '';
        for (let and of or['rule']) {

          let conditionRes = {
            onTrue: this._utils.convertToString(formatConditionRule(conditionType, conditionTypeRule[conditionType], dataRecs)),
            onFalse: this._utils.convertToString(formatConditionRule(conditionType, conditionTypeRule[conditionType], dataRecs))
          };

          conditionCustomValue = and[conditionType] !== undefined;
          if (conditionCustomValue) {
            conditionRes.onTrue = this._utils.convertToString(this._apliPathToValues(newDetailParameter, new Object({ [conditionType]: and[conditionType] }), conditionTypeOnWct), ['object']);
            conditionRes.onFalse = '$elseStatement';
          } else if (param['rules'].length > 1)
            conditionRes.onFalse = '$elseStatement';

          joinAndStatments.push(getCondition(and, conditionRes));

          joinAndStatmentsValue = ` ? ${conditionRes.onTrue} : !${conditionRes.onFalse}`;
          if (conditionCustomValue || param['rules'].length > 1)
            joinAndStatmentsValue = joinAndStatmentsValue.replace(': !', ': ');
        }

        joinOrStatments.push(`${joinAndStatments.join(' && ') + joinAndStatmentsValue}`);
      }

      let apiDynamicProps = this._mappingProps.parameters.find(obj => obj.key == 'apiDynamicPropsTemplate');
      let attrDynamicProps = apiDynamicProps ? apiDynamicProps.value[conditionTypeOnWct] : null;

      let finalCondition = joinOrStatments.reduce((total, str) => total.replace('$elseStatement', str));

      if (finalCondition.indexOf('$elseStatement') >= 0) {
        if (conditionCustomValue)
          finalCondition = finalCondition.replace('$elseStatement', this._utils.convertToString(this._apliPathToValues(newDetailParameter, new Object({ [conditionType]: dataRecs[conditionType] }), conditionTypeOnWct), ['object']));
        else
          finalCondition = finalCondition.replace('$elseStatement', '!' + dataRecs[conditionType]);
      }
      // console.log('newDetailParameter.id ---> ', newDetailParameter.id);
      // console.log('finalCondition ---> ', conditionTypeOnWct, finalCondition);

      if (attrDynamicProps)
        newDetailParameter.dynamicProps[conditionTypeOnWct] = this._dynamiPropsFusion(conditionTypeOnWct, finalCondition, attrDynamicProps);
      else
        newDetailParameter.dynamicProps[conditionTypeOnWct] = finalCondition;
      // if (conditionType == 'hidden' && newDetailParameter.id == 'externalAuthentication')
      //   newDetailParameter.dynamicProps['log'] = joinOrStatments.join(' || ');
    }
  }

  private _removeMustache(param: JsonParams, str: string): string {

    str = this._utils.prepareStringForEval(str);
    str = str.replace(/\(\(+[a-zA-Z0-9]+\)\)/g, key => `{{${key.slice(2, -2)}}}`);
    return str.trim();
  }

  private _dynamiPropsFusion(attr: string, apiCondition: string, mockCondition: string) {

    return apiCondition;

    // let formatToFusion = apiCondition.replace(this._utils.extendedMustacheReg, str => `@replaceTagVars('${str.slice(2, -2)}')`);
    // let fusion = mockCondition.replace(new RegExp('\\$' + attr, 'g'), formatToFusion);
    // return `{{@fv()${fusion}}}`;
  }

  private _apliPathToValues(param: JsonParams, value: any, attr: string) {

    if (attr == 'valueList' && param.pathToValueList)
      value = this._utils.getValueFromDataForThisKey(value, param.pathToValueList);
    return value;
  }
}
