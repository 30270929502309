import { Injectable } from '@angular/core';
import { Router } from '@angular/router';

@Injectable({
  providedIn: 'root'
})
export class PrintService {
  isPrinting = false;
  data: any = {};


  constructor(private router: Router) { }


  //printDocument(documentName: string, documentData: string[]) {
  printDocument(superheroId: string, paymentId: any) {

    console.info("printDocument: " + superheroId + " " + paymentId);

    this.isPrinting = true;
    this.router.navigate(['/',
      {
        outlets: {
          'print': ['superheros', superheroId, 'print', 'payments', paymentId]
        }
      }], { skipLocationChange: true });


  }

  printDocument_original(documentName: string, documentData: string[]) {
    this.isPrinting = true;
    this.router.navigate(['/',
      {
        outlets: {
          'print': ['print', documentName, documentData.join()]
        }
      }], { skipLocationChange: true });


  }

  onDataReady() {
    setTimeout(() => {
      window.print();
      this.isPrinting = false;
      this.router.navigate([{ outlets: { print: null } }]);
    });
  }
}
