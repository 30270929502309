// import { map } from 'rxjs/operators';
// import { ModalService } from './../foundations-webct2-palette/components/modalComponent/modal.service';
import { ModalService} from '../foundations-webct-robot/robot/services/modal.service';

import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { PageService } from '../foundations-webct-robot/robot/pageComponent/page.service';
import { GlobalVarsService } from '../foundations-webct-robot/robot/utils/global-vars.service';
import { CustomEventManagerService } from '../customComponents/custom-event-manager.service';
import { id } from '@swimlane/ngx-charts/release/utils';

@Injectable({
  providedIn: 'root',
})
export class TestService {

  //private programVariables = [];
  public program: Program;
  public testSet: TestSet;
  public count=0;

  httpOptions = {
    headers: new HttpHeaders({
      'Accept': 'application/json; charset=utf-8',
      'Cache-Control': 'no-cache',
      'Authorization': 'Bearer ' + localStorage.token,
      'IXS': localStorage.IXS_id
    })
  };

  constructor(
    private pageService: PageService,
    private http: HttpClient,
    private globalVars: GlobalVarsService,
    private modalService: ModalService,
    private eventManager:CustomEventManagerService) {

  }

  /**
   * Load data from BE to internal variable.
   * @param rawData Raw data from resource
   */

  public setTestSet(rawData: any) {
    this.program = new Program();
    this.program._id = rawData._id;
    this.program.name = rawData.name;
    this.program.code = "rawData.code";
    this.program.version = "rawData.version";
    this.program.chapters = null;
    this.program.intents = this.setProgramIntents(rawData);
    this.program.variables = null;
    //this.program.variables = this.setProgramVariables(rawData.variables);

    this.program.model = new Model();
    this.program.model = null;

  }
  public exectutions=[];
  public errorMockTestSetSelected="";
  

  // public setTestSet(rawData: any):TestSet {
    
  //   let ts:TestSet = new TestSet();



  //   ts._id = rawData._id;
  //   ts.description = rawData.description;
  //   ts.date = rawData.date;
  //   ts.tests = rawData.tests;
  //   ts.status = "finesh";//rawData.status;
    
  //   return ts;
  // }


  public executeTestSetId(id:string){
    let path = this.pageService.getUrlFromConfig("{{testService}}/execute/"+ id);
    return this.http.post(path, this.httpOptions).toPromise().then(response => {
      
      console.log("response: ",response);

    });
  }

  public reloadTestsScenario(){
    this.exectutions=[];
    let path = this.pageService.getUrlFromConfig("{{AppAPIBotConfig}}/testScenario");
    return this.http.get(path, this.httpOptions).toPromise().then(response => {
      
      console.log("response: ",response);

      var arr = response["_embedded"];
      
      console.log("response: ",arr);
      
      for(let r of arr){
        this.exectutions.push(r);
      }

    });
  }

  public reloadProgram(){
    let path = this.pageService.getUrlFromConfig("{{AppAPIBotConfig}}/tests/"+this.globalVars.urlParams['id']+"?filter={\"IXS\":\""+localStorage.IXS_id+"\"}");
    return this.http.get(path, this.httpOptions).toPromise().then(response => {
      this.setTestSet(response);

    });
  }
  
  public getSpecificMock(testErrorSelected, step, line):any{
    let id = testErrorSelected.executeTests[0];
    let path = this.pageService.getUrlFromConfig("{{AppAPIBotConfig}}/tests/"+id);
    this.http.get(path, this.httpOptions).toPromise().then(response => {

    
    let lstArr = response["dialogs"];  
    var lst = Object.keys(lstArr)
    console.log("lst -> ", Object.keys(lstArr))


    for(let i = 0; i < lst.length; i++){
      
      console.log("lst[i][value] -> ", response["dialogs"][lst[i]]);

      if(lst[i] === step){
        let val = this.formatMockConversation(response["dialogs"][lst[i]], line);
        console.log("viewMock -> ", val);
        this.errorMockTestSetSelected =val;
        return val;
      }
    }

      return "";

    });
  }

  public reloadProgramVariables(){
    let path = this.pageService.getUrlFromConfig("{{SchoolBE}}/programs/"+this.globalVars.urlParams['programID']);
    return this.http.get(path, this.httpOptions).toPromise().then(response => {
      this.program.variables = this.setProgramVariables(response['variables']);
    });
  }

  public variableExists(varName){
    return this.program.variables.map(variable => variable.displayName).includes(varName);
  }

  public intentExists(intentName){
    return this.program.intents.map(intent => intent['name']).includes(intentName);
  }

  /**
   * Return programs variables list
   */
  public getProgramVariables(): Variable[] {
    return this.program.variables;
  }


  /**
   * Filter variables from program variables to show only intent variables
   * @param variables Program variables list
   */
  public setProgramVariables(variables: any) {

    let varList = variables.filter(el=>el['entity']!=null);

    varList = varList.map(function (v) {
      v.entityTypeDisplayName = v.entity;
      return v;
    });

    return varList;
  }





  /**
   * returna program intents list
   */
  public getProgramIntents(): Intent[] {
    return this.program.intents;
  }

  public getIntentIndex(intent){

    let index = this.getProgramIntents().findIndex(((i) => {
      return intent.displayName === i.displayName;
    }));

    return index;
  }

  /**
   *
   * @param intents raw intents list
   */
  public setProgramIntents(program: any) {

    let ine={"name":"","index":"","isUsed":"false","showOnList":"true"}
    var arr=[];
    const array1=Object.keys(program.dialogs);

    for (let i = 0; i < array1.length; i++) {
      console.log ("Block statement execution no." + i);
      let ine={"name":"","index":0,"isUsed":"false","showOnList":"true", "step":[]}
      ine.name=array1[i];
      ine.index=i;
      ine.step=program.dialogs[array1[i]]
      arr.push(ine)
    }


    // intentsList=arr

    

    // let intentsList = program.dialogs.map(function (i, index) {
    //   console.log("index", i);
    //   console.log("index", index);
    //   //i = this.getDisplayNameFormated(i, this.program.code+'_');
    //   i.name="teste"//Object.keys(i)
    //   i.index = index;
    //   //isto estava a ser feito no backend
    //   //i.isUsed = !!(program.connections && program.connections.find((c) => c.intent && c.intent.name === i.displayName));
    //   i.isUsed = false
    //   i.showOnList = true;

    //   return i;
    // }.bind(this));
    //let intentsList2 = program.dialogs
    console.log("intentsList", arr);
    return arr;
  }

  public getKeyByValue(object) {
    return Object.keys(object);
  }

  public setProgramDeprecated(){

    let body = {
      "$set": {
        "model.status": "deprecated"
      }
    }
    let path = this.pageService.getUrlFromConfig("{{SchoolBE}}/programs/"+this.globalVars.urlParams['programID']);

    return this.http.patch(path, body, this.httpOptions).toPromise();
  }

  public getVariablesToAutoCompleter(intentVariables){

    intentVariables = intentVariables.map(function (v) {
      v.entityTypeDisplayName = v.entity;
      return v;
    });

    return [this.program.variables, intentVariables];
  }



  public generateTrainingPhrasesList(trainingPhrases: any, programCode: string = '') {



    const trainingPhrasesListResult: object[] = [];
    try {

      const entityNamePrefix = programCode ? '@' + programCode + '_' : '';
        const trainingPhrasesInputAsObj = typeof trainingPhrases === 'string' ? JSON.parse(trainingPhrases) : trainingPhrases;

        trainingPhrasesInputAsObj.forEach(line => {

            if (line && line != []) {

                let parts = [];
                line.forEach(part => {

                    var tokens = part.type.split(" ");

                    if(tokens.includes('text')){
                        parts.push({ text: part.value });
                    } else if(tokens.includes('variable')){
                        let textTemp = part.value.split("|")[0].trim().replace("`", "");
                        let aliasTemp = part.value.split("|")[0].trim().replace("`", "");
                        let entityTypeTemp = part.value.split("|")[1].trim().replace("`", "");

                        entityTypeTemp = entityTypeTemp.startsWith("@sys.") ? entityTypeTemp : entityNamePrefix + entityTypeTemp;
                        parts.push({
                            text: textTemp,
                            alias: aliasTemp,
                            entityType: entityTypeTemp
                        })
                    }
                });
                if (parts.length > 0) {
                    trainingPhrasesListResult.push({ type: "EXAMPLE", parts: parts, timesAddedCount: 2 });
                }


            }

        });

    } catch (err) {
        console.log(err);
        return console.error('generateTrainingPhrasesList - trainingPhrases:', trainingPhrases);
    }
    return trainingPhrasesListResult;
  }



  addDialogsStep(name: string, arr: any) {
    // console.log('createStep: ', name)
    // let body = {
    //     "dialogs": {
    //         [name]: arr
    //     }
    // }
    console.log('createStep: ', name)
    const field:string = "dialogs."+name

    let body = {
      [field]:  arr
    }

    console.log("body: " + JSON.stringify(body))

    let path = this.pageService.getUrlFromConfig("{{SchoolBE}}/tests/"+this.globalVars.urlParams['id']);

    return this.http.patch(path, body, this.httpOptions).toPromise();

}

  public cleanEmptyLines(lines:any){
    var linePhrases = []
    linePhrases=lines;

    var arrClean=[];
    //Percorrer o array
    for(let i=0; i<linePhrases.length; i++){
      
      //verificar se a linha não é null ([[{"type":"text","value":"a3"}],[],null])
        if (linePhrases[i]){
          //verificar se a linha não é array vazio ([[{"type":"text","value":"a3"}],[],null])
          if (linePhrases[i].length > 0){
            arrClean.push(linePhrases[i]);
          }
        }
    }

    return arrClean;
  }

  createStep(name: string, description: string) {
    console.log('createMock: ', name)
    const field:string = "dialogs."+name
    const arr=[];
    let body = {
      [field]:  []
    }
    // let body = {
    //       "dialogs": {
    //           [name]: arr
    //       }
    //   }

    console.log("body: " + JSON.stringify(body))

    let path = this.pageService.getUrlFromConfig("{{SchoolBE}}/tests/"+this.globalVars.urlParams['id']);

    return this.http.patch(path, body, this.httpOptions).toPromise();

  }

  public removeIntent(intent){
    let path = this.pageService.getUrlFromConfig("{{SchoolBE}}/programs/"+this.globalVars.urlParams['programID']+"/intents/"+intent.displayName);

    return this.http.delete(path, this.httpOptions).toPromise();

  }

  changeIntentName(name: string, intent:Intent) {
    let body = {
      "name": this.program.code + "_" + name.trim()
    }
    let path = this.pageService.getUrlFromConfig("{{SchoolBE}}/programs/"+this.globalVars.urlParams['programID']+"/intents/"+intent.displayName+"/name");

    return this.http.patch(path, body, this.httpOptions).toPromise();

  }

  changeIntentDescription(description: string, intent:Intent) {
    let body = {
      "$set": {
        "lessons.[intentIdx].description": description
      }
    }
    let path = this.pageService.getUrlFromConfig("{{SchoolBE}}/programs/"+this.globalVars.urlParams['programID']+"/intents/"+intent.displayName);

    return this.http.patch(path, body, this.httpOptions).toPromise();

  }


  public saveTrainingPhrases(trainingPhrases, displayName){

    console.log("trainingPhrases:"+trainingPhrases)

    let body = {
      "$set": {
          "lessons.[intentIdx].trainingPhrases": this.generateTrainingPhrasesList(trainingPhrases, this.program.code)
      }
    }
    let path = this.pageService.getUrlFromConfig("{{SchoolBE}}/programs/"+this.globalVars.urlParams['programID']+"/intents/"+displayName);

    return this.http.patch(path, body, this.httpOptions).toPromise();

  }

  public saveTrainingPhrasesVariableList(usedVariables, intentIdx, displayName){

    let parameters = usedVariables.map((variable) => {
      let param = {
        "isList": false,
        "displayName": variable.displayName,
        "entityTypeDisplayName": variable.nature =='custom' ? '@' + variable.entity : variable.entity,
        "value": "$" + variable.displayName
    }

      return param;
    });

     let body = {
        "$set": {
          "lessons.[intentIdx].parameters": parameters
      }
    }
    let path = this.pageService.getUrlFromConfig("{{SchoolBE}}/programs/"+this.globalVars.urlParams['programID']+"/intents/"+displayName);

    return this.http.patch(path, body, this.httpOptions).toPromise();

  }






  public variableStrToObj(str = "") {
    let textTemp = str.split("|")[0].trim().replace("`", "");
    let aliasTemp = str.split("|")[0].trim().replace("`", "");
    let entityTypeTemp = str.split("|")[1].trim().replace("`", "");

    entityTypeTemp = entityTypeTemp.startsWith("@sys.") ? entityTypeTemp : entityTypeTemp;


    return {
      text: textTemp,
      alias: aliasTemp,
      entityType: entityTypeTemp
    };
  }

  public getCustomEntitiesList(){
    return this.program.chapters.map(chapter => {
      return {
          label:this.getDisplayNameFormated(chapter.displayName, this.program.code+"_") , value:chapter.displayName
        };
      }
    );
  }

  public getSystemEntitiesList(){
    let path = this.pageService.getUrlFromConfig("{{AppAPIBotConfig}}/RasaSystemEntities/RasaSystemEntities");
    return this.http.get(path, this.httpOptions).toPromise().then(response => {
      return response;
      //console.info("getSystemEntitiesList",response);
/*       return this.program.chapters.map(chapter => {
        console.info("getSystemEntitiesList", {
          chapter
        });
        return {
            label:chapter.entities[0].value , value:chapter.displayName
          };
        }
      ); */
    });

  }



  addVariableToProgram(name: string, description: string = "", nature:string, entityType: string) {

    let body = {
      "$push":{
        "variables":{
          "displayName":name.trim(),
          "description":description.trim(),
          "initialValue": null,
          "nature":nature,
          "entity":entityType,
          "channel":null
        }
      }
    }
    let path = this.pageService.getUrlFromConfig("{{SchoolBE}}/programs/"+this.globalVars.urlParams['programID']);

    return this.http.patch(path, body, this.httpOptions).toPromise();

  }

  /**
   * from customUtils
   **/
  public formatTrainingPhrase(parts: string[]) {
    const VARIABLE_ENTITY_START = '`';
    const VARIABLE_ENTITY_END = '`';
    const VARIABLE_ENTITY_SEPARATOR = ' | ';
    if (!parts) {
        return parts;
    }

    return parts.map(elem =>
        elem['entityType'] ? (
            VARIABLE_ENTITY_START +
            elem['text'] +
            VARIABLE_ENTITY_SEPARATOR +
            this.getDisplayNameFormated(elem['entityType'], '_') +
            VARIABLE_ENTITY_END
        ) : elem['text']).join('');
  }

  public formatMockConversation(list, line) {
    console.log("list: " + list);
      let text = '';
     // list = this.forceArray(list);
      let i =0;
      for (let tf of list) {
        i++;
        console.log("tftftf: " + tf);
        if (i+""===line){
          text += "line " + line + " - ***** - user: " + tf.q + " *****" + "\n";
          text += "VA: " + tf.a + "\n";
        }else{
          text += "user: " + tf.q + "\n";
          text += "VA: " + tf.a + "\n";
        }
      }
      return text;
  }
  /**
  * from customUtils
  **/
  public formatTrainingPhrases(list) {
    console.log("list: " + list);
      let text = '';
     // list = this.forceArray(list);

      for (let tf of list) {
        console.log("tftftf: " + tf);
          text += tf.q + "\n";
      }
      return text;
  }

  /**
  * from customUtils
  **/
 public formatTrainingPhrasesAnswers(list) {
  console.log("list: " + list);
    let text = '';
   // list = this.forceArray(list);

    for (let tf of list) {
      console.log("tftftf: " + tf);
        text += tf.a + "\n";
    }
    return text;
}

  public getDisplayNameFormated(displayName: string, beginCharacters?: string) {
    if (!displayName || !beginCharacters) {
        return displayName;
    }

    const refIdx = displayName.indexOf(beginCharacters);
    if (refIdx !== -1 && refIdx < displayName.length) {
        return displayName.substr(refIdx + beginCharacters.length);
    }
    return displayName;
  }


  /**
  * from customUtils
  **/
  public forceArray = (val: any): any[] => val.constructor !== Array ? [val] : val;


}

export class TestSet {
  constructor(){}
  _id: any;
  status: string;
  description: string;
  vaId: string;
  date: string;
  tests: any;
}

export class Program {
  constructor(){}
  _id: any;
  name: string;
  code: string;
  version: string;
  intents: any[];
  variables: Variable [];
  chapters: any[];
  model: Model;

  /**IXS: "IXS.ALB."
apiKeys: {private: "c69b2774188248ac9ea8982239af174a"}
belongsTo: [null]
categories: null
chapters: [{displayName: "A09_MSISDN", kind: "KIND_MAP", entities: [{value: "MSISDN", synonyms: ["MSISDN"]}]},…]
code: "A09"
connections: [{type: "line", subType: "", isSelected: false, isSujestion: false,…},…]
createdBy: "albuser@mail.pt"
creationDate: "2019-06-18T11:14:27.307+01:00"
description: "Program para testes de front-end"
googleSheetsId: null
language: null
lastPublishedBy: null
lastPublishedDate: null
lastPublishedVersion: null
lastUpdate: "2019-06-18T11:14:27.307+01:00"
lessons: [{displayName: "A09_PIN e PUK", mlEnabled: true, inputContextNames: [],…},…]
model: {status: "deprecated", trainStart: "2019-07-04T15:57:41.384Z", trainEnd: "2019-07-04T15:57:41.965Z",…}
name: "nao_apagar"
public: null
shapes: [{type: "start", icon: "start", faCode: "fas fa-circle",…},…]
status: 1
type: "flow"
variables: [{displayName: "tentErrada", description: "Tentativas erradas", initialValue: "0", nature: null,…},…]
version: "V1.0"
_id: {$oid: "5d08b982339bdccd5d1724ed"} */
}

export class Model {
  constructor(){}

  status: string;
}
export interface Intent {
  _id:any;
  code: string;
  version: string;
  name:string
  intents:[];
  variables: Variable [];
  action: string;
  description: string;
  displayName: string;
  trainingPhrases:[];
  showOnList:boolean;
}


export interface Variable {
  channel: string;
  description: string;
  displayName: string;
  entity: string;
  initialValue: string;
  nature: string;
}
