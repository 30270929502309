// import { ModalService } from './../../foundations-webct2-palette/components/modalComponent/modal.service';
import { ModalService} from './../../foundations-webct-robot/robot/services/modal.service';

import { CustomEventManagerService } from '../../customComponents/custom-event-manager.service';
import { IntentsService } from '../intent.service';
import { Component, Input, OnInit, OnDestroy, AfterViewInit } from '@angular/core';
import { PageService } from './../../foundations-webct-robot/robot/pageComponent/page.service';


@Component({
  selector: 'intents-manager',
  templateUrl: './main-intent.component.html',
  styleUrls: ['./main-intent.component.css']
})
export class MainIntentComponent implements OnInit, OnDestroy, AfterViewInit {



  /* @Input()  */public program;

  public editMode: any;
  public filterArgument;


  public programVariables;
  public intentVariables = [];
  public currentIntent;
  public autoCompleteLists;
  public currentIntentVariables;


  private genericActionSubscription;
  private newIntentCreatedSubscription;


  constructor(
    public pageService: PageService,
    public intentsService: IntentsService,
    private eventManager: CustomEventManagerService,
    private modalService: ModalService) {
  }

  public ngOnInit() {

    this.editMode = "VIEW";

    //load program to model
    //this.intentsService.setProgram(this.program);
/*     this.intentsService.reloadProgram().then(response => {
      this.program = this.intentsService.program;
    }); */

    this.genericActionSubscription = this.eventManager.genericAction.subscribe(
      (action) => {
        if (action == "INTENT_REMOVE_CONFIRMATION") {
          this.removeCurrentIntent();
        } else if (action == "INTENT_REMOVE_CANCELATION") {
          this.modalService.closeModal();
        } else if (action == "INTENT_CREATE_CANCELATION") {
          this.modalService.closeModal();
        }
      }
    );

    this.newIntentCreatedSubscription = this.eventManager.newIntentCreated.subscribe(
      () => {
        let last = this.intentsService.getProgramIntents().length - 1;
        this.currentIntent = this.intentsService.getProgramIntents()[last];
      }
    );

  }

  ngAfterViewInit(): void {

    /*     this.intentsService.reloadProgram().then(response => {
      this.program = this.intentsService.program;
      if (this.program.lessons && this.program.intents.length > 0) {
        this.openIntent(0);
      }

    }); */

    this.intentsService.reloadProgram().then(
      response => {
        if (this.intentsService.program.intents && this.intentsService.program.intents.length > 0) {
          this.currentIntent = this.intentsService.program.intents[0];
        }
      }
    );


  }



  private removeCurrentIntent() {

    let currentIndex = this.intentsService.getIntentIndex(this.currentIntent);
    this.intentsService.removeIntent(this.currentIntent).then((resp) => {
      this.intentsService.reloadProgram().then(response => {
        this.modalService.closeModal();
        if (this.intentsService.getProgramIntents().length > 0) {
          this.currentIntent = this.intentsService.getProgramIntents()[Math.min(currentIndex, this.intentsService.getProgramIntents().length - 1)];
        }

      });

    });

  }



  /**
   *
   * @param index @deprecated
   */
/*   public openIntent(index: number) {
    this.currentIntent = this.program.lessons[index];
  } */

  public intentSelected(data) {
    this.currentIntent = data[0];
  }

  public setEditMode(mode) {
    this.editMode = mode;
  }





  ngOnDestroy(): void {
    if (this.newIntentCreatedSubscription) {
      this.newIntentCreatedSubscription.unsubscribe();
    }

    if (this.genericActionSubscription) {
      this.genericActionSubscription.unsubscribe();
    }
  }
}
