import {Component, Input, OnInit} from '@angular/core';

import { RobotEngineModel } from './../../../foundations-webct-robot/robot/robotEngineModel';
import { Utils } from './../../../foundations-webct-robot/robot/utils/utils.service';
import { JsonParams } from './../../../foundations-webct-robot/robot/classes/jsonParams.class';

@Component({
  selector: 'app-wct-tags',
  templateUrl: './wct-tags.component.html',
  styleUrls: ['./wct-tags.component.css']
})
export class WctTagsComponent implements OnInit {

  @Input() public inputParameters: JsonParams;
  @Input() public inputDataRecs: Object;
  @Input() public dataRecs: Object;

  public details: JsonParams[][] = [];
  public eachValueListData: any[] = [];

  constructor(
    private _robot: RobotEngineModel,
    private _utils: Utils) {

  }

  public ngOnInit() {

    if (this.inputParameters.valueList && this.inputParameters.valueList.length > 0) {

      if (!this._utils.isObjectType(this.inputParameters.valueList, 'Array') || this.inputParameters.valueList.length == 0)
        this.inputParameters.valueList = [this.inputParameters.valueList || {}];

      let newDetail: JsonParams[];
      let contextData: Object = {};

      for (let i in this.inputParameters.valueList) {

        this.eachValueListData[i] = {[this.inputParameters.oid]: this._utils.cloneObject(this.inputDataRecs || this.dataRecs || this.inputParameters.value)};

        if(typeof this.inputParameters.valueList[i] == 'string')
          this.eachValueListData[i]['$primitiveValue'] = this.inputParameters.valueList[i];
        else
          Object.assign(this.eachValueListData[i], this._utils.cloneObject(this.inputParameters.valueList[i]));

        newDetail = [];
        for (let p in this.inputParameters.groups.details.parameters) {

          let clone: JsonParams = this.inputParameters.groups.details.parameters[p].clone('${' + i + '-' + p + '}');

          this._robot.updatePageWithData(clone, this.eachValueListData[i]);
          this._robot.changeParameterByDynamicProps(clone, this.eachValueListData[i]);

          newDetail.push(clone);
        }
        this.details.push(newDetail);
      }
    }
  }
}
