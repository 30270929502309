import { Component, Input, OnInit, OnDestroy} from '@angular/core';
import { PageService } from './../../foundations-webct-robot/robot/pageComponent/page.service';
import { JsonParams } from './../../foundations-webct-robot/robot/classes/jsonParams.class';
// import { ModalService } from './../../foundations-webct2-palette/components/modalComponent/modal.service';
import { ModalService} from './../../foundations-webct-robot/robot/services/modal.service';

@Component({
  selector: 'app-intent-variables',
  templateUrl: './intent-variables.component.html',
  styleUrls: ['./intent-variables.component.css']
})
export class IntentVariablesComponent implements OnInit, OnDestroy {

  @Input() public inputParameters: JsonParams;
  @Input() public filter;
  @Input() public filterDateField = 'creationDate';
  @Input() public dataRecsMainInfo;
  @Input() public inputDataRecs;
  @Input() public dataRecsNoContent;
  @Input() public editMode;


  public intentVariablesList = [];

  constructor(
    public pageService: PageService,
    private modalService: ModalService) {

  }


  @Input() set intentVariables (intentVariables){
    this.intentVariablesList = intentVariables;
  };



  public ngOnInit() {

  }

  public ngOnDestroy() {

  }

  public createVariable() {
    let operation = JSON.parse('{"size":"md", "id":"deleteGroupModal"}');
    let parameters = [];
    let jsonParams = new JsonParams();

/*     jsonParams.key = "context";
    jsonParams.value = [
      'originalName',
      'displayName'
    ];
    parameters.push(jsonParams);
    let obj = { 'originalName': intent.displayName, 'displayName': intent.name } */
    let obj = {};
    this.modalService.openModal('programs/edit-content/program-intent-variable-create', parameters, obj, operation);
  }

}
