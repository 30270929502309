import { Injectable } from '@angular/core';

import { JsonParams } from '../classes/jsonParams.class';

@Injectable()
export class ToggleElementsService {

    private _isSubMenuOpen: Object = new Object();
    private _isTooltipOpen: Object = new Object();
    private _isGroupParamsOpen: Object = new Object();

    private _allowHideOnClickOut: Object = new Object();
    private _positionChangedElements: Object = new Object();

    /**
     * MENUS
     */

    public getSubMenuStatus = (menu: JsonParams): boolean => this._isSubMenuOpen[menu.internalId];

    public toggleSubMenuStatus(menu: JsonParams): void {
        for (let i in this._isSubMenuOpen) {
            if (i == menu.internalId || !this._isSubMenuOpen[i])
                continue;
            this.hideSubMenu(i);
        }
        this._isSubMenuOpen[menu.internalId] = !this._isSubMenuOpen[menu.internalId];
    }
    public hideSubMenu(id?: string): void {
        if (id) {
            this._isSubMenuOpen[id] = false;
            return;
        }
        for (let i in this._isSubMenuOpen)
            this._isSubMenuOpen[i] = false;
    }

    /**
     * TOOLTIPS
     */

    public getTooltipStatus = (id: string): boolean => this._isTooltipOpen[id];

    public toggleTooltipStatus(id: string, hideOnClickOut: boolean = true): void {
        if (!id) return;

        this._allowHideOnClickOut[id] = hideOnClickOut;
        this._isTooltipOpen[id] = !this._isTooltipOpen[id];

        if (!this._isTooltipOpen[id])
            this.toggleElementPotition(id, this._positionChangedElements[id]);
    }

    public hideTooltips(eventPath: string = null): void {
        Object.keys(this._isTooltipOpen).map(id => {
            if (this._allowHideOnClickOut[id] && (!eventPath || eventPath.indexOf(id) < 0)) {
                this._isTooltipOpen[id] = false;
                this.toggleElementPotition(id, this._positionChangedElements[id]);
            }
        });
    }

    /**
     * GROUP PARAMETERS
     */
    public getGroupParamsStatus = (id: string): boolean => this._isGroupParamsOpen[id];
    public toggleGroupParamsStatus = (id: string) => this._isGroupParamsOpen[id] = !this._isGroupParamsOpen[id];


    public toggleElementPotition(id: string, elementToChange: HTMLElement, elementToLook?: HTMLElement): void {

        if (!elementToChange || !(elementToChange instanceof HTMLElement))
            return;

        let changeStyle = (position?: any) => {
            elementToChange.style.zIndex = position ? '10' : '';
            /**
             * Opções comentadas no âmbito do FIX WEBCT-642
             */
            // elementToChange.style.position = position ? 'relative' : '';
            // elementToChange.style.top = position ? position.top.toString() + 'px' : '';
            // elementToChange.style.left = position ? position.left.toString() + 'px' : '';
            // elementToChange.style.width = position ? position.width.toString() + 'px' : '';
            // elementToChange.style.height = position ? position.height.toString() + 'px' : '';
        }
        this._positionChangedElements[id] = elementToChange;
        changeStyle(elementToLook && this._isTooltipOpen[id] ? elementToLook.getBoundingClientRect() : null);
    }

    public findEventPath(event: any): string[] {

        let eventPath: string[] = new Array();
        let eventHtmlPath: HTMLElement[] = event.path || [];

        if ((!eventHtmlPath || eventHtmlPath.length <= 0) && event.target) {
            let addElement = event.target;
            do {
                eventHtmlPath.push(addElement);
                addElement = addElement.parentElement;
            } while (addElement)
        }

        for (let elem of eventHtmlPath)
            if (elem.id || elem.className)
                eventPath.push((elem.id ? '#' + elem.id : '') + (elem.className ? '.' + elem.className : ''));

        return eventPath;
    }

}
