import { Component, Input, OnInit, DoCheck } from '@angular/core';
import { InputService } from './../../../foundations-webct-robot/robot/services/input.service';
import { RobotEngineModel } from './../../../foundations-webct-robot/robot/robotEngineModel';
import { GlobalVarsService } from './../../../foundations-webct-robot/robot/utils/global-vars.service';
import { Utils } from './../../../foundations-webct-robot/robot/utils/utils.service';
import { PageService } from './../../../foundations-webct-robot/robot/pageComponent/page.service';
import { Observable } from 'rxjs/Rx';
import { HttpClient, HttpResponse } from '@angular/common/http';

import { InputTextboxComponent } from '../textboxComponent/textbox.component';
import { ComponentsService } from './../../../foundations-webct-robot/robot/services/components.service';

@Component({
  selector: 'auto-complete-component',
  templateUrl: 'autocomplete.component.html'
})

export class AutocompleteComponent extends InputTextboxComponent implements OnInit, DoCheck {

  public noMatchText = 'warnings___noRecords';

  protected _noRecordsValidator = 'noRecords';

  private _autocompleteUrl: string = null;
  private _autocompleteUrlForkJoin: string[] | null = null;
  private _formatedUrlResource: any = {};

  constructor(
    public inputService: InputService,
    public components: ComponentsService,
    public pageService: PageService,
    public globalVars: GlobalVarsService,
    public utils: Utils,
    public robot: RobotEngineModel,
    private _http: HttpClient
  ) {
    super(inputService, pageService, globalVars, utils, robot, components);
  }

  public ngOnInit() {

    this._setNoRecordsValidation();

    try {
      this.noMatchText = this.inputParameters.validator.autoComplete.description ? this.inputParameters.validator.autoComplete.description : 'warnings___noRecords';
    } catch (err) { }

    let forkJoin: any | null = null;

    if (this.inputParameters.validator.autoComplete) {

      forkJoin = this.utils.findObjectInArray(this.inputParameters.validator.autoComplete.value['urlResource'], 'forkJoin').value;

      if (forkJoin) {

        this._autocompleteUrlForkJoin = [];

        for (let i in forkJoin) {
          for (let obj of forkJoin[i]) {
            this._formatedUrlResource[obj.key] = obj.value;
          }
          this._autocompleteUrlForkJoin[i] = this.pageService.setMyUrlResource(this.inputParameters, this._formatedUrlResource, {}, null, null, {}, this.inputParameters.validator.autoComplete.value['urlResource']);
        }
      } else {
        for (let obj of this.inputParameters.validator.autoComplete.value['urlResource'])
          this._formatedUrlResource[obj.key] = obj.value;

        this._autocompleteUrl = this.pageService.setMyUrlResource(this.inputParameters, this._formatedUrlResource, {}, null, null, {}, this.inputParameters.validator.autoComplete.value['urlResource']);
      }
    }

    const lastInputValue: string = this.inputParameters.value;

    try {
      this.control.valueChanges.debounceTime(500).subscribe((val: string) => {
        if (this.control.dirty && lastInputValue != val) {
          this._robot.findDynamicPropsDependencies(this.inputParameters.id);
          this.components.updatePageParameters(this.inputParameters.parameters);
        }
      });
      /* this.control.valueChanges.debounceTime(1000).subscribe((val: string) => {
        this._evaluateNoRecords();
      }); */
    } catch (e) {
      // console.log('e ---> ', e);
    }
  }

  public ngDoCheck() {
    if (this.inputParameters.subType == 'autocomplete' && this.inputParameters.value && typeof this.inputParameters.value == 'object')
      this._restoreValue(this.inputParameters.value);
    else
      this.inputParameters.value = this.inputParameters.value || '';
  }

  public autocompleteListFormatter = (data: any): string => data;
  public autocompleteValueFormatter = (data: any): string => data;

  public customCallback() {

    if (this.insideFilter) {
      let filterParam = this.globalVars.getPageParameter(this.insideFilter);
      if (filterParam)
        filterParam.sendEvent = 'submit';
    }
  }

  public observableSource = (keyword: any): Observable<any[]> => {
    const rgx = new RegExp('{{' + this.inputParameters.id + '}}', 'i');
    let url: string;
    let forkJoinUrl: string[];
    let forkJoinRequests: any[];

    // headers:
    const currentParamValidator = this.inputParameters.validator.autoComplete || this.inputParameters.validator.unique;
    let headers: any | null = null;
    let forkJoinHeaders: any[] | null = null;

    const response: any | null = null;

    if (this._autocompleteUrlForkJoin) {
      this._autocompleteUrl = null;

      forkJoinUrl = [];
      forkJoinHeaders = [];
      forkJoinRequests = [];

      for (let i in this._autocompleteUrlForkJoin) {
        forkJoinUrl[i] = this._autocompleteUrlForkJoin[i].replace(rgx, keyword);

        forkJoinUrl[i] = this.utils.replaceTagVars(forkJoinUrl[i], this.utils.arrToObj(this.globalVars.getPageParametersAsArray()));
        if (currentParamValidator.value.hasOwnProperty('urlResource')) {
          forkJoinHeaders[i] = this.utils.findObjectInArray(this.utils.findObjectInArray(currentParamValidator.value['urlResource'], 'forkJoin', 'key').value[i], 'headers', 'key').value;
        }
        forkJoinRequests[i] = this.utils.GetAll(forkJoinUrl[i], forkJoinHeaders[i]);
      }

      if (keyword && this.control.valid) {
        return Observable.forkJoin(forkJoinRequests).map((fjData: HttpResponse<any>[]) => {
          if (!fjData || fjData === [])
            return [];

          const fjResponse = [];
          fjData.forEach((data) => {
            fjResponse.push(data);
          });
          let forkJoinData = [];
          for (let i in fjResponse) {
            let valueList: any[] = this.utils.getValueFromDataForThisKey(fjResponse[i], this.inputParameters.pathToValueList);
            if (valueList)
              forkJoinData = forkJoinData.concat(valueList);
          }
          this.inputService.loadValueListFromDataRecs(this.inputParameters, fjResponse, forkJoinData);
          this._evaluateNoRecords();
          return forkJoinData ? forkJoinData.map(obj => this.utils.getValueFromDataForThisKey(obj, this.inputParameters.pathToValue)) : [];
        });
      } else {
        return Observable.of([]);
      }

    } else if (this._autocompleteUrl) {
      this._autocompleteUrlForkJoin = null;

      url = this._autocompleteUrl.replace(rgx, keyword);
      url = this.utils.replaceTagVars(url, this.utils.arrToObj(this.globalVars.getPageParametersAsArray()));
      if (currentParamValidator.value.hasOwnProperty('urlResource')) {
        headers = this.utils.findObjectInArray(currentParamValidator.value['urlResource'], 'headers', 'key').value;
      }

      if (keyword && this.control.valid) {
        return this.utils.GetAll(url, headers, this._formatedUrlResource).map((res) => {
          const results = this.utils.getValueFromDataForThisKey(res, this.inputParameters.pathToValueList) || [];
          this.inputService.loadValueListFromDataRecs(this.inputParameters, res, results);
          this._evaluateNoRecords();
          return results ? results.map(obj => this.utils.getValueFromDataForThisKey(obj, this.inputParameters.pathToValue)) : [];
        });
      } else {
        return Observable.of([]);
      }
    }
  }

  protected _evaluateNoRecords() {
    if (this.inputParameters.value && !this.inputParameters.valueList)
      this.control.setErrors(
        Object.assign(this.control.errors || {}, {
          [this._noRecordsValidator]: true
        })
      );
    else if (this.control.errors)
      delete this.control.errors[this._noRecordsValidator];
  }

  protected _setNoRecordsValidation() {
    if (!this.pageService.errorDefs[this.inputParameters.id])
      this.pageService.errorDefs[this.inputParameters.id] = {};
    this.pageService.errorDefs[this.inputParameters.id][
      this._noRecordsValidator
      ] = null;
  }

  private _restoreValue(val: any) {
    try {
      this.inputParameters.value = this.utils.getValueFromDataForThisKey(val, this.inputParameters.pathToValue) || val || '';
    } catch (e) {
      this.inputParameters.value = val || '';
    }
  }
}
