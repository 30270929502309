import { Component, Input } from '@angular/core';
import { JsonParams } from './../../../foundations-webct-robot/robot/classes/jsonParams.class';

@Component({
    selector: 'json-component',
    templateUrl: 'json.component.html'
})

export class JsonComponent {

    @Input() public inputParameters: JsonParams;

}
