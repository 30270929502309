import { CustomValidator } from './../../customValidators.service';
import { FormBuilder, Validators, FormGroup } from '@angular/forms';
import { IntentsService} from '../intent.service';
import { CustomEventManagerService } from '../../customComponents/custom-event-manager.service';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Component, OnInit, Input } from '@angular/core';
// import { ModalService } from './../../foundations-webct2-palette/components/modalComponent/modal.service';
import { ModalService} from './../../foundations-webct-robot/robot/services/modal.service';

// import { NotificationService } from './../../foundations-webct2-palette/components/notificationComponent/notification.component';
import { WctNotificationService } from './../../foundations-webct2-palette/components/wct-notification/wct-notification.service';

@Component({
  selector: 'intent-creation',
  templateUrl: './intent-creation.component.html',
  styleUrls: ['./intent-creation.component.scss']
})
export class IntentCreationComponent implements OnInit {
  @Input() inputParameters;
  @Input() inputDataRecs;
  @Input() dataRecs;
  public name = "";
  public description = "";

  createIntentForm: FormGroup;
  duplicatedNameError = false;
  submitted = false;

  httpOptions = {
    headers: new HttpHeaders({
      'Accept': 'application/json; charset=utf-8',
      'Cache-Control': 'no-cache',
      'Authorization': 'Bearer ' + localStorage.token,
      'IXS': localStorage.IXS_id
    })
  };

  constructor(
    private modalService: ModalService,
    private intentsService: IntentsService,
    private eventManager: CustomEventManagerService,
    private formBuilder: FormBuilder,
    private customValidator: CustomValidator,
    // private notificationService: NotificationService) { }
    private notificationService: WctNotificationService) { }

  ngOnInit() {
    this.createIntentForm = this.formBuilder.group({
      intentName: ['', [Validators.required, this.customValidator.preventSpecialCharacters]],
      intentDescription: ['']
    });
  }

  public validateName() {
    this.duplicatedNameError = this.intentsService.intentExists(this.name.trim());
  }

  public cancel(){
    this.modalService.closeModal();
  }

  public create(){

    if (!this.isValidForm() || this.submitted) {
      return;
    }
    this.submitted = true;

    this.intentsService.createIntent(this.name, this.description).then(
      (resp) => {
        this.notificationService.showSuccess(
          "Create Intent",
          "Intent successfully created!",
          { timeout: { success: 700 }, positionClass: { success: 'top-right' }, limit: { success: 1 } }
        );
        this.intentsService.reloadProgram().then(
          response => {
            this.eventManager.sendNewIntentCreated();
            this.modalService.closeModal();
          }
        );
      },
      (resp) => {
        this.notificationService.showError(
          "Create Intent",
          "Intent creation error!",
          { timeout: { error: 700 }, positionClass: { error: 'top-right' }, limit: { error: 1 } }
        );
        console.log(resp);
        this.submitted = false;
      }
    );
  }


  public isValidForm(){
    return !(this.createIntentForm.invalid || this.duplicatedNameError);
  }

}
