import { Component, OnInit, Input, Output, EventEmitter, SimpleChange } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';

import { JsonParams, newEvent } from './../../../foundations-webct-robot/robot/classes/jsonParams.class';
import { Utils } from './../../../foundations-webct-robot/robot/utils/utils.service';
import { GlobalVarsService } from './../../../foundations-webct-robot/robot/utils/global-vars.service';
import { RobotEngineModel } from './../../../foundations-webct-robot/robot/robotEngineModel';
import { PageService } from './../../../foundations-webct-robot/robot/pageComponent/page.service';

@Component({
  selector: 'app-wct-infinite-scroll',
  templateUrl: './wct-infinite-scroll.component.html',
  styleUrls: ['./wct-infinite-scroll.component.css']
})

export class WctInfiniteScrollComponent implements OnInit {

  @Input() public inputParameters: JsonParams;
  @Input() public dataRecs: any;
  @Input() public control: FormControl;

  @Input() public baseInfoForm: FormGroup;
  @Input() public componentIndex: number = -1;
  @Input() public loadingFromModal: boolean = false;
  @Input() public insideFilter: string;

  @Input() public value: any[];

  @Output() public newRequest: EventEmitter<any> = new EventEmitter();

  public loading: boolean = false;

  private _page: number = 0;
  private _componentId: string;
  private _configParam: JsonParams;

  private _newEventSubscribe;

  constructor(
    private _utils: Utils,
    private _pageService: PageService,
    private _robot: RobotEngineModel,
    private _globalVars: GlobalVarsService) {
    this._componentId = 'INFINITESCROLL-' + this._utils.guid(4, '');
  }

  public ngOnInit() {
    this._createConfigparam();
    this._observeNewEvent();
  }

  public ngAfterViewInit() {
    this._appendValues(true);
  }

  public ngOnDestroy() {
    this._globalVars.deletePageParametersByGroup(this._componentId);
    if (this._newEventSubscribe)
      this._newEventSubscribe.unsubscribe();
  }

  public ngOnChanges(changes: { [propertyName: string]: SimpleChange }) {
    if (changes.value && changes.value.currentValue)
      this._appendValues();
  }

  public onScroll(event: any) {
    this._updatePage();
    this.loading = true;
    this.newRequest.emit(this._configParam.value);
  }

  private _appendValues(onInit: boolean = false, clean: boolean = false) {

    if (!onInit && this._page == 0)
      return;

    for (let id of this.inputParameters.groups.details.parameters.map(obj => obj.id)) {
      this._appendByType(id, onInit, clean);
      this.loading = false;
    }
  }

  private _appendByType(id: string, onInit: boolean, clean: boolean) {

    let param = this._globalVars.getPageParameter(id);

    if (param.type == 'initTable') {

      let originalRows = onInit ? new Array() : this._utils.cloneObject(param.groups.rows.originalValue) || new Array();
      let rows = onInit ? new Array() : this._utils.cloneObject(param.groups.rows.value) || new Array();

      this._robot.updatePageWithData(param, this.inputParameters.value);

      param.groups.rows.originalValue = clean ? new Array() : originalRows.concat(param.groups.rows.originalValue);
      param.groups.rows.value = clean ? new Array() : rows.concat(param.groups.rows.value);

      if (this.inputParameters.data.pagingSettings) {
        for(let attr in this.inputParameters.data.pagingSettings)
        this._pageService.updateConfigParameterPaging(this._globalVars.getPageParameter('config::' + param.id), this.inputParameters.data.pagingSettings[attr], attr);
      }

      param.sendEvent = 'cleanSelection';

    } else {
      if (!param.valueList)
        param.valueList = new Array();

      param.valueList = clean ? new Array() : param.valueList.concat(this.value);
    }

    param.sendEvent = 'infiniteScrollUpdate';
  }

  private _updateFilter() {
    this._appendValues(false, true);
    this.loading = true;
    this._configParam.value.page = 0;
    this.newRequest.emit(this._configParam.value);
  }

  private _createConfigparam() {
    this._configParam = new JsonParams('config::' + this.inputParameters.id, new Object());
    this._globalVars.setPageParameters(this._configParam, this._componentId);
    this._updatePage();
  }

  private _updatePage() {
    this._configParam.value.page = ++this._page;
  }

  private _observeNewEvent() {
    this._newEventSubscribe = this.inputParameters.newEvent().subscribe(data => this._handleEvent(data));
  }
  private _handleEvent(data: newEvent[]) {

    data.forEach(event => {
      if (event.key == 'updateFilter')
        this._updateFilter();
    });

  }

}
