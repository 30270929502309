import { CustomEventManagerService } from './../customComponents/custom-event-manager.service';
import { GlobalVarsService } from './../foundations-webct-robot/robot/utils/global-vars.service';
import { Utils } from './../foundations-webct-robot/robot/utils/utils.service';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { PageService } from './../foundations-webct-robot/robot/pageComponent/page.service';
import { ConfigurationService } from './../foundations-webct-robot/robot/utils/configuration.service';
import { Injectable } from '@angular/core';

import * as moment from "moment/moment";

@Injectable({
    providedIn: 'root',
})
export class ProgramsService {

    httpOptions = {
        headers: new HttpHeaders({
            'Accept': 'application/json; charset=utf-8',
            'Cache-Control': 'no-cache',
            'Authorization': 'Bearer ' + localStorage.token,
            'IXS': localStorage.IXS_id
        })
    };

    public programNames = [];
    public degreeList = [];
    public categoriesList = [];


    constructor(
        private pageService: PageService,
        private httpClient: HttpClient,
        private globalVarsService: GlobalVarsService,
        private _utils: Utils,
        private configurationService: ConfigurationService) {

    }

    public getAllPrograms() {

        let path = this.pageService.getUrlFromConfig(`{{SchoolBE}}/programs/model`);
        return this.httpClient.get(path, this.httpOptions).toPromise().then(response => {
            this.programNames = response['data'].map(p => p.name);
        });
    }


    public getVAList() {
        let path = this.pageService.getUrlFromConfig(`{{SchoolBE}}/students/model`);
        return this.httpClient.get(path, this.httpOptions).subscribe(response => {
            this.degreeList = response['data'].map(VA => {
                return {
                    name: VA.name,
                    id: VA._id,
                    language: VA.language
                }
            });
        });
    }

    public getCategoriesList() {

        let path = this.pageService.getUrlFromConfig('{{AppAPIBotConfig}}/categories');
        return this.httpClient.get(path, this.httpOptions).toPromise().then(response => {
            this.categoriesList = response['_embedded'].map(function (c) {
                return {
                    label: c.label,
                    id: c._id.$oid,
                    language: c.language
                }
            });
        });
    }

    public getCategoriesListByLanguage(language) {
        let filteredList = JSON.parse(JSON.stringify(this.categoriesList));
        return filteredList.filter((cat) => cat.language == language).map((c) => { delete c.language; return c; });
    }


    public createProgram(name, description, user, category, va, language) {
        let path = this.pageService.getUrlFromConfig("{{SchoolBE}}/programs");

        let timestampWithTZ = moment(Date.now()).format("YYYY-MM-DDTHH:mm:ss.SSSZ");


        let body = {
            "name": name,
            "IXS": localStorage.IXS_id,
            "description": description,
            "createdBy": user,
            "lastUpdate": timestampWithTZ,
            "status": 1,
            "type": this.configurationService.getConfig('entities___program___type'),
            "version": "V1.0",
            "categories": category,
            "creationDate": timestampWithTZ,
            "belongsTo": [
                va.id
            ],
            "accuracyLevel": this.configurationService.getConfig('entities___program___accuracyLevel'),
            "questionConfigLessons": [],
            "lessons": [],
            "chapters": [],
            "language": language,
            "lastPublishedDate": null,
            "lastPublishedBy": null,
            "lastPublishedVersion": null
        };

        console.info(body);

        return this.httpClient.post(path, body, this.httpOptions);

    }

    public addProgramToStudent(superhero, program, user) {
        let path = this.pageService.getUrlFromConfig("{{SchoolBOT}}/students/" + superhero);

        let timestampWithTZ = moment(Date.now()).format("YYYY-MM-DDTHH:mm:ss.SSSZ");
        let body = {
            "$push": {
                "programs": {
                    "programId": program,
                    "status": "N",
                    "lastUpdate": timestampWithTZ,
                    "requestedBy": user,
                    "versionLearned": null,
                    "lastVersionLearned": null
                }
            }
        };

        return this.httpClient.patch(path, body, this.httpOptions);
    }

}
