import { Component, Input } from '@angular/core';

@Component({
  selector: 'ng2-wizard-step',
  templateUrl: 'ng2-wizard-step.component.html'
})

export class Ng2WizardStepComponent {

  @Input() public title = '';

  public active = false;
  public visited = false;
}
