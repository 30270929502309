import { Injectable, EventEmitter } from '@angular/core';
import { HttpClient, HttpResponse, HttpHeaders } from '@angular/common/http';
import { Router } from '@angular/router';
import 'rxjs/add/operator/map';
import { Observable } from 'rxjs/Observable';

import { JsonParams } from '../classes/jsonParams.class';
import { ApiService } from '../services/api.service';
import { DateService } from '../services/date.service';
import { ToggleElementsService } from '../services/toggle-elements.service';
import { GlobalVarsService } from './global-vars.service';
import { UtilsCustomService } from './utils-webct-methods.service';

import * as moment from 'moment/moment';
import * as tz from 'moment-timezone';
import { VersionControlService } from '../services/version-control.service';

@Injectable()
export class Utils {

  public i18nData: Object = {};
  public mustacheReg = /\{\{\s*[\w\.\-\_\$\@\|\'\[\]\=\:\(\)\,\+\?]+\s*\}\}/g;
  public extendedMustacheReg = /\"?\{\{\s*[\w\.\-\_\$\@\|\'\[\]\=\:\(\)\,\+\?]+\s*\}\}\"?/g;
  public urlMustacheReg = /\[\[\s*[\w\.\-\_\$\@\|\'\[\]\=\:\(\)\,]+\s*\]\]/g;

  public htmlTagsReg = /<(\/?(?=!--)!--[\s\S]*--|(\/?(?=\?)\?[\s\S]*\?|(\/?(?=\/)\/[^.-\d][^\/\]'"[!#$%&()*+,;<=>?@^`{|}~ ]*|[^.-\d][^\/\]'"[!#$%&()*+,;<=>?@^`{|}~ ]*(?:\s[^.-\d][^\/\]'"[!#$%&()*+,;<=>?@^`{|}~ ]*(?:=(?:"[^"]*"|'[^']*'|[^'"<\s]*))?)*)\s?\/?))>/g;

  public coin: string = 'RD$';

  public uniqIdRegex: RegExp = /\$\{.*\}/g;
  public configVars: Object = {};

  private _mockVars: any;
  private _mockVarsSufix: string;

  constructor(
    private _http: HttpClient,
    private _apiService: ApiService,
    private _toggle: ToggleElementsService,
    private _dateService: DateService,
    private _versionControl: VersionControlService,
    private _router: Router,
    private _globalVars: GlobalVarsService,
    private _customUtils: UtilsCustomService) {

  }

  /**
   * GENERATE PATH TO NEW JSON MOCK
   */
  public getJson = (json: string, data?: any) => location.origin + '/assets/mock-data/' + this.replaceTagVars(json, data) + '.json' + this._versionControl.preventJsonCache();

  public navigate(route: string, data: Object | Object[] = {}, event = null, openNewTab: boolean = false): string {

    if ((event && event.which == 3) || !route)
      return;
    else if (event)
      event.preventDefault();

    if (Object.prototype.toString.call(data) === '[object Object]')
      data = [data];

    route = this.replaceTagVars(route, data).replace(this.mustacheReg, '');
    if (!route)
      return;

    let isFullUrl = route.startsWith("http://") || route.startsWith("https://");
    if (openNewTab === true || (event && event.ctrlKey) || (event && event.which === 2))
      window.open((isFullUrl ? '' : window.location.origin) + route, '_blank');
    else {
      // this._globalVars.resetDynamicPropsData();
      isFullUrl ? (window.location.href = route) : this._router.navigateByUrl(route);
    }

    this._toggle.hideSubMenu();
    return route;
  }

  /**
   * ORDENATE PARAMETERS ARRAY BY POSITION
   */
  public orderParametersByPosition(parameters: JsonParams[]): JsonParams[] {

    let orderedcolumns = {};
    let count = parameters.length + 1;
    for (let i in parameters) {
      if (parameters[i].position)
        orderedcolumns[parameters[i].position] = parameters[i];
      else
        orderedcolumns[count++] = parameters[i];
    }

    parameters = [];
    for (let i in orderedcolumns)
      parameters.push(orderedcolumns[i]);

    return parameters;
  }

  /**
   * REPLACE {{key}} OR [[key]] FROM STRING
   */
  public replaceTagVars(resource: string | string[], dataArr: any = null, idSuffix: string = null) {

    // console.log("tags", dataArr, this._globalVars.getGlobalVars());
    if (!resource || typeof resource != 'string')
      return;

    if (resource.match(this.urlMustacheReg)) {
      resource = resource.replace(this.urlMustacheReg, (key) => {
        let tmp = this.getValueFromDataForThisKey(this._globalVars.urlParams, key.slice(2, -2));
        return tmp !== undefined ? tmp : key;
      });
    }

    // dataArr = ((!dataArr || Object.prototype.toString.call(dataArr) === '[object Array]' ? dataArr : [dataArr]) || [{}]).filter(obj => obj !== undefined);
    dataArr = (!dataArr || Object.prototype.toString.call(dataArr) === '[object Array]' ? dataArr : [dataArr]) || [{}];
    if (dataArr) {
        for (let data of dataArr) {

          if (resource.match(this.extendedMustacheReg)) {
            resource = resource.replace(this.extendedMustacheReg, (key) => {
              let myUrlParam;

              let rtnTemplate = key;
              if (key.indexOf('"') >= 0)
                key = key.replace(/\"/g, '');

              let keyAux = key.slice(2, -2);

              let asDecorator = this.findDecorator(keyAux);
              if (asDecorator) {
                myUrlParam = this.findValues([asDecorator], data, idSuffix);
              }

              /**
               * Verifica se o valor pretendido está num contexto de listagem com vários Ids "iguais" (ex: Cards)
               * Ordem de pesquisa foi alterada no âmbito do WEBCT-512 - Agora, quando existe um idSuffix, será com esse ID que será feita a primeira pesquisa ao contexto.
               *  Se dessa pesquisa não for devolvido nenhum valor, avança-se para as opções de pesquisa seguintes.
               */
              if (idSuffix && myUrlParam === undefined)
                myUrlParam = this.getValueFromDataForThisKey(data, keyAux + idSuffix);

              // Verifica no contexto que lhe é dado
              if (myUrlParam === undefined)
                myUrlParam = this.getValueFromDataForThisKey(data, keyAux);

              // Verifica nos Endpoints
              if (myUrlParam === undefined)
                myUrlParam = this.getEndpoint(keyAux);
              // Verifica nas variáveis gLobais
              if (myUrlParam === undefined)
                myUrlParam = this.getValueFromDataForThisKey(this._globalVars.getGlobalVars(), keyAux);
              // Verifica nos parâmetros presentes na página
              if (myUrlParam === undefined)
                myUrlParam = this.getValueFromDataForThisKey(this.arrToObj(this._globalVars.getPageParametersAsArray()), keyAux);

              if (myUrlParam && typeof myUrlParam == 'object') {

                let objectType = (myUrlParam).constructor;
                let objectString = JSON.stringify(myUrlParam);
                if (objectType === Array || objectType === Object)
                  return objectString;

                return rtnTemplate.replace(key, objectString.replace(/^\"|\"$/g, ''))
              } else {
                 let rtnVal: any = typeof myUrlParam == 'boolean' || myUrlParam == 'number' ?
                   myUrlParam.toString() : (myUrlParam || this._globalVars.getLocalStorage(keyAux)
                     || this._globalVars.getSessionStorage(keyAux) || key);

                return rtnTemplate.replace(key, this.escapeDoubleQuotes(rtnVal));
              }
            });
          }
        }
    }

    return resource;
  }

  /**
   * FULFILLMENT STRING
   */
  public fulfillment(payload: any, dataParameters: JsonParams[]): string {

    if (typeof payload == 'object')
      payload = JSON.stringify(payload);

    let response: string = this.replaceTagVars(payload, this.arrToObj(dataParameters));
    if (!response)
      return response;
    // Forçar possiveis tags que devolveram outras (tags) a ser substituídas
    response = this.replaceTagVars(response, this.arrToObj(dataParameters));
    response = response.replace(/(\"|\>)(\d{2}([.\-\/])\d{2}[.\-\/]\d{4})( \d{2}:\d{2}:\d{2})?(\"|\<\/)/g, (key) => {
      return this.formatDatesToRequest(key);
    });

    return response;
  }

  public formatDatesToRequest(key: string) {

    /**
     * FORMATA DATAS PARA ISO
     */

    let rtnTemplate = key;
    key = key.indexOf('"') >= 0 ? key.replace(/\"/g, '') : key;
    key = key.indexOf('>') >= 0 ? key.replace('>', '') : key;
    key = key.indexOf('</') >= 0 ? key.replace('</', '') : key;

    let refDate = tz((key.match(/\d{2}:\d{2}:\d{2}/g) ? key : key + ' 00:00:00'), this._dateService.dateTimeFormatFriendly);
    // create a new moment based on the original one
    let refDateWithTZ = refDate.clone();
    // change the time zone of the new moment
    refDateWithTZ.tz(this._dateService.timezone); // or whatever time zone you desire
    // shift the moment by the difference in offsets
    refDateWithTZ.add(refDate.utcOffset() - refDateWithTZ.utcOffset(), 'minutes');

    return rtnTemplate.replace(key, refDateWithTZ.utc().format(this._dateService.timestampWithTZformat));

  }

  /**
   * FIND OBJECT IN ARRAY
   */
  public findObjectInArray(array: JsonParams[], value: string | boolean, property: string = 'key'): JsonParams {
    if (!array)
      return new JsonParams();
    let returnObj: JsonParams = array.find((obj) => { return obj[property] == value; });
    return returnObj || new JsonParams();
  }

  /**
   * TRANSFORM OBJECT INTO ID/VALUE ARRAY<JSONPARAMS>
   */
  public objToJsonParam(objToParam: Object): JsonParams[] {

    let newJsonParamArray: JsonParams[] = [];
    if (!objToParam || Object.keys(objToParam).length == 0)
      return newJsonParamArray;

    let newJsonParam: JsonParams;
    for (let i in objToParam) {
      newJsonParam = new JsonParams();
      newJsonParam.id = i;
      newJsonParam.value = objToParam[i];
      newJsonParamArray.push(newJsonParam);
    }
    return newJsonParamArray;
  }

  /**
   * CREATE RANDOM ID
   */
  public guid(limit: number = 6, separator: string = '-'): string {
    function s4() {
      return Math.floor((1 + Math.random()) * 0x10000)
        .toString(16)
        .substring(1);
    }

    let rtnVal = this.configVars['default'] ? [this.configVars['default']['appName']] : [];
    for (let i = 0; i < limit; i++)
      rtnVal.push(s4());

    return rtnVal.join(separator);
  }

  public GetAll = (endpoint: string, headers: Object = null, urlResource: Object = null, componentId: string = null): Observable<Object | HttpResponse<any>> => {

    let setHeaders = {
      Accept: 'application/json'
    };

    for (let i in headers) {
      let val = this.replaceTagVars(headers[i]).replace(/(\d{2}([.\-\/])\d{2}[.\-\/]\d{4})( \d{2}:\d{2}:\d{2})?/g, key => this.formatDatesToRequest(key)).replace(this.mustacheReg, '')
      if (val)
        setHeaders[i] = val;
    }

    let myHeaders = new HttpHeaders(setHeaders);

    if (urlResource) {
      let body = urlResource['requestBody'] && typeof urlResource['requestBody'] == 'object' ? JSON.stringify(urlResource['requestBody']) : urlResource['requestBody'];
      return this.SetRequest(endpoint, myHeaders, urlResource['requestType'], this.replaceTagVars(body), urlResource['id'], componentId);
    }

    return this.SetRequest(endpoint, myHeaders, 'GET', null);
  }

  public getCallback(config: any, callback: Function, componentId: string = null) {
    this.GetAll(config.url, config.headers, config.urlResource, componentId)
      .subscribe((data) => {
        if (callback) {
          if (data && data['nbrTotal']) {
            data['_size'] = data['nbrTotal'];
            data['_total_pages'] = data['nbrPag'];
          }
          if (data && data['fullListSize']) {
            data['_size'] = data['fullListSize'];
            data['_total_pages'] = Math.ceil(+data['fullListSize'] / +data['resultsPerPage']);
          }
          callback(data);
        }
      },
        // OnError
        (error) => {
          if (callback) {
            error = this._apiService.saveRequestResponse(config.urlResource.id, error, componentId, 'error');
            error.errorDetected = true;
            callback(error);
          }
        });
  }

  public SetRequest = (endpoint: string, headers: HttpHeaders, type: string, body: string, requestId: string = null, componentId: string = null): Observable<Object | HttpResponse<any>> => {

    headers = headers.set("Cache-Control", "no-cache");

    endpoint = this.prepareStringForEval(endpoint.replace('#', '%23'));
    switch (type || 'GET') {
      case 'GET':
        return this._http.get(endpoint, { headers: headers, observe: 'response' }).map(res => this._apiService.saveRequestResponse(requestId, res, componentId));
      case 'POST':
        return this._http.post(endpoint, body, { headers: headers, observe: 'response' }).map(res => this._apiService.saveRequestResponse(requestId, res, componentId));
      case 'PUT':
        return this._http.put(endpoint, body, { headers: headers, observe: 'response' }).map(res => this._apiService.saveRequestResponse(requestId, res, componentId));
      case 'PATCH':
        return this._http.patch(endpoint, body, { headers: headers, observe: 'response' }).map(res => this._apiService.saveRequestResponse(requestId, res, componentId));
      case 'DELETE':
        let httpOptions = { headers: headers, body: body };
        return this._http.delete(endpoint, httpOptions);
      default:
        console.error('ERROR: SetRequest() default type.');
        return;
    }
  }

  public arrToObj(array: JsonParams[], attribute: string = 'value', removeUniqIdRegex: boolean = false): Object {
    let object: Object = {};
    for (let i in array) {

      if (!array[i].id)
        continue;

      let k = (array[i].idPrefix ? array[i].idPrefix + '::' : '') + array[i].id;
      if (removeUniqIdRegex)
        k = k.replace(this.uniqIdRegex, '');

      let v = '';
      if (attribute == 'value' && array[i].readonly && array[i].originalValue)
        v = array[i].originalValue;
      // else if(attribute == 'originalValue' && array[i].originalValue === undefined)
      //   v = array[i].value;
      else if (typeof array[i][attribute] == 'boolean' || typeof array[i][attribute] == 'number')
        v = array[i][attribute];
      else
        v = array[i][attribute] || undefined;

      object[k] = v;
    }
    return object;
  }

  public convertToString(val: any, condition: String[] = null): any {
    if (condition && condition.indexOf(typeof val) < 0)
      return val;
    try {
      return typeof val == 'object' ? JSON.stringify(val) : val.toString();
    } catch (e) {
      return val;
    }
  }

  public findValues(keySearch: string[], data: any, idSuffix?: string) {

    if (keySearch && this._isValueOnParameters(keySearch[0]) && !data.hasOwnProperty(keySearch[0]))
      data = this.arrToObj(this._globalVars.getPageParametersAsArray());

    if ((!data || Object.keys(data).length == 0) && (keySearch && typeof keySearch[0] == 'string' && !keySearch[0].match(/\@[a-zA-Z\_\d]+\(/g)))
      return;
    if ((!keySearch || keySearch.length == 0))
      return data;

    let readingData = data;
    for (let attrToRead of keySearch) {

      if (typeof attrToRead == 'object') {

        if (Object.prototype.toString.call(attrToRead) === '[object Array]')
          readingData = this.findValuesAndJoin(attrToRead, readingData);
        if (attrToRead['$or'])
          readingData = this.findValuesByOr(attrToRead, readingData);
        if (attrToRead['$filter'])
          readingData = this.findValuesBySearch(attrToRead, readingData);

      } else {

        if (typeof readingData == 'string') {
          try {
            readingData = JSON.parse(readingData);
          } catch (e) { }
        }

        if (Object.prototype.toString.call(readingData) === '[object Array]' && readingData.length == 1 && attrToRead != '0' && attrToRead != '[0]')
          readingData = readingData[0];
        if (attrToRead === '')
          return readingData;
        if (attrToRead.match(/^\[(.*)\]$/g))
          attrToRead = attrToRead.slice(1, -1);

        let tempReadingData = readingData !== undefined && readingData !== null ? readingData[attrToRead] : readingData;
        readingData = tempReadingData !== undefined ? tempReadingData : this.findValuesByCondition(attrToRead, readingData, idSuffix);
      }

      if (readingData === undefined)
        return;
    }
    return readingData;

  }

  public findValuesAndJoin(attrToRead: string[], data: any[]) {

    if (!data || data.length == 0 || attrToRead.length == 0)
      return;
    let join: any[] = [];
    for (let obj of data) {
      let tempReadingData = this.findValues(attrToRead, obj);
      if (tempReadingData !== undefined)
        join.push(tempReadingData);
    }
    return join;
  }

  public findValuesByOr(attrToRead: Array<string>[], data: any) {
    if (attrToRead['$or'].length == 0)
      return;
    for (let or of attrToRead['$or']) {
      let tempReadingData = this.findValues(or, data);
      if (tempReadingData !== undefined)
        return tempReadingData;
    }
    return;
  }

  public findValuesBySearch(attrToRead: string[], data: Object[]) {
    if (attrToRead['$filter'].length == 0)
      return;
    let filter: any[] = [];
    for (let obj of data) {
      let tempReadingData = this.findValues(attrToRead['$filter'], obj);
      if (tempReadingData === true)
        filter.push(obj);
      else if (tempReadingData !== undefined && typeof tempReadingData != 'boolean')
        console.error(`$filter: Wrong data type. The result of the expression "${attrToRead['$filter']}" is not a boolean.`);
    }
    return filter.length > 0 ? filter : undefined;
  }

  public findValuesByCondition(condition: string, data: any, idSuffix?: string) {

    try {

      // if (condition.indexOf('getPathType') >= 0 && data) {
      //   if(data['properties::path${0-1}'])
      //     debugger;
      //   let teste = this.removePropertiesObjsFromObj(data);
      //   if(data['properties::path${0-1}'])
      //     debugger;
      // }

      this._mockVarsSufix = idSuffix;
      this._mockVars = data;
      condition = condition
        .replace(/\$this/g, `(${JSON.stringify(this.removePropertiesObjsFromObj(data))})`)
        .replace(/\@i18n\(/g, 'this.i18n(')
        .replace(/\@replaceTagVars\(/g, 'this._replaceMockVars(')
        .replace(/\@getJsonParam\(/g, 'this._getJsonParam(')
        .replace(/\@getFilterConfig\(/g, 'this._getFilterConfig(')
        .replace(/\@[a-zA-Z\_\d]+\(/g, match => match.replace('@', 'this._customUtils.'));

      let evalStr = this.prepareStringForEval(this.replaceTagVars(condition, data));
      let evalRes = eval(evalStr);
      return evalRes;
    } catch (e) {
      return;
    }
  }

  public findDecorator(keySearch: string): string {

    let decorator = '@fv()';
    if (!keySearch || typeof keySearch != 'string' || keySearch.indexOf(decorator) < 0)
      return;

    return keySearch.replace(decorator, '');
  }

  public removePropertiesObjsFromObj(obj: any) {

    if (!this.isObjectType(obj, 'Object'))
      return obj;

    let newObj = {};
    for (let i in obj) {
      if (i.indexOf('properties::') === 0) {
        newObj[i] = obj[i].cloneAsObj();
        continue;
      }
      newObj[i] = obj[i];
    }

    return newObj;
  }

  public getValueFromDataForThisKey(data, keySearch: string | string[], formatValue: boolean = false): any {

    if (typeof keySearch == 'object') {
      return this.findValues(keySearch, data);
    } else if (keySearch) {
      let asDecorator = this.findDecorator(keySearch);
      if (asDecorator)
        return this.findValues([asDecorator], data);
    }

    // communicationProduct___current___[communicationType=='MOBILE'?serviceIdentifiers]___0
    // communicationProduct->current->[communicationType=='MOBILE']->serviceIdentifiers->0
    // communicationProduct=>current=>[communicationType=='MOBILE']=>serviceIdentifiers=>0
    // communicationProduct\current\[communicationType=='MOBILE']\serviceIdentifiers\0

    if (!data || Object.keys(data).length == 0)
      return;
    if (keySearch === '')
      return data['$primitiveValue'] !== undefined ? data['$primitiveValue'] : data;
    if (!keySearch || typeof keySearch != 'string')
      return;

    // Atribui à {{val}}, o valor inicial do Objecto que queremos percorrer
    let val = data;
    // Define o nome pelo qual a Key deve ser chamada
    let keyName = keySearch.indexOf('::as::') >= 0 ? keySearch.split('::as::')[1] : keySearch;
    // Retira do {{keySearch}} a expressão pela qual deve ser chamado este parâmetro (caso exista), ficando apenas com o caminho real que deve ser percorrido
    keySearch = keyName == keySearch ? keySearch : keySearch.replace('::as::' + keyName, '');

    // Caso a Key a ser procurada não exija navegar no Objecto, devolve o valor dessa mesma Key
    let detectVal = this.convertToString(val[keyName], ['boolean', 'number']);
    if (detectVal || (keySearch.indexOf('___') < 0 && keySearch.indexOf('==') < 0 && keySearch.indexOf('||') < 0 && !keySearch.match(/eval\(.*\)/g))) {
      return formatValue ? this.formatValues(keyName, detectVal) : detectVal;
    }

    let deepenPos: number = undefined;
    let currKey: string = null;
    let count: number = 0;

    do {

      // Encontra no {{keySearch}} o index do primeiro match com a express�o "___" (aquela que divide a string com o caminho a percorrer no Objecto)
      deepenPos = keySearch.indexOf('___');
      // Encontra a key � qual queremos aceder (entre o �nicio da string at� ao index {{deepenPos}})
      currKey = keySearch.substring(0, (deepenPos >= 0 ? deepenPos : keySearch.length));
      // Caso o {{currKey}} contenha a express�o "[", indica que queremos navegar atrav�s de recursividade e ajusta o valor dessa mesma vari�vel
      // currKey = currKey.indexOf('[') < 0 ? currKey : keySearch.substring(0,keySearch.lastIndexOf(']')+1);
      // Remove da {{keySearch}} a posi��o � qual vamos aceder ({{currKey}}). Caso esta �ltima seja igual ao {{keySearch}}, indica que cheg�mos ao fim do caminho e limpa o valor � {{keySearch}} para terminar o ciclo
      // keySearch = keySearch == currKey ? '' : keySearch.substring((deepenPos >= 0 ? deepenPos + 3 : 0),keySearch.length);

      // No caso de haver recursividade (deve ser apenas usado quando o {{val}} actual � um array)

      if (currKey.match(/^eval\(.*\)$/g)) {
        try {
          let evalStr = this.replaceTagVars(currKey.slice(5, -1), val);
          let evalRes = eval(evalStr);
          return evalRes ? this.convertToString(evalRes, ['boolean', 'number']) : undefined;
        } catch (e) {
          return;
        }
      } else if (currKey.indexOf('[') >= 0) {
        let response;
        let tempVal: string[] = [];

        // ** Para o caso de existirem Arrays dentro da expressão de validação
        let nthOccurrence = keySearch.split(']')[0].split('[').length - 1;
        let minIndexToFind = this.getPosition(keySearch, ']', nthOccurrence);
        // **

        currKey = keySearch.substring(0, minIndexToFind + 1);
        // Percorre cada uma das posi��es no array existente
        for (let i in val) {
          // Inicia a recursividade, enviando o Objecto presente no val[i], e o caminho que deve ser percorrido l� dentro.

          let currVal = val[i];
          if (currKey.indexOf('[[') >= 0 && (typeof val[i] != 'object' || Object.prototype.toString.call(val[i]) !== '[object Array]'))
            currVal = [val[i]];

          response = this.getValueFromDataForThisKey(currVal, currKey.slice(1, -1), formatValue);
          response = this.convertToString(response, ['boolean', 'number']);
          // Passa para a posi��o seguinte caso a resposta n�o contenha qualquer valor
          if (!response) continue;
          // Define a {{val}} com a resposta caso esta tenha valor e remove da {{keySearch}} as posi��es �s quais acedemos.
          tempVal.push(response);
        }

        // caso n tenha encontrado resultado para prosseguir termina
        if (!tempVal.length)
          return;

        val = tempVal.length == 1 ? tempVal[0] : tempVal;
        keySearch = keySearch.replace(currKey, '');

        // No caso de haver valida��o de uma Key na posi��o que estamos
      } else if (currKey.indexOf('==') >= 0) {

        // Define a Key que queremos validar
        let key = currKey.split('==')[0];
        // Define o valor que a Key definida na linha anterior deve conter
        let value = currKey.split('==')[1].split('?')[0].slice(1, -1);
        // Define a Key que deve ser retornada, caso a valida��o se confirme
        let key2return = currKey.split('==')[1].split('?')[1];

        // Caso haja valida��o da condi��o e n�o haja {{key2return}}, devolve por defeito o val.value
        if (val[key] == value) {
          val = this.convertToString(val[key2return || 'value'], ['boolean', 'number']);
          return formatValue ? this.formatValues(key, val) : val;
        }
        return;

        // Caso haja necessidade de devolver um ou outro campo, consoante aquele que existir ou tiver algum valor
      } else if (currKey.indexOf('||') >= 0) {
        let currKeyOptions = currKey.split('||');
        for (let i in currKeyOptions) {
          val[currKeyOptions[i]] = this.convertToString(val[currKeyOptions[i]], ['boolean', 'number']);
          if (val[currKeyOptions[i]] || val[currKeyOptions[i]] === 0) {
            if (currKey != keySearch)
              return this.getValueFromDataForThisKey(val[currKeyOptions[i]], keySearch.replace(currKey, '').slice(3), formatValue);
            else
              return formatValue ? this.formatValues(currKeyOptions[i], val[currKeyOptions[i]]) : val[currKeyOptions[i]];
          }
        }
        return;
      } else {

        // Atribui novo valor ao {{val}}, com a nova posi��o � qual queremos aceder
        val = this.convertToString(val[currKey], ['boolean', 'number']);

        //  Se a {{val}} for do tipo "string", atribui-lhe o resultado do JSON.parse() dela pr�pria caso esta seja uma string com um json v�lido
        if (typeof val === 'string') {
          try {
            let json = JSON.parse(val)
            val = typeof json == 'object' ? json : val;
          } catch (e) {
            val = val;
          }
        } else if (!val)
          return val;

      }

      // Remove da {{keySearch}} a posicao a qual acedemos ({{currKey}})
      keySearch = keySearch.replace(currKey, '');
      if (keySearch.indexOf('___') == 0) {
        keySearch = keySearch.substr(3);
      }

      // Break de seguran�a. Se alguma coisa correr mal, evita ciclos infinitos
      if (count++ > 10) break;

      // Faz o ciclo at� a string que define o caminho a navegar no Objecto ({{keySearch}}) tiver valor
    } while (keySearch.length > 0);

    val = this.convertToString(val, ['boolean', 'number']);
    return formatValue ? this.formatValues(keyName, val) : val;
  }

  public getPosition(str, m, i) {
    let ret = str.split(m, i).join(m).length;
    return ret >= str.length ? -1 : ret;
  }

  public formatValues(param: any, fieldHtml: any, format: boolean = false) {

    if (!format)
      return fieldHtml;

    fieldHtml = this.convertToString(fieldHtml, ['boolean', 'number']);

    if (!fieldHtml)
      return;
    // if (['auto-complete-details', 'initCards', 'json', 'jsonlabel', 'jsonlist', 'tuple_list', 'tuple', 'slider', 'input-checkbox', 'check_list', 'input-select'].indexOf(param.type) >= 0 && typeof fieldHtml == 'object')
    //   return fieldHtml;
    let key = null;

    if (typeof param === 'object') {
      let htmlResult = [];
      if (Object.prototype.toString.call(fieldHtml) === '[object Array]') {
        if (typeof fieldHtml[0] === 'object')
          return fieldHtml;
        if (fieldHtml.length > (param.max >= 0 ? param.max : 1)) {
          let entityName: string = param.placeholder ? this.i18n(param.placeholder) : 'Multi-entities';
          return '<span title="' + fieldHtml.join(', ') + '">' + entityName + '(' + fieldHtml.length + ')</span>';
        } else
          return fieldHtml.join(', ');
      }
      if (fieldHtml != null && (typeof fieldHtml === 'string') && fieldHtml.match(this._dateService.dateReg))
        return tz.tz(fieldHtml, this._dateService.timezone).format(this._dateService.dateTimeFormatFriendly);
      if (param.icon && typeof fieldHtml != 'object') {

        let tempIconObj = {};
        for (let i in param.icon)
          tempIconObj[this.replaceTagVars(i)] = param.icon[i];

        let result = tempIconObj[fieldHtml.toLowerCase()] || tempIconObj[fieldHtml];
        let myIcon = typeof param.icon === 'object' ? (result ? result : null) : param.icon;
        if (myIcon)
          htmlResult.push('<i class="' + myIcon + '"></i>');
      }
      if (param.type != 'icon' && param.html && typeof fieldHtml != 'object') {
        if (typeof param.html === 'object') {
          if (JSON.stringify(Object.keys(param.html)).indexOf('typeOf') >= 0) {
            for (let i in param.html) {
              if (i.indexOf('typeOf') >= 0) {
                let type = i.slice(7, -1); // deixa o tipo de valor que queremos verificar;
                if (Number(fieldHtml))
                  fieldHtml = Number(fieldHtml);
                if (typeof fieldHtml != type)
                  continue;
                let myHtml = param.html[i];
                if (typeof parseInt(fieldHtml, 0) == type)
                  return myHtml.indexOf('{{}}') >= 0 ? myHtml.replace('{{}}', fieldHtml) : myHtml;
              }
            }
          } else if (param.html['*']) {
            if (param.html['*'] == 'formatDate')
              return this.formatValues(param, (!isNaN(+fieldHtml) ? new Date(+fieldHtml) : new Date(fieldHtml)).toISOString(), true);
          }
        }

        let tempHtmlObj = {};
        for (let i in param.html)
          tempHtmlObj[this.replaceTagVars(i)] = param.html[i];

        let result = this.i18n(tempHtmlObj[fieldHtml.toLowerCase()] || tempHtmlObj[fieldHtml]);
        let myHtml = typeof param.html === 'object' ? (result ? result : null) : param.html;
        myHtml = myHtml && myHtml.indexOf('{{}}') >= 0 ? myHtml.replace('{{}}', fieldHtml) : myHtml;
        if (myHtml)
          htmlResult.push(myHtml);
      } else if (param.type != 'icon')
        htmlResult.push(fieldHtml);
      if (!htmlResult.length)
        return fieldHtml;
      return htmlResult.join('&nbsp;&nbsp;');
    }
    return fieldHtml;
  }

  public date_range(range, fieldName = 'creationDate') {
    let now = moment({ hour: 23, minute: 59, seconds: 59 });
    let start = moment({ hour: 0, minute: 0, seconds: 0 });

    const obj = {};
    if (!range) return obj;

    // start.setFullYear(start.getFullYear(), start.getMonth()-$('.date_range').find(":selected").val());

    switch (range) {
      case 'last year':
        // start.setFullYear(start.getFullYear()-1);
        start = moment(start).subtract(1, 'year')['_d'];
        break;
      case 'last quarter':
        // start.setFullYear(start.getFullYear(), start.getMonth()-3);
        start = moment(start).subtract(3, 'months')['_d'];
        break;
      case 'last month':
        // start.setFullYear(start.getFullYear(), start.getMonth()-1);
        start = moment(start).subtract(1, 'months')['_d'];
        break;
      case 'last week':
        // start.setFullYear(start.getFullYear(), start.getMonth(), start.getDay()-7);
        start = moment(start).subtract(6, 'day')['_d'];
        break;
      case 'last day':
        // start.setFullYear(start.getFullYear(), start.getMonth(), start.getDay()-1);
        start = moment(start).subtract(1, 'day')['_d'];
        break;
      case 'previous day':
        start = moment(start).subtract(1, 'day')['_d'];
        now = moment(now).subtract(1, 'day')['_d'];
        break;

      case 'last 24h':
        start = moment().subtract(1, 'days');
        now = moment();
        break;
      case 'current month':
        start = moment().startOf('month')['_d'];
        break;
      case 'previous month':
        start = moment().subtract(1, 'months').startOf('month')['_d'];
        now = moment().subtract(1, 'months').endOf('month')['_d'];
        break;
      case 'last semester':
        start = moment(start).subtract(6, 'months')['_d'];
        break;
      default: break;
    }

    obj[fieldName] = { $gte: moment(start).toISOString(), $lt: moment(now).toISOString() };
    return obj;

  }

  public i18n(payload: string) {
    if (!payload || typeof payload != 'string')
      return payload;

    if (payload.match(this.mustacheReg) || payload.match(this.urlMustacheReg))
      payload = this.replaceTagVars(payload);

    // let response = this.getValueFromDataForThisKey(this.i18nData, payload);
    // return response !== undefined && typeof response !== 'object' ? response : payload;

    let responsePayload = payload.replace(this.mustacheReg, str => str.slice(2, -2));
    let response = this.getValueFromDataForThisKey(this.i18nData, responsePayload);
    let responseIsObj = typeof response === 'object';
    return response !== undefined && !responseIsObj ? response : responseIsObj ? responsePayload.split('___').pop() : payload;
  }

  public cleanApiGetHistory(parameter: JsonParams) {

    if (!parameter)
      return;

    if (parameter.groups) {
      if (parameter.groups.urlResource) {
        let parameterApiId = this.findObjectInArray(parameter.groups.urlResource.parameters, 'id').value;
        this._apiService.deleteApiHistoryById(parameterApiId);
      }
      if (parameter.groups.details && parameter.groups.details.parameters && parameter.groups.details.parameters.length > 0) {
        for (let i in parameter.groups.details.parameters)
          this.cleanApiGetHistory(parameter.groups.details.parameters[i]);
      }
    }
    if (parameter.parameters && parameter.parameters.length > 0) {
      for (let i in parameter.parameters)
        this.cleanApiGetHistory(parameter.parameters[i]);
    }
  }

  public getEndpoint(id: string, attribute?: string) {

    if (!this.configVars || !this.configVars['endpoints'])
      return;

    let findEndpoint: EndpointConfig = this.configVars['endpoints'].find((obj) => obj.key == id);

    if (!findEndpoint || Object.keys(findEndpoint).length == 0)
      return;
    if (attribute)
      return this.getValueFromDataForThisKey(findEndpoint, attribute);
    if (findEndpoint.useClientConfig)
      return ' ' + this._getEndpointAsString(findEndpoint, 'path');
    if (findEndpoint.value)
      return findEndpoint.value;

    return this._getEndpointAsString(findEndpoint);
  }

  public prepareStringForEval(str: string): string {
    return str
      .replace(/(\"|\')?\{\{\s*[\w\.\-\_\$\@\|\'\[\]\=\:\(\)\,\+\?]+\s*\}\}(\"|\')?/g, this.replaceFnForEval)
      .replace(this.urlMustacheReg, this.replaceFnForEval)
      // .replace(this.htmlTagsReg, '')
      .replace(/\&(.*)\;/g, '');
  }

  public replaceFnForEval = (key) => {
    if (key.match(this.urlMustacheReg))
      return '';
    if (key.indexOf('"') < 0 && key.indexOf('\'') < 0)
      return '""';
    return key.replace(this.mustacheReg, '');
  };

  public cloneJsonParse = (json: Object): Object => JSON.parse(JSON.stringify(json));
  public cloneObject(obj: any): any {
    if (!obj || typeof obj != 'object')
      return obj;
    else if (obj instanceof JsonParams)
      return obj.clone();

    // ALterado no âmbito do REQ WEBCT-713
    // return JSON.parse(JSON.stringify(obj));
    return this.isObjectType(obj, 'Array') ? [].concat(obj) : Object.assign({}, obj);
  }

  public mergeDeep(target: any, source: any) {
    let output = Object.assign({}, target);
    if (this.isObjectType(target, 'Object') && this.isObjectType(source, 'Object')) {
      Object.keys(source).forEach(key => {
        if (this.isObjectType(source[key], 'Object')) {
          if (!(key in target))
            Object.assign(output, { [key]: source[key] });
          else
            output[key] = this.mergeDeep(target[key], source[key]);
        } else {
          Object.assign(output, { [key]: source[key] });
        }
      });
    }
    return output;
  }


  public moveHtmlElementToView(htmlElement: HTMLElement): void {
    setTimeout(() => {
      if (htmlElement) {
        let datePopupPosition = htmlElement.getBoundingClientRect();
        if ((datePopupPosition.top + datePopupPosition.height) > window.innerHeight) {
          // let currentMarginTop = +window.getComputedStyle(htmlElement).getPropertyValue('margin-top').replace('px', '');
          // let elementSpacer = 10;

          // let temp = currentMarginTop - ((datePopupPosition.top + datePopupPosition.height) - window.innerHeight) - elementSpacer;

          htmlElement.style.setProperty('top', `auto`);
          htmlElement.style.setProperty('margin-top', `auto`);
          htmlElement.style.setProperty('bottom', `10px`);

        }
        htmlElement.style.setProperty('opacity', '1');
      }
    }, 1);
  }

  public getObjectValues = (obj: Object) => Object.keys(obj).map(key => obj[key]);

  public isObjectType(value: any, type: 'Object' | 'Array'): boolean {
    if (!value || typeof value != 'object' || Object.prototype.toString.call(value) != `[object ${type}]`)
      return false;
    return true;
  }

  public evaluateBoolean(value: boolean | string): boolean {

    if (typeof value == 'boolean' || typeof value == 'number')
      return !!value;
    if (!isNaN(+value))
      return !!(+value);

    try {
      return JSON.parse(value.toLowerCase());
    } catch (e) {
      return false;
    }

  }

  public escapeDoubleQuotes = (str: string): string => {

    if (!str || typeof str != 'string')
      return str;

    try {
      JSON.parse(str);
      return str;
    } catch (e) {
      return str.replace(/\\([\s\S])|(")/g, "\\$1$2");
    }
  };

  public callMethod(name, context, ...restArgs): Function {
    const namespaces = name.split('.');
    const func = namespaces.pop();
    for (let i = 0; i < namespaces.length; i++) {
      context = context[namespaces[i]];
    }
    try {
      return context[func].apply(context, restArgs);
    } catch (e) {
      console.error('WebCT ERROR -> Could not find method matching this name: ' + func);
      return;
    }
  }

  public removeIdSufixFromObject = (obj: Object): Object => Object.assign({}, ...Object.keys(obj).map(key => ({ [key.replace(this.uniqIdRegex, '')]: obj[key] })));


  /**
   * TODO: Estes métodos deverão voltar para o filter.service -> Ver questão das dependências circulares criadas pelo Utils
   */
  public getFilterObject(filterFields: JsonParams[]): Object {

    let filterFieldsEmpty = {};
    let filterFieldName: string;
    let currentColumn: JsonParams;

    for (let val in filterFields) {
      currentColumn = filterFields[val];

      if (currentColumn.filterTemplate === null)
        continue;

      filterFieldName = currentColumn.apiFieldName || currentColumn.id;

      if (!currentColumn.value || (typeof currentColumn.value == 'object' && Object.keys(currentColumn.value).length == 0))
        continue;

      if (typeof currentColumn.value == 'object' && !currentColumn.filterTemplate) {
        filterFieldsEmpty[filterFieldName] = this.cloneJsonParse(currentColumn.value);
        continue;
      }

      currentColumn.value = this.transformInitFilterValue(currentColumn);

      if (currentColumn.filterTemplate) {
        filterFieldsEmpty[filterFieldName] = this.replaceTagVars(currentColumn.filterTemplate, this.arrToObj(filterFields));
        try {
          filterFieldsEmpty[filterFieldName] = JSON.parse(filterFieldsEmpty[filterFieldName]);
        } catch (e) {
          // WHEN FILTER TEMPLATE IS JUST A STRING
        }
      } else if (currentColumn.value && typeof currentColumn.value == 'string')
        filterFieldsEmpty[filterFieldName] = { $regex: '(?i)' + currentColumn.value + '.*' };
      else
        filterFieldsEmpty[filterFieldName] = currentColumn.value;
    }

    return filterFieldsEmpty;
  }
  public transformInitFilterValue(param: JsonParams): any {

    if (!param.value)
      return param.value;
    if (param.type == 'dates-interval' && typeof param.value == 'string') {
      let apiParam = param.apiFieldName || param.id;
      let apiValue = this.date_range(param.value, apiParam);
      return apiValue ? apiValue[apiParam] : apiValue;
    }

    return typeof param.value == 'string' ? param.value.trim() : param.value;
  }
  /**
   * TODO: Estes métodos deverão voltar para o filter.service -> Ver questão das dependências circulares criadas pelo Utils
   */


  private _isValueOnParameters = (attr: string): boolean => attr ? this._globalVars.getPageParameters().hasOwnProperty(attr) : false;

  private _replaceMockVars = (resource: string) => this.replaceTagVars(`{{${resource}}}`, this._mockVars, this._mockVarsSufix);
  private _getJsonParam = (resource: string) => this._globalVars.getPageParameter(resource + this._mockVarsSufix) || this._globalVars.getPageParameter(resource);

  private _getEndpointAsString(findEndpoint: EndpointConfig, attr?: string) {
    let config: Object = {
      protocol: findEndpoint.protocol + '://',
      host: findEndpoint.host,
      port: findEndpoint.port ? ':' + findEndpoint.port : '',
      path: findEndpoint.path ? ((findEndpoint.path.indexOf('/') == 0 ? '' : '/') + findEndpoint.path) : ''
    };
    return attr ? config[attr] : Object.keys(config).map((key) => config[key]).join('');
  }

  private _getFilterConfig(form: JsonParams): Object {

    let filterFields: JsonParams[] = new Array();

    if ((form.type == 'initParameters' || form.type == 'wrapper') && form.groups && form.groups.details && form.groups.details.parameters)
      for (let field of form.groups.details.parameters)
        filterFields.push(field);
    if (form.type == 'groupParameters' && form.parameters)
      for (let field of form.parameters)
        filterFields.push(field);

    return this.getFilterObject(filterFields);
  }

}

interface EndpointConfig {
  key: string;
  description?: string;
  protocol: string;
  host: string;
  port?: number;
  path?: string;
  value?: string;
  useClientConfig?: boolean;
}

