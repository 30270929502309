import { Component, Input, OnInit, DoCheck, ViewChild, AfterViewInit } from '@angular/core';
import { FormControl } from '@angular/forms';
import { JsonParams } from './../../../foundations-webct-robot/robot/classes/jsonParams.class';
import { Utils } from './../../../foundations-webct-robot/robot/utils/utils.service';
import { RobotEngineModel } from './../../../foundations-webct-robot/robot/robotEngineModel';
import { PageService } from './../../../foundations-webct-robot/robot/pageComponent/page.service';
import { TreeNode } from 'angular-tree-component/dist/models/tree-node.model';
import { TreeComponent } from 'angular-tree-component/dist/components/tree.component';
import { TreeModel } from 'angular-tree-component/dist/models/tree.model';
import { ITreeState } from 'angular-tree-component/dist/defs/api';
import { InputService } from './../../../foundations-webct-robot/robot/services/input.service';

@Component({
  selector: 'tree-view',
  templateUrl: 'tree-view.component.html'
})

export class TreeViewComponent implements OnInit, AfterViewInit, DoCheck {

  // Parametros Mock do teu Componente
  @Input() public inputParameters: JsonParams;
  // Dados de contexto no qual o teu Componente é instanciado
  @Input() public inputDataRecs: Object[];
  // Formulário activo onde o teu Componente é instanciado
  @Input() public control: FormControl;

  public auxElementsOn = 10;
  public treeViewOptions: TreeViewOptions;
  public nodes = [];
  public nodesAux: any[] = [];
  public valueControl: string = null;
  public treeState: ITreeState;

  /**
   * opções WebCT parametrizáveis da tree:
   */
  public expanded = false;
  public search: WebctOptionsCfg | null = null;
  // select all/none
  public selectAllCfg: WebctOptionsCfg | null = null;
  // multiple/single selection:
  public multipleSelection = true;
  public bottomPanelTitle: WebctOptionsCfg | null = null;
  // clear button:
  public bottomPanelClearBtn: WebctOptionsCfg | null = null;
  public bottomPanelAllwaysVisible = true;

  /** ************************************************** */
  @ViewChild('tree') protected treeComponent: TreeComponent;

  protected treeModel: TreeModel;
  protected nodesCfg: any = {};
  protected index = -1;

  constructor(protected _utils: Utils,
              protected _robot: RobotEngineModel,
              protected _pageService: PageService,
              private _inputService: InputService) { }

  public ngOnInit() {

    this.treeViewOptions = {};

    if (!this.control)
      this.control = new FormControl();

    if (!this.inputParameters.valueList)
      this.inputParameters.valueList = [];

    this._inputService.loadValueListFromDataRecs(this.inputParameters, this.inputDataRecs, this.inputParameters.valueList);

    this.inputParameters.value = this.inputParameters.value || [];
    this.auxElementsOn = this.inputParameters.size >= 0 ? this.inputParameters.size : this.auxElementsOn;

    if (this.inputParameters.parameters) {
      for (let i = 0; i < this.inputParameters.parameters.length; i++) {

        if (this.inputParameters.parameters[i].key === 'treeViewOptions') {
          // @luisgoulart - preencher this.treeViewOptions - exclusivo do módulo angular-tree-component
          if (this.inputParameters.parameters[i].parameters instanceof Array && this.inputParameters.parameters[i].parameters.length > 0) {
            this.inputParameters.parameters[i].parameters.forEach((opt) => {
              switch (opt.key) {
                case 'getChildren':
                  // @ luisgoulart - code here!!!!
                  break;
                case 'nodeClass':
                  // @ luisgoulart - code here!!!!
                  break; /*
                case 'nodeHeight':
                  //@ luisgoulart - code here!!!!
                  break;*/
                default:
                  this.treeViewOptions[opt.key] = opt.value;
              }
            });
          }
        } else if (this.inputParameters.parameters[i].key === 'treeViewWebctOptions') {
          // @luisgoulart - configurações webct extra da tree
          this.inputParameters.parameters[i].parameters.forEach((opt) => {
            switch (opt.key) {

              case 'selectAll':
                this.selectAllCfg = {
                  id: opt.value.id || 'tree-select-all',
                  checked: opt.value.checked || false
                };
                if (opt.value.text)
                  this.selectAllCfg['text'] = opt.value.text;
                if (opt.value.style)
                  this.selectAllCfg['style'] = opt.value.style;
                if (opt.value.class)
                  this.selectAllCfg['class'] = opt.value.class;
                break;

              case 'multipleSelection':
                this.multipleSelection = opt.value;
                break;

              case 'bottomPanelTitle':
                this.bottomPanelTitle = {
                  id: opt.value.id || 'tree-bottom-panel-title',
                  text: opt.value.text || 'no title defined'
                };
                if (opt.value.style)
                  this.bottomPanelTitle['style'] = opt.value.style;
                if (opt.value.class)
                  this.bottomPanelTitle['class'] = opt.value.class;
                break;

              case 'bottomPanelClearButton':
                this.bottomPanelClearBtn = {
                  id: opt.value.id || 'tree-bottom-panel-clear-btn'
                };
                if (opt.value.text)
                  this.bottomPanelClearBtn['text'] = opt.value.text || 'no title defined';
                if (opt.value.style)
                  this.bottomPanelClearBtn['style'] = opt.value.style;
                if (opt.value.class)
                  this.bottomPanelClearBtn['class'] = opt.value.class || 'btn';
                if (opt.value.icon)
                  this.bottomPanelClearBtn['icon'] = opt.value.icon || '';
                break;

              case 'bottomPanelAllwaysVisible':
                this.bottomPanelAllwaysVisible = opt.value;
                break;

              case 'search':
                this.search = {
                  id: opt.value.id || 'tree-search',
                  placeholder: opt.value.placeholder || 'generic___search'
                };
                if (opt.value.icon) {
                  this.search['icon'] = opt.value.icon;
                }
                break;

              case 'expanded':
                this.expanded = opt.value;
                break;

              default:
                break;
            }
          });
          if (this.inputParameters.subType === 'dropdownTree') {
            this.bottomPanelAllwaysVisible = true;
          }
        } else {
          this.nodesCfg[this.inputParameters.parameters[i].key] = this.inputParameters.parameters[i];
        }
      }
    }

    this.nodes = [];
    this.nodes = this.dataRecsToNodes(this.inputParameters.valueList, this.nodes, null, 0);
  }

  public ngAfterViewInit() {
    if (this.treeComponent) {
      this.treeModel = this.treeComponent.treeModel;
      if (this.selectAllCfg) {
        this.treeModel.doForAll((node: TreeNode) => {

          if (this.selectAllCfg.checked) {
            this.addItem(node.data, this._utils.findObjectInArray(this.inputParameters.groups.details.parameters, 'id'), false);
            this.nodesAux.forEach((nodeAux) => {
              if (node.id === nodeAux.id) {
                nodeAux.checked = node.data.checked;
              }
            });
          }
        });
      }
      if (this.expanded == true)
        this.treeModel.expandAll();
    }
    this.valueControl = this.inputParameters.value.length > 0 ? '*'.repeat(this.inputParameters.value.length) : null;
  }

  public ngDoCheck() {
    if (this.treeModel) {
      this.control.updateValueAndValidity();
    }
  }

  public preventDefault(e) {
    e.stopPropagation();
  }

  public isCheckable = (): boolean => {
    const detail = this._utils.findObjectInArray(this.inputParameters.groups.details.parameters, 'id');
    Object.keys(detail).forEach((key) => {
      if (key === 'id')
        return true;
    });
    return false;
  }

  public checkboxClick(item) {

    if (item.disabled)
      return;

    this.check(item);
    this.updateDynamicProps(item.disabled);
  }

  public check(item, detail?: JsonParams) {

    if (!detail)
      detail = this._utils.findObjectInArray(this.inputParameters.groups.details.parameters, 'id');

    const arrKeys = Object.keys(detail);
    const index = arrKeys.indexOf('_notifier');
    if (index >= 0)
      arrKeys.splice(index, 1);

    if (arrKeys.length === 0)
      return;

    this.control.markAsTouched();
    this.control.markAsDirty();

    if (item.checked === false) {
      if (this.multipleSelection === false) {
        this.treeModel.doForAll((node: TreeNode) => {
          if (node.id !== item.id)
            node.data.disabled = true;
        });
      }
      this.addItem(item, detail);
    } else {
      this.treeModel.doForAll((node: TreeNode) => {
        if (node.id !== item.id)
          node.data.disabled = false;
      });
      this.removeItem(item, detail);
    }

    this.evaluateItemStatus(item, detail);
    this.selectAllCfg.checked = this.allNodesChecked();
    this.valueControl = this.inputParameters.value.length > 0 ? '*'.repeat(this.inputParameters.value.length) : null;
    this.control.updateValueAndValidity();
  }

  public updateSelectAll() {
    this.selectAllCfg.checked = !this.selectAllCfg.checked;

    if (!this.selectAllCfg.checked) {
      this.removeAll();
      return;
    }

    this.treeModel.doForAll((node: TreeNode) => {
      if (!node.isHidden) {
        if (this.selectAllCfg.checked) {
          this.addItem(node.data, this._utils.findObjectInArray(this.inputParameters.groups.details.parameters, 'id'), false);
        } else {
          this.removeItem(node.data, this._utils.findObjectInArray(this.inputParameters.groups.details.parameters, 'id'), false);
        }

        this.nodesAux.forEach((nodeAux) => {
          if (node.id === nodeAux.id) {
            nodeAux.checked = node.data.checked;
          }
        });
      }
      this.evaluateCheckedOrIndeterminateRecursively(node.data);
    });
    // this.valueControl = this.valueControl === null && this.inputParameters.value.length > 0 ? '*'.repeat(this.inputParameters.value.length) : null;
    this.valueControl = this.inputParameters.value.length > 0 ? '*'.repeat(this.inputParameters.value.length) : null;
    this.control.markAsTouched();
    this.control.markAsDirty();
    this.control.updateValueAndValidity();
  }

  public removeAll() {

    if (this.selectAllCfg)
      this.selectAllCfg.checked = false;

    this.treeModel.doForAll((node: TreeNode) => {
      if (node.data.disabled)
        node.data.disabled = false;
      this.removeItem(node.data, this._utils.findObjectInArray(this.inputParameters.groups.details.parameters, 'id'), false);
    });
    this.valueControl = null;
    this.control.updateValueAndValidity();
  }

  public evaluateDetailValue(dataRecs: any, param: JsonParams = null, key: string = null): string {
    if (key)
      param = this._utils.findObjectInArray(this.inputParameters.groups.details.parameters, key);
    return (param.pathToValue ? this._utils.getValueFromDataForThisKey(dataRecs, param.pathToValue) : param.value) || '';
  }

  public updateDynamicProps(disabled) {
    if (disabled)
      return;
    setTimeout(() => {
      this._robot.updateDynamicPropsOnPageParameters();
    }, 1);
  }

  // tree search:
  public searchOnTree (t: string, currTreeState: ITreeState = this.treeState) {

    this.treeModel.filterNodes((node: TreeNode): boolean => {

      let nodeText: string | null | undefined = node.data.name ? node.data.name : node.data.htmlValues.name;

      // garantir que não existe html na string - just in case...
      nodeText = nodeText.replace(this._utils.htmlTagsReg, '');

      if (nodeText) {
        const haystackLC = nodeText.toLowerCase();
        const needleLC = t.toLowerCase();

        const hlen = nodeText.length;
        const nlen = needleLC.length;

        if (nlen > hlen) {
          return false;
        }
        if (nlen === hlen) {
          return needleLC === haystackLC;
        }
        outer: for (let i = 0, j = 0; i < nlen; i++) {
          const nch = needleLC.charCodeAt(i);

          while (j < hlen) {
            if (haystackLC.charCodeAt(j++) === nch) {
              continue outer;
            }
          }
          return false;
        }
        return true;
      } else {
        return false;
      }
    });

    if (this.selectAllCfg){
      this.selectAllCfg.checked = this.allNodesChecked();
    }
    this.evaluateExpandNodes(t, currTreeState);
  }

  protected evaluateExpandNodes (t: string, currTreeState: ITreeState): void {
    if (t.length > 0) {
      this.treeState = currTreeState;
      return;
    }
    this.treeModel.collapseAll();
    this.treeModel.doForAll((node: TreeNode) => {
      if (node.data.checked || (currTreeState && currTreeState.expandedNodeIds[node.id]) ) {
        node.expand();
      }
    });
  }

  protected evaluateCheckedOrIndeterminateRecursively(item) {
    this.evaluateCheckedOrIndeterminate(item);
    if (item.parent && Object.keys(item.parent).length > 0)
      this.evaluateCheckedOrIndeterminateRecursively(item.parent);
  }

  protected evaluateCheckedOrIndeterminate(item): void {
    if (item.children && item.children.length > 0) {
      const checkedChild = item.children.filter((obj) => { return obj.checked === true; }).length;
      item.checked = checkedChild > 0;
      item.indeterminate = item.checked && checkedChild !== item.children.length;
    }
  }

  protected evaluateItemStatus(item, detail: JsonParams) {
    if (!item)
      return;
    this.evaluateCheckedOrIndeterminate(item);

    if (!item.checked) {
      this.removeItem(item, detail);
    } else {
      this.addItem(item, detail, false);
    }

    if (item.parent && Object.keys(item.parent).length > 0)
      this.evaluateItemStatus(item.parent, detail);
  }

  protected addItem(item, detail: JsonParams, recursive: boolean = true) {
    let value = item.htmlValues[detail.id];
    if (this.inputParameters.value.indexOf(value) >= 0)
      return;
    item.checked = true;
    this.inputParameters.value.push(value);
    if (recursive && item.children && item.children.length > 0)
      for (let i of item.children)
        this.addItem(i, detail);
  }

  protected removeItem(item, detail: JsonParams, recursive: boolean = true) {
    let value = item.htmlValues[detail.id];
    if (this.inputParameters.value.indexOf(value) < 0)
      return;
    item.checked = false;
    item.indeterminate = false;
    this.inputParameters.value.splice(this.inputParameters.value.indexOf(value), 1);
    if (recursive && item.children && item.children.length > 0)
      for (let i of item.children)
        this.removeItem(i, detail);
  }

  protected dataRecsToNodes(dataRecs: any[], nodes: any[], parent: TreeViewNode = null, level: number) {
    level++;
    for (let item of dataRecs) {

      this.index++;
      const node: TreeViewNode = {
        id: '',
        name: '',
        level: level,
        isHidden: false,
        hasChildren: false,
        checked: false,
        disabled: false,
        indeterminate: false,
        parent: parent,
        htmlValues: {},
        index: this.index,
        dataRecs: item,
        children: []
      };

      for (let i in this.nodesCfg) {
        this._robot.changeParameterByDynamicProps(this.nodesCfg[i], item);
        if (this.nodesCfg[i].value !== undefined && this.nodesCfg[i].value !== null) {
          node[this.nodesCfg[i].key] = this.nodesCfg[i].value;
        } else if (this.nodesCfg[i].pathToValue || this.nodesCfg[i].pathToValue === '') {
          if (this.nodesCfg[i].key === 'children') {
            let tmpPath = this._utils.getValueFromDataForThisKey(item, this.nodesCfg[i].pathToValue);
            if (tmpPath instanceof Array && tmpPath.length > 0) {
              node.hasChildren = true;
              node[this.nodesCfg[i].key] = this.dataRecsToNodes(tmpPath, node[this.nodesCfg[i].key], node, level);
            }
          } else {
            node[this.nodesCfg[i].key] = this._utils.getValueFromDataForThisKey(item, this.nodesCfg[i].pathToValue);
          }
        }
      }
      // garantir que os IDs são do tipo string:
      node.id = 'node-' + level.toString() + '-' + this.index.toString();

      if (this.inputParameters.value && typeof this.inputParameters.value == 'object' && this.nodesCfg['id']) {
        let itemValue = this._utils.getValueFromDataForThisKey(item, this.nodesCfg['id'].pathToValue);
        node.checked = this.inputParameters.value.indexOf(itemValue) >= 0;
      }

      nodes.push(node);
      this.inputParameters.groups.details.parameters.forEach((detail) => {
        node.htmlValues[detail.id] = this.evaluateDetailValue(item, detail);
        if (detail.key)
          node.htmlValues[detail.key] = node.htmlValues[detail.id];
      });
      this.nodesAux[node.index] = node;
    }
    return nodes;
  }

  protected allNodesChecked(): boolean {
    let allNodesChecked = true;

    this.treeModel.doForAll((node: TreeNode) => {
      if (!node.isHidden && !node.data.checked) {
        allNodesChecked = false;
        return;
      } else if (node.isHidden) {
        allNodesChecked = false;
      }
    });

    return allNodesChecked;
  }
}

interface TreeViewNode {
  id: string;
  name: string;
  level: number;
  isHidden: boolean;
  isExpanded?: boolean;
  hasChildren: boolean;
  checked: boolean;
  disabled: boolean;
  indeterminate: boolean;
  dataRecs?: any;
  htmlValues?: Object;
  index?: number;
  parent?: TreeViewNode;
  children?: TreeViewNode[];
}

interface TreeViewOptions {
  displayField?: string;
  childrenField?: string;
  idField?: string;
  isExpandedField?: string;
  getChildren?: (node: TreeNode) => any;
  isHiddenField?: string;
  levelPadding?: number;
  nodeClass?: (node: TreeNode) => any;
  allowDrag?: boolean;
  allowDrop?: boolean;
  useVirtualScroll?: boolean;
  nodeHeight?: number;
  dropSlotHeight?: number;
  animateExpand?: boolean;
  animateSpeed?: number;
  animateAcceleration?: number;
}

interface WebctOptionsCfg {
  id: string;
  value?: string;
  checked?: boolean;
  text?: string;
  style?: Object;
  class?: string;
  icon?: string;
  placeholder?: string;
}
