import { Injectable } from '@angular/core';

import { JsonParams } from './../../../foundations-webct-robot/robot/classes/jsonParams.class';

@Injectable()
export class AutoCompleteDetailsService {

  public autoCompleteValue: Object = {};
  public autoCompleteTotalFields: Object = {};
  public autoCompleteMainId: string = null;
  public autoCompleteParametersToValidate: JsonParams[] = [];

  public restartService() {
    this.autoCompleteValue = {};
    this.autoCompleteTotalFields = {};
    this.autoCompleteMainId = null;
    this.autoCompleteParametersToValidate = [];
  }

}
