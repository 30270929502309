import { FormControl } from '@angular/forms';
import { Component, OnInit, IterableDiffers, KeyValueDiffers, Input, Output, EventEmitter, DoCheck, OnDestroy } from '@angular/core';

import { PageService } from './../../../foundations-webct-robot/robot/pageComponent/page.service';
import { RobotEngineModel } from './../../../foundations-webct-robot/robot/robotEngineModel';
import { Utils } from './../../../foundations-webct-robot/robot/utils/utils.service';
import { GlobalVarsService } from './../../../foundations-webct-robot/robot/utils/global-vars.service';
// import { ModalService } from 'foundations-webct-palette/components/modalComponent/modal.service';
import { ModalService} from './../../../foundations-webct-robot/robot/services/modal.service';
import { ComponentsService } from './../../../foundations-webct-robot/robot/services/components.service';
import { JsonParams, newEvent } from './../../../foundations-webct-robot/robot/classes/jsonParams.class';
import { JsonGroups } from './../../../foundations-webct-robot/robot/classes/jsonGroups.class';

@Component({
  selector: 'app-wct-cards',
  templateUrl: './wct-cards.component.html',
  styleUrls: ['./wct-cards.component.css']
})
export class WctCardsComponent implements OnInit, DoCheck, OnDestroy {

  // Parametros Mock do teu Componente
  @Input() public inputParameters: JsonParams;
  // Dados de contexto no qual o teu Componente é instanciado
  @Input() public inputDataRecs: Object[];
  // Formulário activo onde o teu Componente é instanciado
  @Input() public control: FormControl;

  @Input() public pagingSettings: any;
  @Input() public dataRecsNoContent;
  @Input() public allowReload = false;
  @Input() public viewStructureContext: JsonParams[];

  @Output() public pageChanged: EventEmitter<any> = new EventEmitter();
  @Output() public emitMakeFilter: EventEmitter<any> = new EventEmitter();

  public structArr: JsonParams[];
  public structArrAsObj: Object[];
  public valueArr: any[];
  /*public valueRows: Object = {
    value: {},
    originalValue: {}
  };*/

  public cardHasData = false;
  public cardIsLoading = true;
  public tableSearchHidden: number[] = [];
  // public isHorizontalFilter = false;
  public paginationConfig: Object = {};

  private iterableDiffer: any;
  private keyValueDiffer: any;
  private _componentId: string;
  private _showPaging = true;
  private _newEvent: EventEmitter<newEvent[]>;

  public selectable = false;

  private _selectedCards = {};
  private _selectMultiple = false;

  constructor(
    private pageService: PageService,
    private robot: RobotEngineModel,
    private iterableDiffers: IterableDiffers,
    private keyValueDiffers: KeyValueDiffers,
    private utils: Utils,
    private globalVars: GlobalVarsService,
    private modalService: ModalService,
    private componentsService: ComponentsService) {

      this.iterableDiffer = this.iterableDiffers.find([]).create(null);
      this._componentId = 'CARDS-' + this.utils.guid(4, '');
  }

  public ngOnInit() {

    const tableManualActions = this.utils.findObjectInArray(this.inputParameters.parameters, 'tableActions').value || {};
    this._showPaging = this.pagingSettings.numPages >= 1 ? tableManualActions['pagination'] : false;
    // this._showPaging = true;
    this.paginationConfig = tableManualActions['paginationConfig'] || {};

    this.selectable = this.inputParameters.subType == 'card-container-radio' || this.inputParameters.subType == 'card-container-checkBox';
    this._selectMultiple = this.selectable && this.inputParameters.subType == 'card-container-checkBox';

    this._updatePage();
    this._updateInitValue();
  }

  public ngDoCheck() {
    try {
      const changes = this.iterableDiffer.diff(this.inputParameters.valueList);
      if (changes) {
        this._updatePage();
      } else {

        if (!this.inputParameters.valueList || this.inputParameters.valueList.length == 0) {
          this._evaluateCardData();
        }

        this.inputParameters.valueList.forEach(entry => {
          const keyValueDiffer = this.keyValueDiffer[entry]; 2222;
          const keyValueChanges = keyValueDiffer.diff(entry);
          if (keyValueChanges) {
            keyValueChanges.forEachChangedItem(entry => {
              if (entry.key === 'prop1') {
                this._updatePage();
              }
            });
          }
        });
      }
    } catch (err) { }
  }

  public ngOnDestroy() {
    this.globalVars.deletePageParametersByGroup(this._componentId);
    if (this._newEvent) {
      this._newEvent.unsubscribe();
    }
  }

  public getHtmlId = (param: JsonParams, type?: string) => this.componentsService.getHtmlId(param, type);

  public isMyCardSelected = (i: number) => this._selectedCards[this.pagingSettings.currentPage] && this._selectedCards[this.pagingSettings.currentPage].indexOf(i) >= 0;
  public selectCard(i: number, value: Object) {

    if (!this.selectable) {

      if (this.inputParameters.navigateTo) {
        this.utils.navigate(this.inputParameters.navigateTo, value);
      } else {
        const openModal = this.utils.findObjectInArray(this.inputParameters.parameters, 'openModal');
        if (openModal.value) {
          this.modalService.openModal(openModal.value, null, value, new JsonParams(openModal.id));
          return;
        }
      }

      return;
    }

    const page = this.pagingSettings.currentPage;

    this._selectedCards[page] = this._selectedCards[page] || [];

    const findIndex = this._selectedCards[page].indexOf(i);
    if (findIndex < 0) {
      // Is not selected, must add

      this._addToValue(value);
      if (this._selectMultiple) {
        this._selectedCards[page].push(i);
      } else {
        this._selectedCards[page] = [i];
      }

    } else {
      // Is selected, must remove
      this._removeFromValue(value);
      this._selectedCards[page].splice(findIndex, 1);
    }

    this.robot.findDynamicPropsDependencies(this.inputParameters.id);
    this.componentsService.updatePageParameters(this.inputParameters.parameters);
  }

  protected _updatePage() {

    if (this.dataRecsNoContent) {
      return;
    }

    if (this.utils.isObjectType(this.inputParameters.valueList, 'Array')) {
      this.dataRecsNoContent = null;
      this.valueArr = this.inputParameters.valueList;
    } else if (this.utils.isObjectType(this.inputParameters.valueList, 'Object')) {
      this.dataRecsNoContent = null;
      this.valueArr.push(this.inputParameters.valueList);
    }

    this.dataRecsNoContent = null;
    this.structArr = [];
    this.structArrAsObj = [];

    if (this.inputParameters.valueList && this.inputParameters.valueList.length > 0) {

      // ////////////////////////////////////////////////////////////
      for (const i in this.valueArr) {

        this.structArr[i] = new JsonParams();
        this.structArr[i].id = this.inputParameters.id + '-${' + this.pagingSettings.currentPage + '-' + i + '}';
        this.structArr[i].groups = new JsonGroups();
        this.structArr[i].groups.details = new JsonParams();
        this.structArr[i].groups.details.parameters = [];

        for (const param of this.inputParameters.groups.details.parameters) {
          this.structArr[i].groups.details.parameters.push(param.clone('${' + this.pagingSettings.currentPage + '-' + i + '}'));
        }

        this.robot.updatePageWithData(this.structArr[i], this.valueArr[i]);

        this.structArrAsObj[i] = this.utils.arrToObj(this.structArr[i].groups.details.parameters, 'value', true);
        if (this.inputParameters.id) {
          this.structArrAsObj[i][this.inputParameters.id] = this.valueArr[i];
        }
      }

      this.robot.updateDynamicPropsOnPageParameters();

      // ///////////////////////////////////////////////////////////////
    } else if (this.inputParameters.pathToValueList === undefined) {
      // this.robot.updatePageWithData(null, null);
      this.structArr.push(this.inputParameters);
    }

/*     if (this.valueArr || Object.keys(this.valueArr).length > 0) {
      for (let i in this.valueArr) {
        this.valueRows['originalValue'][i] = {};
        this.valueRows['value'][i] = {};
        for (let p in this.inputParameters.groups.details.parameters) {
          let currParam = this.inputParameters.groups.details.parameters[p];
          this.valueRows['originalValue'][i][
            currParam.id
          ] = currParam.pathToValue
              ? this.utils.getValueFromDataForThisKey(
                this.valueArr[i],
                currParam.pathToValue
              )
              : currParam.originalValue;
          this.valueRows['value'][i][currParam.id] = this.utils.formatValues(
            currParam,
            this.valueRows['originalValue'][i][currParam.id]
          );
        }
      }
    }

    this._evaluateCardData(); */
  }

  private _updateInitValue() {

    if (!this.selectable) {
      return;
    }

    if (!this.inputParameters.value) {
      this.inputParameters.value = this._selectMultiple ? [] : {};
    } else {

      let initValue = this.utils.cloneJsonParse(this.inputParameters.value);
      initValue = this.utils.isObjectType(initValue, 'Array') ? initValue : [initValue];
      this.inputParameters.value = null;

      for (const value of <Object[]>initValue) {
        const pos = this.structArrAsObj.findIndex(obj => JSON.stringify(obj[this.inputParameters.id]) == JSON.stringify(value));
        this.selectCard(pos, value);
      }
    }
  }

  private _addToValue(value: object): void {
    if (this._selectMultiple) {
      this.inputParameters.value = this.inputParameters.value || [];
      this.inputParameters.value.push(value);
    } else {
      this.inputParameters.value = value;
    }
  }

  private _removeFromValue(value: Object): void {
    if (this._selectMultiple) {
      const position = this.inputParameters.value.findIndex(obj => JSON.stringify(obj) == JSON.stringify(value));
      this.inputParameters.value.splice(position, 1);
    } else {
      this.inputParameters.value = {};
    }
  }


  public showPaging() {
    return this._showPaging !== false;
  }

  public pageChange() {
    this._startCardLoading();
    // setTimeout(() => {
      this.pageChanged.emit(true);
    // }, 100);
  }

  private _startCardLoading() {
    this.valueArr = [];
    this.structArr = [];
    this.cardHasData = false;
    this.cardIsLoading = true;
  }

  private _evaluateCardData() {
    if (this.inputParameters.valueList === undefined) {
      this.cardHasData = true;
      this.cardIsLoading = false;
    } else {
      this.cardHasData = this.valueArr && this.valueArr.length > 0;
      this.cardIsLoading = !this.valueArr;
    }
  }
}
