import { ProgramsService } from './programs/programs.service';
import { Injectable } from '@angular/core';

@Injectable()
export class CustomValidator {

    constructor(private programsService: ProgramsService){}


    public preventWhiteSpaceValidator(field): any {
        if (/\s/.test(field.value)) {
            return { whiteSpaces: true };
        } else {
            return null;
        }
    }

    public preventSpecialCharacters(field): any {
        let pattern = /[_ç#!$%^&*()+|~=`{}\[\]:";'<>?,.\/]/;
        if (field.value.match(pattern)) {
            return { specialCharacters: true };
        } else {
            return null;
        }
    }

    public preventDuplicatedProgramName(field): any {

        if (this.programsService.programNames.includes(field.value)) {
            return { duplicatedProgramName: true };
        } else {
            return null;
        } 
    }




}