import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';

import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

// import * as FileSaver from 'file-saver'; //o FileSaver é utilizado no componente, não no serviço


@Injectable()
export class FileDownloadService {

    constructor(public http: HttpClient) { }

    /*método utilizado para fazer download de ficheiros. Exemplos de argumentos recebidos:
        -url:
            "/file-management/service-subscriptions/[[subscriptionId]]/files?filename="
            "/files/ficheiro.txt"
        -baseUrl: (opcional)
            "{{wifiCoreAPI}}"
            "http://localhost:4200"
            "/"
        -filename (opcional):
            "User manual_pt_PT"
            "UserManual.pdf"
        -fileType (opcional):
            ".pdf"
      retorna um Observable */
    public downloadFile(url: string, baseUrl?: string, filename?: string, fileType?: string): Observable<Object> {

        let fullUrl; //url completo que vai ser usado para fazer o pedido

        let tempUrl; //url auxiliar que vai conter a 2a parte completa do url: "url/filename.filetype"
        let splitUrl;
        if (baseUrl != null) //apenas caso se especifique o baseUrl
            splitUrl = baseUrl.split("/");

        //verificar se recebeu o "filename" e "filetype" para além do "baseUrl" e "url"
        if ((filename != '' && filename != null) && (fileType != '' && fileType != null)) {
            tempUrl = url + filename + fileType;
        } else {
            //verificar se recebeu apenas o "filename"
            if ((filename != '' && filename != null) && (fileType == '' || fileType == null)) {
                tempUrl = url + filename;
            } else { //recebeu apenas "baseUrl" e "url"
                tempUrl = url;
            }
        }

        if (baseUrl != null) { //usa o urlRecebido
            fullUrl = splitUrl[0] + '//' + splitUrl[2] + tempUrl;
        } else { //caso não se receba o "baseUrl", usa o baseUrl local
            fullUrl = tempUrl;
        }

        //preencher o header
        const headers = this._getHeaders();

        //fazer o request (GET) para obter o ficheiro
        return this.http.get(
            fullUrl,
            {
                headers: headers,
                responseType: 'blob',
                observe: 'response'
            }
        ).pipe(map(resp => resp)); // devolve o ficheiro (raw data)
    }


    /*construir os headers para o request*/
    private _getHeaders() {
        let headers = new HttpHeaders({
            'Content-Type': 'application/json',
            'Cache-Control': 'no-cache',
            'Authorization': 'Bearer ' + localStorage.token,
            'IXS': localStorage.IXS_id
        });

        return headers;
    }

    private handleError(error: any) {
        return Observable.throw(error.status);
    }
}
