import { Injectable } from '@angular/core';
import { EventEmitter } from '@angular/core';

@Injectable()
export class CustomEventManagerService {

  intentSelected: EventEmitter<Object> = new EventEmitter();
  editorLoaded: EventEmitter<Object> = new EventEmitter();
  genericAction: EventEmitter<Object> = new EventEmitter();
  createNewIntent: EventEmitter<Object> = new EventEmitter();
  newIntentCreated: EventEmitter<Object> = new EventEmitter();
  newVariableCreated: EventEmitter<Object> = new EventEmitter();
  questionsUpdate: EventEmitter<Object> = new EventEmitter();
  answersUpdate: EventEmitter<Object> = new EventEmitter();
  viewMode: EventEmitter<Object> = new EventEmitter();
  
  newTestSetCreated: EventEmitter<Object> = new EventEmitter();
  createNewTestSet: EventEmitter<Object> = new EventEmitter();

  constructor() {

  }

  editorIsLoaded() {
    this.editorLoaded.emit();
  }

  sendGenericAction(actionCode) {
    this.genericAction.emit(actionCode);
  }

  sendCreateNewIntent(){
    this.createNewIntent.emit();
  }
  sendNewIntentCreated(){
    this.newIntentCreated.emit();
  }
  sendNewVariableCreated(){
    this.newVariableCreated.emit();
  }


}
