import { FormBuilder, Validators, FormGroup } from '@angular/forms';
import { IntentsService } from '../intent.service';
import { CustomEventManagerService } from '../../customComponents/custom-event-manager.service';
import { Component, OnInit, Input } from '@angular/core';
// import { ModalService } from './../../foundations-webct2-palette/components/modalComponent/modal.service';
import { ModalService} from './../../foundations-webct-robot/robot/services/modal.service';

@Component({
  selector: 'variable-creation',
  templateUrl: './variable-creation.component.html',
  styleUrls: ['./variable-creation.component.scss']
})
export class VariableCreationComponent implements OnInit {
  @Input() inputParameters;
  @Input() inputDataRecs;
  @Input() dataRecs;
  public name: string = "";
  public description: string = "";
  public nature: string = "";
  public entityType: string = "";
  public customEntityList;
  public systemEntityList = [];
  public entityList;
  public entityTypeDisabled: boolean = true;

  createVariableForm: FormGroup;
  submitted = false;
  duplicatedNameError = false;
  invalidFormatName = false;
  invalidFormatDescription = false;


  constructor(
    private modalService: ModalService,
    public intentsService: IntentsService,
    private eventManager: CustomEventManagerService,
    private formBuilder: FormBuilder) { }

  ngOnInit() {



    this.customEntityList = this.intentsService.getCustomEntitiesList();
    this.intentsService.getSystemEntitiesList().then(response => {
      Object.keys(response).forEach((key: Extract<keyof typeof response, string>) => {
        if (key.toString() != "_id") {
          this.systemEntityList.push({
            label: key.toString(), value: "@sys." + key.toString()
          })
        }
      })
    });

    this.createVariableForm = this.formBuilder.group({
      variableName: ['', Validators.required],
      variableDescription: [''],
      variableEntityType: ['', Validators.required]

    });
  }


  public setNature(e) {
    if (e === 'system') {
      this.entityList = this.systemEntityList;
    } else {
      this.entityList = this.customEntityList;
    }
    this.nature = e;
    this.entityTypeDisabled = false;
    this.entityType = "";
  }

  public cancel() {
    this.modalService.closeModal();
  }

  public validateName() {
    this.duplicatedNameError = this.intentsService.variableExists(this.name.trim());
    this.invalidFormatName = !/^[^\"]*$/.test(this.name);
  }

  public validateDescription() {
    this.invalidFormatDescription = !/^[^\"]*$/.test(this.description);
  }

  public isInvalidForm(){
    return (this.createVariableForm && this.createVariableForm.invalid) || this.duplicatedNameError || this.invalidFormatDescription  || this.invalidFormatName || this.submitted;
  }

  public create() {

    if (this.createVariableForm.invalid || this.intentsService.variableExists(this.name) || this.submitted) {
      return;
    }

    this.submitted = true;

    this.intentsService.addVariableToProgram(this.name, this.description, this.nature, this.entityType).then(
      (resp) => {
        this.intentsService.reloadProgramVariables().then(
          (response) => {
            this.eventManager.sendNewVariableCreated()
            this.modalService.closeModal();
          }
        );
      },
      (resp) => {
        this.submitted = false;
        console.log("Some error in request:")
        console.log(resp)
      }
    );
  }

}
