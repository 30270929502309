import {HttpClient} from '@angular/common/http';
import {Injectable} from '@angular/core';
import {HeadersGeneratorService} from './headersGenerator.service';
import {PageService} from '../foundations-webct-robot/robot/pageComponent/page.service';

@Injectable()
export class RequestManagerService {

  constructor(private headersGenerator: HeadersGeneratorService,
              private pageService: PageService,
              private httpClient: HttpClient) {}

  public get(url: string, params = {}): any {
    const path = this.pageService.getUrlFromConfig(url);
    const headers = this.headersGenerator.getHttpHeaders();
    return this.httpClient.get(path, {headers, params});
  }
}
