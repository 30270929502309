import { Injectable, EventEmitter } from '@angular/core';
import { Router } from '@angular/router';

import { ConfigurationService } from '../utils/configuration.service';
import { GlobalVarsService } from '../utils/global-vars.service';
import { Utils } from '../utils/utils.service';
import { PageService } from '../pageComponent/page.service';
import { JsonParams } from '../classes/jsonParams.class';
import { RobotEngineModel } from '../robotEngineModel';

declare let Keycloak: any;

@Injectable()
export class AuthzService {

    public loggedIn = null;
    public requiredAuth: boolean = true;
    public userInfo = null;

    private _keycloakJS = null;
    private _loginStatus: EventEmitter<boolean> = new EventEmitter();

    constructor(
        private _router: Router,
        private _config: ConfigurationService,
        private _utils: Utils,
        private _pageService: PageService,
        private _globalVars: GlobalVarsService,
        private _robot: RobotEngineModel) {
    }

    public getLoginStatus = (): EventEmitter<boolean> => this._loginStatus;

    public logout(forceBaseUrl: boolean = false) {

        if (this._keycloakJS) {
            this.clearAuthzData();

            this._keycloakJS.forceBaseUrl = forceBaseUrl;
            this._keycloakJS.logout();
        } else
            console.error('Logout is not available!');

    }

    public clearAuthzData() {
        this.loggedIn = false;
        this._globalVars.cleanLocalStorage();
        this._globalVars.cleanSessionStorage();
    }

    public initAuthzService() {
        this._keycloakJS = Keycloak({
            url: this._config.getEndpoint('OICIpPort'),
            realm: this._config.getEndpoint('OICIpPort', 'entries___[key=="OICRealmId"?value]'),
            clientId: this._config.getEndpoint('OICIpPort', 'entries___[key=="OICClientId"?value]'),
            credentials: {
                secret: this._config.getEndpoint('OICIpPort', 'entries___[key=="OICSecretId"?value]')
            }
        });
    }

    public AuthzService(forceBaseUrl: boolean = false) {

        return new Promise((resolve, reject) => {
            this.initAuthzService();

            this.userInfo = this._config.getConfig('default___userInfo') || null;
            if (this.userInfo) {

                for (let i in this.userInfo)
                    this._globalVars.setGlobalVars(i, this.userInfo[i], true);

                this.requiredAuth = false;
                this._setUserLang(this._config.getLanguage());
                this._emitLoginStatusOk();
                resolve(true);
                return;
            } else if (this._globalVars.getLocalStorage('auth_token') && +this._globalVars.getLocalStorage('auth_token_exp') > new Date().getTime()) {
                // Caso o utilizador já tenha um Token válido

                this.userInfo = JSON.parse(this._globalVars.getLocalStorage('auth_user_info'));
                this._keycloakJS.init();

                let getUserDetails = this._getUserDetails();
                if (!getUserDetails) {
                    this._setUserLang(this._config.getLanguage());
                    this._emitLoginStatusOk();
                }
                resolve(true);
                return;
            }

            this._keycloakJS.forceBaseUrl = forceBaseUrl;
            this._keycloakJS.init({
                onLoad: 'login-required',
                token: this._globalVars.getLocalStorage('auth_token'),
                checkLoginIframe: false,
                responseMode: 'query'
            })
                .success(() => {
                    this._keycloakJS.loadUserInfo()
                        .success((userRes) => {
                            this._setLoggedUserInfo(userRes);
                            resolve(true);
                        })
                        .error(() => {
                            this.logout();
                        });
                })
                .error(() => {
                    this.logout();
                });
        });

    }

    public isLoggedIn = () => this.loggedIn;
    public setLoggedIn(value: boolean) {
        this.loggedIn = value;
    }

    public decodeToken(str) {
        str = str.split('.')[1];

        str = str.replace('/-/g', '+');
        str = str.replace('/_/g', '/');
        switch (str.length % 4) {
            case 0:
                break;
            case 2:
                str += '==';
                break;
            case 3:
                str += '=';
                break;
            default:
                throw 'Invalid token';
        }

        str = (str + '===').slice(0, str.length + (str.length % 4));
        str = str.replace(/-/g, '+').replace(/_/g, '/');

        str = decodeURIComponent(encodeURI(atob(str)));

        str = JSON.parse(str);
        return str;
    }

    public get keycloakJS() {
        return this._keycloakJS;
    }

    private _setLoggedUserInfo(data: Object): void {

        this._globalVars.cleanLocalStorage();
        this.userInfo = data;

        if (!this.userInfo.roles)
            this.userInfo.roles = [];

        if (this._keycloakJS.resourceAccess && this._keycloakJS.resourceAccess[this._config.getConfig('default___appName')])
            this.userInfo.roles = this.userInfo.roles.concat(this._keycloakJS.resourceAccess[this._config.getConfig('default___appName')].roles.sort());
        if (this._keycloakJS.realmAccess)
            this.userInfo.roles = this.userInfo.roles.concat(this._keycloakJS.realmAccess.roles.sort());

        this._globalVars.setLocalStorage('auth_user_info', JSON.stringify(this.userInfo));
        this._globalVars.setLocalStorage('auth_token_exp', (this.decodeToken(this._keycloakJS.idToken).exp * 1000).toString());

        this._globalVars.setLocalStorage('auth_token', this._keycloakJS.idToken || this._keycloakJS.token);
        this._globalVars.setLocalStorage('auth-token', this._keycloakJS.idToken || this._keycloakJS.token);

        this._globalVars.setLocalStorage('idToken', this._keycloakJS.idToken);
        this._globalVars.setLocalStorage('token', this._keycloakJS.token);

        this._globalVars.setLocalStorage('user_roles', JSON.stringify(this.userInfo.roles));
        this._globalVars.setLocalStorage('user-roles', JSON.stringify(this.userInfo.roles));

        this._globalVars.setLocalStorage('user_email', this.userInfo.email);
        this._globalVars.setLocalStorage('user-email', this.userInfo.email);
        this._globalVars.setLocalStorage('logged_user', this.userInfo.email);
        this._globalVars.setLocalStorage('logged-user', this.userInfo.email);

        this._globalVars.setLocalStorage('preferred_username', this.userInfo.preferred_username);
        this._globalVars.setLocalStorage('preferred-username', this.userInfo.preferred_username);

        let getUserDetails = this._getUserDetails(this.userInfo);
        if (!getUserDetails) {
            this._setUserLang(this._globalVars.getLocalStorage('language'));
            this._emitLoginStatusOk();
        }

    }

    private _getLoggedUserInfo(urlResource: Object[], dataRecs: Object) {

        this._robot.loadPageParams({ urlResource }, 'AUTHZSERVICE').then(res => {

            let obj = new Object();
            for (let conf of res.groups.urlResource.parameters) {
                obj[conf.key] = conf.value;
            }

            let formatedUrlResource = this._pageService.setMyUrlResource(res, obj, new Object(), null, null);
            formatedUrlResource = this._utils.replaceTagVars(formatedUrlResource, dataRecs);


            this._utils.GetAll(formatedUrlResource, obj['headers'], obj)
                .subscribe(data => {

                    if (obj['pathToMainData'])
                        data = this._utils.getValueFromDataForThisKey(data, obj['pathToMainData'])

                    let tokenUserInfo = this._config.getConfig('default___loggedUser___saveAttr');
                    if (tokenUserInfo) {
                        for (let i in tokenUserInfo) {
                            let temp = this._utils.replaceTagVars(tokenUserInfo[i], [data, dataRecs]);
                            if (!temp.match(this._utils.mustacheReg))
                                this._globalVars.setLocalStorage(i, temp);
                        }
                    }

                    this._setUserLang(this._globalVars.getLocalStorage('language') || this._config.getLanguage());
                    this._emitLoginStatusOk();
                });

        })

    }

    private _getUserDetails(data: Object = null): boolean {
        let loggedUserRequest = this._config.getConfig('default___loggedUser___urlResource');
        if (loggedUserRequest) {
            this._getLoggedUserInfo(loggedUserRequest, data);
            return true;
        }
        return false;
    }

    private _setUserLang(lang: string = this._config.i18nLang) {
        this._config.set_i18n(lang);
    }

    private _emitLoginStatusOk() {
        this.loggedIn = true;
        this._loginStatus.next(this.loggedIn);
    }

}
