import { Injectable } from '@angular/core';
import { HttpResponse } from '@angular/common/http';

import { GlobalVarsService } from '../utils/global-vars.service';
import { JsonParams } from '../classes/jsonParams.class';

@Injectable()
export class ApiService {

  private _apiRecycle: Object = {};
  private _apiHistory: Object = {};
  private _apiByComponent: Object = {};

  constructor(
    private _globalVars: GlobalVarsService) {

  }

  public getApiRecycle = (id: string): any => this._apiRecycle[id];
  public getApiHistory = (id?: string): any => id ? this._apiHistory[id] : this._apiHistory;
  public dealWithApiResponse = (response: HttpResponse<any>, bodyAttr: string = 'body') => response && response[bodyAttr] ? response[bodyAttr] : response;

  public deleteApiHistoryById(id: string): any {
    if (!id)
      return;

    delete this._apiRecycle[id];
    delete this._apiHistory[id];
    this._globalVars.deleteGlobalVar(id);
  }

  public deleteApiHistory(componentId?: string): void {
    let keys = componentId ? this._apiByComponent[componentId] : Object.keys(this._apiHistory);
    for (let key in keys)
      this.deleteApiHistoryById(key);
  }

  public setApiHistory(config: Object, data: any): void {

    if (!config['id'])
      return;
    if (config['apiRecycle'] !== false)
      this._apiRecycle[config['id']] = data;

    this._apiHistory[config['id']] = data;
    this._globalVars.setGlobalVars(config['id'], data);
    this._saveApiByComponent(config);
  }

  public saveRequestResponse(id: string, body: HttpResponse<any>, componentId: string = null, bodyAttr: string = 'body'): HttpResponse<any> {

    let response = this.dealWithApiResponse(body, bodyAttr);
    if (id) {
      this._globalVars.setPageParameters(new JsonParams(id, response), componentId);
      this._globalVars.setPageParameters(new JsonParams('response::' + id, body), componentId);
      this._globalVars.setPageParameters(new JsonParams('headers::' + id, this._getResponseHeaders(body)), componentId);
    }

    return response;
  }

  private _saveApiByComponent(config) {

    if (!config['_componentId'])
      return;
    if (!this._apiByComponent[config['_componentId']])
      this._apiByComponent[config['_componentId']] = [];

    this._apiByComponent[config['_componentId']].push(config['id']);
  }

  private _getResponseHeaders(res: HttpResponse<any>): any {

    let headers = {};
    res.headers.keys().forEach(key => headers[key] = res.headers.get(key));

    return headers;
  }
}
