﻿import { Component, Input, OnInit } from '@angular/core';
import { FormGroup } from '@angular/forms';

import { PageService } from './../../../foundations-webct-robot/robot/pageComponent/page.service';
import { AbstractValueAccessor } from './../../../foundations-webct-robot/robot/utils/abstractValueAccessor';
import { Utils } from './../../../foundations-webct-robot/robot/utils/utils.service';
import { JsonParams } from './../../../foundations-webct-robot/robot/classes/jsonParams.class';

@Component({
  selector: 'app-search',
  templateUrl: 'app-search.component.html'
})

export class AppSearchComponent extends AbstractValueAccessor implements OnInit {

  @Input() public inputParameters: JsonParams;
  // @Input() public baseInfoForm: FormGroup;

  @Input('id') public id: string;
  @Input('name') public name: string;
  @Input('placeholder') public placeholder: string;
  @Input('maxlen') public maxlength: number;
  @Input('focus') public focus: boolean;
  @Input('size') public size = 'lg';
  @Input('label') public label = '';
  @Input('validation-display-mode') public valDisplayMode = 'toastr';

  public value;
  public hasError = false;
  public errorMessage = '';
  public form: FormGroup;
  public showResults = false;
  public showResultsDataRecs: Object;

  constructor(
    private _utils: Utils,
    private _pageService: PageService) {
    super();
  }

  public search() {
    if (this.form.status == 'INVALID')
      return;

    this.showResults = false;
    this.showResultsDataRecs = this._utils.arrToObj(this.inputParameters.groups.details.parameters);
    if (this.inputParameters.mockToLoad) {
      setTimeout(() => {
        this.showResults = true;
      }, 100);
    } else if (this.inputParameters.navigateTo)
      this._utils.navigate(this.inputParameters.navigateTo, this.showResultsDataRecs);
  }

  public ngOnInit() {
    this.form = this._pageService.configValidators(this.inputParameters.groups.details.parameters, false, null, false, this.inputParameters.id);
  }

  public onDestroy() {
    if (this.inputParameters)
      this._pageService.deleteActiveForm(this.inputParameters.id);
  }
}
