import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs/Rx';

import { JsonParams } from '../classes/jsonParams.class';
import { Utils } from './utils.service';
import { GlobalVarsService } from './global-vars.service';

import { DateService } from './../../../foundations-webct-robot/robot/services/date.service';
import { VersionControlService } from '../services/version-control.service';

@Injectable()
export class ConfigurationService {

  public i18nData: Object = {};
  public i18nLang: string = 'en-US';

  private _config: Object = {};
  private _env: Object = {};

  constructor(
    private _utils: Utils,
    private _dateService: DateService,
    private _versionControl: VersionControlService,
    private _http: HttpClient,
    private _globalVars: GlobalVarsService) {

  }

  public getConfig(attribute: string | string[]): any {
    return this._utils.getValueFromDataForThisKey(this._config, attribute);
  }

  public getEndpoint(id: string, attribute?: string) {
    return this._utils.getEndpoint(id, attribute);
  }

  public getLanguage(): string {
    let languageConfig = this._utils.findValues(['default', 'language'], this._config) || this._utils.findValues(['language', 'default'], this._config) || this.i18nLang;
    let language = this._utils.replaceTagVars(languageConfig);
    return language ? language.replace(this._utils.mustacheReg, '') : language;
  }

  public loadConfigurations = (): Promise<any> => {
    console.log('LoadConfig');
    
    console.log(window.location.hostname);
    console.log(window.location.href);
    console.log(window.location.origin);

    let configPath = '/assets/configuration';
    return new Promise((resolve, reject) => {
      this._http.get(configPath + '/env.json' + this._versionControl.preventJsonCache()).catch((error: any): any => {
        console.error('Configuration file "env.json" could not be read');
        resolve(true);
        return Observable.throw(error.json().error || 'Server error');
      }).subscribe((envResponse) => {
        this._env = envResponse;
        this._versionControl.version = this._env['version'];

        let request: any = null;
        let requestTenant: any = null;

        this._globalVars.setGlobalVars('enviroment::init', this._env, true);

        //request = this._http.get(configPath + '/config.' + envResponse['env'] + '.json' + this._versionControl.preventJsonCache());
        //requestTenant = this._http.get(configPath + '/config.' + window.location.hostname + '.json' + this._versionControl.preventJsonCache());
        request = this._http.get(configPath + '/config.' + window.location.hostname + '.json' + this._versionControl.preventJsonCache());
        console.log(request);
        console.log(requestTenant);

        if (request) {
          request
            .catch((error: any) => {
              console.error('Error reading ' + envResponse['env'] + ' configuration file');
              resolve(error);
              return Observable.throw(error.json().error || 'Server error');
            })
            .subscribe((responseData) => {
              this._config = responseData;
            
              this._utils.configVars = this._config;
  
              this._globalVars.setGlobalVars('enviroment::config', this._config, true);
  
              let supportedLang = this.getConfig(['language', 'supported']);
              if (supportedLang)
                this._globalVars.setGlobalVars('languageSupported', Object.keys(supportedLang), true);
  
              this._dateService.dateTimeFormatFriendly = this.getConfig('default___dateTimeFormat') || this._dateService.dateTimeFormatFriendly;
              this._dateService.dateTimeFormatFriendlyRegex = this.getConfig('default___dateTimeFormatRegex') || this._dateService.dateTimeFormatFriendlyRegex;
  
              this._dateService.dateFormatFriendly = this.getConfig('default___dateFormat') || this._dateService.dateFormatFriendly;
              this._dateService.dateFormatFriendlyRegex = this.getConfig('default___dateFormatRegex') || this._dateService.dateFormatFriendlyRegex;
  
              this._dateService.timezone = this.getConfig('default___timezone') || this._dateService.timezone;
              resolve(true);
            });
            // this.updateEnpoints();
        } else {
          console.error('Env config file "env.json" is not valid');
          resolve(true);
        }
      });

    });

  }

  /**
   * GET NEW LANGUAGE FILE
   */
  public set_i18n(lang: string = null) {
    let currLang = lang || this.getLanguage();
    this._globalVars.setLocalStorage('language', currLang);

    let supportedLang = this.getConfig(['language', 'supported']);
    if (supportedLang)
      this._setLangFiles(supportedLang, currLang);
    else {
      this._utils
        .GetAll(this._i18nFile(currLang).replace('_', '-'))
        .subscribe((response) => {
          this.i18nData = response;
          this._utils.i18nData = response;
        });
    }
  }

  public isI18n = () => this.i18nData && Object.keys(this.i18nData).length > 0;

  private _setLangFiles(supportedLang: Object, currLang: string): void {

    let forkJoinRequests: Observable<any>[] = [];
    if (supportedLang[currLang]) {
      for (let file of supportedLang[currLang])
        forkJoinRequests.push(this._utils.GetAll(this._i18nFile(file)));

      Observable.forkJoin(forkJoinRequests).subscribe(response => {
        let i18nData = {};

        for (let resp of response)
          i18nData = this._utils.mergeDeep(i18nData, resp);

        this.i18nData = i18nData;
        this._utils.i18nData = i18nData;
      });
    } else
      console.error('ERROR: _setLangFiles() -> Lang files not found!');

  }

  private _i18nFile = (file: string): string => location.origin + '/assets/i18n/' + file + '.json' + this._versionControl.preventJsonCache();

}
