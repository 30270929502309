import { Router } from '@angular/router';
import { ProgramsService } from './../programs.service';
import { CustomValidator } from '../../customValidators.service';
import { FormBuilder, Validators, FormGroup } from '@angular/forms';
import { Component, OnInit, Input } from '@angular/core';
import { ModalService } from './../../foundations-webct-robot/robot/services/modal.service';

import { JsonParams } from './../../foundations-webct-robot/robot/classes/jsonParams.class';

@Component({
  selector: 'program-creation',
  templateUrl: './programCreation.component.html',
  styleUrls: ['./programCreation.component.scss']
})
export class ProgramCreationComponent implements OnInit {
  @Input() inputParameters;
  @Input() inputDataRecs;
  @Input() dataRecs;



  createProgramStep1: FormGroup;
  createProgramStep2: FormGroup;
  submitted = false;
  duplicatedNameError = false;
  invalidFormatName = false;
  invalidFormatDescription = false;

  categoriesByLanguage;
  selectedLanguage;
  selectedCategoriesInternal = []

  showCategoriesSelect = false;
  competenceName: String;
  competenceDesc: String;
  competenceCategory: String;
  programId: String;
  superheroId: String = undefined;

  createOnSuperhero: Boolean = false

  selectedVALoading: Boolean = true;
  selectedCategoriesLoading: Boolean = true;

  totalSteps = 2
  currentStep = 1;


  constructor(
    private modalService: ModalService,
    private formBuilder: FormBuilder,
    public programsService: ProgramsService,
    private customValidator: CustomValidator,
    private router: Router) { }

  ngOnInit() {

    this.createProgramStep1 = this.formBuilder.group({
      programName: ['', [Validators.required, this.customValidator.preventWhiteSpaceValidator, this.customValidator.preventSpecialCharacters, this.customValidator.preventDuplicatedProgramName.bind(this)]],
      programDescription: ['', Validators.required]
    });

    this.createProgramStep2 = this.formBuilder.group({
      selectedVA: ['', Validators.required],
      selectedCategories: [[]]
    });

    this.programsService.getAllPrograms();
    this.programsService.getCategoriesList().then(() => {
      this.programsService.getVAList();

      this.selectedCategoriesLoading = false;
      this.selectedVALoading = false;

    });

  }

  public superheroChanged(superhero) {
    this.showCategoriesSelect = false;
    this.selectedCategoriesLoading = true;
    if (superhero) {
      this.superheroId = superhero.id;
      this.createProgramStep2.get('selectedVA').setValue(superhero);
      this.categoriesByLanguage = this.programsService.getCategoriesListByLanguage(superhero.language);
      this.selectedLanguage = superhero.language;
      this.showCategoriesSelect = true;
      this.selectedCategoriesLoading = false;
    } else {
      this.superheroId = undefined;
      this.showCategoriesSelect = false;
      this.selectedCategoriesLoading = false;
    }

    this.createProgramStep2.get('selectedCategories').patchValue([]);

  }

  public checkSave() {
    if (this.createProgramStep1.get('programName').value.length > 0 && this.createProgramStep1.get('programDescription').value.length > 0 && !(this.superheroId == undefined)) {
      return false;
    } else {
      return true;
    }
  }



  public categoriesChanged(categories) {
    this.selectedCategoriesInternal = categories;
  }


  public save() {

    if (!this.isValidForm() || this.submitted) {
      return;
    }
    this.submitted = true;

    this.programsService.createProgram(
      this.createProgramStep1.get('programName').value,
      this.createProgramStep1.get('programDescription').value,
      localStorage.getItem("logged-user"),
      this.selectedCategoriesInternal,
      this.createProgramStep2.get('selectedVA').value,
      this.selectedLanguage).subscribe(result => {
        this.programId = result["data"]["_id"];
          if (this.superheroId) {
            this.programsService.addProgramToStudent(this.superheroId, this.programId, localStorage.getItem("logged-user")).subscribe(arg => {
              this.router.navigateByUrl('/knowledgecenter/programs/' + this.programId + '/designFlow');
              this.modalService.closeModal();
            });
          } else {
            this.modalService.closeModal();
          }
      });


  }
  public openModal(mock: string) {
    this.modalService.openModal(mock, null, new Object(), new JsonParams());
  }

  public cancel() {
    this.modalService.closeModal();
  }

  public next() {
    this.currentStep++;
  }

  public previous() {
    this.currentStep--;
  }

  public isValidForm() {
    return !(this.createProgramStep1.invalid || this.duplicatedNameError);
  }

}
