import { Injectable } from '@angular/core';
import { ModalDirective } from 'ngx-bootstrap/modal';
import { Utils } from './../../../foundations-webct-robot/robot/utils/utils.service';
import { GlobalVarsService } from './../../../foundations-webct-robot/robot/utils/global-vars.service';
import { JsonParams } from './../../../foundations-webct-robot/robot/classes/jsonParams.class';

@Injectable()
export class ModalService {

  public lgModal: ModalDirective;

  public dataRecs: Object = null;
  public mockToLoad: string = null;

  public inputParameters: JsonParams = null;
  public viewStructure: JsonParams;
  public newMockStructure: JsonParams[] = [];
  public modalSize: string = null;

  public allowModalClose: boolean = false;
  public isModalShown: boolean | null = false;
  public modalProps: JsonParams;

  constructor(
    private _utils: Utils,
    private _globalVars: GlobalVarsService) {
  }

  public setMockToLoad(value: string) {
    // this.mockToLoad = null;
    // setTimeout(() => {
    //   this.mockToLoad = value;
    // }, 1);
    this.mockToLoad = value;
  }

  public setDataRecs(value: Object) {
    this.dataRecs = value;
  }

  public closeModal() {
    if (!this.isModalShown)
      return;
    if (this.modalProps.subType === "draggable") {
      try {
        this.isModalShown = false;
      } catch (err) { }

    } else {
      try {
        this.lgModal.hide();
      } catch (err) { };
    }
  }

  public openModal(mockToLoad: string, parameters: JsonParams[] = null, dataRecs: Object = null, operation: JsonParams = null) {

    this.inputParameters = null;
    this.modalSize = 'modal-' + (operation.size || 'md');

    this.modalProps = operation;

    /**
     * POR DEFEITO, ENVIAMOS PARA A MODAL TODOS OS PARÂMETROS DISPONÍVEIS
     */
    let dataRecsToSend = dataRecs;
    let pageParametersData = this._utils.arrToObj(this._globalVars.getPageParametersAsArray(), 'originalValue');

    if (parameters) {
      /**
       * CASO SEJA DEFINIDO NO MOCK, ENVIAMOS PARA A MODAL APENAS OS PARÂMETROS INDICADOS
       */
      let contextParameters = this._utils.findObjectInArray(parameters, 'context');
      if (dataRecs && contextParameters.key) {
        if (Object.prototype.toString.call(dataRecs) === '[object Array]') {
          dataRecsToSend = [];
          for (let d in dataRecs) {
            dataRecsToSend[d] = {};
            for (let i in contextParameters.value) {
              if (this._manualContext(dataRecsToSend[d], contextParameters.value[i]))
                continue;
              dataRecsToSend[d][contextParameters.value[i]] = this._utils.getValueFromDataForThisKey(this._utils.removeIdSufixFromObject(dataRecs[d]), contextParameters.value[i]);
              if (!dataRecsToSend[d][contextParameters.value[i]])
                dataRecsToSend[d][contextParameters.value[i]] = this._utils.getValueFromDataForThisKey(this._utils.removeIdSufixFromObject(pageParametersData), contextParameters.value[i]);
            }
          }
        } else {
          dataRecsToSend = {};
          for (let i in contextParameters.value) {
            if (this._manualContext(dataRecsToSend, contextParameters.value[i]))
              continue;
            dataRecsToSend[contextParameters.value[i]] = this._utils.getValueFromDataForThisKey(this._utils.removeIdSufixFromObject(dataRecs), contextParameters.value[i]);
            if (!dataRecsToSend[contextParameters.value[i]])
              dataRecsToSend[contextParameters.value[i]] = this._utils.getValueFromDataForThisKey(this._utils.removeIdSufixFromObject(pageParametersData), contextParameters.value[i]);
          }
        }
      }
      this.allowModalClose = !!this._utils.findObjectInArray(parameters, 'allowModalClose').value;
    }
    this.mockToLoad = null;
    setTimeout(() => {
      this.setMockToLoad(mockToLoad);
      this.setDataRecs(dataRecsToSend);
      this.isModalShown = true;
    }, 1000);

    /*     new Promise((resolve) => {
          this.setMockToLoad(mockToLoad);
          this.setDataRecs(dataRecsToSend);
          this.isModalShown = true;
          resolve();
        }); */



    /*}).then(() => {
    });*/
  }

  private _manualContext(obj: any, context: string | ManualContext) {
    if (typeof context == 'string' || context.key === undefined)
      return false;
    obj[context.key] = context.value;
    return true;
  }

}

interface ManualContext {
  key: string;
  value: string;
}
