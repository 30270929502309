import { Injectable } from '@angular/core';

import { JsonParams } from '../classes/jsonParams.class';

import { PageService } from '../pageComponent/page.service';
import { OperationsService } from '../utils/operations.service';
import { Utils } from '../utils/utils.service';
import { GlobalVarsService } from '../utils/global-vars.service';
import { AuthzService } from '../utils/authz.service';

// import { ModalService } from 'foundations-webct-palette/components/modalComponent/modal.service';
import { ModalService } from '../services/modal.service';
import { ComponentsService } from './components.service';

@Injectable()
export class NavigationService {

    private _dataRecs: Object;
    private _dataRecsContext: JsonParams[] = [];

    private _hasSideMenu: boolean = false;
    private _isSideMenuOpen: boolean = false;
    private _isSideMenuFixed: boolean = false;

    constructor(
        private _utils: Utils,
        private _components: ComponentsService,
        private _authz: AuthzService,
        private _globalVars: GlobalVarsService,
        private _operations: OperationsService,
        private _modalService: ModalService,
        private _pageService: PageService) { }

    public navigateByType(e, operation: JsonParams, componentId?: string) {

        switch (operation.type) {
            case 'openModal':
            const contextParamsToObj: Object = this._utils.arrToObj(this._dataRecsContext, 'value', true);
                this._modalService.openModal(
                    operation.mockToLoad,
                    operation.parameters,
                    Object.keys(contextParamsToObj).length > 0 ? contextParamsToObj : this._dataRecs, operation
                );
            this._components.updatePageParameters(operation.parameters);
                break;
            case 'navigate':
            this._utils.navigate(operation.navigateTo, this._dataRecs, e);
            case 'updatePage':
                this._components.updatePageParameters(operation.parameters, this._dataRecs);
                break;
            case 'submit':
            case 'execute':
            this._pageService.operationLoading = true;
            this._operations.execute(this._globalVars.getPageParametersAsArray(), operation.parameters, componentId, this._dataRecs);
                break;
            case 'logout':
            this._authz.logout(true);
        }

        const newConfiguration = this._utils.findObjectInArray(operation.parameters, 'onSuccess').value ||
            this._utils.findObjectInArray(operation.parameters, 'onError').value;
        if (!operation.parameters ||
            (!newConfiguration && this._utils.findObjectInArray(operation.parameters, 'keepModal').value !== true) ||
            (newConfiguration && newConfiguration.keepModal !== true)
        ) {
            this._modalService.closeModal();
        }

        this._dataRecs = undefined;
        this._dataRecsContext = undefined;
    }

    public btnStatusByMappingId(param: JsonParams, controlers: any): boolean {
        // TODO: Procurar controladores dinâmicos quando este não é encontrado na listagem inicial de controladores
        return !!(<string[]>(this._utils.isObjectType(param.mappingId, 'Array') ? param.mappingId : [param.mappingId])).find(id => controlers[id] && controlers[id].invalid);
    }

    public set dataRecs(value: Object) {
        this._dataRecs = value;
    }
    public set dataRecsContext(value: JsonParams[]) {
        this._dataRecsContext = value;
    }

    public get hasSideMenu(): boolean {
        return this._hasSideMenu;
    }
    public set hasSideMenu(value: boolean) {
        this._hasSideMenu = value;
    }

    public get isSideMenuOpen(): boolean {
        return this._isSideMenuOpen;
    }
    public set isSideMenuOpen(value: boolean) {
        this._isSideMenuOpen = value;
    }

    public get isSideMenuFixed(): boolean {
        return this._isSideMenuFixed;
    }
    public set isSideMenuFixed(value: boolean) {
        this._isSideMenuFixed = value;
    }

}
