export class Validator {
    
    get required(): ValidatorDefs<boolean, string> {
        return this._required;
    }
    set required(value: ValidatorDefs<boolean, string>) {
        this._required = value;
    }
    get minlength(): ValidatorDefs<number, string> {
        return this._minlength;
    }
    set minlength(value: ValidatorDefs<number, string>) {
        this._minlength = value;
    }
    get maxlength(): ValidatorDefs<number, string> {
        return this._maxlength;
    }
    set maxlength(value: ValidatorDefs<number, string>) {
        this._maxlength = value;
    }
    get pattern(): ValidatorDefs<string, string> {
        return this._pattern;
    }
    set pattern(value: ValidatorDefs<string, string>) {
        this._pattern = value;
    }
    get validatorFn(): ValidatorDefs<string[], string[]> {
        return this._validatorfn;
    }
    set validatorFn(value: ValidatorDefs<string[], string[]>) {
        this._validatorfn = value;
    }
    get autoComplete(): ValidatorDefs<Object, string>{
        return this._autoComplete;
    }
    set autoComplete(value: ValidatorDefs<Object, string>){
        this._autoComplete=value;
    }
    get unique():ValidatorDefs<Object, string>{
        return this._unique;
    }
    set unique(value:ValidatorDefs<Object, string>){
        this._unique=value;
    }

    private _required: ValidatorDefs<boolean, string>;
    private _minlength: ValidatorDefs<number, string>;
    private _maxlength: ValidatorDefs<number, string>;
    private _pattern: ValidatorDefs<string, string>;
    private _validatorfn: ValidatorDefs<string[], string[]>;
    private _autoComplete: ValidatorDefs<Object, string>;
    private _unique: ValidatorDefs<Object, string>;

    constructor(
        required?: ValidatorDefs<boolean, string>,
        minlength?: ValidatorDefs<number, string>,
        maxlength?: ValidatorDefs<number, string>,
        pattern?: ValidatorDefs<string, string>,
        validatorFn?: ValidatorDefs<string[], string[]>,
        autoComplete?: ValidatorDefs<Object, string>, 
        unique?: ValidatorDefs<Object, string>) {
        this.required = required;
        this.minlength = minlength;
        this.maxlength = maxlength;
        this.pattern = pattern;
        this.validatorFn = validatorFn;
        this.autoComplete = autoComplete;
        this.unique = unique;
    }
}

export class ValidatorDefs<T, U> {

    get value(): T {
        return this._value;
    }
    set value(value: T) {
        this._value = value;
    }
    get description(): U {
        return this._description;
    }
    set description(description: U) {
        this._description = description;
    }

    private _value: T;
    private _description: U;

    constructor(value: T, description: U) {
        this._value = value;
        this._description = description;
    }

}
