import { Injectable } from '@angular/core';

@Injectable()
export class DateService {

  private _dateformat = 'MM-DD-YYYY HH:mm:ss';
  private _dateReg = /^\d{4}([.\/-])\d{2}\1\d{2}T\d{2}:\d{2}:\d{2}(\.(\d{1}|\d{2}|\d{3}))?(Z|(\+|\-)\d{2}:?\d{2})?$/;

  private _dateTimeFormatFriendlyRegex: RegExp = /^\d{2}\-\d{2}\-\d{4}\ \d{2}\:\d{2}\:\d{2}$/g;
  private _dateFormatFriendlyRegex: RegExp = /^\d{2}\-\d{2}\-\d{4}$/g;

  private _dateTimeFormatFriendly = 'DD-MM-YYYY HH:mm:ss';
  private _dateFormatFriendly = 'DD-MM-YYYY';
  private _timezone = 'Europe/London';
  private _timestampWithTZformat = 'YYYY-MM-DDTHH:mm:ss.SSSZ';

  get dateformat() {
    return this._dateformat;
  }
  get dateReg() {
    return this._dateReg;
  }

  get dateTimeFormatFriendlyRegex() {
    return this._dateTimeFormatFriendlyRegex;
  }
  set dateTimeFormatFriendlyRegex(value: RegExp) {
    this._dateTimeFormatFriendlyRegex = value;
  }

  get dateFormatFriendlyRegex() {
    return this._dateFormatFriendlyRegex;
  }
  set dateFormatFriendlyRegex(value: RegExp) {
    this._dateFormatFriendlyRegex = value;
  }

  get dateTimeFormatFriendly() {
    return this._dateTimeFormatFriendly;
  }
  set dateTimeFormatFriendly(value: string) {
    this._dateTimeFormatFriendly = value;
  }

  get dateFormatFriendly() {
    return this._dateFormatFriendly;
  }
  set dateFormatFriendly(value: string) {
    this._dateFormatFriendly = value;
  }

  get timezone() {
    return this._timezone;
  }
  set timezone(value: string) {
    this._timezone = value;
  }

  get timestampWithTZformat() {
    return this._timestampWithTZformat;
  }
  set timestampWithTZformat(value: string) {
    this._timestampWithTZformat = value;
  }
}
