import { NgModule, CUSTOM_ELEMENTS_SCHEMA } from '@angular/core';
import { DatePipe } from '@angular/common';

/**
 * ROBOT
 */
import { EXPORTS, DECLARATIONS } from './webct.components';
import { EXPORTS_V2, DECLARATIONS_V2 } from './webct-v2.components';
import { SharedModule } from './foundations-webct-robot/robot/shared.module';
// import { NotificationService } from './foundations-webct2-palette/components/notificationComponent/notification.component';
import { AutoCompleteDetailsService } from './foundations-webct2-palette/components/autoCompleteDetailsComponent/auto-complete-details.service';
// import { ExportService } from './foundations-webct2-palette/components/wct-table/table-export.service';
import { ExportService } from './foundations-webct2-palette/components/tableComponent/table-export.service';
//import { UploadService } from './foundations-webct2-palette/components/upload/upload.service';
// import { ModalService } from './foundations-webct2-palette/components/modalComponent/modal.service';
import { ModalService } from './foundations-webct-robot/robot/services/modal.service';

// import { BreadcrumbService } from './foundations-webct2-palette/components/breadcrumbComponent/breadcrumb.service';
import { BreadcrumbService } from './foundations-webct2-palette/components/wct-breadcrumb/breadcrumb.service';
// import { AppHeaderService } from './foundations-webct2-palette/components/appHeaderComponent/app-header.service';
import { AppHeaderService } from './foundations-webct2-palette/components/wct-header/app-header.service';
/**
 * CUSTOM COMPONENTS / SERVICES
 */
import { CustomComponentsModule } from './customComponents/custom.module';
import { ClientCustomValidatorProvider, ClientCustomUtilsProvider } from './app.custom-factory';
// import { UserManagementService } from './foundations-webct2-palette/components/userManagementComponent/usermanagement.service';
import { TextboxUnicityValidatorService } from './foundations-webct2-palette/components/unicityValidatorComponent/unicity-validator.service';

import { ActivityLogService } from './customComponents/activitylog.service';
import { ImagesService } from './customComponents/images.service';
import { IxsService } from './customComponents/ixs.service';
import { StripeService } from './customComponents/stripe/stripe.service';
import { PrintService } from './customComponents/print/print.service';
import { UploadService } from './foundations-webct2-palette/components/wct-upload/wct-upload.service';
import { FileDownloadService } from './foundations-webct2-palette/components/wct-file-download/wct-file-download.service';
import { WctNotificationService } from './foundations-webct2-palette/components/wct-notification/wct-notification.service';

import { TagInputModule } from 'ngx-chips';
import { MentionModule } from 'ngx-ui-mention';
import { TextInputHighlightModule } from 'angular-text-input-highlight';

import { DragDropModule } from '@angular/cdk/drag-drop';
import { QuillModule } from 'ngx-quill';
import { OnBoardingService } from './OnBoarding.service';
import { NgHighlightModule } from 'ngx-text-highlight';
import { UtilsCustomLocalService } from './customComponents/custom-utils.service';
import { SuperherosService } from './superheros/superheros.service';
import { HeadersGeneratorService } from './requests/headersGenerator.service';
import { RequestManagerService } from './requests/requestManager.service';


@NgModule({
  declarations: [...DECLARATIONS, ...DECLARATIONS_V2],
  imports: [ // import Angular's modules
    SharedModule,
    CustomComponentsModule,
    TagInputModule,
    MentionModule,
    TextInputHighlightModule,
    DragDropModule,
    QuillModule,
    NgHighlightModule
  ],
  providers: [ // expose our Services and Providers into Angular's dependency injection
    ClientCustomValidatorProvider,
    ClientCustomUtilsProvider,
    // NotificationService,
    AutoCompleteDetailsService,
    ExportService,
    UploadService,
    FileDownloadService,
    ModalService,
    DatePipe,
    // UserManagementService,
    BreadcrumbService,
    TextboxUnicityValidatorService,
    AppHeaderService,
    ActivityLogService,
    ImagesService,
    IxsService,
    StripeService,
    PrintService,
    WctNotificationService,
    OnBoardingService,
    SuperherosService,
    RequestManagerService,
    HeadersGeneratorService,
    UtilsCustomLocalService
  ],
  exports: [...EXPORTS, ...EXPORTS_V2, DragDropModule],
  schemas: [CUSTOM_ELEMENTS_SCHEMA]
})
export class WebctModule {
}
