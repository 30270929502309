import { PrintService } from './customComponents/print/print.service';
import {
  Component,
  ViewEncapsulation,
  ViewContainerRef,
  ViewChild,
  OnInit,
  AfterViewInit,
  HostListener
} from '@angular/core';
import { Router } from '@angular/router';

import { ConfigurationService } from './foundations-webct-robot/robot/utils/configuration.service';
import { PageService } from './foundations-webct-robot/robot/pageComponent/page.service';
import { AuthzService } from './foundations-webct-robot/robot/utils/authz.service';
import { GlobalVarsService } from './foundations-webct-robot/robot/utils/global-vars.service';

import { ToggleElementsService } from './foundations-webct-robot/robot/services/toggle-elements.service';

import { AppState } from './app.service';
import { WctNotificationService } from './foundations-webct2-palette/components/wct-notification/wct-notification.service';
import { WctNotificationComponent } from './foundations-webct2-palette/components/wct-notification/wct-notification.component';

import { DECLARATIONS } from './webct.components';
import { WctAppComponent } from './foundations-webct2-palette/components/wct-app/wct-app.component';
import { NavigationService } from './foundations-webct-robot/robot/services/navigation.service';
import { FilterService } from './foundations-webct-robot/robot/services/filter.service';
// import { ModalService } from './foundations-webct2-palette/components/modalComponent/modal.service';
import { ModalService } from './foundations-webct-robot/robot/services/modal.service';
import { JsonParams } from './foundations-webct-robot/robot/classes/jsonParams.class';

import { } from 'jasmine';

import { OnBoardingService } from './OnBoarding.service';
import * as $ from 'jquery';

@Component({
  selector: 'app-root',
  encapsulation: ViewEncapsulation.None,
  templateUrl: 'app.component.html',
  styleUrls: ['app.component.css']
})
export class AppComponent extends WctAppComponent
  implements OnInit, AfterViewInit {

  @ViewChild(WctNotificationComponent) public notification;
  // @ViewChild(NotificationComponent) public notificationWct;
  private _BTN_CANCEL = 'fx-wizard-buttons-cancel';
  private _BTN_PREV = 'fx-wizard-buttons-previous';

  constructor(
    appState: AppState,
    notificationService: WctNotificationService,
    // public notificationServiceWct: NotificationService,
    kc: AuthzService,
    config: ConfigurationService,
    pageService: PageService,
    globalVars: GlobalVarsService,
    toggle: ToggleElementsService,
    navigation: NavigationService,
    filter: FilterService,
    viewContainerRef: ViewContainerRef,
    public modalService: ModalService,
    private _appService: AppState,
    public printService: PrintService,
    public router: Router,
    public onBoarding: OnBoardingService
  ) {
    super(
      appState,
      notificationService,
      kc,
      config,
      pageService,
      globalVars,
      toggle,
      navigation,
      filter,
      viewContainerRef
    );
  }

  private _slideNavBar = true;

  public ngAfterViewInit() {
    this.notificationService.initialize(this.notification);
    // this.notificationServiceWct.initialize(this.notificationWct);
  }

  public ngOnInit() {
    this._globalVars.setGlobalVars('current-page', 'app-home');
    this._globalVars.setGlobalComponents(DECLARATIONS);

    this.config
      .loadConfigurations()
      .then(response => {
        this.kc.AuthzService();
        this._pageService.kc = this.kc;
        this.detectBrowserSize(document.documentElement.clientWidth);

        /**
         * Set the platform language using browser language
         */
        this.setLanguage();

        setTimeout(() => this.finishPageLoading = true, 1000);

        // // Dynamic start of OnBoarding
        // this.onBoarding.checkTour(undefined, 0, this.onBoarding.getPathname(window.location))
        //   .then(() => this.onBoarding.validateDOMElement());

      }).catch(error => console.error('loadConfigurations --> ', error));
    //this.firstPage();


  }

  public firstPage() {
    const cookie: string = document.cookie;
    if (!cookie.includes('startDontShowModalAgain') && !sessionStorage.getItem('modalStart')) {
      const operations = new JsonParams();
      operations.size = 'start';
      operations.id = 'startModal';
      this.modalService.openModal('modalStart', null, null, operations);
      this._appService.isStartModal = true;
    }
  }

  public isStartModal = () => this._appService.isStartModal;
  public hasSlideNavBar = () => this._slideNavBar;
  public isModalShow = () => this.modalService.isModalShown;

  /**
   * setLanguage
   */
  public setLanguage() {
    const availableLanguages = this.config.getConfig('default___languages');
    let browserLanguage = navigator.language;
    let setLanguage = 'en-US';

    if (browserLanguage && browserLanguage.includes('-')) {
      browserLanguage = browserLanguage.substring(0, browserLanguage.indexOf('-'));
    }

    if (availableLanguages && Object.keys(availableLanguages).includes(browserLanguage)) {
      setLanguage = availableLanguages[browserLanguage];
    }
    this.config.set_i18n(setLanguage);
  }


  @HostListener('window:scroll', ['$event'])
  scrollHandler(event) {
    this._slideNavBar = window.pageYOffset < 200;
  }

  // Listener for Back button on OnBoarding's tours
  @HostListener('window:popstate', ['$event'])
  onPopState(event: any) {
    if (this.onBoarding.getTour()) {
      this.onBoarding.endTour();
    }
  }

  // Listener for ESCAPE button on OnBoarding's tours to exit it
  @HostListener('window:keydown.escape', ['$event'])
  onKeydownHandler(event: KeyboardEvent) {
    if (this.onBoarding.getTour()) {
      this.onBoarding.endTour();
    }
  }

  // Listener for click events in the OnBoarding's tours
  @HostListener('window:mousedown', ['$event'])
  onMouseDown(event: any) {
    const className = event.target.className;
    if (className && typeof className === 'string') {
      if (className.includes('introjs-nextbutton')) {
        this.onBoarding.goToStep(event, 1, true);
      } else if (className.includes('introjs-prevbutton')) {
        this.onBoarding.goToStep(event, -1, true);
      } else if (
        className.includes(this._BTN_CANCEL) ||
        className.includes(this._BTN_PREV) ||
        className.includes('introjs-overlay') ||
        className.includes('app-wct-page-wizard')) {
        this.onBoarding.endTour();
      } else {
        this.onBoarding.goToStep(event, 1);
      }
    } else {
      this.onBoarding.goToStep(event, 1);
    }
  }
}
