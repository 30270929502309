import { Directive, Input, ElementRef, DoCheck, KeyValueDiffers } from '@angular/core';
import { HttpClient } from '@angular/common/http';

import { JsonParams } from '../classes/jsonParams.class';
import { GlobalVarsService } from './global-vars.service';

@Directive({ selector: '[myIp]' })
export class MyIpDirective implements DoCheck {

  @Input() public myIpConfig: JsonParams;
  public differ: any;

  constructor(public el: ElementRef,
    private _differs: KeyValueDiffers,
    private _globalVars: GlobalVarsService,
    private _http: HttpClient) {
    this.differ = _differs.find({}).create();
  }

  public ngDoCheck() {
    let changes = this.differ.diff(this.myIpConfig);

    if (changes) {
      this._getMyIp(this.myIpConfig);
    }
  }

  private _getMyIp(cfg: JsonParams) {

    let formatedUrlResource: any = {};

    if (cfg.mockJson) {
      for (let obj of cfg.mockJson['urlResource'])
        formatedUrlResource[obj.key] = obj.value;

      this._http.get(formatedUrlResource["path"], { headers: formatedUrlResource["headers"] }).subscribe((res) => {
        this._globalVars.setLocalStorage('my_ip', res['headers'].get("X-Real-IP"));
      });
    }

  }
}