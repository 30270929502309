import { Pipe, PipeTransform } from '@angular/core';
import { PageService } from '../pageComponent/page.service';

@Pipe({ name: 'myI18n' })
export class I18nPipe implements PipeTransform {

  constructor(
    private _pageService: PageService) {

  }

  public transform(value, keepKey: boolean = true): any {
    let result = this._pageService.i18n(value);
    return value != result || keepKey ? result : '';
  }

}
