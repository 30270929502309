import { Component, Input, OnInit } from '@angular/core';
import { FormControl } from '@angular/forms';
import { InputService } from './../../../foundations-webct-robot/robot/services/input.service';
import { RobotEngineModel } from './../../../foundations-webct-robot/robot/robotEngineModel';
import { JsonParams } from './../../../foundations-webct-robot/robot/classes/jsonParams.class';
import { GlobalVarsService } from './../../../foundations-webct-robot/robot/utils/global-vars.service';
import { Utils } from './../../../foundations-webct-robot/robot/utils/utils.service';
import { PageService } from './../../../foundations-webct-robot/robot/pageComponent/page.service';
import { ComponentsService } from './../../../foundations-webct-robot/robot/services/components.service';

@Component({
  selector: 'input-textbox',
  templateUrl: 'textbox.component.html'
})
export class InputTextboxComponent implements OnInit {
  @Input() public inputParameters: JsonParams;
  @Input() public type = 'text';
  @Input() public control: FormControl;
  @Input() public insideFilter: string;

  public observableValue = '';
  public componentElementsId = {
    component: '',
    icon: '',
    description: ''
  };

  protected _minMaxValuesValidator = 'minMaxValue';

  private _subscription: any;

  constructor(
    public inputService: InputService,
    public pageService: PageService,
    public globalVars: GlobalVarsService,
    public utils: Utils,
    public _robot: RobotEngineModel,
    private _components: ComponentsService) { }

  public ngOnInit() {
    if (!this.control) this.control = new FormControl();

    this._setComponentIds();
    this._setMinMaxValuesValidation();

    this.inputParameters.value = this.inputService.formatMyValue(
      this.inputParameters.originalValue || this.inputParameters.value,
      false,
      {},
      false,
      this.globalVars.getPageParametersAsArray()
    );

    const lastInputValue: string = this.inputParameters.value;

    try {
      this.control.valueChanges.debounceTime(500).subscribe((val: string) => {
        if (this.control.dirty) {
          this._robot.findDynamicPropsDependencies(this.inputParameters.id);
          this._components.updatePageParameters(this.inputParameters.parameters);
        }
      });
      this.control.valueChanges.debounceTime(100).subscribe((val: string) => {
        this._evaluateMinMaxValues();
      });
    } catch (e) {
      // console.log('e ---> ', e);
    }

    this._subscription = this.inputParameters.observe().subscribe(param => {
      this._robot.findDynamicPropsDependencies(this.inputParameters.id);
    });
  }

  public ngOnDestroy() {
    if (this._subscription)
      this._subscription.unsubscribe();
  }

  protected _evaluateMinMaxValues() {
    if (
      (this.inputParameters.min && typeof +this.inputParameters.min === 'number' && this.inputParameters.value < +this.inputParameters.min) ||
      (this.inputParameters.max && typeof +this.inputParameters.max === 'number' && this.inputParameters.value > +this.inputParameters.max)
    )
      this.control.setErrors(
        Object.assign(this.control.errors || {}, {
          [this._minMaxValuesValidator]: true
        })
      );
    else if (this.control.errors)
      delete this.control.errors[this._minMaxValuesValidator];
  }

  protected _setMinMaxValuesValidation() {
    if (!this.pageService.errorDefs[this.inputParameters.id])
      this.pageService.errorDefs[this.inputParameters.id] = {};
    this.pageService.errorDefs[this.inputParameters.id][
      this._minMaxValuesValidator
      ] = this.utils
      .i18n('warnings___minMaxValuesValidation')
      .replace('{{min}}', this.inputParameters.min)
      .replace('{{max}}', this.inputParameters.max);
  }

  private _setComponentIds() {
    this.componentElementsId.component = this._components.getHtmlId(this.inputParameters);
    if (this.inputParameters.icon)
      this.componentElementsId.icon = this._components.getHtmlId(this.inputParameters, 'input-icon');
    if (this.inputParameters.description)
      this.componentElementsId.description = this._components.getHtmlId(this.inputParameters, 'input-description');
  }
}
