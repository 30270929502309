import { Injectable } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { HttpClient, HttpResponse, HttpHeaders } from '@angular/common/http';
import { Observable } from 'rxjs/Observable';

import { PageService } from '../pageComponent/page.service';
import { Utils } from '../utils/utils.service';
import { DateService } from '../services/date.service';
import { GlobalVarsService } from '../utils/global-vars.service';
import { ConfigurationService } from '../utils/configuration.service';
import { JsonParams } from '../classes/jsonParams.class';

import { ComponentsService } from '../services/components.service';
import { ApiService } from '../services/api.service';

import { RobotEngineModel } from '../robotEngineModel';

// import { ModalService } from './../../foundations-webct2-palette/components/modalComponent/modal.service';
import { ModalService } from '../services/modal.service';

// import { WctNotificationService } from 'foundations-webct2/components/wct-notification/wct-notification.service';
// import {  INotificationOptions } from 'foundations-webct2/components/wct-notification/wct-notification.component';
// import { NotificationService, INotificationOptions } from './../../foundations-webct2-palette/components/notificationComponent/notification.component';
import { WctNotificationService } from './../../../foundations-webct2-palette/components/wct-notification/wct-notification.service';
import {  INotificationOptions } from './../../../foundations-webct2-palette/components/wct-notification/wct-notification.component';

import * as moment from 'moment/moment';
import * as tz from 'moment-timezone';

@Injectable()
export class OperationsService {

  public currentDataParameters: JsonParams[];
  public operationConfig: JsonParams[];
  public componentId: string;

  private _AOPConfiguration: Object = null;

  constructor(
    private _notificationService: WctNotificationService,
    // private _notificationService: NotificationService,
    private _config: ConfigurationService,
    private _robot: RobotEngineModel,
    private _router: Router,
    private _routeParams: ActivatedRoute,
    private _http: HttpClient,
    private _utils: Utils,
    private _dateService: DateService,
    private _globalVars: GlobalVarsService,
    private _components: ComponentsService,
    private _apiService: ApiService,
    private _modalService: ModalService,
    private _pageService: PageService) {

  }

  public updateSistemParameters = (config: JsonParams[]) => this._components.updatePageParameters(config);

  public execute(dataParameters: JsonParams[], operationConfig: JsonParams[], componentId: string = null, dataRecs?: Object) {

    this._pageService.operationLoading = true;

    this.componentId = componentId || this.componentId;
    this.currentDataParameters = dataParameters;
    this.operationConfig = operationConfig;

    if (dataRecs)
      this.currentDataParameters = this.currentDataParameters.concat(this._utils.objToJsonParam(dataRecs));

    let requestHttp: boolean = !!this._utils.findObjectInArray(this.operationConfig, 'requestEndpoint').value;
    if (requestHttp) {
      let AOPConfiguration = this._utils.findObjectInArray(this.operationConfig, 'AOPConfiguration').value;
      let requestEndpoint = this._utils.fulfillment(this._utils.findObjectInArray(this.operationConfig, 'requestEndpoint').value, this.currentDataParameters);
      if (AOPConfiguration) {
        this._AOPConfiguration = AOPConfiguration;
        let requestBody = this._formatRequestBody(this._utils.fulfillment(this._utils.findObjectInArray(this.operationConfig, 'requestBody').value, this.currentDataParameters));
        this._createAOP(AOPConfiguration, requestEndpoint, requestBody);
      } else {
        this._evaluateOperationType(this._utils.guid(), requestEndpoint);
      }
    } else {
      this._components.updatePageParameters(this.operationConfig);
      let successRequest: JsonParams[] = this._utils.findObjectInArray(this.operationConfig, 'successRequest').value;
      if (successRequest) {
        this.execute(this.currentDataParameters, successRequest);
      } else {
        this._showPopupRequestStatusMessage('success');
        this._pageService.operationLoading = false;
      }
    }
  }

  public executeOperation = (requestUrl: string, requestContentType: string, requestType: string, requestBody: string, inputHeaders: Object = null, requestId: string = null): Observable<Object | HttpResponse<any>> => {

    let successOnEmptyBody: boolean = this._utils.findObjectInArray(this.operationConfig, 'successOnEmptyBody').value;
    if (successOnEmptyBody) {
      let isRequestBody = !!requestBody;
      if (isRequestBody) {
        try {
          isRequestBody = Object.keys(JSON.parse(requestBody)).length > 0;
        } catch (e) {
          isRequestBody = true;
        }
      }
      if (!isRequestBody)
        return new Observable(observer => { observer.next(); });
    }

    try {
      requestUrl = requestUrl.replace(this._utils.mustacheReg, (val) => {
        let rtn = this._pageService.getUrlFromConfig(val);
        return rtn && !rtn.match(this._utils.mustacheReg) ? rtn : '';
      });
      requestBody = requestBody.replace(this._utils.mustacheReg, (val) => {
        let rtn = this._pageService.getUrlFromConfig(val);
        return rtn && !rtn.match(this._utils.mustacheReg) ? rtn : null;
      });

      // ** TODO: Retirar esta linha assim que o ASM corrigir do lado deles a possibilidade de enviar parâmetros da oferta a null
      // requestBody = requestBody.replace(/\"null\"/g, null);
      // requestBody = requestBody.replace(/(\\)\"null(\\)\"/g, '\\"\\"');
      // **
      requestBody = requestBody.replace(/(\\)?\"null(\\)?\"/g, null);
      requestBody = requestBody.replace(/(\\)?\"(\\)?\"/g, null);

      requestBody = requestBody.replace(/\&quot;/g, '\"');
    } catch (error) {
      console.error('Operation executeOperation: Error in parameters...');
    }

    let requestHeaders: Object = this._utils.findObjectInArray(this.operationConfig, 'requestHeaders').value;
    let useHeaders = inputHeaders || requestHeaders;
    let setHeaders = {
      'Content-Type': 'application/' + (requestContentType || 'json'),
      'Cache-Control': 'no-cache',
      'Accept': 'application/json'
    };
    // setHeaders['Authorization] = 'Bearer ' + this._globalVars.getLocalStorage('auth_token');
    // setHeaders['X-Authenticated-Userid] = this._globalVars.getLocalStorage('preferred_username');

    for (let i in useHeaders)
      if (useHeaders[i])
        setHeaders[i] = this._utils.fulfillment(useHeaders[i], this.currentDataParameters);

    this._pageService.operationLoading = true;
    return this._utils.SetRequest(this._pageService.getUrlFromConfig(requestUrl), new HttpHeaders(setHeaders), requestType, requestBody, requestId, this.componentId);
  }

  private _createAOP(AOPConfiguration: Object, requestEndpoint: string, requestBody: string) {

    /**
     * TODO: Rever método de configuração dos pedidos AOP (WEBCT-714)
     */

    // for (let i in AOPConfiguration) {
    //   if (!AOPConfiguration[i])
    //     continue;
    //   if (typeof AOPConfiguration[i] == 'string') {
    //     AOPConfiguration[i] = this._utils.replaceTagVars(AOPConfiguration[i], this._utils.arrToObj(this.currentDataParameters));
    //     AOPConfiguration[i] = !AOPConfiguration[i].match(this._utils.mustacheReg) ? AOPConfiguration[i] : null;
    //   } else if (this._utils.isObjectType(AOPConfiguration[i], 'Array'))
    //     AOPConfiguration[i] = this._utils.findValues(AOPConfiguration[i], JSON.parse(requestBody));
    // }

    let entityAOP = this._evaluateAOPFields(AOPConfiguration['entity'], requestBody);
    let AOPBody: any = {
      entities: entityAOP ? (this._utils.isObjectType(entityAOP, 'Array') ? entityAOP : [entityAOP]) : [],
      requestOperation: this._evaluateAOPFields(AOPConfiguration['requestOperation'], requestBody),
      requestAffectedEntity: this._evaluateAOPFields(AOPConfiguration['requestAffectedEntity'], requestBody),
      requestStatus: this._evaluateAOPFields(AOPConfiguration['requestStatus'] || 'SUBMITTED', requestBody),
      requestUser: this._globalVars.getLocalStorage(this._evaluateAOPFields(AOPConfiguration['requestUser'], requestBody) || 'user_email'),
      requestDetails: {},
      requestReasonDesc: this._evaluateAOPFields(AOPConfiguration['requestReasonDesc'] || 'ARM log history', requestBody)
    };

    /**
     * Envio de novos parâmetros opcionáis no âmbito do issue WEBCT-714
     */
    for (let key of ['requestChannel', 'requestContextEntity', 'requestContextEntityId']) {
      if (AOPConfiguration[key] === undefined)
        continue;
      AOPBody[key] = this._evaluateAOPFields(AOPConfiguration[key], requestBody);
    }
    AOPBody = JSON.stringify(AOPBody);

    this.executeOperation(this._config.getEndpoint('AOPServer'), 'json', 'POST', AOPBody, { Authorization: `Bearer {{${AOPConfiguration['requestToken'] || 'auth_token'}}}` })
      .subscribe((data) => {
        this._evaluateOperationType(data['requestId'], requestEndpoint, AOPConfiguration);
      },
        (error) => {
          console.error('addNewAOP() -> ', error);
          try {
            if (this._utils.findValues(['error', 'errorCode'], error) == '10003') {
              this._notificationService.showError('warnings___information', 'warnings___sessionExpired');
              this._pageService.operationLoading = false;
            } else {
              this._evaluateOperationType(this._utils.guid(), requestEndpoint);
            }
          } catch (error) {
            this._notificationService.showError('warnings___information', 'warnings___errorOcurred');
            this._pageService.operationLoading = false;
          }
        });

  }

  private _evaluateAOPFields(value: any, requestBody: string): any {

    if (!value)
      return value;

    if (typeof value == 'string') {
      value = this._utils.replaceTagVars(value, this._utils.arrToObj(this.currentDataParameters));
      return !value.match(this._utils.mustacheReg) ? value : null;
    } else if (this._utils.isObjectType(value, 'Array'))
      return this._utils.findValues(value, JSON.parse(requestBody));

    return value;
  }

  private _evaluateOperationType(uuid: string, requestEndpoint: string, AOPBodyData: Object = null) {

    let requestBulkOperation: JsonParams = this._utils.findObjectInArray(this.operationConfig, 'bulkOperation');
    if (requestBulkOperation.key) {

      let bulkParameter: JsonParams = this._utils.findObjectInArray(this.currentDataParameters, requestBulkOperation.value.slice(2, -2), 'id');
      if (bulkParameter.value && bulkParameter.value.length > 0) {
        let bulkOperation: BulkOperation = {
          dataRecs: null,
          isFirstRequest: true,
          isLastRequest: false
        };
        for (let i in bulkParameter.value) {
          bulkOperation.dataRecs = this._utils.objToJsonParam(!this._utils.isObjectType(bulkParameter.value[i], 'Object') ? { ['each::' + bulkParameter.id]: bulkParameter.value[i] } : bulkParameter.value[i]);
          bulkOperation.isFirstRequest = !(+i);
          bulkOperation.isLastRequest = (+i) == (bulkParameter.value.length - 1);
          this._initOperation(uuid, requestEndpoint, AOPBodyData, bulkOperation);
        }
      } else {
        let forceRequestSuccess = new JsonParams();
        forceRequestSuccess.key = 'successOnEmptyBody';
        forceRequestSuccess.value = true;
        this.operationConfig.push(forceRequestSuccess);
        this._utils.findObjectInArray(this.operationConfig, 'requestBody').value = [];
        this._initOperation(uuid, requestEndpoint);
      }

    } else {
      this._initOperation(uuid, requestEndpoint, AOPBodyData);
    }

  }

  private _initOperation(uuid: string, requestEndpoint: string, AOPBodyData: Object = null, bulkOperation: BulkOperation = null) {

    let AOPUpdateUrl: string = null;
    if (AOPBodyData) {
      AOPUpdateUrl = this._config.getEndpoint('AOPServer') + '/' + uuid;

      if (!bulkOperation || bulkOperation.isFirstRequest) {
        // generate paramReqUpdate
        let paramReqUpdate: JsonParams = new JsonParams();
        paramReqUpdate.id = 'url';
        paramReqUpdate.value = AOPUpdateUrl;
        this.currentDataParameters.push(paramReqUpdate);
      }
    }

    if (!bulkOperation || bulkOperation.isFirstRequest) {
      // generate timestamp
      let timestamp: JsonParams = new JsonParams();
      timestamp.id = 'timestamp';
      timestamp.value = (new Date()).toISOString();
      this.currentDataParameters.push(timestamp);

      // generate timestamp with timezone
      let timestampWithTZ: JsonParams = new JsonParams();
      timestampWithTZ.id = 'timestampWithTZ';
      timestampWithTZ.value = tz.tz(this._dateService.timezone).format(this._dateService.timestampWithTZformat);
      this.currentDataParameters.push(timestampWithTZ);

      // generate paramUuid
      let paramUuid: JsonParams = new JsonParams();
      paramUuid.id = 'uuid';
      paramUuid.value = uuid;
      this.currentDataParameters.push(paramUuid);
    }

    let requestData: JsonParams[] = this.currentDataParameters;
    if (bulkOperation && bulkOperation.dataRecs) {
      requestData = bulkOperation.dataRecs.concat(this.currentDataParameters);
      requestEndpoint = this._utils.fulfillment(requestEndpoint, requestData);
    }


    let onSuccessConfig: JsonParams;
    let hasOnSuccessConfig: boolean = false;

    let onErrorConfig: JsonParams;
    let hasOnErrorConfig: boolean = false;

    let onCompleteConfig: JsonParams;
    let hasOnCompleteConfig: boolean = false;

    let requestSuccessRouter: string = null;
    let requestSuccessText: string | boolean = null;
    let errorCodePath: string = null;

    let requestConfigId: JsonParams = this._utils.findObjectInArray(this.operationConfig, 'requestId');
    let requestContentType: string = this._utils.findObjectInArray(this.operationConfig, 'requestBody').type;
    let requestType: string = this._utils.findObjectInArray(this.operationConfig, 'requestType').value;
    let requestBody: string = this._formatRequestBody(this._utils.fulfillment(this._utils.findObjectInArray(this.operationConfig, 'requestBody').value, requestData));

    let requestFinish = !bulkOperation || bulkOperation.isLastRequest;
    if (requestFinish) {

      onSuccessConfig = this._utils.findObjectInArray(this.operationConfig, 'onSuccess');
      hasOnSuccessConfig = !!onSuccessConfig.key;
      onErrorConfig = this._utils.findObjectInArray(this.operationConfig, 'onError');
      hasOnErrorConfig = !!onErrorConfig.key;
      onCompleteConfig = this._utils.findObjectInArray(this.operationConfig, 'onComplete');
      hasOnCompleteConfig = !!onCompleteConfig.key;

      requestSuccessRouter = this._utils.findObjectInArray(this.operationConfig, 'successRouter').value;
      requestSuccessText = this._utils.findObjectInArray(this.operationConfig, 'successText').value;
      errorCodePath = this._utils.findObjectInArray(this.operationConfig, 'errorCode').value;
    }

    let messageRequestLink: string = this._pageService.i18n('warnings___verifyRequest').replace('{{uuid}}', uuid);

    this.executeOperation(requestEndpoint, requestContentType, requestType, requestBody, null, requestConfigId.value)
      .subscribe((data) => {

        let response = data;
        this._saveRequestRespose(data, requestConfigId);

        /**
         * UPDATE REQUEST
         */
        if (AOPBodyData) {
          let AOPUpdateBody = JSON.stringify({
            requestStatus: this._evaluateAOPFields(AOPBodyData['successStatus'] || 'PROCESSED', requestBody),
            requestDetails: {
              contextId: this._evaluateAOPFields(AOPBodyData['contextId'], requestBody),
              requestUrl: requestEndpoint,
              requestBody: requestBody,
              requestResponse: JSON.stringify(response)
            }
          });
          this._updateAOP(AOPUpdateUrl, AOPUpdateBody);
          // TODO: WEBCT-176
          // messageSuccess += ' ' + messageRequestLink;
        }

        if (hasOnSuccessConfig) {
          // Alteração no âmbito do REQ WEBCT-692
          this._onRequestResponse(onSuccessConfig, response, 'success');
        } else {

          // DEPRECATED

          this._components.updatePageParameters(this.operationConfig);

          let successRequest: JsonParams[] = this._utils.findObjectInArray(this.operationConfig, 'successRequest').value;
          if (successRequest) {
            this.execute(this.currentDataParameters, successRequest);
            return;
          }

          let messageSuccess = null;
          let messageErrorCode = null;

          if (requestSuccessText !== false) {
            if (typeof requestSuccessText == 'string')
              messageSuccess = this._utils.fulfillment(this._pageService.i18n(requestSuccessText), this._utils.objToJsonParam(response)).replace(this._utils.mustacheReg, '');
            if (errorCodePath)
              messageErrorCode = this._utils.fulfillment(errorCodePath, this._utils.objToJsonParam(response)).replace(this._utils.mustacheReg, '');
          }

          /**
           * UPDATE PAGE
           */
          if (requestFinish) {

            if (requestSuccessText !== false) {
              let isError = null;
              let isSuccess = null;
              if (messageErrorCode) {
                let isErrorPath = 'errorCodes___' + messageErrorCode;
                let isSuccessPath = 'successCodes___' + messageErrorCode;
                isError = this._pageService.i18n(isErrorPath).replace(isErrorPath, '');
                isSuccess = this._pageService.i18n(isSuccessPath).replace(isSuccessPath, '');
                if (isError)
                  this._showPopupRequestStatusMessage('error', this._utils.objToJsonParam(data), isError);
                else if (isSuccess)
                  this._showPopupRequestStatusMessage('success', this._utils.objToJsonParam(data), isSuccess);
              }
              if (!isError && !isSuccess)
                this._showPopupRequestStatusMessage('success', this._utils.objToJsonParam(data), messageSuccess);
            }

            this._pageService.operationLoading = false;
            let goTo = requestSuccessRouter ? this._utils.fulfillment(requestSuccessRouter, requestData) : null;
            if (!goTo || this._router.isActive(goTo, true)) {
              this._pageService.viewStructure.refresh();
              // setTimeout(() => this._pageService.updateFuxiView(), 0.5);
              setTimeout(() => { }, 0.5);
            } else {
              const newTab: boolean = this._utils.findObjectInArray(this.operationConfig, 'successRouterNewTab').value;
              this._utils.navigate(goTo, null, null, newTab);
            }
          }
        }
      },

        // OnError
        (error) => {
          error = this._apiService.saveRequestResponse(requestConfigId.value, error, this.componentId, 'error');

          console.error('initOperation() -> ', error);

          let messageAlert;

          if (hasOnErrorConfig) {
            // Alteração no âmbito do REQ WEBCT-692
            this._onRequestResponse(onErrorConfig, error, 'error');
          } else {
            messageAlert = this._showPopupRequestStatusMessage('error', this._utils.objToJsonParam(error));
          }

          /**
           * UPDATE REQUEST
           */
          if (AOPBodyData) {
            let AOPUpdateBody = JSON.stringify({
              requestErrorCode: error.status,
              requestErrorDetails: messageAlert['message'],
              requestStatus: this._evaluateAOPFields(AOPBodyData['errorStatus'] ? AOPBodyData['errorStatus'] : 'ERROR', requestBody),
              requestDetails: {}
            });
            this._updateAOP(AOPUpdateUrl, AOPUpdateBody);
            // TODO: WEBCT-176
            // messageAlert += ' ' + messageRequestLink;
          }
          /**
           * UPDATE REQUEST
           */

          this._pageService.operationLoading = false;
        });
  }

  private _onRequestResponse(onResponseConfig: JsonParams, response: Object | Response, requestResponseType: 'success' | 'error') {

    if (onResponseConfig.value.fn)
      this._utils.findValuesByCondition(onResponseConfig.value.fn, response);

    if (onResponseConfig.value.updatePageParameters)
      this._components.updateParameterById(onResponseConfig.value.updatePageParameters);

    if (onResponseConfig.value.notification)
      this._showNotification(onResponseConfig.value.notification, response, requestResponseType);

    if (onResponseConfig.value.request) {
      // Condições abaixo deste "if" não serão avaliadas quando existe um novo request
      this.execute(this.currentDataParameters, onResponseConfig.value.request);
      return;
    }

    if (onResponseConfig.value.keepModal !== undefined) {
      let keepModal = typeof onResponseConfig.value.keepModal == 'boolean' ? onResponseConfig.value.keepModal : this._utils.findValuesByCondition(onResponseConfig.value.keepModal, response);
      if (!keepModal)
        this._modalService.closeModal();
    } else
      this._modalService.closeModal();

    if (onResponseConfig.value.navigateTo)
      this._navigateAfterRequest(onResponseConfig.value.navigateTo, response);

    this._pageService.operationLoading = false;
  }

  private _showNotification(notificationConfig: any, response: Object | Response, requestResponseType: 'success' | 'error') {

    let type: string = notificationConfig.type ? this._utils.replaceTagVars(notificationConfig.type, response) : requestResponseType;
    let title: string = this._utils.replaceTagVars(this._utils.i18n(this._utils.replaceTagVars(notificationConfig.title, response)), response);
    let text: string = this._utils.replaceTagVars(this._utils.i18n(this._utils.replaceTagVars(notificationConfig.text, response)), response);

    let option: INotificationOptions = {
      positionClass: notificationConfig.position ? 'toast-' + this._utils.replaceTagVars(notificationConfig.position, response) : notificationConfig.position,
      timeout: notificationConfig.timeout === undefined || typeof notificationConfig.timeout == 'number' ? notificationConfig.timeout : +this._utils.replaceTagVars(notificationConfig.timeout, response),
      limit: notificationConfig.limit === undefined || typeof notificationConfig.limit == 'number' ? notificationConfig.limit : +this._utils.replaceTagVars(notificationConfig.limit, response),
      iconClasses: notificationConfig.icon ? { [type]: this._utils.replaceTagVars(notificationConfig.icon, response) } : notificationConfig.icon
    };

    this._notificationService.notify(type, title, text, option);
  }

  private _navigateAfterRequest(navigationConfig: any, response: Object | HttpResponse<any>) {

    const goTo = navigationConfig.route ? this._utils.replaceTagVars(navigationConfig.route, response) : null;

    if (!goTo || this._router.isActive(goTo, true)) {
      this._pageService.viewStructure.refresh();
    } else
      this._utils.navigate(goTo, null, null, navigationConfig.target == '_blank');

  }

  private _saveRequestRespose(data: any, requestConfigId: JsonParams) {
    if (requestConfigId) {
      // generate request response parameter
      let requestResponse: JsonParams = new JsonParams();
      requestResponse.id = requestConfigId.value;
      requestResponse.value = data;
      this.currentDataParameters.push(requestResponse);
      if (requestConfigId.global) {
        // saves response on global vars service
        let globalName = typeof requestConfigId.global == 'string' ? requestConfigId.global : requestResponse.id;
        this._globalVars.setGlobalVars(globalName, requestResponse.value, true);
      }
    }
  }

  // private _showPopupRequestStatusMessage(type: string = 'success', data: JsonParams[] = [], msg: string = null): Object {
  public _showPopupRequestStatusMessage(type: string = 'success', data: JsonParams[] = [], msg: string = null): Object {

    const requestManuelkMessage = this._utils.findObjectInArray(this.operationConfig, type + 'Text').value;
    if (requestManuelkMessage === false)
      return;

    const options: Object = {
      timeout: {
        [type]: this._utils.findObjectInArray(this.operationConfig, type + 'TextTimeout').value
      },
      positionClass: {
        [type]: this._utils.findObjectInArray(this.operationConfig, type + 'TextPosition').value
      },
      limit: {
        [type]: this._utils.findObjectInArray(this.operationConfig, 'notificationLimit').value
      }
    };

    const requestTitle: string = this._pageService.i18n('warnings___' + type);
    let requestMessage: string = msg || requestManuelkMessage || requestTitle;
    requestMessage = this._utils.fulfillment(this._pageService.i18n(requestMessage), data);
    const hideRequestTitle: boolean = this._utils.findObjectInArray(this.operationConfig, 'hideRequestTitle').value || false;
    const hideRequestMessage: boolean = this._utils.findObjectInArray(this.operationConfig, 'hideRequestMessage').value || false;
    switch (type) {
      case 'success':
        this._notificationService.showSuccess(hideRequestTitle ? null : requestTitle, hideRequestMessage ? null : requestMessage, options);
        break;
      case 'error':
        this._notificationService.showError(hideRequestTitle ? null : requestTitle, hideRequestMessage ? null : requestMessage, options);
        break;
      case 'info':
        this._notificationService.showInfo(hideRequestTitle ? null : requestTitle, hideRequestMessage ? null : requestMessage, options);
        break;
      case 'wait':
        this._notificationService.showWait(hideRequestTitle ? null : requestTitle, hideRequestMessage ? null : requestMessage, options);
        break;
      case 'warning':
        this._notificationService.showWarning(hideRequestTitle ? null : requestTitle, hideRequestMessage ? null : requestMessage, options);
        break;
    }
    return {
      title: requestTitle,
      message: requestMessage
    };
  }

  private _updateAOP(requestEndpoint, AOPUpdateBody) {

    if (this._AOPConfiguration['requestDetails'] && typeof this._AOPConfiguration['requestDetails'] == 'object')
      Object.assign(AOPUpdateBody['requestDetails'], this._AOPConfiguration['requestDetails']);

    this.executeOperation(requestEndpoint, 'json', 'PATCH', AOPUpdateBody, { Authorization: `Bearer {{${this._AOPConfiguration['requestToken'] || 'auth_token'}}}` })
      .subscribe((data) => {
        // console.log('REQUEST UPDATED: ',data);
      });
  }

  private _formatRequestBody(requestBody: string): string {

    let requestBodyParameters: JsonParams[] = this._utils.findObjectInArray(this.operationConfig, 'requestBody').parameters;

    if (!requestBodyParameters || requestBodyParameters.length <= 0)
      return requestBody;

    for (let i in requestBodyParameters) {

      if (requestBodyParameters[i].key == 'paramAsString') {
        try {
          requestBody = JSON.parse(requestBody);
          let tempBody: Object = requestBody;
          for (let p in requestBodyParameters[i].value) {
            tempBody = tempBody[requestBodyParameters[i].value[p]];
            if (!tempBody) break;
          }
          if (tempBody) {
            let newValue: string = '';
            try {
              newValue = JSON.stringify(tempBody);
            } catch (e) {
              newValue = tempBody.toString();
            }

            if (newValue.indexOf('\"') === 0)
              newValue = newValue.slice(1, -1);

            this._updateObject(requestBody, newValue, requestBodyParameters[i].value);
          }
          requestBody = JSON.stringify(requestBody);
        } catch (e) {
          // CATCH
        }
      }

    }
    return requestBody;
  }

  private _updateObject(object: Object, newValue: any, stack: string[]) {
    let myStack = JSON.parse(JSON.stringify(stack));
    while (myStack.length > 1)
      object = object[myStack.shift()];
    object[myStack.shift()] = newValue;
  }

}

// just an interface for type safety.
interface BulkOperation {
  dataRecs: JsonParams[];
  isFirstRequest: boolean;
  isLastRequest: boolean;
}
