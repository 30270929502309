import { CustomEventManagerService } from '../../customComponents/custom-event-manager.service';
import { Component, Input, OnInit, OnDestroy, Output, EventEmitter } from '@angular/core';
import { PageService } from '../../foundations-webct-robot/robot/pageComponent/page.service';
import { Utils } from '../../foundations-webct-robot/robot/utils/utils.service';
import { GlobalVarsService } from '../../foundations-webct-robot/robot/utils/global-vars.service';
import { JsonParams } from '../../foundations-webct-robot/robot/classes/jsonParams.class';
import { TestService } from '../test.service';
// import { ModalService } from './../../foundations-webct2-palette/components/modalComponent/modal.service';
import { ModalService} from '../../foundations-webct-robot/robot/services/modal.service';



export enum KEY_CODE {
  UP_ARROW = 38,
  DOWN_ARROW = 40
}


@Component({
  selector: 'app-testSet-list',
  templateUrl: './testSet-list.component.html',
  styleUrls: ['./testSet-list.component.css']
})
export class TestSetListComponent implements OnInit, OnDestroy {


  @Input() public inputParameters: JsonParams;
  @Input() public filterDateField = 'creationDate';
  // @Input() public dataRecsMainInfo;
  // @Input() public program;
  // @Input() public dataRecsNoContent;
  @Input() public editMode: any;
  @Output() currentTestSet = new EventEmitter();

  @Output() editModeChange = new EventEmitter<string>();

  @Input() searchMode: any;
  @Output() searchModeChange = new EventEmitter<string>();

  public selectedRow = 0;
  private _componentId: string;

  public newTestSetCreatedSubscription;
  public createNewTestSetSubscription;
  public execs=[];


  public selectedTestSet:any;



  public filterArgumentForTextHighlight = "";


  constructor(
    public pageService: PageService,
    private _utils: Utils,
    private _globalVars: GlobalVarsService,
    private _modalService: ModalService,
    private eventManager: CustomEventManagerService,
    public testsService: TestService) {

    this._componentId = 'TESTSET-' + this._utils.guid(4, '');
  }

  public ngOnInit() {

    this.execs = this.testsService.exectutions;
    // if(this.execs.length<=0)
    //   this.testsService.reloadTestsSet();

    this.newTestSetCreatedSubscription = this.eventManager.newTestSetCreated.subscribe(
      () => {
        this.selectedRow = this.testsService.exectutions.length - 1;
      }
    );

    this.createNewTestSetSubscription = this.eventManager.createNewTestSet.subscribe(
      () => {
        this.createTestSet()
      }
    );


  }

  public refresh(){
    this.execs = [];
    this.testsService.reloadTestsScenario();
    this.execs = this.testsService.exectutions;
  }



  public ngOnDestroy() {
    this._globalVars.deletePageParametersByGroup(this._componentId);
    /*     if (this.editorLoadedSubscription){
          this.editorLoadedSubscription.unsubscribe();
        } */

    if (this.newTestSetCreatedSubscription) {
      this.newTestSetCreatedSubscription.unsubscribe();
    }

    if (this.createNewTestSetSubscription) {
      this.createNewTestSetSubscription.unsubscribe();
    }

  }


  

  public onSelectRow(event, index, row) {
    this.selectedRow = index;

    for(let i of this.execs){
      i["selected"] =false;  
    }
    
    console.log("onSelectRow: ", row)
    row["selected"]=true
    this.selectedTestSet=row;
    
    this.currentTestSet.emit(row);

    
  }

  

  public createTestSet() {
    let operation = JSON.parse('{"size":"md", "id":"deleteGroupModal"}');
    let parameters = [];
    let jsonParams = new JsonParams();

    /*     jsonParams.key = "context";
        jsonParams.value = [
          'originalName',
          'displayName'
        ];
        parameters.push(jsonParams);
        let obj = { 'originalName': intent.displayName, 'displayName': intent.name } */
    let obj = {};
    this._modalService.openModal('tests/tests-set-create-area-testset', parameters, obj, operation);
  }


}
