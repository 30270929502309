import {HttpHeaders} from '@angular/common/http';
import {Injectable} from '@angular/core';

@Injectable()
export class HeadersGeneratorService {

  public getHttpHeaders() {
    return new HttpHeaders()
      .set('Accept', 'application/json; charset=utf-8')
      .set('Cache-Control', 'no-cache')
      .set('Authorization', `Bearer ${localStorage.token}`)
      .set('IXS', localStorage.IXS_id);
  }
}
