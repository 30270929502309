import { Pipe, PipeTransform } from '@angular/core';

@Pipe({ name: 'myObject' })
export class ObjectPipe implements PipeTransform {
  public transform(value, args: string[]): any {
    let keys = [];
    for (let key in value) {
      if (key == 'errorDetected' && value[key] === true)
        return keys;
      keys.push({ key: key, value: value[key] });
    }
    return keys;
  }
}
