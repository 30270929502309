import { Injectable } from '@angular/core';
import { RequestManagerService } from '../requests/requestManager.service';

@Injectable()
export class SuperherosService {
  constructor(private requestManager: RequestManagerService) { }

  public listSuperherosModel(params = {}) {
    return this.requestManager.get('{{SchoolBE}}/superheros', params).toPromise();
  }
}
