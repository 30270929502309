import {
  Component,
  Input,
  OnInit,
  Output,
  EventEmitter,
  DoCheck
} from "@angular/core";

export enum ItemsPerPagePagination {
  Five = 5,
  Ten = 10,
  TwentyFive = 25,
  Hundred = 100
}

@Component({
  selector: "custom-pagination",
  templateUrl: "custom-pagination.component.html",
  styleUrls: ["custom-pagination.component.css"]
})
export class CustomPaginationComponent implements OnInit, DoCheck {
  defaultConfiguration: any = {
    maxSize: void 0,
    autoCompact: false,
    itemsPerPage: ItemsPerPagePagination.Five,
    boundaryLinks: true,
    directionLinks: true,
    rotate: true,
    compact: true,
    pageSelection: false,
    recordsPerPageSelection: true
  };

  config: any;

  /** limit number for page links in pager */
  @Input() maxSize: number;
  /** if maxSize > 0 pagination will be displayed in compact format in number of pages > maxSize*/
  @Input() autoCompact: boolean;
  /** if false first and last buttons will be hidden */
  @Input() boundaryLinks: boolean;
  /** if false previous and next buttons will be hidden */
  @Input() directionLinks: boolean;
  /** if true pagination component will be disabled */
  @Input() disabled: boolean;
  /** if true current page will in the middle of pages list */
  @Input() rotate: boolean;
  /** if pagination will be displayed in compact format */
  @Input() compact: boolean;
  /** shown page selection in extended mode */
  @Input() pageSelection: boolean;
  /** shown page selection in extended mode */
  @Input() recordsPerPageSelection: boolean;

  @Input() public pagingSettings: any;
  @Input() public rows: [];

  @Output() public paginationEventSender: EventEmitter<any> = new EventEmitter<
    any
  >();

  public refreshMe = false;
  public tableHasData = false;
  public tableIsLoading = true;
  public pages: any[];
  public numPagesPicker = (): number[] => {
    let resultPicker = [];
    resultPicker = Object.keys(ItemsPerPagePagination).filter(
      key => !isNaN(Number(key))
    );
    return resultPicker.map(obj => (obj = parseInt(obj)));
  };

  constructor() {
    if (!this.config) {
      this.configureOptions(this.defaultConfiguration);
    }
  }

  selectPage(page: number, event?: Event): void {
    if (event) {
      event.preventDefault();
    }

    if (!this.disabled) {
      if (event && event.target) {
        const target: any = event.target;
        target.blur();
      }

      this.pagingSettings.currentPage = page;
    }
  }

  public ngOnInit() {
    this.pagingSettings = this.pagingSettings || {
      id: "",
      totalItems: 0,
      paging: true,
      currentPage: 1
    };
    this.maxSize =
      typeof this.maxSize !== "undefined" ? this.maxSize : this.config.maxSize;
    this.boundaryLinks =
      typeof this.boundaryLinks !== "undefined"
        ? this.boundaryLinks
        : this.config.boundaryLinks;
    this.directionLinks =
      typeof this.directionLinks !== "undefined"
        ? this.directionLinks
        : this.config.directionLinks;
    this.rotate =
      typeof this.compact !== "undefined" ? this.rotate : this.config.rotate;
    this.compact =
      typeof this.compact !== "undefined" ? this.compact : this.config.compact;
    this.autoCompact =
      typeof this.autoCompact !== "undefined"
        ? this.autoCompact
        : this.config.autoCompact;
    this.pageSelection =
      typeof this.pageSelection !== "undefined"
        ? this.pageSelection
        : this.config.pageSelection;
    this.recordsPerPageSelection =
      typeof this.recordsPerPageSelection !== "undefined"
        ? this.recordsPerPageSelection
        : this.config.recordsPerPageSelection;

    this._evaluateTableData();
  }

  public ngDoCheck() {
    this._evaluateTableData();
  }

  /* ______________________________________EVENTS EMIT_________________________________________ */

  public firstPageEvent = (): void => {
    this.pagingSettings.currentPage = 1;
    this.paginationEventSender.emit({ name: "paginator-first-page" });
  };
  public previousPageEvent = (): void => {
    this.pagingSettings.currentPage--;
    this.paginationEventSender.emit({ name: "paginator-previous-page" });
  };
  public nextPageEvent = (): void => {
    this.pagingSettings.currentPage++;
    this.paginationEventSender.emit({ name: "paginator-next-page" });
  };
  public lastPageEvent = (): void => {
    this.pagingSettings.currentPage = Math.ceil(
      this.pagingSettings.totalItems / this.pagingSettings.itemsPerPage
    );

    this.paginationEventSender.emit({ name: "paginator-last-page" });
  };
  public itemsPerPage = (itemsNumber: number): void => {
    this.pagingSettings.itemsPerPage = itemsNumber;
    this.pagingSettings.numPages = Math.ceil(
      this.pagingSettings.totalItems / itemsNumber
    );
    this.paginationEventSender.emit({
      name: "paginator-itemsPerPage",
      value: itemsNumber
    });
  };

  /* _____________________________________CONFIGURATION________________________________________ */

  /**
   * Load component configurations
   */
  private configureOptions(config: any): void {
    this.config = Object.assign({}, config);
  }

  /**
   * Verifies that there are pages to show before the current page
   */
  public noPrevious(): boolean {
    return this.pagingSettings.currentPage === 1;
  }

  /**
   * Verifies that there are pages to show after the current page
   */
  public noNext(): boolean {
    return this.pagingSettings.currentPage === this.pagingSettings.numPages;
  }

  /**
   * verifies if the pagination component must be shown in compact mode
   */
  public isCompact(): boolean {
    if (
      this.maxSize > 0 &&
      this.pagingSettings.numPages > this.maxSize &&
      this.autoCompact
    ) {
      return true;
    }
    return this.compact;
  }

  /**
   * verifies if the pagination component must be shown page selection in extended mode
   */
  public hasPageSelection(): boolean {
    return this.pageSelection;
  }

  public showRecordsPerPageSelection() {
    return this.recordsPerPageSelection;
  }

  /*_____________________________________Validations________________________________________ */

  /**
   * checks if paging elements are going to be shown
   */
  public showPaging() {
    return this.pagingSettings.paging && this.pagingSettings.numPages > 1;
  }

  /**
   * Validates the values entered in the page selection text box and changes to the desired page
   */
  public checkInputPaging(e) {
    let execute = e.which === 13;
    let inputValue = e.target.value;

    if (
      (e.which == 38 || e.which == 39) &&
      this.pagingSettings.currentPage < this.pagingSettings.numPages
    ) {
      // 38 = up arrow, 39 = right arrow
      this.nextPageEvent();
      return;
    } else if (
      (e.which == 37 || e.which == 40) &&
      this.pagingSettings.currentPage > 1
    ) {
      // 37 = left arrow, 40 = down arrow
      this.previousPageEvent();
      return;
    }

    if (isNaN(inputValue)) {
      this.pagingSettings.currentPage = +inputValue
        .toString()
        .replace(/[^\d]/g, "");
      return;
    }

    if (!execute) return;

    if (+inputValue > this.pagingSettings.numPages) {
      this.pagingSettings.currentPage = this.pagingSettings.numPages;
      this.lastPageEvent();
    } else if (+inputValue < 1) {
      this.firstPageEvent();
    } else {
      this.pagingSettings.currentPage = +inputValue;
      this.firstPageEvent();
    }

    // this.pages = this.getPages();
  }

  private _evaluateTableData() {
    this.tableHasData = this.rows && this.rows.length > 0;

    this.pagingSettings.currentPage = this.pagingSettings.currentPage || 1;
    this.pagingSettings.itemsPerPage =
      this.pagingSettings.itemsPerPage || ItemsPerPagePagination.Five;

    this.pagingSettings.numPages = Math.ceil(
      this.pagingSettings.totalItems / this.pagingSettings.itemsPerPage
    );
    this.tableIsLoading = !this.rows;
  }
}

export interface PagingSettings {
  id: string;
  currentPage?: number;
  totalItems: number;
  paging: true;
  itemsPerPage?: number;
  numPages?: number;
}
