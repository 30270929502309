import { Input, Component, OnInit, OnChanges, SimpleChange, ViewChild, ElementRef } from '@angular/core';
import { Router } from '@angular/router';
import { InputService } from './../../../foundations-webct-robot/robot/services/input.service';
import { RobotEngineModel } from './../../../foundations-webct-robot/robot/robotEngineModel';
import { PageService } from './../../../foundations-webct-robot/robot/pageComponent/page.service';
import { Utils } from './../../../foundations-webct-robot/robot/utils/utils.service';
import { GlobalVarsService } from './../../../foundations-webct-robot/robot/utils/global-vars.service';
import { JsonParams } from './../../../foundations-webct-robot/robot/classes/jsonParams.class';
import { ToggleElementsService } from './../../../foundations-webct-robot/robot/services/toggle-elements.service';

@Component({
  selector: 'output-label',
  templateUrl: 'label.component.html',
  styleUrls: ['label.component.css']
})

export class OutputLabelComponent implements OnInit, OnChanges {

  @Input() public inputParameters: JsonParams;
  @Input() public inputDetails: JsonParams[];
  @Input() public inputValue;
  @Input() public dataRecs;
  @Input() public dataRecsFormated;
  @Input() public textboxAlign = false;
  @Input() public tableDisplay = false;
  @Input() public rowIndex = 0;
  @Input() public updateParamWithVal = true;
  @Input() public showTitle = false;

  @ViewChild('openQuickView') openQuickView: ElementRef;
  @ViewChild('quickviewPopupPositionSimulator') quickviewPopupPositionSimulator: ElementRef;

  public myValue: any;
  public myTitle = '';
  public componentId: string;
  public componentQuickViewId: string;
  public componentQuickViewOnRight = false;
  public componentQuickViewOnTop = false;

  private _noValueChar = '-';
  private _componentId: string;
  private _subscription: any;

  constructor(
    private _robot: RobotEngineModel,
    private _utils: Utils,
    private _globalVars: GlobalVarsService,
    private _pageService: PageService,
    private _toggle: ToggleElementsService,
    private _router: Router,
    private _inputService: InputService) {

    this._componentId = 'LABEL-' + this._utils.guid(4, '');
  }

  public clearHtml = (str: string) => str ? str.toString().replace(this._utils.htmlTagsReg, '').replace(/&nbsp;/g, '').replace(this._utils.mustacheReg, '') : '';

  public ngOnInit() {
    this.componentId = this._utils.guid(4, '');
    this.componentQuickViewId = 'wct-label-quickView' + (this.inputParameters ? '-' + this.inputParameters.id : '') + '-${' + this.rowIndex + '}';

    if (this.inputParameters && this.inputParameters.id) {
      this._subscription = this.inputParameters.observe().subscribe(obj => {
        this._startComponent();
      });
    }
    this._startComponent();
  }

  public ngOnDestroy() {
    this._globalVars.deletePageParametersByGroup(this._componentId);
    if (this._subscription)
      this._subscription.unsubscribe();
  }

  ngOnChanges(changes: { [propertyName: string]: SimpleChange }) {
    if (changes['inputValue'])
      this._startComponent();
  }

  public navigate(e, navigateTo: string) {
    this._utils.navigate(navigateTo, [this.dataRecs, this._utils.arrToObj(this.inputDetails, 'originalValue'), this._utils.arrToObj(this._globalVars.getPageParametersAsArray(), 'originalValue')], e)
  }

  public labelClick() {

    if (!this.inputParameters)
      return;

    let changeParamConfig = this._utils.findObjectInArray(this.inputParameters.parameters, 'changeParamById');
    if (changeParamConfig.key) {
      let paramToChange = this._utils.findObjectInArray(this._globalVars.getPageParametersAsArray(), changeParamConfig.id, 'id');
      if (changeParamConfig.value) {
        let newParamConfig = JSON.parse(this._utils.replaceTagVars(JSON.stringify(changeParamConfig.value), this.dataRecs));
        for (let i in newParamConfig)
          paramToChange[i] = newParamConfig[i];
      }
    }
  }

  public printValue = () => this.myValue || this.inputParameters.value || this._noValueChar;

  public getTooltipStatus = () => this._toggle.getTooltipStatus(this.componentQuickViewId);
  public toggleTooltip = (event) => {
    this.componentQuickViewOnRight = (event.x * 1) / document.body.clientWidth > 0.5;
    this.componentQuickViewOnTop = (event.y * 1) / document.body.clientHeight > 0.5;
    this._toggle.toggleTooltipStatus(this.componentQuickViewId);

    this._toggle.toggleElementPotition(this.componentQuickViewId, this.quickviewPopupPositionSimulator.nativeElement, this.openQuickView.nativeElement);
  };

  private _startComponent() {
    if (!this.inputParameters) {
      this.inputParameters = new JsonParams();
      this.inputParameters.value = this.inputValue;
      return;
    }

    let suffix: string;
    if (this.tableDisplay) {

      this.myValue = this._utils.formatValues(this.inputParameters, this._pageService.i18n(this.inputValue), true);
      this.myValue = this._inputService.formatMyValue(this.myValue, this.inputParameters, false, this.dataRecs, this._globalVars.getPageParametersAsArray());

      if (this.showTitle)
        this._evaluateTitle(this.myValue);

      suffix = '${1-' + this.rowIndex + '}';
      let tableParam = new JsonParams();
      tableParam.id = this.inputParameters.id + '${1-' + this.rowIndex + '}';
      tableParam.value = this.inputValue;

      this._globalVars.setPageParameters(tableParam, this._componentId);

    } else {

      this.myValue = this.inputValue || this._utils.formatValues(this.inputParameters, this.inputParameters.value, true);
      this.myValue = this._findValueInValueList(this.myValue);
      this.myValue = this._utils.replaceTagVars(this._pageService.i18n(this.myValue), this.dataRecs);

      // ------ TODO: Linha necesária para quando tag do i18n depende da API, e valor no i18n dessa tag, depende também da API
      this.myValue = this._utils.replaceTagVars(this._pageService.i18n(this.myValue), this.dataRecs);
      // ------

      this.myValue = this._inputService.formatMyValue(this.myValue, this.inputParameters, null, this.dataRecs, this._globalVars.getPageParametersAsArray());

      this._evaluateTitle(this.inputParameters.title);

      if (this.updateParamWithVal) {
        this.inputParameters.value = this.myValue;
        this.myValue = undefined;
      }
    }

    if (this.myValue)
      this.myValue = this._formatValue(this.myValue);
    else
      this.inputParameters.value = this._formatValue(this.inputParameters.value);

    this._robot.findDynamicPropsDependencies(this.inputParameters.id, this.dataRecs, suffix);
  }

  private _findValueInValueList(text: any): any {
    if (this.inputParameters.valueList) {
      for (let i in this.inputParameters.valueList) {
        if (!this.inputParameters.valueList[i][text])
          continue;
        return this.inputParameters.valueList[i][text];
      }
    }
    return text;
  }

  private _evaluateTitle(title: string) {
    if (!title) return;
    this.myTitle = this.clearHtml(this._utils.replaceTagVars(title, this.dataRecs));
  }

  private _formatValue(value: any) {
    if (!value)
      return value;

    let maskConfig = this._utils.findObjectInArray(this.inputParameters.parameters, 'mask');
    if (maskConfig.key && maskConfig.value) {
      let maskChar = maskConfig.text || '*';
      let initMaskIndex = +(this._utils.convertToString(maskConfig.value[0], ['number']) || 0);
      let endMaxkIndex = +(this._utils.convertToString(maskConfig.value[1], ['number']) || value.length);
      endMaxkIndex = endMaxkIndex + 1 <= value.length ? endMaxkIndex + 1 : value.length;
      if (maskConfig.value.length > endMaxkIndex)
        value = value.substring(0, initMaskIndex) + Array((endMaxkIndex - initMaskIndex) + 1).join(maskChar) + value.substring(endMaxkIndex);
    }

    // TODO: Validar codições de conversão de valores
    let convertConfig = this._utils.findObjectInArray(this.inputParameters.parameters, 'convert');
    if (convertConfig.key) {
      let unit: string;
      if (convertConfig.pathToValue)
        unit = this._utils.getValueFromDataForThisKey(this.dataRecsFormated || this._utils.arrToObj(this._globalVars.getPageParametersAsArray()), convertConfig.pathToValue);
      else
        unit = convertConfig.value;

      if (unit) {
        if (unit.toLowerCase() == 'volume' || unit.toLowerCase() == 'bytes')
          value = !isNaN(+value) ? this._bytesToSize(+value) : value;
        else if (unit.toLowerCase() == 'time' || unit.toLowerCase() == 'seconds')
          value = !isNaN(+value) ? this._secondsToHours(+value) : value;
        else
          value += ' ' + unit;
      }
    }
    return this._utils.replaceTagVars(value, this.dataRecs);
  }

  private _bytesToSize(bytes: number) {
    let sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB'];
    if (bytes == 0)
      return '0 Bytes';
    let i = Math.floor(Math.log(bytes) / Math.log(1000));
    return Math.round(bytes / Math.pow(1000, i)) + ' ' + sizes[i];
  }

  private _secondsToHours(seconds: number) {
    var hours = Math.floor(seconds / 3600);
    var minutes = Math.floor(seconds % 3600 / 60);
    var seconds = Math.floor(seconds % 3600 % 60);
    return ((hours > 0 ? hours + "h:" + (minutes < 10 ? "0" : "") : "") + minutes + "m:" + (seconds < 10 ? "0" : "") + seconds + "s");
  }
}
