import { JsonParams } from './../../../foundations-webct-robot/robot/classes/jsonParams.class';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs/Observable';
import { Utils } from './../../../foundations-webct-robot/robot/utils/utils.service';
import { InputService } from './../../../foundations-webct-robot/robot/services/input.service';
import { PageService } from './../../../foundations-webct-robot/robot/pageComponent/page.service';
import { GlobalVarsService } from './../../../foundations-webct-robot/robot/utils/global-vars.service';

@Injectable()
export class TextboxUnicityValidatorService {

  private _unicityValidatorUrl: string = null;
  private _unicityValidatorUrlForkJoin: string[] | null = null;

  constructor(private _utils: Utils,
              private _globalVars: GlobalVarsService,
              private _inputService: InputService,
              private _pageService: PageService) { }

  public searchApiByKeyword (keyword: Observable<string>, parameter: JsonParams, control) {
    return keyword.debounceTime(1000).distinctUntilChanged().switchMap(word => this._request(word, parameter, control));
  }

  private _request(word, parameter, control): Observable<any> {

    // return Observable.of(word);
    this._setUrlResourceForUnicityValidator(parameter);

    const rgx = new RegExp('{{' + parameter.id + '}}');
    let url: string;
    let forkJoinUrl: string[];
    let forkJoinRequests: any[];

    // headers:
    const currentParamValidator = parameter.validator.unique;
    let headers: any | null = null;
    let forkJoinHeaders: any[] | null = null;

    const response: any | null = null;

    if (this._unicityValidatorUrlForkJoin) {

      forkJoinUrl = [];
      forkJoinHeaders = [];
      forkJoinRequests = [];

      for (let i in this._unicityValidatorUrlForkJoin) {
        forkJoinUrl[i] = this._unicityValidatorUrlForkJoin[i].replace(rgx, control.value);

        forkJoinUrl[i] = this._utils.replaceTagVars(forkJoinUrl[i], this._utils.arrToObj(this._globalVars.getPageParametersAsArray()));
        if (currentParamValidator.value.hasOwnProperty('urlResource')) {
          forkJoinHeaders[i] = this._utils.findObjectInArray(this._utils.findObjectInArray(currentParamValidator.value['urlResource'], 'forkJoin', 'key').value[i], 'headers', 'key').value;
        }
        forkJoinRequests[i] = this._utils.GetAll(forkJoinUrl[i], forkJoinHeaders[i]);
      }

      if (control.value) {
        return Observable.forkJoin(forkJoinRequests);
      } else {
        return Observable.of(null);
      }
    } else if (this._unicityValidatorUrl) {

      url = this._unicityValidatorUrl.replace(rgx, control.value);
      url = this._utils.replaceTagVars(url, this._utils.arrToObj(this._globalVars.getPageParametersAsArray()));
      if (currentParamValidator.value.hasOwnProperty('urlResource')) {
        headers = this._utils.findObjectInArray(currentParamValidator.value['urlResource'], 'headers', 'key').value;
      }

      if (control.value) {
        return this._utils.GetAll(url, headers);
      } else {
        return Observable.of(null);
      }
    }
  }

  private _setUrlResourceForUnicityValidator(parameter: JsonParams): void {
    if (parameter.validator.unique) {

      const formatedUrlResource: any = {};
      const forkJoin = this._utils.findObjectInArray(parameter.validator.unique.value['urlResource'], 'forkJoin').value || null;

      if (forkJoin) {

        this._unicityValidatorUrl = null;
        this._unicityValidatorUrlForkJoin = [];
        for (let i in forkJoin) {
          for (let obj of forkJoin[i]) {
            formatedUrlResource[obj.key] = obj.value;
          }
          this._unicityValidatorUrlForkJoin[i] = this._pageService.setMyUrlResource(parameter, formatedUrlResource, {}, null, null, {}, parameter.validator.unique.value['urlResource']);
        }
      } else {
        this._unicityValidatorUrlForkJoin = null;
        for (let obj of parameter.validator.unique.value['urlResource'])
          formatedUrlResource[obj.key] = obj.value;

        this._unicityValidatorUrl = this._pageService.setMyUrlResource(parameter, formatedUrlResource, {}, null, null, {}, parameter.validator.unique.value['urlResource']);
      }
    }
  }
}
