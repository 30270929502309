import { Component, Input, OnInit, OnDestroy } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { Observable } from 'rxjs/Observable';
import { PageService } from './page.service';
import { RobotEngineModel } from '../robotEngineModel';
import { OperationsService } from '../utils/operations.service';
import { GlobalVarsService } from '../utils/global-vars.service';
import { Utils } from '../utils/utils.service';
import { ConfigurationService } from '../utils/configuration.service';
import { AuthzService } from '../utils/authz.service';
import { JsonParams, newEvent } from '../classes/jsonParams.class';
import { ApiService } from '../services/api.service';
import { ToggleElementsService } from '../services/toggle-elements.service';
import { FilterService } from '../services/filter.service';
import { ModalService } from '../services/modal.service';
import { AutoCompleteDetailsService } from './../../../foundations-webct2-palette/components/autoCompleteDetailsComponent/auto-complete-details.service';

@Component({
  selector: 'page-component',
  templateUrl: 'page.component.html'
})

export class PageComponent implements OnInit, OnDestroy {
  @Input() public inputParameters: JsonParams;
  @Input() public inputMock: string;
  @Input() public inputMockJson: string;
  @Input() public inputDataRecs: Object = null;
  @Input() public allowReload = true;
  @Input() public firstLevelLayer = true;
  @Input() public loadingFromModal = false;
  @Input() public eachtab = null;
  @Input() public componentIsLoading = true;
  @Input() public baseInfoForm: FormGroup = this._fb.group({});
  @Input() public componentIndex = -1;
  @Input() public origin: string | null;
  @Input() public cloneAs: string;
  @Input() public insideFilter: string;

  public pagingSettings: any = {
    paging: true,
    id: 'page-component',
    itemsPerPage: 20,
    currentPage: 1,
    totalItems: 0,
    numPages: 0
  };
  public config: any = {
    tableId: '',
    sorting: { columns: [] },
    filtering: { filterString: '', columnName: 'position' }
  };
  public filter: Object = {};
  public dataRecs: any;
  public dataRecsMainInfo: any;
  public dataRecsNoContent: any;
  public initDateSearch = false;
  public filterDateInterval: string = null;
  public filterDateField: string = null;
  public viewStructure: JsonParams = null;
  public loadComponentHtml = true;
  public showComponentByIndex = 0;
  public displayParametersComponent = true;
  public colapsedSidebar = false;
  public componentId: string;

  protected _mainStructure: JsonParams = null;
  protected _robot: RobotEngineModel;
  protected _offset: any;

  private _urlResource: any;
  private _currentEntity: Object = null;
  private _isFilter = true;
  private _forceReload = false;
  private _tableConfig: JsonParams;
  private _filterSubscribe;
  private _newEventSubscribe;
  private _refreshSubscribe;

  constructor(
    public pageService: PageService,
    public modalService: ModalService,
    public configService: ConfigurationService,
    public authzService: AuthzService,
    private _fb: FormBuilder,
    protected _utils: Utils,
    private _apiService: ApiService,
    private _toggle: ToggleElementsService,
    private _filterService: FilterService,
    protected _globalVars: GlobalVarsService,
    private _operations: OperationsService,
    private _routeParams: ActivatedRoute,
    private _router: Router,
    private _acService: AutoCompleteDetailsService
  ) {
    this.componentId = 'PAGE-' + this._utils.guid(4, '');
    this._robot = new RobotEngineModel(
      this._utils,
      this._globalVars,
      this._apiService
    );

    if (
      this.authzService.requiredAuth &&
      this.authzService.loggedIn &&
      +this._globalVars.getLocalStorage("auth_token_exp") < new Date().getTime()
    )
      this.authzService.logout();
  }

  public navigate = (route: string) =>
    this._utils.navigate(route, this.dataRecs);

  /**
   * Devolte a configuração do filtro activa no componente
   */
  protected getFilterValues = () =>
    this._tableConfig ? this._tableConfig.value.filter : this.filter;

  public ngOnInit() {
    // if (this.firstLevelLayer)
    //     this._pageObjects = new PageObjectsService();

    if (this.authzService.isLoggedIn()) {
      this._startNgOnInit();
    } else {
      this.authzService.getLoginStatus().subscribe(val => {
        if (val) this._startNgOnInit();
      });
      return;
    }
  }

  public ngOnDestroy() {
    this._filterService.deleteFilterConfig(this.componentId);
    this._globalVars.deletePageParametersByGroup(this.componentId);

    if (this.viewStructure)
      this.pageService.deleteActiveForm(this.viewStructure.id);

    if (this._filterSubscribe) this._filterSubscribe.unsubscribe();
    if (this._newEventSubscribe) this._newEventSubscribe.unsubscribe();
    if (this._refreshSubscribe) this._refreshSubscribe.unsubscribe();

    if (
      this.viewStructure &&
      ['initTable', 'initCards'].indexOf(this.viewStructure.type) >= 0
    )
      this._globalVars.removeFilterSubscriber(this.viewStructure);
  }

  public makeFilter(value: string | Object) {
    this._isFilter = false;
    this.filter = {};

    if (value)
      this._setFilterValues(
        typeof value == "object" ? value : JSON.parse(value)
      );

    this.pagingSettings.currentPage = 1;
    this._applyFilter({ page: this.pagingSettings.currentPage });

    // COMENTADO NO ÂMBITO DO ISSUE WEBCT-915
    // if (this.pagingSettings.currentPage == 1)
    //     this._applyFilter({ page: this.pagingSettings.currentPage });
    // else
    //     this.pagingSettings.currentPage = 1;
  }

  public pageChange() {
    this._changePage({ page: this.pagingSettings.currentPage }, null);
  }

  public onChangeTable(config: any, page: any = config.paging) {
    if (page) {
      let currSearch: JsonParams = config.sorting[0];
      this._setSort(<JsonParams>config.sorting[0]);
      this._applyFilter({ page: page.currentPage });
    }
  }

  public manualReloadComponent() {
    this.viewStructure = null;
    this.componentIsLoading = true;
    this._forceReload = true;
    // setTimeout(() => {
    this._loadParameters();
    // }, 1000);
  }

  public onNext(indx: number): void {
    return;
  }
  public onPrevious(indx: number): void {
    return;
  }
  public onFinish(): void {
    return;
  }
  public onTabChange(): void {
    return;
  }

  public modalButtonsArray(buttons: Object = {}): JsonParams[] {
    if (!buttons || Object.keys(buttons).length == 0) return [];
    return Object.keys(buttons).map(key => {
      return buttons[key];
    });
  }

  public onSumbitClick(operation: JsonParams) {
    let asNewConfiguration =
      !!this._utils.findObjectInArray(operation.parameters, "onSuccess").key ||
      !!this._utils.findObjectInArray(operation.parameters, "onError").key;
    if (
      !operation.parameters ||
      (!asNewConfiguration &&
        this._utils.findObjectInArray(operation.parameters, 'keepModal')
          .value !== true)
    )
      this.modalService.closeModal();

    let submit: JsonParams = this.pageService.buttonsConfig.modal['submit'];
    if (!submit) {
      console.error('Missing operation parameters.');
      return;
    }
    if (submit.parameters && submit.parameters.length > 0) {
      this.pageService.operationLoading = true;
      this._operations.execute(
        this._globalVars.getPageParametersAsArray(),
        this.pageService.buttonsConfig.modal['submit'].parameters,
        this.viewStructure.id
      );
    }
  }

  public isSubmitDisabled(param: JsonParams): boolean {
    return (
      (this.baseInfoForm && !this.baseInfoForm.valid) ||
      this.pageService.buttonsConfig.modal.submit.disabled
    );
  }

  /**
   * DEFINE QUAIS OS PARÂMTROS QUE DEVEM SER ENVIADOS PARA O "initParameters"
   */
  public dataRecsToParameters() {
    if (!this.viewStructure.groups || !this.viewStructure.groups.sidebar)
      return this.dataRecsMainInfo;
    if (
      Object.prototype.toString.call(this.dataRecsMainInfo) ===
      '[object Array]' &&
      this.detectSidebarComponents(
        this.viewStructure.groups.sidebar.parameters,
        'toggleRows'
      )
    )
      return this.dataRecsMainInfo[this.showComponentByIndex];
    return this.dataRecsMainInfo;
  }

  /**
   * EMITE QUAL O ID DO COMPONENTE QUE DEVE SER MOSTRADO
   */
  public showComponentById(
    elementId: string,
    parameters: JsonParams[],
    findIdsInParams: boolean = false
  ) {
    let brotherParams = [];
    if (findIdsInParams) {
      for (let findParam of parameters) {
        let param: JsonParams = this._globalVars.getPageParameter(<string>(
          findParam.showComponentById
        ));
        if (param.id == findParam.showComponentById) brotherParams.push(param);
      }
    } else brotherParams = parameters;

    if (brotherParams.length > 0) brotherParams.map(obj => (obj.hidden = true));

    let showElement: JsonParams = this._globalVars.getPageParameter(elementId);
    if (showElement.id == elementId) {
      showElement.lazyLoading = false;
      showElement.hidden = false;
    }
    // setTimeout(() => 1);
  }

  /**
   * FAZ TOGGLE AO "initParameters" COM OS NOVOS DATARECS
   */
  public showComponentByIndexEmited(elementIndex: number) {
    if (this.showComponentByIndex == elementIndex) return;

    // this.displayParametersComponent = false;
    this.showComponentByIndex = elementIndex;
    // setTimeout(() => {
    this.displayParametersComponent = true;
    // }, 1);
  }

  public pageDetectClick(e) {
    if (e.target.dataset && e.target.dataset['togglequickview'] != 'true') {
      this.pageService.openedQuickViews = [];
    }
  }

  /**
   * DETECTA SE HÁ UM DETERMINADO TIPO DE COMPONENTE NA SIDEBAR
   */
  public detectSidebarComponents(parameters: JsonParams[], type: string) {
    for (let i in parameters) {
      if (parameters[i].type != type) continue;
      return true;
    }
    return false;
  }

  public isHorizontalFilter(): boolean {
    if (this.isSideBar())
      return !!!this._utils.findObjectInArray(
        this.viewStructure.groups.sidebar.parameters,
        'filter',
        'type'
      );
    if (this.viewStructure.groups.columns)
      return !!this.viewStructure.groups.columns.parameters.find(obj => {
        return obj.filter;
      });
    return false;
  }

  public isSideBar(): boolean {
    if (
      /* this.pageService.pageSize >= this.pageService.devices.desktop &&  */ this
      .viewStructure.groups.sidebar
    )
      return true;
    return false;
  }

  public isWizard(): boolean {
    if (
      this.viewStructure.groups.wizard &&
      this.viewStructure.groups.wizard.parameters.length > 0
    )
      return true;
    return false;
  }

  public generalContentScroll(e) {
    this._toggle.hideTooltips();
  }

  public isComponentActive(name: string): boolean {
    return true; // this._globalVars.getActiveComponent(name);
  }

  private _startNgOnInit() {
    let routeParamsSubscribe = false;
    this._routeParams.params.subscribe(data => {
      if (this.firstLevelLayer && !this.loadingFromModal) {
        // this._globalVars.resetDP();
        // this._globalVars.resetGlobalVars();
        this._globalVars.urlParams = data;
        this._globalVars.setSessionStorage(
          'wct-previous-route',
          this._globalVars.getSessionStorage('wct-current-route')
        );
        this._globalVars.setSessionStorage(
          'wct-current-route',
          this._router.url
        );
      }
      routeParamsSubscribe = true;
      this.componentIsLoading = true;
      this._loadParameters();
    });
    if (!routeParamsSubscribe) this._loadParameters();
  }

  private _setSort(param: JsonParams) {
    if (typeof param.sort == 'string') {
      if (param.sortTemplate) {
        for (let i in param.sortTemplate)
          this._urlResource['queryString'][i] = this._utils.replaceTagVars(
            param.sortTemplate[i],
            param
          );
      } else
        this._urlResource['queryString']['sort_by'] =
          (param.sort == 'DESC' ? '-' : '') + (param.apiFieldName || param.id);
    } else if (this._urlResource['queryString']) {
      if (param.sortTemplate) {
        for (let i in param.sortTemplate)
          delete this._urlResource['queryString'][i];
      }
      if (this._urlResource['queryString']['sort_by'])
        delete this._urlResource['queryString']['sort_by'];
    }
  }

  private _loadParameters() {
    this._clearComponentData();

    if (this.inputMockJson) {
      this._loadLayer(this.inputMockJson);
    } else if (this.inputParameters && !this.inputMock) {
      this._loadLayer();
    } else {
      if (!this.inputMock) {
        this.pageService.resetConfig();
        this._robot.paramsToValidate = [];

        this._acService.restartService();

        this._routeParams.data.subscribe(data => {
          this._currentEntity = data;
          this._globalVars.urlDataParams = data;
        });
      }

      if (this._currentEntity && this._currentEntity['mock'])
        this._globalVars.setGlobalVars(
          'current-page',
          this._utils.replaceTagVars(
            this._currentEntity['mock'],
            this.inputDataRecs
          )
        );

      this._utils
        .GetAll(
          this._utils.getJson(
            this.inputMock ? this.inputMock : this._currentEntity['mock'],
            this.inputDataRecs
          )
        )
        .subscribe(
          response => {
            this._loadLayer(response);
          },
          // OnError
          error => console.error('PageComponent-> ', error)
        );
    }
  }

  protected _loadLayer(response = null) {
    let loadPageData = (res: JsonParams) => {
      this._mainStructure = res;
      this._robot.updatePageWithData(this._mainStructure);
      this.viewStructure = this._robot.view;

      // if (this.firstLevelLayer)
      //     this._pageObjects.init(this.viewStructure);

      this._evaluateTableConfig();

      if (['initTable', 'initCards'].indexOf(this.viewStructure.type) >= 0) {
        this._observeFilter();
        this._observeRefresh();
        this._observeNewEvent();
      }

      let myParameters = this._globalVars.getPageParametersByGroup(
        this.viewStructure.id
      );
      for (let param of myParameters)
        this._robot.changeParameterByDynamicProps(
          param,
          this._utils.arrToObj(this._globalVars.getPageParametersAsArray())
        );

      if (response && this.inputParameters) {
        this._mainStructure.lazyLoading = this.inputParameters.lazyLoading;
        this._mainStructure.hidden = this.inputParameters.hidden;
      }

      if (this.firstLevelLayer) {
        if (this.loadingFromModal) {
          this.modalService.newMockStructure = [];
          this.modalService.viewStructure = this.viewStructure;
        } else {
          this.pageService.newMockStructure = [];
          this.pageService.viewStructure = this.viewStructure;
        }
      } else if (response) {
        if (this.loadingFromModal)
          this.modalService.newMockStructure.push(this.viewStructure);
        else this.pageService.newMockStructure.push(this.viewStructure);
      }

      this.pagingSettings.id = this.viewStructure.id;
      this.colapsedSidebar = this.isHorizontalFilter();

      if (this.firstLevelLayer && this.loadingFromModal)
        this.pageService.wizardFormStepsModal = [];
      else if (this.firstLevelLayer) this.pageService.wizardFormSteps = [];

      if (
        (!this.inputParameters || this.componentIndex >= 0) &&
        this._robot.paramsToValidate.length > 0
      ) {
        this.baseInfoForm = this.pageService.configValidators(
          this._robot.paramsToValidate,
          this.loadingFromModal,
          this.componentIndex,
          false,
          this.viewStructure.id
        );
      }
      if (
        this.viewStructure &&
        this.viewStructure.groups &&
        this.firstLevelLayer
      ) {
        this.pageService.setButtonsConfig(
          this.viewStructure.groups.buttons,
          'modal'
        );
      }

      this._evaluateInitFilterConfig();

      if (this.viewStructure.groups.urlResource) {
        let forkJoinConfig = this._utils.findObjectInArray(
          this.viewStructure.groups.urlResource.parameters,
          'forkJoin'
        ).value;
        if (forkJoinConfig) {
          let forkJoinUrl = [];
          let forkJoinRequests = [];

          this._offset = this._getOffset();

          for (let i in forkJoinConfig) {
            forkJoinUrl[i] = this._startApiConfiguration(forkJoinConfig[i]);
            forkJoinRequests[i] = this._utils.GetAll(
              this.pageService.setMyUrlResource(
                this.viewStructure,
                forkJoinUrl[i],
                {
                  page: { page: this.pagingSettings.currentPage },
                  offset: this._offset
                },
                this.pagingSettings,
                this.inputDataRecs,
                this.getFilterValues()
              ),
              forkJoinUrl[i].headers
            );
          }
          Observable.forkJoin(forkJoinRequests).subscribe(data => {
            let forkJoinData = {};
            for (let i in data)
              forkJoinData[forkJoinUrl[i]['id'] || i] = data[i];
            let dataIndex = 0;
            for (let i in forkJoinData) {
              this._fillData(
                data[dataIndex],
                dataIndex,
                forkJoinUrl[dataIndex],
                forkJoinData
              );
              dataIndex++;
            }
          });
        } else
          this._dealWithApiData(
            this.viewStructure.groups.urlResource.parameters
          );
      } else this._dealWithApiData();

      setTimeout(() => 100);
    };

    if (response)
      this._robot
        .loadPageParams(response, this.componentId, this.cloneAs)
        .then(res => loadPageData(res));
    else loadPageData(this.inputParameters);
  }

  protected _evaluateInitFilterConfig() {
    let filterFields: JsonParams[] = [];
    if (this.isSideBar()) {
      let sidebar = this._utils.findObjectInArray(
        this.viewStructure.groups.sidebar.parameters,
        'filter',
        'type'
      );
      if (sidebar.type == 'filter') {
        if (
          this.viewStructure.type == 'initTable' &&
          this.viewStructure.groups.columns &&
          this.viewStructure.groups.columns.parameters
        )
          filterFields = this._filterService.getFilterFields(
            this.viewStructure.groups.columns.parameters
          );
        else if (
          sidebar.groups.details &&
          sidebar.groups.details.parameters &&
          sidebar.groups.details.parameters.length > 0
        )
          this._filterService.setFilterConfig(sidebar, this.componentId);
      }
    }

    if (filterFields && filterFields.length === 0)
      filterFields = this._filterService.getFilterConfigById(
        this.viewStructure.id
      );
    if (filterFields && filterFields.length > 0)
      this._setFilterValues(this._filterService.getFilterObject(filterFields));
  }

  protected _dealWithApiData(urlResourceConfig: JsonParams[] = null) {
    let currentUrlResource = this._startApiConfiguration(urlResourceConfig);
    if (currentUrlResource && currentUrlResource.path) {
      let recycleApi = this._apiService.getApiRecycle(currentUrlResource.id);
      if (currentUrlResource.id && recycleApi && !this._forceReload)
        this._fillData(recycleApi);
      else if (this.pageService.getParameterByName('filter'))
        this._fillData({});
      else this._changePage({ page: 1 }, null, currentUrlResource);
      this.allowReload =
        currentUrlResource.reload !== undefined
          ? currentUrlResource.reload
          : true;
    } else if (this.inputDataRecs) {
      this._fillData(this.inputDataRecs);
      this.allowReload = false;
    } else {
      this.componentIsLoading = false;
      this.allowReload = false;
      this._updateMyIconTab(null);
    }
  }

  protected _startApiConfiguration(urlResourceToFormat: JsonParams[]) {
    this._urlResource =
      this.pageService.startApiConfiguration(
        urlResourceToFormat,
        this.inputDataRecs
      ) || {};

    /**
     * Através de indicação nos Mocks, podemos mostrar ou não o HTML do componente, caso não existam dados para preencher os campos.
     * Se a pripriedade estiver definida a true, o componente irá logo à partida esconder o HTML, voltando a mostrar assim que receba os dados que o preencham
     */
    this.loadComponentHtml = !this._urlResource.mustHaveDataToShow;

    // Configuração do filtro temporal que limita a chamada à API quando é aberta uma listagem
    if (this._urlResource['initDateSearch']) {
      this.initDateSearch = true;
      this.filterDateInterval = this._urlResource['initDateSearch']['interval'];
      this.filterDateField = this._urlResource['initDateSearch']['fieldName'];

      this._setFilterValues(
        this._utils.date_range(this.filterDateInterval, this.filterDateField),
        true
      );
    }

    return this._urlResource;
  }

  protected _fillData = (
    data,
    forkJoin: number = -1,
    forkJoinUrl = null,
    forkJoinData: Object = null
  ) => {
    let currUrlResource = forkJoinUrl || this._urlResource || {};

    // Resposta total que nos chega após concluída a chamada a API. Definição de variáveis gerais
    this.dataRecs = data;
    this.dataRecsNoContent = null;
    this.componentIsLoading = false;
    this._forceReload = false;

    this.pagingSettings.totalItems = 0;
    this.pagingSettings.numPages = 0;

    if (this.dataRecs.errorDetected) {
      this.dataRecsNoContent = this.dataRecs;
      this._updateMyIconTab('fa fa-exclamation fx-status-error');
      return;
    }

    this._setPagingSettings();
    // this.updateMyIconTab('glyphicon glyphicon-ok-sign fx-status-success',true);
    this._updateMyIconTab(null);

    currUrlResource['_componentId'] = this.componentId;
    this.dataRecsMainInfo = this.pageService.fillData(
      this.viewStructure,
      this.dataRecs,
      this.inputDataRecs,
      currUrlResource,
      forkJoin,
      this._globalVars.getPageParametersAsArray(),
      forkJoinData
    );

    // Se houver indicação no Mock para esconder o HTML, no caso de haver dados, o HTML é apresentado
    if (currUrlResource['mustHaveDataToShow'])
      this.loadComponentHtml =
        this.dataRecsMainInfo &&
        (this.dataRecsMainInfo.length > 0 ||
          Object.keys(this.dataRecsMainInfo).length > 0);

    setTimeout(() => 100);
  };

  private _setPagingSettings() {
    const mockTotalRecords = this._urlResource['pathToTotalRecords'];
    const mockTotalPages = this._urlResource['pathToTotalPages'];

    if (mockTotalRecords) {
      const tmp = this._utils.getValueFromDataForThisKey(
        this.dataRecs,
        mockTotalRecords
      );
      this.pagingSettings.totalItems =
        tmp !== undefined && typeof tmp !== 'object' && !isNaN(tmp)
          ? +tmp
          : undefined;
    } else {
      this.pagingSettings.totalItems =
        this.dataRecs._size !== undefined
          ? !isNaN(this.dataRecs._size)
          ? this.dataRecs._size
          : 10
          : this.dataRecs.count != undefined
          ? !isNaN(this.dataRecs.count)
            ? this.dataRecs.count
            : 10
          : 0;
    }
    if (mockTotalPages) {
      const tmp = this._utils.getValueFromDataForThisKey(
        this.dataRecs,
        mockTotalPages
      );
      this.pagingSettings.numPages =
        tmp !== undefined && typeof tmp !== 'object' && !isNaN(tmp)
          ? +tmp
          : undefined;
    } else {
      this.pagingSettings.numPages =
        this.dataRecs._size !== undefined
          ? this.dataRecs._total_pages
          : this.dataRecs.count != undefined
          ? Math.ceil(this.dataRecs.count / this.dataRecs.limit)
          : 0;
    }

    if (
      this.pagingSettings.totalItems === undefined ||
      this.pagingSettings.numPages === undefined
    )
      console.error('ERROR: values for totalItems or numPages are not valid!');
  }

  private _updateMyIconTab(icon: string, hideAfter: boolean = false) {
    if (!this.eachtab || !this.eachtab.icon) return;
    // TODO: Verificar o erro quando este setTimeout() não existe (Expression has changed after it was checked.)
    setTimeout(() => {
      this.eachtab.icon = icon;
      if (hideAfter) {
        setTimeout(() => {
          this.eachtab.icon = null;
        }, 2000);
      }
    }, 100);
    /*  new Promise((resolve) => {
        this.eachtab.icon = icon;
        resolve();
      }).then(() => {
        if (hideAfter) {
          this.eachtab.icon = null;
        }
      });*/
  }

  private _applyFilter(page: any, data: any = null) {
    this._changePage(page, data);
  }

  private _changePage(
    currPage: any,
    currData: any,
    currentUrlResource: Object = null
  ) {
    if (currPage.page) {
      let useUrlResource = currentUrlResource || this._urlResource;
      if (this.viewStructure.groups.rows) {
        this.viewStructure.groups.rows.value = null;
        this.viewStructure.groups.rows.originalValue = null;
      }

      // let queryFilter: any = this.pageService.getParameterByName('filter');
      // if (queryFilter) {
      //     queryFilter = JSON.parse(queryFilter);
      //     if (this.viewStructure.groups.columns && this.viewStructure.groups.columns.parameters) {
      //         let columnConfig: JsonParams;
      //         for (let i in queryFilter) {
      //             columnConfig = this._utils.findObjectInArray(this.viewStructure.groups.columns.parameters, i, 'id');
      //             if (columnConfig.filter && typeof columnConfig.filter != 'boolean') {
      //                 if (typeof columnConfig.filter == 'object')
      //                     columnConfig = columnConfig.filter;
      //                 this.filter[columnConfig.id] = columnConfig.filterTemplate ? columnConfig.filterTemplate.replace('{{' + columnConfig.id + '}}', queryFilter[i]) : { $regex: '(?i)' + queryFilter[i] + '.*' };
      //                 try {
      //                     this.filter[columnConfig.id] = JSON.parse(this.filter[columnConfig.id]);
      //                 } catch (e) {
      //                     // WHEN FILTER TEMPLATE IS JUST A STRING
      //                 }
      //             } else
      //                 this.filter[i] = { $regex: '(?i)' + queryFilter[i] + '.*' };
      //         }
      //     } else
      //         Object.assign(this.filter, queryFilter);
      // }
      this._offset = this._getOffset(currPage.page);
      let formatedUrlResource = this.pageService.setMyUrlResource(
        this.viewStructure,
        useUrlResource,
        { page: currPage, data: currData, offset: this._offset },
        this.pagingSettings,
        this.inputDataRecs,
        this.getFilterValues()
      );
      this._utils.getCallback(
        {
          urlResource: useUrlResource,
          url: formatedUrlResource,
          headers: useUrlResource.headers
        },
        this._fillData,
        this.componentId
      );
    }
  }

  private _clearComponentData() {
    this._deleteFilterValues();
    this.dataRecs = null;
    this.dataRecsMainInfo = null;
    this.dataRecsNoContent = null;
    this.filterDateInterval = null;
    this.filterDateField = null;
    this.viewStructure = null;
    this.loadComponentHtml = true;
    this.showComponentByIndex = 0;
    this.displayParametersComponent = true;

    this._mainStructure = null;

    this._urlResource = null;
    this._currentEntity = null;
    this._isFilter = true;
    this._offset = null;

    this._robot = new RobotEngineModel(
      this._utils,
      this._globalVars,
      this._apiService
    );
  }

  protected _evaluateTableConfig() {
    if (this.viewStructure.type != 'initTable') return;

    this._tableConfig = this.pageService.createTableConfigObject(
      this.viewStructure
    );

    this.pageService.updateConfigParameterPaging(
      this._tableConfig,
      this.pagingSettings
    );
    this.pageService.updateConfigParameterSort(
      this._tableConfig,
      this.viewStructure.groups.columns.parameters.find(
        obj => obj.sort == 'ASC' || obj.sort == 'DESC'
      )
    );
    this._globalVars.setPageParameters(this._tableConfig, this.componentId);
  }

  protected _getOffset(currPage?: number): number {
    return (
      (currPage !== undefined ? currPage : this.pagingSettings.currentPage) *
      this.pagingSettings.itemsPerPage -
      this.pagingSettings.itemsPerPage
    );
  }

  private _setFilterValues(obj: Object, join: boolean = false): void {
    // if (!obj || Object.keys(obj).length == 0)
    //     return;

    this._isFilter = obj && Object.keys(obj).length > 0;
    if (!this.filter || !join) this.filter = {};

    Object.assign(this.filter, obj);
    this._saveTableFilterConfig();
  }

  private _saveTableFilterConfig() {
    if (this._tableConfig)
      this._tableConfig.value.filter = this._utils.cloneJsonParse(this.filter);
  }
  private _deleteFilterValues() {
    this.filter = {};
    this._saveTableFilterConfig();
  }

  protected _observeFilter() {
    if (
      !this.viewStructure.groups.urlResource ||
      this.viewStructure.data.hasFilterObserver
    )
      return;

    this.viewStructure.data = { hasFilterObserver: true };
    this._filterSubscribe = this._globalVars
      .getFilterById(this.viewStructure)
      .subscribe(filters => {
        if (filters[this.viewStructure.id])
          this.makeFilter(filters[this.viewStructure.id]);
      });
  }

  protected _observeRefresh() {
    if (this.viewStructure.data.hasRefreshObserver) return;

    this.viewStructure.data = { hasRefreshObserver: true };
    this._refreshSubscribe = this.viewStructure
      .observeRefresh()
      .subscribe(elem => this._startNgOnInit());
  }

  protected _observeNewEvent() {
    this._newEventSubscribe = this.viewStructure
      .newEvent()
      .subscribe(data => this._handleEvent(data));
  }
  private _handleEvent(data: newEvent[]) {
    data.forEach(event => {
      if (event.key == 'updateFilter')
        this.makeFilter(
          this._tableConfig ? this._tableConfig.value.filter : {}
        );
    });
  }
}

interface PagingSettingsFromUrlResource {
  totalItems: string;
  numPages: string;
}
