ace.define("ace/theme/traningPhrases",["require", "exports", "module", "ace/lib/dom"], function(acequire, exports, module) {
    "use strict";
    
    exports.isDark = false;
    exports.cssClass = "ace-traningPhrases";
    exports.cssText = acequire("../ace/theme/theme-traningPhrases.css");
    exports.$id = "ace/theme/traningPhrases";
    
    var dom = acequire("../lib/dom");
    dom.importCssString(exports.cssText, exports.cssClass);
    });