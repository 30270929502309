import { Component, Input, DoCheck, KeyValueDiffers, ElementRef } from '@angular/core';
import { JsonParams } from './../../../foundations-webct-robot/robot/classes/jsonParams.class';
import * as L from 'leaflet';

@Component({
  selector: 'assymetrik-leaflet-map',
  templateUrl: 'assymetrikMap.component.html'
})

export class AssymetrikMapComponent implements DoCheck {

  @Input() public inputParameters: JsonParams;
  @Input() public inputDataRecs: Object;

  public options: MapOptions = {
    zoom: 4,
    center: L.latLng([40.62970, -8.64674])
  };

  public loadMap = false;
  public maxBounds: L.LatLngBounds;
  public layers: L.Layer[];
  public layersControl: any;
  public markers: L.FeatureGroup;
  public LAYER_OSM = {
    id: 'openstreetmap',
    name: 'Open Street Map',
    enabled: true,
    tms: true,
    reuseTiles: true,
    detectRetina: true,
    layer: L.tileLayer('http://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png', {
      maxZoom: 18,
      minZoom: 1,
      attribution: '&copy; <a href=\"http://osm.org/copyright\">Open Street Map</a> contributors'
    })
  };

  public mapComponentFullId; // the component's full id (example: 'wct-map-ID_DEFINED_BY_THE_USER')
  public mapContainer; // the div that contains the map
  public mapContainerWidth; // the width of the map container div
  public mapContainerHeight; // the height of the map container div

  private differ: any;
  private map: L.Map;

  constructor(private elRef: ElementRef, private differs: KeyValueDiffers) { this.differ = differs.find({}).create(); }

  public ngOnInit() {

    let markerArr: any[] = [];
    if (this.inputParameters.groups.rows.value && this.inputParameters.groups.rows.value.length > 0) {
      this.inputParameters.groups.rows.value.forEach((item: Marker) => {
        if (!this.inputParameters.position) {
          this.inputParameters['position'] = {};
        }
        if (!this.inputParameters.position.lat || !this.inputParameters.position.lng) {
          this.inputParameters.position['lat'] = +item.lat;
          this.inputParameters.position['lng'] = +item.lng;
        }
        let mrk = {
          id: item.label ? item.label : item.text,
          layer: L.marker([item.lat, item.lng], {
            icon: L.icon({
              iconSize: [26, 34],
              iconAnchor: [13, 34],
              popupAnchor: [0, -34],
              iconUrl: 'assets/webct/img/map-marker.png?var=1',
              iconRetinaUrl: 'assets/webct/img/map-marker-2x.png?var=1'
            }),
            title: "Marker: " + item.label ? item.label : item.text,
            riseOnHover: true
          }).bindPopup(item.description)
        };
        markerArr.push(mrk.layer);
      });
    }
    this.markers = markerArr.length > 0 ? L.featureGroup(markerArr) : null;

    this.setOptions(this.inputParameters.position);

    this.layers = [this.LAYER_OSM.layer];

    this.layersControl = {
      /*baseLayers: {
        'Open Street Map': this.LAYER_OSM.layer
      },*/
      overlays: {}
    };

    if (this.markers) {
      this.layers.push(this.markers as any);
      this.layersControl.overlays['Markers'] = this.markers;
    }

    let topCorner: L.LatLng = L.latLng(90, -180);
    let bottomCorner: L.LatLng = L.latLng(-90, 180);
    this.maxBounds = L.latLngBounds(topCorner, bottomCorner);
    this.loadMap = true;
  }

  public ngDoCheck() {
    let changePosition = this.differ.diff(this.inputParameters.position);
    if (changePosition) {
      this.setOptions(this.inputParameters.position);
    }

    // check if the size of the container div has changed
    if (this.mapContainer != "" && this.mapContainer != null) {
      let mapContainerCurrentWidth = this.mapContainer.getBoundingClientRect().width;
      let mapContainerCurrentHeight = this.mapContainer.getBoundingClientRect().height;
      if (mapContainerCurrentWidth != this.mapContainerWidth || mapContainerCurrentHeight != this.mapContainerHeight) {
        this.mapContainerWidth = mapContainerCurrentWidth;
        this.mapContainerHeight = mapContainerCurrentHeight;
        this.map.invalidateSize(true); // refresh the map
      }
    }
  }

  public saveMapDivContainerSize() {
    this.mapComponentFullId = 'wct-map-' + this.inputParameters.id;
    // this.mapContainer = this.elRef.nativeElement.querySelector('#'+this.mapComponentFullId); //get by Id
    // this.mapContainer = document.getElementById(this.mapComponentFullId); //get by Id (alternative)
    this.mapContainer = this.elRef.nativeElement.querySelector('.map-component'); //get by Class
    this.mapContainerWidth = this.mapContainer.getBoundingClientRect().width;
    this.mapContainerHeight = this.mapContainer.getBoundingClientRect().height;
  }

  public onMapReady(map: L.Map) {
    this.map = map;

    setTimeout(() => {
      this.map.invalidateSize();
    }, 1);

    this.map.setMaxBounds(this.maxBounds);
    this.saveMapDivContainerSize(); // save the current map container div width and height
  }

  public handleClick(ev) {
    // console.log(this.map.mouseEventToLatLng(ev));
  }

  private setOptions(position) {
    for (let key in position) {
      if (typeof position[key] !== "number") {
        try {
          position[key] = Number(position[key]);
          if (Number.isNaN(position[key]))
            return;
        } catch (err) { }
      }
    }
    this.options = {
      zoom: position.zoom ? position.zoom : 4,
      center: L.latLng([+position.lat, +position.lng])
    };
  }
}

// just an interface for type safety.
interface MapOptions {
  zoom: number;
  center: L.LatLng;
}

interface Marker {
  label?: string;
  text?: string;
  description?: string;
  zoom?: number;
  lat: number;
  lng: number;
  draggable: boolean;
}
