import { Component, OnInit, Input } from '@angular/core';

import { JsonParams } from './../../foundations-webct-robot/robot/classes/jsonParams.class';
import { Utils } from './../../foundations-webct-robot/robot/utils/utils.service';


@Component({
  selector: 'survey-horizontal-chart',
  templateUrl: 'custom-survey-horizontal-chart.component.html',
  styleUrls: ['custom-survey-horizontal-chart.component.css']
})
export class SurveyHorizontalChartComponent implements OnInit {

  @Input() public inputParameters: JsonParams;
  @Input() public dataRecs: Object[];
  @Input() public total: number;

  public chartSurveyColors: string[] = [
    '#47b748',
    '#e32e2e',
    '#ffdf01'
  ];

  constructor(private _utils: Utils) {
  }

  public ngOnInit() {

  }

  public chartSurveyTitle = answer => `${answer.option} (${this._percentage(answer.count)})`;

  public chartSurveySize = (currPoint: number) => {
    let totalPoints = 0;

    this.dataRecs.map(obj => totalPoints += obj['count']);

    return `calc(${(
      currPoint *
      100 /
      totalPoints
    ).toString()}%)`
  };

  private _percentage(value: number): string {
    let totalPoints = 0;

    this.dataRecs.map(obj => totalPoints += obj['count']);
    return value.toString() ? ((value / totalPoints) * 100).toFixed(2) + ' %' : '';
  }

}
