import { Input, Component, OnInit } from "@angular/core";

import { Utils } from "./../../foundations-webct-robot/robot/utils/utils.service";
import { GlobalVarsService } from "./../../foundations-webct-robot/robot/utils/global-vars.service";
import { JsonParams } from "./../../foundations-webct-robot/robot/classes/jsonParams.class";
// import { ModalService } from "foundations-webct2-palette/modalComponent/modal.service";
import { ModalService } from "./../../foundations-webct-robot/robot/services/modal.service";

import { AppState } from "../../app.service";

@Component({
  selector: "app-label-start",
  templateUrl: "./labelStartPopUp.component.html",
  styleUrls: ["./labelStartPopUp.component.css"]
})
export class LabelStartComponent implements OnInit {
  @Input() public inputParameters: JsonParams;

  constructor(
    private _globalVars: GlobalVarsService,
    public modalService: ModalService,
    private _utils: Utils,
    private _appService: AppState
  ) {}

  ngOnInit() {}

  public closeNavigateTo() {
    let checkBox = this._globalVars
      .getPageParametersAsArray()
      .find(obj => obj.key === "startModal_showCheckBox");

    if (checkBox && checkBox.value[0] == true) {
      var now = new Date();
      var time = now.getTime();
      var expireTime = time + 3600 * 1000 * 24 * 365 * 10;
      now.setTime(expireTime);

      document.cookie =
        "startDontShowModalAgain=true; expires=" +
        now.toUTCString() +
        "; path=/";
      //localStorage.setItem("startDontShowModalAgain", "true");
    }
    if (this.inputParameters.navigateTo) {
      this._utils.navigate(this.inputParameters.navigateTo);
    }
    let openModal = this.inputParameters.parameters.find(
      obj => obj.key == "openModal"
    );
    if (openModal && openModal.value) {
      this.modalService.openModal(
        openModal.value,
        null,
        null,
        new JsonParams(openModal.id)
      );
      return;
    }
    sessionStorage.setItem("modalStart", "true");
    this.modalService.closeModal();

    /**
     * Validate what browser is.. to advise to use Chrome or FireFox
     */
    this.validateBrowser();

    this._appService.isStartModal = false;
  }

  /**
   * validateBrowser
   */
  public validateBrowser() {
    let validBrowser = true,
      sUsrAg = navigator.userAgent;

    if (sUsrAg.indexOf("Firefox") > -1) {
      validBrowser = true;
    } else if (sUsrAg.indexOf("SamsungBrowser") > -1) {
      validBrowser = false;
    } else if (sUsrAg.indexOf("Opera") > -1 || sUsrAg.indexOf("OPR") > -1) {
      validBrowser = false;
    } else if (sUsrAg.indexOf("Trident") > -1) {
      validBrowser = true;
    } else if (sUsrAg.indexOf("Edge") > -1) {
      validBrowser = false;
    } else if (sUsrAg.indexOf("Chrome") > -1) {
      validBrowser = true;
    } else if (sUsrAg.indexOf("Safari") > -1) {
      validBrowser = false;
    }
    if (!validBrowser) {
      setTimeout(() => {
        let operationValidateBrowser = JSON.parse(
          '{"size":"md", "subType":"fx-modal-xl", "id": "browserValidationPopUp"}'
        );

        this.modalService.openModal(
          "browserValidationPopUp",
          null,
          null,
          operationValidateBrowser
        );
      }, 1000);
    }
  }
}
