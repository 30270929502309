
import {throwError as observableThrowError,  Observable } from 'rxjs';

import {catchError, map} from 'rxjs/operators';
import { Injectable } from "@angular/core";
import { Http, Response, RequestOptions, Headers } from "@angular/http";

@Injectable()
export class ImagesService {

    constructor(private http: Http) {
    }

    uploadPhoto(baseUrl: string, entity: string, id: string, formData: FormData): Observable<any> {
        // TODO definir o endPoint a ser atacado
        let url = baseUrl + '/' + entity + '/' + id + '/upload';
        let options = this.getHeaders(id);

        return this.http
            .post(url, formData, options).pipe(
            map((response: Response) => {
                return <any>response.json();
            }),
            catchError(this.handleError),);
    }

    private handleError(error: any) {
        return observableThrowError(error);
    }

    private getHeaders(id?: string) {
        let headers = new Headers();
        headers.append('Authorization', 'Bearer ' + localStorage.token);
        let options = new RequestOptions({ headers: headers });
        return options;
    }
}