﻿import { Component, Input, OnChanges } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { PageService } from './../../../foundations-webct-robot/robot/pageComponent/page.service';
import { JsonParams } from './../../../foundations-webct-robot/robot/classes/jsonParams.class';
import { Utils } from './../../../foundations-webct-robot/robot/utils/utils.service';
import { ComponentsService } from './../../../foundations-webct-robot/robot/services/components.service';

@Component({
  selector: 'parameters-format',
  templateUrl: 'parameters-format.component.html'
})

export class ParametersFormatComponent implements OnChanges {

  @Input() public inputParameters: JsonParams;
  @Input() public parametersFormat = true;
  @Input() public labelText = '';
  @Input() public inputErrors: string;
  @Input() public labelFor: string;
  @Input() public hidden = false;
  @Input() public formModel: FormGroup;
  @Input() public required = false;
  @Input() public size = 2;
  @Input() public fixedSize = false;
  @Input() public loadingFromTable = false;
  @Input() public datarecs: any;

  public errorMessage = '';
  public showError = false;

  constructor(
    private _utils: Utils,
    private _components: ComponentsService,
    private _pageService: PageService) {

  }

  public ngOnInit() {
    this.labelFor = this._components.getHtmlId(this.inputParameters);
    this.labelText = this._utils.replaceTagVars(this.labelText, this.datarecs);
  }

  public ngOnChanges(changes: any): void {
    if (!changes.inputErrors) {
      return;
    }

    if (changes.inputErrors) {
      let errors: any = changes.inputErrors.currentValue;
      this.errorMessage = '';
      this.showError = false;

      let errorDefs = this._pageService.errorDefs[this.inputParameters.id];

      if (errors && errorDefs) {
        // console.error("parameters-format", errors);
        Object.keys(errorDefs).some((key) => {
          if (errors[key]) {
            this.errorMessage = errorDefs[key];
            this.showError = this.formModel.controls[this.inputParameters.id].invalid && this.formModel.controls[this.inputParameters.id].dirty;
            return true;
          }
        });
      }
    }
  }
}
