import { Component, OnInit, Input } from '@angular/core';
import { GlobalVarsService } from './../../../foundations-webct-robot/robot/utils/global-vars.service';

import { WctNotificationService } from '../wct-notification/wct-notification.service';
import { PageService } from './../../../foundations-webct-robot/robot/pageComponent/page.service';
import { Utils } from './../../../foundations-webct-robot/robot/utils/utils.service';

import { FileDownloadService } from './wct-file-download.service';
import * as FileSaver from 'file-saver';


/*NOTAS:
 Este componente pode receber como parâmetros:
  -obrigatórios:
    -text - texto do link (opcional caso se especifique o "icon"), exemplos:
        "texto hardcoded"
        "texto___do___i18n"
    -icon - icone do link (opcional caso se especifique o "text")
    -url - caminho para o ficheiro, exemplos:
        "/file-management/subscription/files?filename=" (apenas path)
        "/wifim/files/ficheiro_teste.csv" (path, nome do ficheiro e extensão)
        "filePath" (campo da API, com tableAction==true)
  -opcionais:
    -baseUrl - url base, exemplos:
        "{{wifiCoreAPI}}"
        "http://localhost:4200"
    -filename - nome do ficheiro, exemplos:
        "User manual_pt_PT" (sem extensão)
        "UserManual.pdf" (com extensão)
        "filename" (campo da API, com tableAction==true)
    -fileType - extensão do ficheiro, exemplos:
        ".pdf", ".csv", ".zip" (extensão)
        "fileType" (campo da API, com tableAction==true)
    -tableAction - para saber se se está a usar o componente como um link ou tableAction (false por default)
        -false: obtém os dados normalmente, hardcoded ou através de variáveis
        -true: obtém os dados através do dataRecs
    -description - tooltip (usada no icone e texto), exemplos:
        "texto hardcoded"
        "texto___do___i18n"
    -info - descrição que aparece por baixo do link, exemplos:
        "texto hardcoded"
        "texto___do___i18n"
    -label - label que aparece ao lado do link, exemplos:
        "texto hardcoded"
        "texto___do___i18n"
    -successToastType - tipo do toast de sucesso, ex.: "success", "info"
       (por defeito fica com o valor "success")
    -errorToastType - tipo do toast de erro, ex.: "error", "info"
       (por defeito fica com o valor "error")
    -successMessageTitle - titulo do toast de sucesso
    -successMessage - mensagem do toast de sucesso
    -errorMessageTitle - titulo do toast de erro
    -errorMessage - mensagem do toast de erro
 Notas:
  -o toast de erro é sempre mostrado.
  -para não mostrar o toast de sucesso, basta não especificar o titulo e a mensagem.
  -com o tableActions==true, o "filename" deixa de aceitar um valor hardcoded (aceita apenas valor vazio ou
    da API). Neste caso o "filename" e fileType viriam incluidos no "url".
*/

@Component({
    selector: 'app-wct-file-download',
    template: `
    <div class="form-group div-as-table">
        <div class="tr">
            <div *ngIf="label" class="control-label td fx-required-field">
                <label>{{label}}</label>
            </div>
            <div class="td">
                <div *ngIf="!errorFlag" (click)="downloadFile()"
                [class]="text != null ? 'download-file-link-div-underline' : 'download-file-link-div'"
                id="{{inputParameters.id}}">
                    <i [class]="text != null ? icon : icon + ' cursor-pointer'"
                        id="{{inputParameters.id}}-icon"
                        title="{{description}}"></i>
                    <span *ngIf="!tableAction"
                        id="{{inputParameters.id}}-text"
                        title="{{description}}">{{text}}</span>
                </div>
                <div *ngIf="info" class="info-style">
                    {{info}}
                </div>
            </div>
        </div>
    </div>


        <div *ngIf="errorFlag" id="{{inputParameters.id}}">
            <span class="download-link-error-flag">
                Error: missing parameters (see console)!
            </span>
        </div>
    `,
    styles: [`
        .cursor-pointer {
            cursor: pointer;
        }
        .download-file-link-div {
            width: max-content;
        }
        .download-file-link-div-underline {
            width: max-content;
            cursor: pointer;
            color: #007180;
        }
        .download-file-link-div-underline:hover {
            text-decoration: underline;
            color: #00444d;
        }
        .download-link-error-flag {
            color: red;
        }
        .info-style {
            font-size: 0.9em;
            color: #888;
            padding-top: 3px;
        },
        .div-as-table{ display: table; padding: 0; margin: 10; width: 100%; }
        .div-as-table .tr{ display: table-row; }
        .div-as-table .tr .td{ display: table-cell; vertical-align: middle; }
        .div-as-table .tr .td.control-label{
            width: 160px; min-width: 160px; max-width: 160px;
            text-align: right; padding-right: 15px; vertical-align: top;
            padding-top: 6px; padding-bottom: 6px; }
        .div-as-table .tr .td.control-label,
        .div-as-table .tr .td .form-control-static{ padding-top: 6px; padding-bottom: 6px; }
        .div-as-table .tr .td label{ margin: 0; }
        .div-as-table .tr .td.control-label label{ text-overflow: ellipsis; overflow: hidden; }
    `]
})
export class WctFileDownloadComponent implements OnInit {
    @Input() inputParameters;
    @Input() inputDataRecs;
    @Input() dataRecs;
    @Input() text = null; // texto do link de download (opcional)
    @Input() icon = null; // icone do link de download (opcional)
    @Input() baseUrl = null; // "{{wifiCoreAPI}}"" ou "http://localhost:4200"
    @Input() url = null; // "/file-management/service-subscriptions/[[subscriptionId]]/files?filename=" ou "/files/ficheiro.txt"
    @Input() filename = null; // "User manual_pt_PT" ou "User_manual.pdf"
    @Input() fileType = null; // ".pdf"


    private HEADER_CONTENT_TYPE = 'content-type';
    private HEADER_CONTENT_DISPOSITION = 'content-disposition';
    private FILE_NAME_PROPERTY = 'filename';
    private GET_NAME_BY_HEADERS = false;

    public description; //texto da tooltip usada no icone (opcional)
    public info; //descrição que aparece por baixo do link (opcional)
    public label; //label lateral (opcional)
    public tableAction = false; //identificar se o componente está instanciado como se fosse um tableAction

    //nota: as variáveis "url", "filename" e fileType" também podem receber um campo da API, desde que a
    // variável "tableAction" esteja a true

    public successToastType; //tipo do toast de sucesso (success/info)
    public errorToastType; //tipo de toast de erro (error/info)
    public successMessageTitle; //titulo do toast de sucesso
    public successMessage; //mensagem do toast de sucesso
    public errorMessageTitle; //titulo do toast de erro
    public errorMessage; //mensagem do toast de erro

    public errorFlag = false; //caso não sejam recebidos os 4 parâmetros necessários para fazer o request

    constructor(
        public _globalVars: GlobalVarsService,
        public pageService: PageService,
        public utils: Utils,
        public notificationService: WctNotificationService,
        public fileDownloadService: FileDownloadService) { }

    /*inicializar as variáveis dos toasts*/
    public initializeToasts() {
        // inicializar variáveis dos tipos de toast (se não receber nada, fica com os valores default)
        this.successToastType = this.inputParameters.mockJson.successToastType;
        if (!this.successToastType) { this.successToastType = 'success'; }

        this.errorToastType = this.inputParameters.mockJson.errorToastType;
        if (!this.errorToastType) { this.errorToastType = 'error'; }

        // texto e titulo dos toasts (sucesso e erro)
        this.successMessageTitle = this.inputParameters.mockJson.successMessageTitle;
        this.successMessage = this.inputParameters.mockJson.successMessage;
        this.errorMessageTitle = this.inputParameters.mockJson.errorMessageTitle;
        this.errorMessage = this.inputParameters.mockJson.errorMessage;
    }

    /*mostra um toast dependendo do tipo recebido
        (toastType: success/error/info) e da operação (success, error)*/
    public showToast(toastType, operation) {
        let toastTitle;
        let toastMessage;
        //filtrar a mensagem do toast pelo tipo de operação (successo ou erro no request)
        if (operation === 'success') {
            toastTitle = this.successMessageTitle ? this.pageService.i18n(this.successMessageTitle) : '';
            toastMessage = this.successMessage ?
                this.pageService.i18n(this.successMessage) :
                this.pageService.i18n("generic___operations___downloadToasts___successMessage");
        } else { //operation == 'error'
            toastTitle = this.errorMessageTitle ?
                this.pageService.i18n(this.errorMessageTitle) :
                this.pageService.i18n("generic___operations___downloadToasts___errorTitle");

            toastMessage = this.errorMessage ?
                this.pageService.i18n(this.errorMessage) :
                this.pageService.i18n("generic___operations___downloadToasts___errorMessage");
        }
        switch (toastType) {
            case 'success':
                // mostrar toast de sucesso
                this.notificationService.showSuccess(
                    toastTitle,
                    toastMessage,
                    { timeout: { success: 5000 }, positionClass: { success: 'top-center' }, limit: { success: 1 } }
                );
                break;
            case 'error':
                // mostrar toast de erro
                this.notificationService.showError(
                    toastTitle,
                    toastMessage,
                    { timeout: { error: 5000 }, positionClass: { error: 'top-center' }, limit: { error: 1 } }
                );
                break;
            case 'info':
                // mostrar toast de info
                this.notificationService.showInfo(
                    toastTitle,
                    toastMessage,
                    { timeout: { info: 9000 }, positionClass: { info: 'top-center' }, limit: { info: 1 } }
                );
                break;
            default:
                // não mostra toast
                break;
        }
    }

    public downloadFile() {
        const downloadedFile = this.fileDownloadService.downloadFile(this.url, this.baseUrl, this.filename, this.fileType).subscribe(
            (resp: any) => {
                console.log('SAVE FILE ', resp, this.url, this.baseUrl, this.filename, this.fileType);

                // construir o nome do ficheiro
                const blobFilename = this._getFileName(resp);

                // criar um blob do ficheiro recebido
                const blob = new Blob([resp.body]);
                FileSaver.saveAs(blob, blobFilename); //guardar o ficheiro

                if (this.successMessage) {
                    this.showToast(this.successToastType, 'success');
                }
            },
            (error) => {
                console.error('download request failed..');
                console.error('error: ', error);

                this.showToast(this.errorToastType, 'error');
            }
        );
    }

    public ngOnInit() {
        // text
        this.text = this.utils.replaceTagVars(
            this.text ?
                this.pageService.i18n(this.text) :
                (this.inputParameters.text ? this.pageService.i18n(this.inputParameters.text) : this.text)
        );

        // icon
        if (!this.icon) { this.icon = this.inputParameters.icon; }
        // verificar se não foi especificado nem o texto nem o icone
        if (!this.text && !this.icon) {
            console.error('Error: You need to specify at least one of the following parameters: "text", "icon"!');
            this.errorFlag = true;
            return;
        }

        // description (tooltip)
        this.description = this.inputParameters.mockJson.description;
        if (this.description) { this.description = this.pageService.i18n(this.description); }

        // info (description)
        this.info = this.inputParameters.mockJson.info;
        if (this.info) { this.info = this.pageService.i18n(this.info); }

        // label
        this.label = this.inputParameters.mockJson.label;
        if (this.label) { this.label = this.pageService.i18n(this.label); }

        // obter os dados do mock
        if (!this.baseUrl) { this.baseUrl = this.inputParameters.mockJson.baseUrl; }
        if (this.baseUrl) { this.baseUrl = this.pageService.getUrlFromConfig(this.utils.replaceTagVars(this.baseUrl)); }

        if (!this.url) { this.url = this.inputParameters.mockJson.url; }
        if (!this.url) { // nota: esta verificação pode não ser necessária
            console.error('Error: This component requires at least 1 parameter: "url"!');
            this.errorFlag = true;
            return;
        }
        this.url = this.utils.replaceTagVars(this.url);

        if (!this.filename) { this.filename = this.inputParameters.mockJson.filename; }

        // this.filename = this.utils.replaceTagVars(this.filename);

        if (!this.fileType) { this.fileType = this.inputParameters.mockJson.fileType; }
        this.fileType = this.utils.replaceTagVars(this.fileType) || '';

        // Boolean if get File Name from Headers
        // tslint:disable-next-line: max-line-length
        this.GET_NAME_BY_HEADERS = this.inputParameters.mockJson.useHeaderFileName ? this.inputParameters.mockJson.useHeaderFileName : false;

        this.fileType = this.utils.replaceTagVars(this.fileType) || '';

        // verificar se o componente está a ser instanciado como se fosse um tableAction
        // nota: o tableAction está a "false" por default
        if (this.inputParameters.mockJson.tableAction) { this.tableAction = this.inputParameters.mockJson.tableAction; }
        // obter os valores da API através do dataRecs (apenas caso se use o componente como tableAction)
        if (this.tableAction == true) {
            // nota: o baseUrl não é obtido da API (apenas se obtém o url, filename e filetype)

            // apenas obtém o url do dataRecs caso o url que esteja definido no mock não tenha "/", ou seja,
            // não obtém o url do dataRecs caso já o receba hardcoded no mock
            if (this.url.indexOf('/') <= -1) {
                this.url = this.dataRecs[this.url];
            }

            this.filename = this.dataRecs[this.filename]; // devolve undefined caso o campo não exista

            // apenas obtém o fileType do dataRecs caso o fileType que esteja definido no mock não tenha ".",
            // ou seja, não obtém o fileType do dataRecs caso já o receba hardcoded no mock
            if (this.fileType && this.fileType.indexOf('.') === -1) {
                this.fileType = this.dataRecs[this.fileType];
            }
        }

        // processar a informação relativa aos toasts de sucesso e erro
        this.initializeToasts();

    }

    private _getFileName(response: any): string {
        let fileName;
        if (this.GET_NAME_BY_HEADERS) {
            try {
                fileName = this._parseHeaderContent(response.headers.get(this.HEADER_CONTENT_DISPOSITION))[this.FILE_NAME_PROPERTY];
            } catch (error) {
                fileName = `shero-file${this.fileType}`;
            }
        }
        else {
            fileName = !this.filename ? this.url.split('/').pop() : `${this.filename}${this.fileType}`;
        }
        return fileName;
    }

    private _parseHeaderContent(headerContent: string, separator: string = ';') {
        // separa propriedade nome / valor
        let headerContentObj = new Object();
        headerContent.split(separator).forEach(
            prop => {
                const propKeyValue = prop.split('=');
                headerContentObj[propKeyValue[0]] = propKeyValue.length === 2 ? propKeyValue[1] : null;
            }
        );

        return headerContentObj;
    }
}
