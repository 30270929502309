import { Injectable } from '@angular/core';

import { JsonParams } from '../classes/jsonParams.class';
import { Utils } from '../utils/utils.service';

@Injectable()
export class FilterService {

  private _filterConfig: Object = {};
  private _filterStatus: any = {};

  constructor(
    private _utils: Utils) {

  }

  public setFilterConfig(config: JsonParams, componentId: string): void {
    this._filterConfig[componentId] = config;
  }
  public getFilterConfig = (componentId?: string): JsonParams => componentId ? this._filterConfig[componentId] : this._filterConfig;
  public deleteFilterConfig(componentId: string): void {
    if (this._filterConfig[componentId])
      delete this._filterConfig[componentId];
  }

  public getFilterConfigById(id: string): JsonParams[] {

    let sideBar = this._utils.getObjectValues(this.getFilterConfig()).find(obj => obj.mappingId.indexOf(id) >= 0);
    if (!sideBar || !sideBar.groups.details)
      return;

    return sideBar.groups.details.parameters;
  }

  public getFilterStatus = (id: string) => this._filterStatus[id];
  public toggleFilterStatus = (id: string) => this._filterStatus[id] = !this._filterStatus[id];
  public deleteFilter = (id: string) => delete this._filterStatus[id];

  public hideFilter = (id?: string) => {
    if (id) {
      this._filterStatus[id] = false;
      return;
    }
    this._filterStatus = {};
  }

  public getFilterFields(fields: JsonParams[]): JsonParams[] {

    let filterFields: JsonParams[] = [];
    for (let i in fields) {
      if (!fields[i].filter)
        continue;

      let tempField: JsonParams;
      if (typeof fields[i].filter == 'object') {
        tempField = fields[i].filter;
        filterFields.push(tempField);
      } else {
        tempField = new JsonParams();
        for (let p in fields[i])
          tempField[p] = fields[i][p];
        tempField.type = fields[i].filter == 'dates' || fields[i].filter == 'dateTime' ? 'dates-interval' : 'textbox';
        tempField.subType = fields[i].filter == 'dateTime' ? 'dateTime' : '';
        tempField.value = '';
        filterFields.push(tempField);
      }
    }

    return filterFields;
  }

  public getFilterObject = (filterFields: JsonParams[]): Object => this._utils.getFilterObject(filterFields);
  public transformInitFilterValue = (param: JsonParams): any => this._utils.transformInitFilterValue(param);

}
