import { Component, OnInit } from '@angular/core';
import { RobotEngineModel } from './../../../foundations-webct-robot/robot/robotEngineModel';
import { Utils } from './../../../foundations-webct-robot/robot/utils/utils.service';
import { GlobalVarsService } from './../../../foundations-webct-robot/robot/utils/global-vars.service';
import { PageService } from './../../../foundations-webct-robot/robot/pageComponent/page.service';
import { InputService } from './../../../foundations-webct-robot/robot/services/input.service';
import { InputTextboxComponent } from '../textboxComponent/textbox.component';
import { FormControl } from '@angular/forms';
import { Subject } from 'rxjs/Subject';
import { TextboxUnicityValidatorService } from './unicity-validator.service';
import { ComponentsService } from './../../../foundations-webct-robot/robot/services/components.service';

@Component({
  selector: 'textbox-unicity-validator',
  templateUrl: 'unicity-validator.component.html'
})

export class TextboxUnicityValidatorComponent extends InputTextboxComponent implements OnInit {

  public keyword: Subject<string>;

  constructor(
    public inputService: InputService,
    public components: ComponentsService,
    public pageService: PageService,
    public globalVars: GlobalVarsService,
    public utils: Utils,
    public robot: RobotEngineModel,
    private _unicityService: TextboxUnicityValidatorService,
    private _inputService: InputService
  ) {
    super(inputService, pageService, globalVars, utils, robot, components);
  }

  public ngOnInit() {
    this.keyword = new Subject<string>();

    if (!this.control) this.control = new FormControl();

    this._setNoRecordsValidation();

    this.inputParameters.value = this.inputService.formatMyValue(
      this.inputParameters.originalValue || this.inputParameters.value,
      false,
      {},
      false,
      this.globalVars.getPageParametersAsArray()
    );

    this._unicityService.searchApiByKeyword(this.keyword, this.inputParameters, this.control).subscribe((results) => {

      const forkJoin: boolean = !!this.utils.findObjectInArray(this.inputParameters.validator.unique.value['urlResource'], 'forkJoin').value || false;
      if (forkJoin) {
        let forkJoinData: any[] | null = [];
        for (let i in results) {
          let valueList: any[] = this.utils.getValueFromDataForThisKey(results[i], this.inputParameters.pathToValueList);
          if (valueList)
            forkJoinData = forkJoinData.concat(valueList);
        }
        if (forkJoinData.length === 0)
          forkJoinData = null;
        this._inputService.loadValueListFromDataRecs(this.inputParameters, results, forkJoinData);
        this._evaluateNoRecords()

      } else {
        this._inputService.loadValueListFromDataRecs(this.inputParameters, results);
        this._evaluateNoRecords(results);
      }
    });

    const lastInputValue: string = this.inputParameters.value;

    try {
      this.control.valueChanges.debounceTime(500).subscribe((val: string) => {
        if (this.control.dirty && lastInputValue != val) {
          this._robot.findDynamicPropsDependencies(this.inputParameters.id);
          this.components.updatePageParameters(this.inputParameters.parameters);
        }
      });
      /* this.control.valueChanges.debounceTime(1000).subscribe((val: string) => {
        this._evaluateNoRecords();
      }); */
    } catch (e) {
      // console.log('e ---> ', e);
    }
  }

  protected _evaluateNoRecords(results?: any) {

    let value;
    if (results && this.inputParameters.validator.unique.value['pathToValue'])
      value = this.utils.getValueFromDataForThisKey(results, this.inputParameters.validator.unique.value['pathToValue']);

    if (
      (this.inputParameters.validator.unique.value['pathToValue'] && !value) ||
      (this.inputParameters.pathToValueList && this.inputParameters.valueList && this.inputParameters.valueList.length > 0)) {
      this.control.setErrors(Object.assign(this.control.errors || {}, { unique: true }));
    } else if (this.control.errors)
      delete this.control.errors['unique'];
  }

  protected _setNoRecordsValidation() {
    if (!this.pageService.errorDefs[this.inputParameters.id])
      this.pageService.errorDefs[this.inputParameters.id] = {};
    this.pageService.errorDefs[this.inputParameters.id]['unique'] = this.inputParameters.validator.unique.description;
  }
}
