import { Injectable } from '@angular/core';

import { GlobalVarsService } from '../utils/global-vars.service';
import { JsonParams } from '../classes/jsonParams.class';
import { Utils } from '../utils/utils.service';
import { RobotEngineModel } from '../robotEngineModel';

@Injectable()
export class ComponentsService {

  private idTag = '{{id}}';
  private _componentIdPrefixByType: Object = {
    initTable: 'table',
    textbox: 'input-textbox',
    textarea: 'input-textarea',
    colorpicker: 'input-colorpicker',
    date: 'date-picker-' + this.idTag + '-open',
    dateTime: 'date-time-picker-' + this.idTag + '-open',
    'input-radio': 'radio-option',
    'input-checkbox': 'checkbox-option',
    'input-select': 'select'
  };

  constructor(
    private _utils: Utils,
    private _robot: RobotEngineModel,
    private _globalVars: GlobalVarsService) { }

  public getHtmlId(parameter: JsonParams, type?: string, wct: boolean = true): string {
    let prefix = type || this._componentIdPrefixByType[parameter.subType] || this._componentIdPrefixByType[parameter.type] || 'component';
    let id = parameter.id || parameter.internalId;

    return (wct ? 'wct-' : '') + (prefix.indexOf(this.idTag) >= 0 ? prefix.replace(this.idTag, id) : prefix + '-' + id);
  }

  public updatePageParameters(parameters: JsonParams[], dataRecs?: Object) {
    let groupPageParams: Object[] = [];
    let updatePageParameter = this._utils.findObjectInArray(parameters, 'updatePageParameter');
    let uniqueId: string[];
    if (updatePageParameter.value) {
      uniqueId = updatePageParameter.internalId ? updatePageParameter.internalId.match(this._utils.uniqIdRegex) : null;
      groupPageParams.push(updatePageParameter.value);
    }

    // TODO: Remover esta opção (updateWizard) e usar apenas a de cima (updatePageParameter)
    let updateWizardParameter = this._utils.findObjectInArray(parameters, 'updateWizard').value;
    if (updateWizardParameter) {
      console.error('WARNING (DEPRECATED): "updateWizard" is deprecated. Use "updatePageParameter" instead');
      groupPageParams.push(updateWizardParameter);
    }

    for (let updateData of groupPageParams)
      this.updateParameterById(updateData, dataRecs, uniqueId ? uniqueId[0] : null);
  }

  public updateParameterById(updateData: Object, dataRecs?: Object, uniqueId?: string) {

    for (let i in updateData) {
      let parameter = this._globalVars.getPageParameter(i + uniqueId) || this._globalVars.getPageParameter(i);
      if (parameter) {
        for (let p in updateData[i]) {
          // if (p == 'lazyLoading' && parameter[p] === false && updateData[i][p] === false) {
          //   this._utils.cleanApiGetHistory(parameter);
          //   parameter.refresh();
          // } else {

          // Avalia se o valor a atribuir é um objecto. Se for, transform-o numa string de forma a ser tratado antes da atribuição
          let valueIsObject = false;
          if (updateData[i][p] && typeof updateData[i][p] == 'object') {
            updateData[i][p] = JSON.stringify(updateData[i][p]);
            valueIsObject = true;
          }

          let valueForAttr: any;
          if (updateData[i][p] && typeof updateData[i][p] == 'string' && updateData[i][p].match(this._utils.mustacheReg)) {
            let tmp = this._utils.replaceTagVars(updateData[i][p], [dataRecs, this._utils.arrToObj(this._globalVars.getPageParametersAsArray())], uniqueId);

            try {
              // Para quando o resultado é um Objecto "stringifado"
              valueForAttr = JSON.parse(tmp);
            } catch (e) {
              valueForAttr = /* tmp.match(this._utils.mustacheReg) ? null :  */tmp;
            }

          } else
            valueForAttr = updateData[i][p];

          if (!valueIsObject) {

            let hasDecorator = this._utils.findDecorator(valueForAttr);
            if (hasDecorator)
              valueForAttr = eval(this._utils.prepareStringForEval(hasDecorator));

            if (p == 'lazyLoading' && parameter.lazyLoading === false && valueForAttr === false)
              parameter.refresh();
            else
              parameter.setPropertyValue(p, valueForAttr);

            // parameter.setPropertyValue(p, valueForAttr);
            continue;
          }

          try {
            valueForAttr = JSON.parse(valueForAttr);
          } catch (e) { }

          // if (p == 'lazyLoading' && parameter.lazyLoading === false && valueForAttr === false)
          //   parameter.refresh();
          // else
          parameter.setPropertyValue(p, valueForAttr);

          // parameter.setPropertyValue(p, JSON.parse(valueForAttr));
          updateData[i][p] = JSON.parse(updateData[i][p]);
          // }
        }
      }
    }
  }

  public evaluateValueList(parameter: JsonParams, componentId): void {

    if (!parameter.valueList)
      return;

    let newValueList = parameter.valueList.map(obj => {

      if (!this._robot.validateRoles(obj.role))
        return;
      if (obj instanceof JsonParams)
        return obj;

      let jsonParam: JsonParams = new JsonParams();
      let keyValueFormat: boolean = !('id' in obj);

      jsonParam.internalId = this._utils.guid(2, '');
      jsonParam.id = (keyValueFormat ? obj.key : (obj.id || jsonParam.internalId)).toString();
      jsonParam.value = keyValueFormat ? obj.key : obj.value;
      jsonParam.text = keyValueFormat ? obj.value : obj.text;
      jsonParam.title = obj.title;
      jsonParam.dynamicProps = obj.dynamicProps;
      jsonParam.data = obj.data;
      jsonParam.icon = obj.icon;

      this._globalVars.setPageParameters(jsonParam, componentId);

      return jsonParam;
    });

    if (!this._isValueListEqual(parameter, newValueList)) {
      parameter.valueList = newValueList.filter(obj => obj);
      parameter.data = { lastValueListUpdate: this._utils.cloneObject(parameter.valueList) }
      parameter.resetValue();
    }
  }

  private _isValueListEqual(parameter: JsonParams, newValueList: JsonParams[]): boolean {
    let transformValueList = (val: JsonParams[]) => val ? val.map(obj => new Object({ key: obj.value, value: obj.text })) : [];
    return JSON.stringify(transformValueList(newValueList)) === JSON.stringify(transformValueList(parameter.data.lastValueListUpdate));
  }
}
