import { Component, Input, Output, EventEmitter, OnInit } from '@angular/core';
import { Utils } from './../../../foundations-webct-robot/robot/utils/utils.service';
import { JsonParams } from './../../../foundations-webct-robot/robot/classes/jsonParams.class';

@Component({
  selector: 'sidebar-toggle-rows-component',
  templateUrl: 'sidebar-toggle-rows.component.html'
})

export class SidebarToggleRowsComponent implements OnInit {

  @Input() public inputParameters: JsonParams[];
  @Input() public dataRecs: Object[];
  @Input() public showComponentByIndex = 0;

  @Output() public changeComponentByIndex = new EventEmitter();

  public toggleOptions: any[] = [];

  constructor(
    private _utils: Utils) {
  }

  public ngOnInit() {
    for (let i in this.dataRecs) {
      this.toggleOptions[i] = [];
      for (let p in this.inputParameters) {
        this.toggleOptions[i].push({
          name: this.inputParameters[p].text,
          value: this._utils.getValueFromDataForThisKey(this.dataRecs[i], this.inputParameters[p].pathToValue, true)
        });
      }
    }
  }

  public showComponentByIndexEvent(elementIndex: number) {
    this.showComponentByIndex = elementIndex;
    this.changeComponentByIndex.emit(elementIndex);
  }
}
