import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';

import { JsonParams } from './../../../../foundations-webct-robot/robot/classes/jsonParams.class';
import { ComponentsService } from './../../../../foundations-webct-robot/robot/services/components.service';
import { RobotEngineModel } from './../../../../foundations-webct-robot/robot/robotEngineModel';

@Component({
  selector: 'app-wct-table-checkbox',
  templateUrl: './wct-table-checkbox.component.html',
  styleUrls: ['./wct-table-checkbox.component.css']
})
export class WctTableCheckboxComponent implements OnInit {

  @Input() public viewStructure: JsonParams;
  @Input() public column: JsonParams;
  @Input() public dataRecs: any;
  @Input() public isRowSelected: boolean = false;
  @Input() public rowIndex: number;

  @Output() public toggleRowsSelection: EventEmitter<any> = new EventEmitter();

  public checkbox: JsonParams;

  constructor(
    private _component: ComponentsService,
    private _robot: RobotEngineModel) {

  }

  public getHtmlId = (param: JsonParams, type?: string, wct: boolean = true) => this._component.getHtmlId(param, type, wct);
  public click = () => this.toggleRowsSelection.emit(true);

  public ngOnInit() {
    this.checkbox = this.column.clone();
    this._robot.changeParameterByDynamicProps(this.checkbox, this.dataRecs);
  }

}
