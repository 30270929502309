﻿import { HostListener, Directive, Input } from '@angular/core';

@Directive({
    selector: '[href]'
})
export class PreventDefaultLinkDirective {

    @Input() public href;
    @HostListener('click', ['$event']) public onClick(event) { this.preventDefault(event); }

    private preventDefault(event) {
        if (this.href.length === 0 || this.href === '#') {
            event.preventDefault();
        }
    }
}
