// import { ModalService } from './../../foundations-webct2-palette/components/modalComponent/modal.service';
import { ModalService} from '../../foundations-webct-robot/robot/services/modal.service';

import { CustomEventManagerService } from '../../customComponents/custom-event-manager.service';
import { TestService } from '../test.service';
import { Component, Input, OnInit, OnDestroy, AfterViewInit } from '@angular/core';
import { PageService } from '../../foundations-webct-robot/robot/pageComponent/page.service';


@Component({
  selector: 'test-testSet-manager',
  templateUrl: './main-testSet.component.html',
  styleUrls: ['./main-testSet.component.css']
})
export class MainTestSetComponent implements OnInit, OnDestroy, AfterViewInit {



  /* @Input()  */

  public selectedTestSet:any;

  public newTestSetCreatedSubscription;
  

  constructor(
    public pageService: PageService,
    public testsService: TestService,
    private eventManager: CustomEventManagerService,
    private modalService: ModalService) {
  }

  public ngOnInit() {

    //this.editMode = "VIEW";

    //load program to model
    //this.testsService.setProgram(this.program);
/*     this.testsService.reloadProgram().then(response => {
      this.program = this.testsService.program;
    }); */

    this.testsService.reloadTestsScenario();

    this.newTestSetCreatedSubscription = this.eventManager.newTestSetCreated.subscribe(
      () => {
        let last = this.testsService.exectutions.length - 1;
        this.currentTestSet = this.testsService.exectutions[last];
      }
    );


  }

  public currentTestSet(data) {
    console.log("datasssssss: ", data);
    this.selectedTestSet = data;
    
  }

  ngAfterViewInit(): void {



  }
  cancelEdit() {
    
  }



  public testSet(data) {
    //this.currentTestset = data[0];
  }


  ngOnDestroy(): void {
    
  }
}
