import { Component, OnInit, Input, EventEmitter, } from '@angular/core';

import { newEvent, JsonParams } from './../../../foundations-webct-robot/robot/classes/jsonParams.class';
import { PageService } from './../../../foundations-webct-robot/robot/pageComponent/page.service';
import { Utils } from './../../../foundations-webct-robot/robot/utils/utils.service';
import { UploadService } from './wct-upload.service';
import { WctNotificationService } from '../wct-notification/wct-notification.service';
import { RobotEngineModel } from './../../../foundations-webct-robot/robot/robotEngineModel';
import { ComponentsService } from './../../../foundations-webct-robot/robot/services/components.service';

@Component({
  selector: 'app-wct-file-upload',
  template: '<input type="file" (change)="onFileChanged($event)" #fileInput>'
})
export class WctUploadComponent implements OnInit {

  @Input() inputParameters;
  @Input() inputDataRecs;
  @Input() dataRecs;

  private REQUEST_ENDPOINT_KEY = 'requestEndpoint';
  private REQUEST_BODY_KEY = 'requestBody';

  private TOAST_TYPE_INFO = 'info';
  private TOAST_TYPE_SUCCESS = 'success';
  private TOAST_TYPE_ERROR = 'error';

  private basePath = '{{SchoolBE}}';
  private baseUrl;

  public readonly: boolean = true;
  private requestEndpointObj: JsonParams;
  private requestBodyObj: JsonParams;
  private uploadFormData: FormData;
  private fileTypeAllowed: string;

  private _newEvent: EventEmitter<newEvent[]>;

  constructor(
    private _robot: RobotEngineModel,
    public pageService: PageService,
    public utils: Utils,
    private _components: ComponentsService,
    public _notificationService: WctNotificationService,
    private uploadService: UploadService) {
  }

  public ngOnInit() {

    this.readonly = this.inputParameters.readonly === undefined ? false : this.inputParameters.readonly;
    this._newEvent = this.inputParameters.newEvent().subscribe(data => this._handleEvent(data));
    //get activitylog url
    //let requestsBasePath = "{{AppAPIBotConfig}}/requests";
    //this.requestsUrl = this.pageService.getUrlFromConfig(this.utils.replaceTagVars(requestsBasePath, this.inputDataRecs));
    this.requestEndpointObj = this.utils.findObjectInArray(this.inputParameters.parameters, this.REQUEST_ENDPOINT_KEY);
    this.baseUrl = this.pageService.getUrlFromConfig(
      this.utils.replaceTagVars(
        this.requestEndpointObj ? this.requestEndpointObj.value : this.basePath,
        this.inputDataRecs
      )
    );

    this.requestBodyObj = this.utils.findObjectInArray(this.inputParameters.parameters, this.REQUEST_BODY_KEY);
    this.fileTypeAllowed = this.requestBodyObj ? this.requestBodyObj.type : null;
  }

  public onFileChanged(event) {

    if (this.readonly) { return; }

    this.pageService.operationLoading = true;
    const file = event.target.files[0];

    try {
      if (this.fileTypeAllowed && file.type != this.fileTypeAllowed) {
        this.pageService.operationLoading = false; //desactivar o loading

        let messageCustom = "";
        switch (this.fileTypeAllowed) {
          case "application/x-zip-compressed":
            messageCustom = this.pageService.i18n("errors___upload___invalidFileTypes___zipCompressed");
            break;
          case "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet":
            messageCustom = this.pageService.i18n("errors___upload___invalidFileTypes___excel");
            break;

          default:
            messageCustom = this.fileTypeAllowed;
            break;
        }

        //mostrar toast de erro
        let uploadErrorMessage = this.pageService.i18n("errors___upload___invalidFileType") + messageCustom;
        this._notificationService.showError(null,
          uploadErrorMessage,
          { timeout: { error: -1 }, positionClass: { error: 'top-center' }, limit: { error: 1 } }
        );
        return;
      }

      this.uploadFormData = new FormData();
      this.uploadFormData.append('type', this.fileTypeAllowed || 'text/xml');
      // add body properties to formData
      if (this.requestBodyObj && this.requestBodyObj.value) {
        const requestBody = this.requestBodyObj.value;
        for (let k in requestBody) {
          if (requestBody.hasOwnProperty(k)) {
            //this.uploadFormData.append(k, requestBody[k] === '{{file}}' ? file : requestBody[k]);
            this.uploadFormData.append(k, requestBody[k] === '{{file}}' ? file :this.utils.replaceTagVars(requestBody[k], null));
          }
        }
      } else {
        this.uploadFormData.append('file', file);
      }

      this.inputParameters.value = file['name'];
      this._robot.findDynamicPropsDependencies(this.inputParameters.id);
      this._components.updatePageParameters(this.inputParameters.parameters);

    } catch (error) {
      console.error('onFileChanged ERROR: ', error);
    }

    this.pageService.operationLoading = false;
  }

  public submit() {
    if (!this.uploadFormData) {
      this._showToast(this.TOAST_TYPE_ERROR, 'errors___upload___noFileSelected');
      return;
    }

    this.pageService.operationLoading = true;
    this.uploadService.uploadFile(this.baseUrl, this.uploadFormData)
      .subscribe(
        response => {
          this.pageService.operationLoading = false;
          this._showToast(this.TOAST_TYPE_SUCCESS, 'success___uploadFile');
          //create activity log
          /*  this.activityLogService.logActivity(this.requestsUrl, 'FILE', 'FILE_UPLOAD', 'parentEntityId', 'entity', 'entityId', true)
             .subscribe(
               response => {
                 this.pageService.operationLoading = false;
               },
               error => {
                 this.pageService.operationLoading = false;
               }
             ); */
        },
        error => {

          //create activity log
          /*  this.activityLogService.logActivity(this.requestsUrl, 'FILE', 'FILE_UPLOAD', 'parentEntityId', 'entity', 'entityId', false)
             .subscribe(
               response => {
                 this.pageService.operationLoading = false;
               },
               error => {
                 this.pageService.operationLoading = false;
               }
             ); */
          let errorMsg: any;
          try {

            errorMsg = JSON.parse(error._body);

            errorMsg.msg ? errorMsg = errorMsg['msg'] : errorMsg = 'errors___upload___genericError';

          } catch (error) {
            errorMsg = 'errors___upload___genericError';
          }

          switch (error.status) {
            case 403: {
              if (JSON.parse(error._body.msg) === 'FILES_SIZE_LIMIT_REACHED') {
                this._showToast(this.TOAST_TYPE_ERROR, 'errors___upload___limitReached');
              }
              break;
            }
            case 409: {
              this._showToast(this.TOAST_TYPE_ERROR, 'errors___upload___existingFile');
              break;
            }
            default: {
              this._showToast(this.TOAST_TYPE_ERROR, errorMsg);
            }
          }
          this.pageService.operationLoading = false;
        }
      );
  }

  /**
   * Trata os eventos que chegam ao componente
   * @param data é o array de eventos que foram emitidos
   */
  private _handleEvent(data: newEvent[]) {
    data.forEach(event => {
      if (event.key === 'uploadSubmit') {
        this.submit();
      }
    });

  }

  private _showToast(toastType: string, i18nToastMessage: string, i18nToastTitle?: string) {
    const toastTitle = i18nToastTitle ? this.pageService.i18n(i18nToastTitle) : null;
    const toastMessage = this.pageService.i18n(i18nToastMessage);

    switch (toastType) {
      case this.TOAST_TYPE_SUCCESS:
        // mostrar toast de sucesso
        this._notificationService.showSuccess(
          toastTitle,
          toastMessage,
          { timeout: { success: 5000 }, positionClass: { success: 'top-center' }, limit: { success: 1 } }
        );
        break;
      case this.TOAST_TYPE_ERROR:
        // mostrar toast de erro
        this._notificationService.showError(
          toastTitle,
          toastMessage,
          { timeout: { error: 5000 }, positionClass: { error: 'top-center' }, limit: { error: 1 } }
        );
        break;
      case this.TOAST_TYPE_INFO:
        // mostrar toast de info
        this._notificationService.showInfo(
          toastTitle,
          toastMessage,
          { timeout: { info: 9000 }, positionClass: { info: 'top-center' }, limit: { info: 1 } }
        );
        break;
      default:
        // não mostra toast
        break;
    }
  }

}