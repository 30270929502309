﻿import { forwardRef } from '@angular/core';
import { FormsModule, NG_VALUE_ACCESSOR } from '@angular/forms';

export abstract class AbstractValueAccessor {

    private _value: any = '';

    get value(): any { return this._value; };
    set value(v: any) {
        if (v !== this._value) {
            this._value = v;
            this.onChange(v);
        }
    }

    public writeValue(value: any) {
        this._value = value;
        this.onChange(value);
    }

    public onChange = (_) => { return; };
    public onTouched = () => { return; };
    public registerOnChange(fn: (_: any) => void): void { this.onChange = fn; }
    public registerOnTouched(fn: () => void): void { this.onTouched = fn; }
}
