import { Injectable } from '@angular/core';

import { Utils } from './../../../foundations-webct-robot/robot/utils/utils.service';
import { JsonParams } from './../../../foundations-webct-robot/robot/classes/jsonParams.class';
import { ComponentsService } from './../../../foundations-webct-robot/robot/services/components.service';
import { RobotEngineModel } from './../../../foundations-webct-robot/robot/robotEngineModel';

@Injectable()
export class InputService {

  constructor(
    private _utils: Utils,
    private _robot: RobotEngineModel,
    private _components: ComponentsService) {
  }

  public formatMyValue(value: any, props: any = false, showMyLabel = {}, dataRecs = false, dataParameters: JsonParams[] = []) {

    if (!value || typeof value == 'object')
      return null;
    if (typeof value == 'number')
      return value.toString();


    let idSuffix: string = props && props.id && props.id.match(this._utils.uniqIdRegex) ? props.id.match(this._utils.uniqIdRegex)[0] : null;
    let result = this._utils.replaceTagVars(value, [dataRecs, this._utils.arrToObj(dataParameters)], idSuffix);

    if (result.indexOf('((randomId))') >= 0)
      result = result.split('((randomId))').join(this.getRandom());

    return result.replace(this._utils.mustacheReg, '');
  }

  public loadValueListFromDataRecs(inputParameters: JsonParams, dataRecs: any, valueList: any[] = null, componentId: string = null) {

    valueList = valueList || this._utils.getValueFromDataForThisKey(dataRecs, inputParameters.pathToValueList);

    valueList = !valueList ? null : (this._utils.isObjectType(valueList, 'Array') ? valueList : [valueList]);

    if (!valueList || !valueList.length) {
      inputParameters.valueList = null;
      inputParameters.data = { lastValueListUpdate: new Array() };
      return;
    } else if (valueList.findIndex(option => option instanceof JsonParams) >= 0)
      return;

    for (let i in valueList) {
      if (typeof valueList[i] === "string") {
        valueList[i] = {
          key: valueList[i],
          value: valueList[i]
        };
      }
    }

    // inputParameters.originalValue = valueList;
    inputParameters.valueList = this._setListTemplate(inputParameters.templateOfValueList, valueList);

    if (inputParameters.templateOfValue && typeof inputParameters.value == 'object')
      inputParameters.value = this._setListTemplate(inputParameters.templateOfValue, inputParameters.value);

    if (componentId) {
      this._components.evaluateValueList(inputParameters, componentId);
      for (let param of inputParameters.valueList)
        this._robot.changeParameterByDynamicProps(param, {});
    }
  }

  public getRandom(): string {
    function s4() {
      return Math.floor((1 + Math.random()) * 0x10000)
        .toString(16)
        .substring(1);
    }
    return s4() + '-' + s4() + s4() + '-' + s4()/*+'-'+s4()+'-'+s4()+'-'+s4()+s4()+s4()*/;
  }

  private _setListTemplate(template: string, valueList: any[] = []) {

    let formatedList: any[] = valueList;

    if (!template || typeof valueList[0] != 'object')
      return formatedList

    try {
      let parsedTemplate = JSON.parse(template);
      for (let i in parsedTemplate) {
        if (valueList[0][i] === undefined) {
          formatedList = [];
          for (let v in valueList)
            formatedList.push(JSON.parse(this._utils.replaceTagVars(template, valueList[v]).replace(this._utils.mustacheReg, '')));
        }
      }
    } catch (e) {
      formatedList = [];
      for (let v in valueList)
        formatedList.push(this._utils.replaceTagVars(template, valueList[v]).replace(this._utils.mustacheReg, ''));
    }
    return formatedList;
  }

}
