import { EventEmitter } from '@angular/core';

import { JsonGroups } from './jsonGroups.class';
import { Validator } from './validator.class';

export class JsonParams {
    public mockJson: Object;
    public key: string;
    public internalId: string;
    public idPrefix: string;
    public apiFieldName: string;
    public title: string;
    public text: string;
    public description: string;
    public originalValue: any;
    public value: any;
    public icon: any;
    public html: any;
    public type: string;
    public subType: string;
    public class: string;
    public style: Object;
    public navigateTo: string;
    public showComponentById: boolean | string;
    public refData: string;
    public pathToValue: string | string[];
    public templateOfValue: string;
    public pathToValueList: string | string[];
    public templateOfValueList: string;
    public mockToLoad: string;
    // public hidden: any;
    // public disabled: any;
    public readonly: boolean;
    public checked: boolean;
    public filter: any;
    public filterTemplate: any;
    public placeholder: string;
    public position: any;
    public role: string;
    public mappingId: string | string[];
    public sort: string | boolean;
    public sortTemplate: Object;
    public size: any;
    public min: number;
    public max: number;
    public lazyLoading: any;
    public global: boolean | string;
    public validator: Validator;
    public dynamicProps: Object;
    public parameters: JsonParams[];
    public groups: JsonGroups;

    private _id: string;
    private _data: any;
    private _valueList: any[];
    private _hidden: any;
    private _disabled: any;

    private _notifier: EventEmitter<JsonParams> = new EventEmitter();
    private _refreshNotifier: EventEmitter<JsonParams> = new EventEmitter();
    private _newEvent: EventEmitter<newEvent[]> = new EventEmitter();

    constructor(id?: string, value?: any) {
        if (id) {
            this.id = id;
            this.value = value;
        }
    }

    public get oid(): string {
        return this._id ? this._id.replace(/\$\{.*\}/g, '') : '';
    }
    public set oid(value: string) {
        // NÃO DEVE DEFINIR QUALQUER VALOR ATRAVÉS DESTE MÉTODO
    }

    public get id(): string {
        return this._id !== undefined ? this._id : '';
    }
    public set id(value: string) {
        // if (this._id && this._id != value)
        //     console.warn(`Resetting parameter ID: from "${this._id}" to "${value}"`);
        this._id = value !== undefined ? value.toString() : '';
    }

    public get data(): any {
        return this._data || {};
    }
    public set data(value: any) {
        if (this._data === undefined)
            this._data = new Object();
        Object.assign(this._data, value);
    }

    public get valueList(): any[] {
        return this._valueList;
    }
    public set valueList(value: any[]) {
        this._valueList = value;
        // this._cleanValue();
    }

    public get hidden(): any {
        return this._hidden;
    }
    public set hidden(value: any) {
        if (this._hidden === false && value === true)
            this.value = this.originalValue;

        this._hidden = value;
        this.notify();
    }

    public get disabled(): any {
        return this._disabled;
    }
    public set disabled(value: any) {
        if (this._disabled === false && value === true)
            this.value = this.originalValue;

        this._disabled = value;
    }

    public setPropertyValue(key: string, value: any, notify: boolean = true): void {

        if (key.indexOf('.') > 0) {
            this._updateObject(this, value, key.split('.'));
            return;
        }

        if (this[key] === value)
            return;
        this[key] = value;
        if (notify)
            this.notify();
    }

    public getPropertyValue = (key: string): any => this[key];
    public observe = (): EventEmitter<JsonParams> => this._notifier;
    public observeRefresh = (): EventEmitter<JsonParams> => this._refreshNotifier;
    public newEvent = (): EventEmitter<newEvent[]> => this._newEvent;

    public notify(): void {
        this._notifier.next(this);
    }
    public set sendEvent(data: newEvent[] | string) {

        if (data && typeof data == 'string') {
            data = [{ key: <string>data }];
            data = (<newEvent[]>data).map(obj => ({ ...obj, param: this }));
        }

        this._newEvent.next(<newEvent[]>data);
    }

    public refresh() {
        if (this.data.hasRefreshObserver)
            this._refreshNotifier.next(this);
        else {
            this.lazyLoading = true;
            setTimeout(() => this.lazyLoading = false, 1);
        }
    }

    public clone(sufixId?: string) {
        let clone = new JsonParams();
        for (let i in <JsonParams>this) {
            if (typeof this[i] == 'function' || ['id', '_id', '_notifier', '_refreshNotifier', '_newEvent'].indexOf(i) >= 0)
                continue;
            clone[i] = this._chooseCloneType(this[i], sufixId);
        }
        clone.id = this._id ? this._id + (sufixId || '') : '';
        clone.internalId = this.internalId + (sufixId || '');

        return clone;
    }

    public cloneAsObj() {
        let clone = new Object();
        for (let i in <JsonParams>this) {
            if (typeof this[i] == 'function' || ['_notifier', '_refreshNotifier', '_newEvent'].indexOf(i) >= 0)
                continue;
            clone[i] = this._chooseCloneType(this[i]);
        }

        return clone;
    }

    public resetValue(): void {
        this.value = this.originalValue && typeof this.originalValue == 'object' ? JSON.parse(JSON.stringify(this.originalValue)) : this.originalValue;
    }

    private _chooseCloneType(value: any, sufixId?: string): any {

        if (!value)
            return value;
        if (value instanceof JsonParams)
            return value.clone(sufixId);
        if (value instanceof JsonGroups)
            return this._createCloneObject(value, new JsonGroups(), sufixId);
        if (typeof value == 'object') {
            if (Object.prototype.toString.call(value) === '[object Array]')
                return this._createCloneObject(value, new Array(), sufixId);
            if (Object.prototype.toString.call(value) === '[object Object]')
                return this._createCloneObject(value, new Object(), sufixId);
        }

        return value;
    }

    private _createCloneObject(value: any, clone: any, sufixId?: string): any {
        for (let i in value)
            clone[i] = this._chooseCloneType(value[i], sufixId);
        return clone;
    }

    private _cleanValue() {

        let newValue: any = '';
        if (this.value !== null && typeof this.value == 'object')
            newValue = this.value.constructor === Array ? [] : newValue;

        this.value = newValue;
    }

    private _updateObject(object: Object, newValue: any, stack: string[]) {
        let myStack = JSON.parse(JSON.stringify(stack));
        while (myStack.length > 1)
            object = object[myStack.shift()];
        object[myStack.shift()] = newValue;
    }

}

export interface newEvent {
    key: string;
    value?: any;
    param?: JsonParams
}

/*
 * key -> Identificador de um Objecto no Robot. Indica uma operação especídica
 * id -> Identificador que deverá ser único em toda a estrutura
 * text -> Texto amigável que aparece na vista ao utilizador (Título)
 * description -> Texto amigável que aparece na vista ao utilizador (Descrição)
 * value -> Valor associado ao Objecto
 * valueList -> Listagem de valores associado ao Objecto
 * icon -> Icon associado ao Objecto
 * html -> Alteração do resultado apresentado consoante o valor do campo recebido
 * type -> O robot terá comportamentos específicos para cada valor colocado aqui [menu / list]
 * subType -> Sub-tipo do parâmetro (terá igualmente compormanentos específicos, tal como o "type")
 * class -> Nome da(s) class(s) CSS que será(ão) aplicada(s) ao elemento
 * style -> Estilos que serão aplicados ao elemento
 * navigateTo -> Rota para onde o utilizador deverá ser encaminhado caso clique no elemento correspondente ao Objecto
 * showComponentById -> Recebe um ID, que identificatá o elemento a mostrar
 * pathToValue -> Caminho que deverá ser percorrigo num determinado objecto para chegar ao valor do parâmetro
 * pathToValueList -> Caminho que deverá ser percorrigo num determinado objecto para chegar à listagem de valores do parâmetro
 * templateOfValueList -> Template que deverá ser usado para a contrução da listagem de valores num determinado parâmetro (quando essa listagem é desconhecida)
 * mockToLoad -> Nome do MOCK que será carregado para estruturar a página/modal que será aberta
 * hidden -> Propriedade que define se o elemento estará escondido (true) ou não (false)
 * filter -> Propriedade que define se o elemento estará disponível no componente dos filtros
 * filterTemplate -> Quando definida, altera a syntax da pesquisa feita para o campo em questão
 * placeholder -> Valor que irá aparecer como placeholder nos elementos (ex: <input />)
 * position -> Posição do elemento (ex: reordenar a posição dos inputs de pesquisa no componente dos filtros)
 * role -> Role necessária para mostrar o elemento ao utilizador
 * mappingId -> Identificador para mapear com o ID de outros parâmetros
 * readonly -> Indica se o parâmetro deve ser, ou não, apresentado como readonly
 * checked -> Indica se o parâmetro deve ser, ou não, apresentado como checked
 * sort -> Indicação da ordenação de determinado parâmetro
 * size -> Indicação do tamanho que o parâmetro deverá ter
 * lazyLoading -> Se o parâmetro deve assumir o comportamento de "lazy loading"
 * validator -> regras para validação do elemento (usado apenas em campos dos formulários)
 * parameters -> Parâmetros associados ao Objecto, também eles do tipo "JsonParams"
 * groups -> Agrupadores de Parâmetros do tipo "JsonParams"
 */
