import { Injectable, EventEmitter } from '@angular/core';
import { Observable } from 'rxjs/Observable';

import { JsonParams } from '../classes/jsonParams.class';

@Injectable()
export class GlobalVarsService {

  private _globalVars: Object = {};
  private _pageParameters: Object = {};
  private _pageParametersByGroup: Object = {};

  private _dynamicPropsData: Object = {};
  private _dynamicPropsEvaluationHistory: Object = {};

  private _filterById: Object = {};
  private _filterByIdEmitter: Object = {};

  private _urlParams: Object = new Object();
  private _urlParamsObservable: EventEmitter<Object> = new EventEmitter();

  private _urlDataParams: Object = new Object();
  private _urlDataParamsObservable: EventEmitter<Object> = new EventEmitter();

  public cleanLocalStorage = (): void => !!localStorage ? localStorage.clear() : undefined;
  public getLocalStorage = (item: string): string => !!localStorage ? localStorage.getItem(item) : undefined;
  public setLocalStorage = (item: string, value: string): void => !!localStorage ? localStorage.setItem(item, value) : undefined;

  public cleanSessionStorage = (): void => !!sessionStorage ? sessionStorage.clear() : undefined;
  public getSessionStorage = (item: string): string => !!sessionStorage ? sessionStorage.getItem(item) : undefined;
  public setSessionStorage = (item: string, value: string): void => !!sessionStorage ? sessionStorage.setItem(item, value) : undefined;

  public resetGlobalVars() {
    this._globalVars = {};
  }
  public resetDP() {
    this._dynamicPropsData = {};
    this._dynamicPropsEvaluationHistory = {};
  }

  public setGlobalVars(key: string, value: any, saveStorage: boolean = false) {
    this._globalVars[key] = value;
    if (saveStorage)
      this.setSessionStorage(key, value && typeof value == 'object' ? JSON.stringify(value) : value);
  }
  public getGlobalVars(key: string = null) {

    if (key) {
      if (this._globalVars[key])
        return this._globalVars[key];
      try {
        return JSON.parse(this.getSessionStorage(key));
      } catch (e) {
        return this.getSessionStorage(key);
      }
    }
    return this._globalVars;
  }
  public deleteGlobalVar(key: string) {
    if (key && key in this._globalVars)
      delete this._globalVars[key];
  }

  public setGlobalComponents(list: Object[]): void {
    if (!list || list.length == 0)
      return;
    this.setGlobalVars('appActiveComponents', list.map(obj => obj['name']));
  }
  public getActiveComponent(name: string): boolean {
    let activeComponents = this.getGlobalVars('appActiveComponents');
    if (!activeComponents)
      return true;
    return activeComponents.indexOf(name) >= 0;
  }

  public setFilterById(id: string, value: Object) {
    this._filterById[id] = value;
  }
  public getFilterById = (parameter: JsonParams): Observable<Object> => Observable.create(observer => this._filterByIdEmitter[parameter.id || parameter.internalId] = observer);

  public emitFilters(): void {
    for (let i in this._filterByIdEmitter)
      this._filterByIdEmitter[i].next(this._filterById);
  }

  public removeFilterSubscriber(parameter: JsonParams): void {
    delete this._filterByIdEmitter[parameter.id || parameter.internalId];
  }

  public setPageParameters(parameter: JsonParams, groupId: string = null): Promise<JsonParams> {
    if (!parameter.id)
      return new Promise((reject) => { reject(null) });

    return new Promise((resolve) => {

      // Serão eliminados do sistema e substituidos pela versão mais recente, os parâmetros duplicados.
      this._checkAndDeleteExistingIds(parameter);

      if (parameter.id.indexOf('properties::') < 0)
        this._setPageParameterPropertyReader(parameter, groupId);

      this._pageParameters[parameter.id] = parameter;
      if (groupId) {
        this._pageParametersByGroup[groupId] = this._pageParametersByGroup[groupId] || [];
        this._pageParametersByGroup[groupId].push(parameter.id);
      } else
        console.error(`GlobarVarsService.setPageParameters(): Page parameters are being created without Group ID.`);
      resolve(parameter);
    });

  }

  public getPageParametersIdsByGroup(groupId: string): string[] {
    return this._pageParametersByGroup[groupId];
  }
  public getPageParametersByGroup(groupId: string): JsonParams[] {
    let groupIds: string[] = this.getPageParametersIdsByGroup(groupId);
    let parametersToReturn: JsonParams[] = [];
    if (groupIds) {
      for (let paramId of groupIds)
        parametersToReturn.push(this.getPageParameter(paramId));
    }
    return parametersToReturn;
  }
  public deletePageParametersByGroup(groupId: string) {
    if (!this._pageParametersByGroup[groupId])
      return;
    for (let parameterId of this._pageParametersByGroup[groupId])
      this.deletePageParameterById(parameterId);

    delete this._pageParametersByGroup[groupId];
  }
  public deletePageParameterById(id: string) {
    if (!this._pageParameters[id])
      return;

    delete this._pageParameters[id];
    delete this._dynamicPropsData[id];
    delete this._filterById[id];
  }

  public getPageParameter(key: string): JsonParams {
    return this._pageParameters[key];
  }
  public getPageParameters(): Object {
    return this._pageParameters;
  }
  public getPageParametersAsArray(): JsonParams[] {
    return Object.keys(this._pageParameters).map((key) => { return this._pageParameters[key] });
  }

  public setDynamicPropsData(data: Object) {
    if (!data)
      return;
  /*  for (let i in data) {
        if (this._dynamicPropsData[i] === undefined || (i in data))
        this._dynamicPropsData[i] = data[i];
    }*/
    for (let i in data) {
      if (data[i]) {
        if (data[i] instanceof JsonParams) {
          if (data[i].hasOwnProperty('dynamicProps') && data[i].dynamicProps) {
            this._dynamicPropsData[i] = {};
            this._dynamicPropsData[i].dynamicProps = data[i].dynamicProps;
          } else if (this._dynamicPropsData[i]) {
            this._dynamicPropsData[i] = data[i];
          }
    /*    } else if (this._dynamicPropsData[i]) {
          this._dynamicPropsData[i] = data[i]; */
        }
        else if (this._dynamicPropsData[i] === undefined || (i in data))
          this._dynamicPropsData[i] = data[i];
      }
    }
  }

  public getDynamicPropsData() {
    return this._dynamicPropsData;
  }
  public resetDynamicPropsData(): void {
    this._dynamicPropsData = new Object();
  }

  public setDynamiPropsHistory(id: string, evaluation: string) {
    this._dynamicPropsEvaluationHistory[id] = evaluation;
  }
  public getDynamiPropsHistory(id: string) {
    return this._dynamicPropsEvaluationHistory[id];
  }

  /**
   * URLPARAMS
   */
  public observeUrlParams = (): EventEmitter<Object> => this._urlParamsObservable;
  public get urlParams(): Object {
    return this._urlParams;
  }
  public set urlParams(value: Object) {
    this._urlParams = new Object();
    Object.assign(this._urlParams, value, this.getQueryParameters());
    this._urlParamsObservable.next(this._urlParams);
  }

   /**
   * URLDATAPARAMS
   */
  public observeUrlDataParams = (): EventEmitter<Object> =>
    this._urlDataParamsObservable;
  public get urlDataParams(): Object {
    return this._urlDataParams;
  }
  public set urlDataParams(value: Object) {
    this._urlDataParams = new Object();
    Object.assign(this._urlDataParams, value, this.getQueryParameters());
    this._urlDataParamsObservable.next(this._urlDataParams);
  }

  public getQueryParameters(str: string = window.location.href): Object {
    let vars: Object = new Object();
    if (str.indexOf('?') < 0)
      return vars;

    let hashes = str.slice(str.indexOf('?') + 1).split('&');
    for (let i in hashes) {
      let param = hashes[i].split('=');
      vars[param[0]] = param[1] ? decodeURIComponent(param[1].replace(/\+/g, ' ')) : undefined;
    }

    return vars;
  }

  private _getParamIdOptions(id: string): string[] {
    return Object.keys(this._pageParameters).filter(str => str.match(new RegExp(`^((.*)\\:\\:)?${id}(\\$\\{\\d+\\-\\d+\\})?$`)));
  }

  private _setPageParameterPropertyReader(parameter: JsonParams, groupId: string = null): void {

    let aux = new JsonParams()
    aux.id = 'properties::' + parameter.id;
    aux.value = parameter;

    this.setPageParameters(aux, groupId);
  }

  private _checkAndDeleteExistingIds(parameter: JsonParams) {
    if (this._pageParameters[parameter.id] !== undefined) {
      delete this._pageParameters[parameter.id];
      let idPosition: number;
      for (let i in this._pageParametersByGroup) {
        idPosition = this._pageParametersByGroup[i].indexOf(parameter.id);
        if (idPosition >= 0) {
          this._pageParametersByGroup[i].splice(idPosition, 1);
          break;
        }
      }
    }
  }

}
