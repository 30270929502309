import { Component, Input } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { Utils } from './../../../foundations-webct-robot/robot/utils/utils.service';
import { GlobalVarsService } from './../../../foundations-webct-robot/robot/utils/global-vars.service';
import { JsonParams } from './../../../foundations-webct-robot/robot/classes/jsonParams.class';
import { ModalService } from './../../../foundations-webct-robot/robot/services/modal.service';

@Component({
    selector: 'select-from-modal',
    templateUrl: 'select-from-modal.component.html'
})

export class SelectFromModalComponent {

    @Input() public inputParameters: JsonParams;
    @Input() public control: FormGroup;

    constructor(
        private _modalService: ModalService,
        private _utils: Utils,
        private _globalVars: GlobalVarsService) {

    }

    public openModal() {
        this._modalService.openModal(this.inputParameters.mockToLoad, this.inputParameters.parameters, this._utils.arrToObj(this._globalVars.getPageParametersAsArray()), this.inputParameters);
        this._modalService.inputParameters = this.inputParameters;
    }

    public cleanValue(index: number) {
        this.inputParameters.value.splice(index, 1);
    }
}
