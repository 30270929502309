import { Component, Input, OnInit, Output, EventEmitter, DoCheck } from '@angular/core';
// import { FormControl } from '@angular/forms';
import { JsonParams } from './../../../foundations-webct-robot/robot/classes/jsonParams.class';
// import { RobotEngineModel } from './../../../foundations-webct-robot/robot/robotEngineModel';

@Component({
  selector: 'app-wct-pagination',
  templateUrl: 'wct-pagination.component.html',
  styleUrls: ['wct-pagination.component.css']
})

export class WctPaginationComponent implements OnInit, DoCheck {

  defaultConfiguration: any = {
    maxSize: void 0,
    autoCompact: false,
    itemsPerPage: 10,
    boundaryLinks: true,
    directionLinks: true,
    rotate: true,
    compact: true,
    pageSelection: false,
    recordsPerPageSelection: true
  };

  config: any;

  /** limit number for page links in pager */
  @Input() maxSize: number;
  /** if maxSize > 0 pagination will be displayed in compact format in number of pages > maxSize*/
  @Input() autoCompact: boolean;
  /** if false first and last buttons will be hidden */
  @Input() boundaryLinks: boolean;
  /** if false previous and next buttons will be hidden */
  @Input() directionLinks: boolean;
  /** if true pagination component will be disabled */
  @Input() disabled: boolean;
  /** if true current page will in the middle of pages list */
  @Input() rotate: boolean;
  /** if pagination will be displayed in compact format */
  @Input() compact: boolean;
  /** shown page selection in extended mode */
  @Input() pageSelection: boolean;
  /** shown page selection in extended mode */
  @Input() recordsPerPageSelection: boolean;


  @Input() public pagingSettings: any;
  @Input() public rows: JsonParams;
  @Input() public dataRecsNoContent;

  @Output() public pageChanged: EventEmitter<any> = new EventEmitter();

  public refreshMe = false;
  public tableHasData = false;
  public tableIsLoading = true;
  public pages: any[];

  constructor() {
    if (!this.config) {
      this.configureOptions(this.defaultConfiguration);
    }
  }

  selectPage(page: number, event?: Event): void {
    if (event) {
      event.preventDefault();
    }

    if (!this.disabled) {

      if (event && event.target) {
        const target: any = event.target;
        target.blur();
      }

      this.pagingSettings.currentPage = page;
      this.pageChange(this.pagingSettings);
      this.pages = this.getPages();
    }
  }

  public ngOnInit() {
    this.maxSize = typeof this.maxSize !== 'undefined' ? this.maxSize : this.config.maxSize;
    this.boundaryLinks = typeof this.boundaryLinks !== 'undefined' ? this.boundaryLinks : this.config.boundaryLinks;
    this.directionLinks = typeof this.directionLinks !== 'undefined' ? this.directionLinks : this.config.directionLinks;
    this.rotate = typeof this.compact !== 'undefined' ? this.rotate : this.config.rotate;
    this.compact = typeof this.compact !== 'undefined' ? this.compact : this.config.compact;
    this.autoCompact = typeof this.autoCompact !== 'undefined' ? this.autoCompact : this.config.autoCompact;
    this.pageSelection = typeof this.pageSelection !== 'undefined' ? this.pageSelection : this.config.pageSelection;
    this.recordsPerPageSelection = typeof this.recordsPerPageSelection !== 'undefined' ? this.recordsPerPageSelection : this.config.recordsPerPageSelection;

    this.pages = this.getPages();
    this._evaluateTableData();
  }

  public ngDoCheck() {
    this._evaluateTableData();
    this.pages = this.getPages();
  }

  /**
   * checks if paging elements are going to be shown
   */
  public showPaging() {
    return this.pagingSettings.paging && this.pagingSettings.numPages > 1;
  }

  /**
   * call in change of select box with number of records to show per page
   */
  public changeItemsPerPage(ipp) {
    this.pagingSettings.itemsPerPage = ipp;
    this.pageChange(this.pagingSettings, false, 1);
  }

  /**
   * Emit pagechanged event
   */
  public pageChange(paginationSettings, loading: boolean = false, pageNumber: number = null) {

    if (pageNumber)
      paginationSettings.currentPage = pageNumber;

    this._startTableLoading();
    if (loading) {
      this.rows.value = null;
      this.rows.originalValue = null;
      this.refreshMe = true;
      this.dataRecsNoContent = null;
      this._evaluateTableData();
      setTimeout(() => {
        this.refreshMe = false;
        this.pageChanged.emit(paginationSettings);
      }, 500);
    } else {
      this.pageChanged.emit(paginationSettings);
    }
  }

  /**
   * Validates the values entered in the page selection text box and changes to the desired page
   */
  public checkInputPaging(e) {

    let execute = e.which === 13;
    let inputValue = e.target.value;

    if ((e.which == 38 || e.which == 39) && this.pagingSettings.currentPage < this.pagingSettings.numPages)  {
      // 38 = up arrow, 39 = right arrow
      this.pagingSettings.currentPage++;
      this.pageChange(this.pagingSettings);
      this.pages = this.getPages();
      return;
    } else if ((e.which == 37 || e.which == 40) && this.pagingSettings.currentPage > 1) {
      // 37 = left arrow, 40 = down arrow
      this.pagingSettings.currentPage--;
      this.pageChange(this.pagingSettings);
      this.pages = this.getPages();
      return;
    }

    if (isNaN(inputValue)) {
      this.pagingSettings.currentPage = +inputValue.toString().replace(/[^\d]/g, '');
      return;
    }

    if (!execute)
      return;

    if (+inputValue > this.pagingSettings.numPages)  {
      this.pagingSettings.currentPage = this.pagingSettings.numPages;
    } else if (+inputValue < 1)  {
      this.pagingSettings.currentPage = 1;
    } else {
      this.pagingSettings.currentPage = +inputValue;
    }

    this.pageChange(this.pagingSettings);
    this.pages = this.getPages();
  }

  private _startTableLoading() {
    this.tableHasData = false;
    this.tableIsLoading = true;
  }

  private _evaluateTableData() {
    this.tableHasData = this.rows && this.rows.value && this.rows.value.length > 0;
    this.tableIsLoading = !(this.rows && this.rows.value);
  }

  /* _____________________________________________________________________________________ */

  /**
   * Load component configurations
   */
  private configureOptions(config: any): void {
    this.config = Object.assign({}, config);
  }

  /**
   * Verifies that there are pages to show before the current page
   */
  public noPrevious(): boolean {
    return this.pagingSettings.currentPage === 1;
  }

  /**
   * Verifies that there are pages to show after the current page
   */
  public noNext(): boolean {
    return this.pagingSettings.currentPage === this.pagingSettings.numPages;
  }

  /**
   * verifies if the pagination component must be shown in compact mode
   */
  public isCompact(): boolean {
    if (this.maxSize > 0 && this.pagingSettings.numPages > this.maxSize && this.autoCompact)
      return true;
    return this.compact;
  }

  /**
   * verifies if the pagination component must be shown page selection in extended mode
   */
  public hasPageSelection(): boolean {
    return this.pageSelection;
  }

  public showRecordsPerPageSelection(){
    return this.recordsPerPageSelection;
  }

  /**
   * Create page object used in template
   */
  protected makePage(num: number, text: string, active: boolean ) : { number: number; text: string; active: boolean } {
    return { text, number: num, active };
  }


  /**
   * Generates page objects to create visual elements for each page
   */
  protected getPages(): any[] {
    let currentPage: number = this.pagingSettings.currentPage;
    let totalPages: number = this.pagingSettings.numPages;

    const pages: any[] = [];

    // Default page limits
    let startPage = 1;
    let endPage = totalPages;
    const isMaxSized =
      typeof this.maxSize !== 'undefined' && this.maxSize > 0 && this.maxSize < totalPages;

    // recompute if maxSize
    if (isMaxSized) {
      if (this.rotate) {
        // Current page is displayed in the middle of the visible ones
        startPage = Math.max(currentPage - Math.floor(this.maxSize / 2), 1);
        endPage = startPage + this.maxSize - 1;

        // Adjust if limit is exceeded
        if (endPage > totalPages) {
          endPage = totalPages;
          startPage = endPage - this.maxSize + 1;
        }
      } else {
        // Visible pages are paginated with maxSize
        startPage =
          (Math.ceil(currentPage / this.maxSize) - 1) * this.maxSize + 1;

        // Adjust last page if limit is exceeded
        endPage = Math.min(startPage + this.maxSize - 1, totalPages);
      }
    }

    // Add page number links
    for (let num = startPage; num <= endPage; num++) {
      const page = this.makePage(num, num.toString(), num === currentPage);
      pages.push(page);
    }

    // Add links to move between page sets
    if (isMaxSized && !this.rotate) {
      if (startPage > 1) {
        const previousPageSet = this.makePage(startPage - 1, '...', false);
        pages.unshift(previousPageSet);
      }

      if (endPage < totalPages) {
        const nextPageSet = this.makePage(endPage + 1, '...', false);
        pages.push(nextPageSet);
      }
    }

    return pages;
  }
}
