import { NgModule, CUSTOM_ELEMENTS_SCHEMA } from '@angular/core';
import { DatePipe } from '@angular/common';

/**
 * ROBOT
 */
import { SharedModule } from './foundations-webct-robot/robot/shared.module';
import { PageComponent } from './foundations-webct-robot/robot/pageComponent/page.component';

/**
 * Removed
 */

/*import { PlaygroundComponent } from './foundations-webct2-palette/components/playgroundComponent/playground.component';
import { PaginationComponent } from './foundations-webct2-palette/components/paginationComponent/pagination.component';
import { AppFooterComponent } from './foundations-webct2-palette/components/appFooterComponent/app-footer.component';
import { NotificationComponent } from './foundations-webct2-palette/components/notificationComponent/notification.component';
import { BreadcrumbComponent } from './foundations-webct2-palette/components/breadcrumbComponent/breadcrumb.component';
import { PageHeaderComponent } from './foundations-webct2-palette/components/pageHeaderComponent/page-header.component';
import { UploadComponent } from './foundations-webct2-palette/components/upload/upload.component';
import { DndDirective } from './foundations-webct2-palette/components/upload/dnd.directive';
import { BtnIpComponent } from './foundations-webct2-palette/components/btnIpComponent/btn-ip.component';
import { HtmlContainerComponent } from './foundations-webct2-palette/components/htmlContainerComponent/htmlContainer.component';
import { AppHeaderComponent } from './foundations-webct2-palette/components/appHeaderComponent/app-header.component';
import { AppHeaderMenuComponent } from './foundations-webct2-palette/components/appHeaderComponent/app-header-menu.component';
import { AppHeaderMenuSidebarComponent } from './foundations-webct2-palette/components/appHeaderComponent/app-header-menu-sidebar.component';
import { UserInfoComponent } from './foundations-webct2-palette/components/appHeaderComponent/userinfo.component';
import { ButtonsComponent } from './foundations-webct2-palette/components/buttonsComponent/buttons.component';
import { TuplelistComponent } from './foundations-webct2-palette/components/tuplelistComponent/tuplelist.component';
import { TabsComponent } from './foundations-webct2-palette/components/tabsComponent/tabs.component';
import { TabComponent } from './foundations-webct2-palette/components/tabsComponent/tab.component';
import { MapComponent } from './foundations-webct2-palette/components/mapComponent/map.component';
import { CardComponent } from './foundations-webct2-palette/components/cardComponent/card.component';
import { DropdownComponent } from './foundations-webct2-palette/components/dropdownComponent/dropdown.component';
import { OutputTagsComponent } from './foundations-webct2-palette/components/tagsComponent/tags.component';


*/

/**
 * PALETE
 */
/**----------------validar se pode ser eleminado----------------------- */
import { SelectedItemsContainerComponent } from './foundations-webct2-palette/components/selected-items-container/selected-items-container.component';
import { ParametersFormatComponent } from './foundations-webct2-palette/components/detailsComponent/parameters-format.component';
import { ShowParametersByTypeComponent } from './foundations-webct2-palette/components/detailsComponent/show-parameters-by-type.component';
import { TableComponent } from './foundations-webct2-palette/components/tableComponent/table.component';
import { TableFilterComponent } from './foundations-webct2-palette/components/tableComponent/table-filter.component';
import { TableOptionsComponent } from './foundations-webct2-palette/components/tableComponent/table-options.component';
import { NgxChartsComponent } from './foundations-webct2-palette/components/chartsComponent/ngx-charts.component';
import { AutoCompleteDetailsComponent } from './foundations-webct2-palette/components/autoCompleteDetailsComponent/auto-complete-details.component';
import { InputSelectComponent } from './foundations-webct2-palette/components/selectComponent/select.component';
import { InputRadioComponent } from './foundations-webct2-palette/components/radioComponent/radio.component';
import { InputCheckboxComponent } from './foundations-webct2-palette/components/checkboxComponent/checkbox.component';
import { SelectFromModalComponent } from './foundations-webct2-palette/components/selectFromModalComponent/select-from-modal.component';
import { TreeViewComponent } from './foundations-webct2-palette/components/treeViewComponent/tree-view.component';
import { SliderComponent } from './foundations-webct2-palette/components/sliderComponent/slider.component';
import { AssymetrikMapComponent } from './foundations-webct2-palette/components/assymetrikMapComponent/assymetrikMap.component';
import { CaptchaComponent } from './foundations-webct2-palette/components/captchaComponent/captcha.component';
import { AutocompleteComponent } from './foundations-webct2-palette/components/autocompleteComponent/autocomplete.component';
import { TextboxUnicityValidatorComponent } from './foundations-webct2-palette/components/unicityValidatorComponent/unicity-validator.component';
/**--------------------------------------- */
import { InputTextboxComponent } from './foundations-webct2-palette/components/textboxComponent/textbox.component';
import { AppSearchComponent } from './foundations-webct2-palette/components/appSearchComponent/app-search.component';
import { Ng2WizardComponent } from './foundations-webct2-palette/components/wizardComponent/ng2-wizard.component';
import { Ng2WizardTabComponent } from './foundations-webct2-palette/components/wizardComponent/ng2-wizard-tab.component';
import { Ng2WizardStepComponent } from './foundations-webct2-palette/components/wizardComponent/ng2-wizard-step.component';
import { SidebarToggleRowsComponent } from './foundations-webct2-palette/components/sidebarToggleRowsComponent/sidebar-toggle-rows.component';
import { OutputLabelComponent } from './foundations-webct2-palette/components/labelComponent/label.component';
import { WctNgSelectComponent } from './foundations-webct2-palette/components/wct-ng-select/wct-ng-select.component';
import { WctTableCheckboxComponent } from './foundations-webct2-palette/components/tableComponent/wct-table-checkbox/wct-table-checkbox.component';
import { WctInfiniteScrollComponent } from './foundations-webct2-palette/components/wct-infinite-scroll/wct-infinite-scroll.component';



/**
 * CUSTOM COMPONENTS / SERVICES
 */
/**----------------validar se pode ser eleminado----------------------- */
import { JsonComponent } from './foundations-webct2-palette/components/jsonComponent/json.component';
import { ImageComponent } from './foundations-webct2-palette/components/imageComponent/image.component';
import { ProgressbarComponent } from './foundations-webct2-palette/components/progressbarComponent/progressbar.component';
/**--------------------------------------- */
import { DragComponentComponent } from './designerFlow/drag-component/drag-component.component';
import { FormHandlerComponentComponent } from './designerFlow/form-handler-component/form-handler-component.component';
import { NavbarComponentComponent } from './designerFlow/navbar-component/navbar-component.component';
import { BsOrderConnectionsComponent } from './customComponents/bs-order-connections/bs-order-connections.component';
import { BsVaAnswerComponent } from './customComponents/bs-va-answer/bs-va-answer.component';
import { BsSuperheroChatElementInitComponent } from './customComponents/bs-superhero-chat-element-init/bs-superhero-chat-element-init.component';

import { BsVaActionComponent } from './customComponents/bs-va-action/bs-va-action.component';


export const DECLARATIONS: any[] = [

  /**----------------validar se pode ser eleminado----------------------- */
  SelectedItemsContainerComponent,
  TableComponent,
  TableOptionsComponent,
  TableFilterComponent,
  ParametersFormatComponent,
  ShowParametersByTypeComponent,
  AutoCompleteDetailsComponent,
  InputSelectComponent,
  InputRadioComponent,
  InputCheckboxComponent,
  SelectFromModalComponent,
  NgxChartsComponent,
  TreeViewComponent,
  SliderComponent,
  AssymetrikMapComponent,
  CaptchaComponent,
  AutocompleteComponent,
  TextboxUnicityValidatorComponent,
  JsonComponent,
  ImageComponent,
  ProgressbarComponent,
  /**--------------------------------------- */
  InputTextboxComponent,
  PageComponent,
  AppSearchComponent,
  Ng2WizardComponent,
  Ng2WizardTabComponent,
  Ng2WizardStepComponent,
  SidebarToggleRowsComponent,
  OutputLabelComponent,
  WctNgSelectComponent,
  DragComponentComponent,
  FormHandlerComponentComponent,
  NavbarComponentComponent,
  BsOrderConnectionsComponent,
  BsVaAnswerComponent,
  BsVaActionComponent,
  BsSuperheroChatElementInitComponent,
  WctTableCheckboxComponent,
  WctInfiniteScrollComponent
];

export const EXPORTS: any[] = [
  /**----------------validar se pode ser eleminado----------------------- */
  TableComponent,
  TableOptionsComponent,
  TableFilterComponent,
  ParametersFormatComponent,
  ShowParametersByTypeComponent,
  AutoCompleteDetailsComponent,
  InputSelectComponent,
  InputRadioComponent,
  InputCheckboxComponent,
  SelectFromModalComponent,
  NgxChartsComponent,
  TreeViewComponent,
  SliderComponent,
  AssymetrikMapComponent,
  CaptchaComponent,
  AutocompleteComponent,
  TextboxUnicityValidatorComponent,
  /**--------------------------------------- */
  InputTextboxComponent,
  SharedModule,
  PageComponent,
  AppSearchComponent,
  Ng2WizardComponent,
  Ng2WizardTabComponent,
  Ng2WizardStepComponent,
  SidebarToggleRowsComponent,
  OutputLabelComponent,
  WctNgSelectComponent,
  DragComponentComponent,
  FormHandlerComponentComponent,
  NavbarComponentComponent,
  BsOrderConnectionsComponent,
  BsVaAnswerComponent,
  BsVaActionComponent,
  BsSuperheroChatElementInitComponent,
  WctTableCheckboxComponent,
  WctInfiniteScrollComponent
]
