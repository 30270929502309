
import { Component, OnInit, Input, ElementRef, Output, EventEmitter, HostListener, ViewChild, ViewEncapsulation } from '@angular/core';
import { JsonParams } from './../../foundations-webct-robot/robot/classes/jsonParams.class';
import { FormControl } from '@angular/forms';
import { RobotEngineModel } from './../../foundations-webct-robot/robot/robotEngineModel';
import { InputService } from './../../foundations-webct-robot/robot/services/input.service';
import { Subject } from 'rxjs';
import { debounceTime, distinctUntilChanged } from 'rxjs/operators';
import { ComponentsService } from './../../foundations-webct-robot/robot/services/components.service';
import 'quill-mention';
import 'quill-emoji';
import { QuillEditorComponent } from 'ngx-quill';
import { RequestManagerService } from '../../requests/requestManager.service';
import { PageService } from '../../foundations-webct-robot/robot/pageComponent/page.service';


@Component({
  selector: 'app-text-editor',
  templateUrl: './text-editor.component.html',
  styleUrls: ['./text-editor.component.css'],
  encapsulation: ViewEncapsulation.None
})
export class TextEditorComponent implements OnInit {

  public TagsList: any = [];
  

  @Input() public inputParameters: JsonParams;
  @Input() public inputDataRecs: Object;
  @Input() public control: FormControl;
  @Input() public inputValue;
  @Input() public dataRecs;

  @Output()
  public clickOutside = new EventEmitter<MouseEvent>(); //event to hide toolbar if needed

  @ViewChild('editor') editor: QuillEditorComponent     //editor class 


  public value$ = new Subject<string>(); // wait x sec before verify input value
  public displayValue: string;           // string that is showed on input
  public editing = false;                // variable to controll hide toolbar
  private _mentionsArray: any[];
  private _messageTagsArray: any[];

  private static EMOJI_ICON = '<svg class="i" viewBox="0 0 24 24"><use href="#emoticon-happy"></use></svg>';

  quillConfig = {
    toolbar: {
      container: [
        ['bold', 'italic', 'underline', 'strike'],                // Typography Emphasis
        [{ 'color': [] }, { 'background': [] }],                  // Typography Colors
        ['link'],                                                 // Links
        // ['code-block'],                                        // Code inside editor
        // [{ 'header': 1 }, { 'header': 2 }],                    // custom button values
        // [{ 'list': 'ordered' }, { 'list': 'bullet' }],         // Lists style
        //[{ 'script': 'sub'}, { 'script': 'super' }],            // superscript/subscript
        //[{ 'indent': '-1'}, { 'indent': '+1' }],                // outdent/indent
        //[{ 'direction': 'rtl' }],                               // text direction
        //[{ 'align': [false, 'center', 'right', 'justify'] }],   // text alignment
        [{ 'size': ['small', false, 'large', 'huge'] }],        // custom dropdown
        //[{ 'header': [1, 2, 3, 4, 5, 6, false] }],              // header sizes
        //[{ 'font': [] }],                                       // fonts (Can be custom, just add to css and add here the font name)
        // ['clean'],                                             // remove formatting button
        // ['link', 'image', 'video']                              // Links , images , videos
        ['emojis'],                                              // Emojis (Need to import quill-emojis)
      ],
      //   handlers: {
      //   // handlers object will be merged with default handlers object
      //   'link': function (value) {
      //     if (value) {
      //       var href = prompt('Enter the URL');
      //       this.quill.format('link', href);
      //     } else {
      //       this.quill.format('link', false);
      //     }
      //   }
      // }
    },
    "emoji-shortname": false,
    "emoji-toolbar": false,
    "emoji-textarea": true,
    mention: {
      onSelect(item, insertItem,mentionChar) {
        let val = item.id == item.value ? '`' : '-';
        insertItem({ "id":  item.id, "value": val + item.value + val }); // custom item format
      },
      allowedChars: /^[A-Za-z\sÅÄÖåäö]*$/,
      mentionDenotationChars: ["@","$"],
      showDenotationChar: false,
      dataAttributes: ['id', 'value', 'link', 'target','disabled'],
      source: (searchTerm, renderList, mentionChar, onSelect, renderItem) => {
        let values = mentionChar=="@" ? this._mentionsArray : this._messageTagsArray;
        if (searchTerm.length === 0) {
          renderList(values, searchTerm);
        } else {
          const matches = [];
          for (let i = 0; i < values.length; i++) {
            if (!values[i].value.toLowerCase().indexOf(searchTerm.toLowerCase())) {
              matches.push(values[i]);
            }
          }
          renderList(matches, searchTerm);
        }
      },
    },


  }

  constructor(
    private pageService: PageService,
    private requestManager: RequestManagerService,
    private _robot: RobotEngineModel,
    private elementRef: ElementRef
  ) { }

  ngOnInit() {
    this._mentionsArray = this.inputParameters.valueList ? this.inputParameters.valueList.map(elem => { return { id: elem.key, value: elem.value } }) : [];
    console.log(this.inputParameters.valueList);
    this._messageTagsArray = this.loadMessageTags();
    if (this.inputParameters.value) {
      try {
        this.displayValue = (this.inputParameters.value).replace(/\\\"/g, '"').replace(/[\u200B-\u200D\uFEFF]/g, '').replace(/\\'/g, "'");
      } catch (e) {
        this.displayValue = '';
        console.error(e);
      }
    } else { this.displayValue = ''; }


    // this.value$.pipe(
    //   debounceTime(0),
    //   distinctUntilChanged()).subscribe(inputTxt => this.modelChanged(inputTxt));
  }

  /**
   * modelChanged : inputTxt
   * Verifies modelChanges, escape content to correct the html -> (error html on json because of double quotation marks)
   */
  public onChange(inputTxt: any) {

    if (inputTxt) {
      try {
        this.inputParameters.value = inputTxt.replace(/\"/g, "\\\"").replace(/[\u200B-\u200D\uFEFF]/g, '').replace(/\'/g, "\\\'");
      } catch (e) {
        this.inputParameters.value = '';

        console.error('error replace text editor value');
      }
    } else {
      this.inputParameters.value = this.displayValue = '';
    }
    this._robot.findDynamicPropsDependencies(this.inputParameters.id);
  }

  /**
   * onDocumentClick : MouseEvent
   * Verifies click events to check if hides or not toolbar
   * @HostListener sets the listeners once the directive is initialized and removes them automatically once the directive gets destroyed.
   */
  @HostListener('document:click', ['$event'])
  public onDocumentClick(event: MouseEvent): void {
    const targetElement = event.target as HTMLElement;

    // Check if the click was outside the element
    if (targetElement && !this.elementRef.nativeElement.contains(targetElement)) {
      this.editing = false;
    }
    else if (targetElement && this.elementRef.nativeElement.contains(targetElement)) {
      this.editing = true;
    }
  }

  private loadMessageTags(){
    return this.requestManager.get('{{SchoolBE}}/channelParameters',{filter:"{\"removed\":null, \"context\": \"message\"}"}).toPromise()
    .then(response => {
      let arr = [];
      response.forEach(tag => {
        arr.push({id: tag.name , value: tag.messageTag});
      });
      this._messageTagsArray = arr;
      return this._messageTagsArray;
    });
}
}
