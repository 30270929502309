import { Injectable } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { ModalService } from '../services/modal.service';
import { ConfigurationService } from '../utils/configuration.service';
import { RobotEngineModel } from '../robotEngineModel';
import { Utils } from '../utils/utils.service';
import { GlobalVarsService } from '../utils/global-vars.service';
import { AuthzService } from '../utils/authz.service';
import { JsonParams } from '../classes/jsonParams.class';
import { PageCustomValidatorService } from './page-custom-validations.service';
import { ApiService } from '../services/api.service';

import * as moment from 'moment/moment';

@Injectable()
export class PageService {

  public buttonsConfig = {
    page: null,
    modal: null
  };
  public wizardFormSteps: FormGroup[] = [];
  public wizardFormStepsModal: FormGroup[] = [];
  public activeForms: any = {};
  public dataRecs: any;
  public dataRecsMainInfo: Object;
  public viewStructure: JsonParams;
  public newMockStructure: JsonParams[] = [];
  public kc: AuthzService;
  public operationLoading = false;
  public errorDefs: any = {};
  public openedQuickViews: Object[] = [];

  // RESPONSIVE CONFIGURATONS
  public pageSize: number;
  public devices: any = {
    desktop: 1024,
    tablet: 768,
    smartphone: 500
  };

  private _defaultValidator: any = Validators.minLength(0);
  // private _baseInfoForm: FormGroup;

  constructor(
    private _utils: Utils,
    private _apiService: ApiService,
    private _globalVars: GlobalVarsService,
    private _robot: RobotEngineModel,
    private _fb: FormBuilder,
    private _config: ConfigurationService,
    private _customValidation: PageCustomValidatorService,
    private _modalService: ModalService) {
  }

  public deleteActiveForm(id: string) {
    delete this.activeForms[id];
  }

  public resetConfig() {
    this._apiService.deleteApiHistory();
    this.wizardFormSteps = [];
    this.errorDefs = {};
  }

  public setButtonsConfig(buttons: JsonParams, pageType: string) {
    this.buttonsConfig[pageType] = null;
    if (!buttons || buttons.parameters.length < 0)
      return;
    this.buttonsConfig[pageType] = {};
    for (let i in buttons.parameters)
      this.buttonsConfig[pageType][buttons.parameters[i].key || buttons.parameters[i].id] = buttons.parameters[i];
  }

  public configValidators(validateParameters: JsonParams[], loadingFromModal: boolean, formIndex: number = -1, formIndexMerge: boolean = false, formName?: string): FormGroup {

    if (!validateParameters || validateParameters.length <= 0)
      return;

    let ctlsConfig: Object = {};
    for (let i in validateParameters) {

      if (validateParameters[i].type == 'title')
        continue;

      let currParam: JsonParams = validateParameters[i];
      if (validateParameters[i].type == 'groupParameters') {
        for (let d in validateParameters[i].parameters) {
          currParam = validateParameters[i].parameters[d];
          ctlsConfig = this.configValidatorsEachParam(currParam, ctlsConfig);
        }
      } else {
        ctlsConfig = this.configValidatorsEachParam(currParam, ctlsConfig);
      }
    }
    let baseInfoForm: FormGroup = this._fb.group(ctlsConfig);
    let wizardLocation = 'wizardFormSteps' + (loadingFromModal ? 'Modal' : '');

    if (formName) {
      if (this.activeForms[formName]) {
        if (!this._utils.isObjectType(this.activeForms[formName], 'Array'))
          this.activeForms[formName] = [this.activeForms[formName]];
        this.activeForms[formName].push(baseInfoForm);

      } else
        this.activeForms[formName] = baseInfoForm;
    }


    if (formIndex >= 0) {

      if (formIndex == 0)
        this[wizardLocation] = [];

      if (this[wizardLocation][formIndex]) {
        if (formIndexMerge) {
          for (let c in baseInfoForm.controls) {

            if (this[wizardLocation][formIndex].contains(c))
              this[wizardLocation][formIndex].removeControl(c);

            this[wizardLocation][formIndex].addControl(c, baseInfoForm.controls[c])
          }
        } else
          this[wizardLocation].splice(formIndex, 0, baseInfoForm);
      } else
        this[wizardLocation][formIndex] = baseInfoForm;
    } else
      this[wizardLocation].push(baseInfoForm);

    return baseInfoForm;
  }

  public configValidatorsEachParam(parameter: JsonParams, ctlsConfig: Object): Object {

    if (!parameter)
      return;

    if (parameter.id) {
      let validator = this._defaultValidator;
      let validatorAsync = null;
      let errorDefs = {};
      if (parameter.validator) {
        let compose: any[] = [];
        let composeAsync: any[] = [];
        if (parameter.validator.required && parameter.validator.required.value) {
          errorDefs['required'] = parameter.validator.required.description;
          compose.push(Validators.required);
        }
        if (parameter.validator.minlength) {
          errorDefs['minlength'] = parameter.validator.minlength.description;
          compose.push(Validators.minLength(parameter.validator.minlength.value));
        }
        if (parameter.validator.maxlength) {
          errorDefs['maxlength'] = parameter.validator.maxlength.description;
          compose.push(Validators.maxLength(parameter.validator.maxlength.value));
        }
        if (parameter.validator.pattern) {
          errorDefs['pattern'] = parameter.validator.pattern.description;
          compose.push(Validators.pattern(parameter.validator.pattern.value));
        }
        if (parameter.validator.validatorFn) {
          for (let i in parameter.validator.validatorFn.value) {
            errorDefs[parameter.validator.validatorFn.value[i]] = parameter.validator.validatorFn.description[i];
            compose.push(this._customValidation.customValidator(parameter, parameter.validator.validatorFn.value[i], this._globalVars.getPageParametersAsArray()));
          }
        }

        validator = Validators.compose(compose);
        validatorAsync = Validators.composeAsync(composeAsync);
      }
      ctlsConfig[parameter.id] = this._fb.control('', validator, validatorAsync);
      this.errorDefs[parameter.id] = errorDefs;

      if (ctlsConfig[parameter.id] && (parameter.hidden || parameter.disabled))
        ctlsConfig[parameter.id].disable();
    }
    return ctlsConfig;
  }

  /**
   * GET TRANSLATION FROM LANGUAGE JSON
   */
  public i18n(payload: string) {
    return this._utils.i18n(payload);

    // TODO: VALIDAR PORQUE É QUE EXISTEM DOIS MÉTODOS IGUAIS - AQUI E NO UTILS.SERVICE
    // TODO: VALIDAR SE ESTE MÉTODO PODERÁ CHAMAR O DO UTILS DIRECTAMENTE PARA CENTRALIZAR A LÓGICA
    // if (!payload || typeof payload != 'string')
    //     return payload;
    // let response = this._utils.getValueFromDataForThisKey(this._config.i18nData, payload);
    // return response || payload;
  }

  public getUrlFromConfig(value: string) {

    value = value.replace(this._utils.mustacheReg, (match) => {
      try {
        return this._config.getEndpoint(match.slice(2, -2)) || match;
      } catch (e) {
        console.error('Function: getUrlFromConfig | Parameters: [' + value + '] | Error: ' + e);
        return match;
      }
    });
    return value;
  }

  public fillData(viewStructure: JsonParams, dataRecs, inputDataRecs, currUrlResource, forkJoin: number = -1, dataParameters: JsonParams[] = [], forkJoinData: Object = null): any {

    this._apiService.setApiHistory(currUrlResource, dataRecs);

    let dataRecsMainInfo: any = this._utils.findValues(currUrlResource['pathToMainData'], dataRecs) || [];

    /**
     * TODO: VALIDAR FORMA DE ELIMINAR ISTO!!!!!!!!! -----> Utilização de métodos Custom para remover esta opção!!!
     */
    // Caso haja alguma condição para a apresentação dos valores que nos chegam na resposta da API
    if (currUrlResource['responseValuesCondition']) {
      console.warn('responseValuesCondition: It is advised to use custom methods in "pathToMainData" to filter the API information!');
      let tempVar = {
        keepData: [],
        originalData: dataRecsMainInfo
      };
      for (let i in dataRecsMainInfo) {
        let myCondition = this._utils.replaceTagVars(currUrlResource['responseValuesCondition'], dataRecsMainInfo[i]).replace(this._utils.extendedMustacheReg, '').replace(this._utils.extendedMustacheReg, '');
        if (eval(myCondition))
          tempVar.keepData.push(i);
      }
      dataRecsMainInfo = [];
      for (let i in tempVar.keepData)
        dataRecsMainInfo.push(tempVar.originalData[tempVar.keepData[i]]);
      tempVar = undefined;
    }
    /**
     * TODO: VALIDAR FORMA DE ELIMINAR ISTO!!!!!!!!!
     */

    this.dataRecs = dataRecs;
    this.dataRecsMainInfo = dataRecsMainInfo;

    this._robot.updatePageWithData(viewStructure, dataRecsMainInfo, this._utils.arrToObj(this._globalVars.getPageParametersAsArray()), inputDataRecs, forkJoin, forkJoinData);

    return dataRecsMainInfo;
  }

  public setMyUrlResource(viewStructure: JsonParams, urlResource: any, params: Object, pagingSettings, inputDataRecs, filterConfig = {}, urlResourceFromValidator: JsonParams[] = null) {

    let resource = urlResource;
    let idSuffix: string = viewStructure.id.match(this._utils.uniqIdRegex) ? viewStructure.id.match(this._utils.uniqIdRegex)[0] : null;

    if (!resource)
      return null;

    let rtnValue = '';
    if (typeof resource === 'object') {

      resource = Object.assign({}, resource);

      if (resource['path'])
        rtnValue = typeof resource['path'] == 'string' ? resource['path'] : resource['path'].join('/');

      let paramsToRead = ['queryString', 'headers'];
      for (let p in paramsToRead) {
        let readingParam = paramsToRead[p];

        if (resource[readingParam]) {

          // RESET ÀS CONFIGURAÇÕES PARA O PARÂMETRO EM QUESTÃO (queryString ou headers)
          let readingParamConfig = this._utils.findObjectInArray(urlResourceFromValidator || viewStructure.groups.urlResource.parameters, readingParam).value;
          if (readingParamConfig)
            resource[readingParam] = readingParamConfig;

          let urlParams = [];
          for (let i in resource[readingParam]) {
            if (resource[readingParam][i] == '((page))' && params['page'])
              urlParams.push(i + '=' + params['page']['page']);
            else if (resource[readingParam][i] == '((offset))')
              urlParams.push(i + '=' + params['offset']);
            else if (pagingSettings && resource[readingParam][i] == '((pagesize))')
              urlParams.push(i + '=' + pagingSettings.itemsPerPage);
            else if (i == 'filter') {
              if (resource[readingParam][i] == '((filter))') {
                if (filterConfig && Object.keys(filterConfig).length > 0)
                  urlParams.push(i + '=' + JSON.stringify(filterConfig));
              } else {
                if (resource[readingParam][i]) {
                  try {
                    let setFilter = JSON.parse(this._utils.replaceTagVars(resource[readingParam][i], [inputDataRecs, this._utils.arrToObj(this._globalVars.getPageParametersAsArray())], idSuffix));
                    if (filterConfig && Object.keys(filterConfig).length > 0) {
                      for (let f in filterConfig) {
                        if (setFilter['$and']) {
                          let tempObj = {};
                          tempObj[f] = filterConfig[f];
                          setFilter['$and'].push(tempObj);
                        } else
                          setFilter[f] = filterConfig[f];
                      }
                    }
                    urlParams.push(i + '=' + JSON.stringify(setFilter));
                  } catch (e) {
                    urlParams.push(i + '=' + resource[readingParam][i]);
                  }
                } else
                  urlParams.push(i + '=' + JSON.stringify(filterConfig));
              }
            } else if (i == '((filter))') {
              if (filterConfig && Object.keys(filterConfig).length > 0) {
                for (let f in filterConfig) {
                  if (resource[readingParam]['((' + f + '))'])
                    continue;

                  let duplicatedParam = urlParams.findIndex(str => str.indexOf(f + '=') >= 0);
                  if (duplicatedParam >= 0)
                    urlParams.splice(duplicatedParam, 1);

                  urlParams.push(f + '=' + filterConfig[f]);
                }
              }
            } else if (i.indexOf('((') >= 0 && i.indexOf('))') >= 0) {
              if (filterConfig[i.slice(2, -2)]) {
                for (let f in resource[readingParam][i]) {
                  // let filterValue = filterConfig[i.slice(2, -2)][resource[readingParam][i][f].slice(2, -2)];
                  let filterValue = this._utils.getValueFromDataForThisKey(filterConfig[i.slice(2, -2)], resource[readingParam][i][f].slice(2, -2));
                  if (filterValue)
                    urlParams.push(f + '=' + filterValue);
                }
              }
            } else if ((!filterConfig || filterConfig[i] === undefined) && resource[readingParam][i] != null) {
              urlParams.push(i + '=' + resource[readingParam][i]);
            } else if (!filterConfig || filterConfig[i] === undefined)
              urlParams.push(i);
          }
          if (readingParam == 'headers') {
            let equalPosition: number;
            urlResource.headers = {};
            for (let u in urlParams) {
              equalPosition = urlParams[u].indexOf('=');
              urlResource.headers[urlParams[u].substring(0, equalPosition)] = this._utils.replaceTagVars(urlParams[u].substring(equalPosition + 1), [this._utils.arrToObj(this._globalVars.getPageParametersAsArray()), inputDataRecs], idSuffix);
            }
          } else
            rtnValue = rtnValue + (urlParams ? '?' + urlParams.join('&') : '');
        }

      }

    } else {
      rtnValue = resource;
    }

    if (urlResourceFromValidator) {
      return rtnValue;
    }
    rtnValue = this._utils.replaceTagVars(rtnValue, [inputDataRecs, this._utils.arrToObj(this._globalVars.getPageParametersAsArray())], idSuffix);

    // rtnValue = this._utils.replaceTagVars(rtnValue, inputDataRecs);
    // rtnValue = this._utils.replaceTagVars(rtnValue, this._utils.arrToObj(this._globalVars.getPageParametersAsArray()));
    // if (viewStructure.filter && viewStructure.filter.parameters)
    //     rtnValue = this._utils.replaceTagVars(rtnValue, this._utils.arrToObj(viewStructure.filter.parameters));

    rtnValue = rtnValue.replace(/(\d{2}([.\-\/])\d{2}[.\-\/]\d{4})( \d{2}:\d{2}:\d{2})?/g, key => new Date(this._utils.formatDatesToRequest(key)).toISOString());
    return this.getUrlFromConfig(rtnValue + (params['data'] || ''));
  }

  public startApiConfiguration(urlResourceToFormat: JsonParams[], inputDataRecs: Object) {

    let urlResource = null;
    if (!urlResourceToFormat)
      return urlResource;

    // Estrutura do endpoint para chamada à API
    urlResource = {};
    for (let i in urlResourceToFormat) {
      urlResource[urlResourceToFormat[i].key] = urlResourceToFormat[i].value;
    }

    if (urlResource['requestBody'])
      urlResource.requestBody = this._utils.replaceTagVars(urlResource['requestBody'], inputDataRecs);

    return urlResource;
  }

  public getParameterByName = (name: string, myUrl: string = window.location.href): string => this._globalVars.getQueryParameters()[name];
  public evaluatePageComponents = () => this._robot.updateDynamicPropsOnPageParameters();

  public createTableConfigObject(parameter: JsonParams): JsonParams {

    let tableConfig: JsonParams = this._globalVars.getPageParameter('config::' + parameter.id);
    if (!tableConfig) {
      tableConfig = new JsonParams;
      tableConfig.id = 'config::' + parameter.id;
      tableConfig.value = {
        pagingSettings: {},
        sort: {},
        filter: {},
        selectedRowsConfig: {
          selected: [],
          unselected: [],
          checkAll: false
        }
      };
    }
    return tableConfig;
  }

  public updateConfigParameterPaging(tableConfig: JsonParams, value: any, attr?: string) {
    if (attr) {
      tableConfig.value['pagingSettings'] = tableConfig.value['pagingSettings'] || {};
      tableConfig.value['pagingSettings'][attr] = value;
      return;
    }
    tableConfig.value['pagingSettings'] = value;
  }
  public updateConfigParameterSort(tableConfig: JsonParams, column: JsonParams) {
    tableConfig.value['sort'] = {
      field: column ? (column.apiFieldName || column.id) : '',
      direction: column ? column.sort : ''
    };
  }
}
