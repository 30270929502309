import { CustomEventManagerService } from '../../customComponents/custom-event-manager.service';
import { Component, Input, OnInit, OnDestroy, Output, EventEmitter } from '@angular/core';
import { PageService } from './../../foundations-webct-robot/robot/pageComponent/page.service';
import { Utils } from './../../foundations-webct-robot/robot/utils/utils.service';
import { GlobalVarsService } from './../../foundations-webct-robot/robot/utils/global-vars.service';
import { JsonParams } from './../../foundations-webct-robot/robot/classes/jsonParams.class';
import { Intent, IntentsService } from '../intent.service';
// import { ModalService } from './../../foundations-webct2-palette/components/modalComponent/modal.service';
import { ModalService} from './../../foundations-webct-robot/robot/services/modal.service';



export enum KEY_CODE {
  UP_ARROW = 38,
  DOWN_ARROW = 40
}


@Component({
  selector: 'app-intent-list',
  templateUrl: './intent-list.component.html',
  styleUrls: ['./intent-list.component.css']
})
export class IntentListComponent implements OnInit, OnDestroy {


  @Input() public inputParameters: JsonParams;
  @Input() public filterDateField = 'creationDate';
  @Input() public dataRecsMainInfo;
  @Input() public program;
  @Input() public dataRecsNoContent;
  @Input() public editMode: any;
  @Output() intentSelected = new EventEmitter();

  @Input() searchMode: any;
  @Output() searchModeChange = new EventEmitter<string>();

  public selectedRow = 0;
  private _componentId: string;
  //private editorLoadedSubscription;
  private newIntentCreatedSubscription;
  private createNewIntentSubscription;

  @Input() filterArgument: string = "";
  @Output() filterArgumentChange = new EventEmitter<string>();

  public filterArgumentForTextHighlight = "";


  constructor(
    public pageService: PageService,
    private _utils: Utils,
    private _globalVars: GlobalVarsService,
    private _modalService: ModalService,
    private eventManager: CustomEventManagerService,
    public intentsService: IntentsService) {

    this._componentId = 'TABLE-' + this._utils.guid(4, '');
  }

  public ngOnInit() {

    //this.intentsService.setProgram(this.program);
    //this.code = this.intentsService.program.code;
    //this.rows = this.intentsService.getProgramIntents();
    this.searchMode = "INTENT";
    //this.filter();


    this.newIntentCreatedSubscription = this.eventManager.newIntentCreated.subscribe(
      () => {
        this.selectedRow = this.intentsService.getProgramIntents().length - 1;
      }
    );

    this.createNewIntentSubscription = this.eventManager.createNewIntent.subscribe(
      () => {
        this.createIntent()
      }
    );



  }


  /*   @HostListener('window:keyup', ['$event'])
    keyEvent(event: KeyboardEvent) {
      console.log(event);

      if (event.keyCode === KEY_CODE.UP_ARROW) {
        this.selectedRow--;
      }

      if (event.keyCode === KEY_CODE.DOWN_ARROW) {
        this.selectedRow++;
      }
    } */



  public ngOnDestroy() {
    this._globalVars.deletePageParametersByGroup(this._componentId);
    /*     if (this.editorLoadedSubscription){
          this.editorLoadedSubscription.unsubscribe();
        } */

    if (this.newIntentCreatedSubscription) {
      this.newIntentCreatedSubscription.unsubscribe();
    }

    if (this.createNewIntentSubscription) {
      this.createNewIntentSubscription.unsubscribe();
    }

  }


  @Input()
  set currentIntent(intent: Intent) {
    //find intent on list
    if (intent) {
      let index = this.intentsService.getIntentIndex(intent);
      this.selectedRow = index;
    }

  }



  public onSelectRow(event, index, row) {
    this.selectedRow = index;
    this.intentSelected.emit([row, this.filterArgument]);
  }

  public getDisplayNameFormated(displayName: string, beginCharacters?: string) {
    return this.intentsService.getDisplayNameFormated(displayName, beginCharacters);
  }

  /*   private searchOnNameAndDescription(intent:Intent){
      intent.description.toLocaleUpperCase().
    } */

  private searchOnNameAndDescription = (query) => {
    return this.intentsService.getProgramIntents().filter((el) =>
      (el.description || '').toLowerCase().indexOf(query.toLowerCase()) > -1 || el.displayName.toLowerCase().indexOf(query.toLowerCase()) > -1
    );
  }

  public deleteIntent(intent) {
    let operation = JSON.parse('{"size":"md", "id":"deleteGroupModal"}');
    let parameters = [];
    let jsonParams = new JsonParams();

    jsonParams.key = "context";
    jsonParams.value = [
      'originalName',
      'displayName'
    ];
    parameters.push(jsonParams);
    let obj = { 'originalName': intent.displayName, 'displayName': intent.name }

    this._modalService.openModal('programs/edit-content/program-intent-remove', parameters, obj, operation);
  }



  public addIntent() {

  }

  public createIntent() {
    let operation = JSON.parse('{"size":"md", "id":"deleteGroupModal"}');
    let parameters = [];
    let jsonParams = new JsonParams();

    /*     jsonParams.key = "context";
        jsonParams.value = [
          'originalName',
          'displayName'
        ];
        parameters.push(jsonParams);
        let obj = { 'originalName': intent.displayName, 'displayName': intent.name } */
    let obj = {};
    this._modalService.openModal('programs/edit-content/program-intent-create', parameters, obj, operation);
  }

  private searchOnTrainingPhrases = (query) => {
    return this.intentsService.getProgramIntents().filter((el) =>
      JSON.stringify(el.trainingPhrases).toLowerCase().indexOf(query.toLowerCase()) > -1
    );
  }

  public filter() {
    this.filterArgumentChange.emit(this.filterArgument);
    if (this.filterArgument == "") {
      this.intentsService.program.intents.map((intent) => {
        intent.showOnList = true;
        return intent;
      });
      this.filterArgumentForTextHighlight = "";
    } else {
      if (this.searchMode === "INTENT") {
        this.filterArgumentForTextHighlight = this.filterArgument;
        this.intentsService.program.intents.map((intent) => {
          intent.showOnList = intent.name.toLowerCase().indexOf(this.filterArgument.toLowerCase()) > -1;
          return intent;
        });
      } else {
        this.filterArgumentForTextHighlight = "";
        this.intentsService.program.intents.map((intent) => {
          intent.showOnList = JSON.stringify(intent.trainingPhrases).toLowerCase().indexOf(this.filterArgument.toLowerCase()) > -1;
          return intent;
        });
      }
    }
  }


  public changeSearchMode() {
    if (this.searchMode === "INTENT") {
      this.searchMode = "TRAINIGN-PHRASES";
    } else {
      this.searchMode = "INTENT";
    }
    this.filter();
    this.searchModeChange.emit(this.searchMode);
  }


}
