import * as moment from 'moment/moment';

export class UtilsCustomService {

  protected bytesToSize(bytes: number) {
    let sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB'];
    if (bytes == 0)
      return '0 Bytes';
    let i = Math.floor(Math.log(bytes) / Math.log(1000));
    return Math.round(bytes / Math.pow(1000, i)) + ' ' + sizes[i];
  }

  protected secondsToHours(seconds: number) {
    var hours = Math.floor(seconds / 3600);
    var minutes = Math.floor(seconds % 3600 / 60);
    var seconds = Math.floor(seconds % 3600 % 60);
    return ((hours > 0 ? hours + "h:" + (minutes < 10 ? "0" : "") : "") + minutes + "m:" + (seconds < 10 ? "0" : "") + seconds + "s");
  }

  public dynamicDateFromNow(timeGap, timeUnit: string, timeGapDirection: string): string {

    const timeUnitValidOpts = ['days', 'weeks', 'months', 'years'];
    const errorMsg1 = 'WEBCT dynamicDateFromNow error -> second parameter must be one of the following:\n\'days\', \'weeks\', \'months\', \'years\'';
    const errorMsg2 = 'WEBCT dynamicDateFromNow error -> third parameter must be one of the following:\n\'past\', \'future\'';

    if (timeUnitValidOpts.filter((opt) => timeUnit === opt).length === 0)
      console.error(errorMsg1);

    if (!(timeGapDirection === 'past' || timeGapDirection === 'future'))
      console.warn(errorMsg2);

    if (timeGapDirection === 'past') {
      return moment().subtract(timeGap, timeUnit).format('DD-MM-YYYY');
    } else {
      return moment().add(timeGap, timeUnit).format('DD-MM-YYYY');
    }
  }

  public browserLanguage(lang: string) {

    if (!lang) {
      console.error('Error: browserLanguage() -> Missing default language configuration');
      return;
    }
    let languageSupported = !!sessionStorage ? sessionStorage.languageSupported : undefined;
    if (languageSupported) {
      let supportedLanguages = JSON.parse(languageSupported);
      return supportedLanguages.indexOf(navigator.language) >= 0 ? navigator.language : lang;
    } else
      console.error('Error: browserLanguage() -> Missing "languageSupported" configuration');

    return lang;
  }

  public forceArray = (val: any): any[] => val.constructor !== Array ? [val] : val;

  /**
   * Devolve a página que deverá ser chamada num pedido de Infinite Scroll
   * @param config é o objecto JsonParams que guarda a configuração do componente "wct-infinite-scroll" em questão
   */
  public infiniteScrollPage = (config: any) => config && config.value && config.value.page ? config.value.page : 1;

  public formatValueList(list: any[], key: string, value: string, data?: Object) {

    if (!list)
      return [];

    return this.forceArray(list).map(obj => {
      let rtn = typeof obj == 'string' ? this._valueListMainFormat(obj, obj) : this._valueListMainFormat(obj[key], obj[value]);
      if (data) {
        rtn['data'] = new Object();
        for (let i in data)
          rtn['data'][i] = obj[data[i]];
      }
      return rtn;
    });

  }
  private _valueListMainFormat = (key: string, value: string) => new Object({ key, value });

}
