import { AbstractControl } from '@angular/forms';
import { Validator, ValidatorDefs } from '../classes/validator.class';
import { JsonParams } from '../classes/jsonParams.class';

export class PageCustomValidatorService {

  public customValidator(parameter: JsonParams, fnName: string, parametersWidthData: JsonParams[]) {
    return (control: AbstractControl): { [key: string]: any } => {
      return null;
    };
  }
}
