import { InvoiceComponent } from './customComponents/print/invoice/invoice.component';
import { PrintLayoutComponent } from './customComponents/print/print-layout/print-layout.component';
import { Routes } from '@angular/router';

// import { PageComponent } from './foundations-webct-robot/robot/pageComponent/page.component';
import { WctPageComponent } from './foundations-webct2-palette/components/wct-page/wct-page.component';

export const ROUTES: Routes = [
  {
    path: '',
    component: WctPageComponent,
    data: {
      mock: 'app-home'
    },
    // redirectTo: '/statistics',
    // pathMatch: 'full'
  },
  // -------STUDENTS-----------
  {
    path: 'superheros',
    component: WctPageComponent,
    data: {
      mock: 'superheros/superhero-list',
      breadcrumb: {
        trail: [
          '{{IXS_name}}',
          'Superheros'
        ]
      }
    }
  },
  {
    path: 'superheros/create',
    component: WctPageComponent,
    data: {
      mock: 'superheros/superhero-create',
      breadcrumb: {
        trail: [
          '{{IXS_name}}',
          'Superheros',
          'Create'
        ],
        trailRoutes: [
          '/knowledgecenter',
          '/superheros'
        ]
      }
    }
  },
  {
    path: 'superheros/:id',
    component: WctPageComponent,
    data: {
      mock: 'superheros/superhero-detail',
      navBarMock: 'superheros/superhero-sliding-navbar',
      breadcrumb: {
        trail: [
          '{{IXS_name}}',
          'Superheros',
          '[[id]]'
        ],
        trailRoutes: [
          '/knowledgecenter',
          '/superheros'
        ]
      }
    }
  },
  {
    path: 'superheros/:id/programs',
    component: WctPageComponent,
    data: {
      mock: 'superheros/superhero-program-list',
      breadcrumb: {
        trail: [
          '{{IXS_name}}',
          'Superheros',
          '[[id]]',
          'Programs'
        ],
        trailRoutes: [
          '/knowledgecenter',
          '/superheros',
          '/superheros/[[id]]']
      }
    }
  },
  {
    path: 'superheros/:id/program/:programID',
    component: WctPageComponent,
    data: {
      mock: 'programs/program-details',
      navBarMock: 'programs/program-sliding-navbar',
      breadcrumb: {
        trail: [
          '{{IXS_name}}',
          'Superheros',
          '[[id]]',
          'Programs',
          '[[programID]]'

        ],
        trailRoutes: [
          '/knowledgecenter',
          '/superheros',
          '/superheros/[[id]]',
          '/superheros/[[id]]/programs'
        ]
      }
    }
  },
  {
    path: 'superheros/:id/dialogs',
    component: WctPageComponent,
    data: {
      mock: 'dialogs/superheros-dialogs',
      breadcrumb: {
        trail: [
          '{{IXS_name}}',
          'Superheros',
          '[[id]]',
          'Dialogs'
        ],
        trailRoutes: [
          '/knowledgecenter',
          '/superheros',
          '/superheros/[[id]]']
      }
    }
  },
  {
    path: 'superheros/:id/testSets',
    component: WctPageComponent,
    data: {
      mock: 'superheros/superhero-testSets-main',
      breadcrumb: {
        trail: [
          '{{IXS_name}}',
          'Superheros',
          '[[id]]',
          'TestSets'
        ],
        trailRoutes: [
          '/knowledgecenter',
          '/superheros',
          '/superheros/[[id]]']
      }
    }
  },
  {
    path: 'superheros/:id/statistics',
    component: WctPageComponent,
    data: {
      mock: 'superheros/superhero-statistics',
      breadcrumb: {
        trail: [
          '{{IXS_name}}',
          'Superheros',
          '[[id]]',
          'Statistics'
        ],
        trailRoutes: [
          '/knowledgecenter',
          '/superheros',
          '/superheros/[[id]]'
        ]
      }
    }
  },
  {
    path: 'superheros/:id/plan',
    component: WctPageComponent,
    data: {
      mock: 'superheros/superhero-plan',
      breadcrumb: {
        trail: [
          '{{IXS_name}}',
          'Superheros',
          '[[id]]',
          'Plan'
        ],
        trailRoutes: [
          '/knowledgecenter',
          '/superheros',
          '/superheros/[[id]]'
        ]
      }
    }
  },
  {
    path: 'superheros/:id/fallbacks',
    component: WctPageComponent,
    data: {
      mock: 'superheros/superhero-fallbacks',
      breadcrumb: {
        trail: [
          '{{IXS_name}}',
          'Superheros',
          '[[id]]',
          'Fallbacks'
        ],
        trailRoutes: [
          '/knowledgecenter',
          '/superheros',
          '/superheros/[[id]]'
        ]
      }
    }
  },
  {
    path: 'superheros/superhero-create-payPlan',
    component: WctPageComponent,
    data: {
      mock: 'superheros/superhero-create-payPlan',
      breadcrumb: {
        trail: [
          '{{IXS_name}}',
          'Superheros',
          'Create Pay Plan'
        ],
        trailRoutes: [
          '/knowledgecenter',
          '/superheros'
        ]
      }
    }
  },

  // -------SUBSCRIPTION-----------
  {
    path: 'learnMore',
    component: WctPageComponent,
    data: {
      mock: 'subscription/learnMore',
      breadcrumb: {
        trail: [
          '{{IXS_name}}',
          'Knowledge Center'
        ]
      }
    }
  },
  // -------SUBSCRIPTION-----------
  {
    path: 'knowledgecenter',
    component: WctPageComponent,
    data: {
      mock: 'subscription/knowledgecenter',
      breadcrumb: {
        trail: [
          '{{IXS_name}}',
          'Knowledge Center'
        ]
      }
    }
  },
  {
    path: 'knowledgecenter/programs/:programID',
    component: WctPageComponent,
    data: {
      mock: 'programs/program-details',
      navBarMock: 'programs/program-sliding-navbar',
      breadcrumb: {
        trail: [
          '{{IXS_name}}',
          'Knowledge Center',
          'Programs',
          '[[programID]]'
        ],
        trailRoutes: [
          '/knowledgecenter',
          '/knowledgecenter',
          '/knowledgecenter/programs'
        ]
      }
    }
  },
  {
    path: 'knowledgecenter/programs/:programID/designFlow',
    component: WctPageComponent,
    data: {
      mock: 'programs/program-details-designFlow'
    }
  },
  // ---------COURSES------------
  {
    path: 'knowledgecenter/courses',
    component: WctPageComponent,
    data: {
      mock: 'courses/course-list',
      breadcrumb: {
        trail: [
          '{{IXS_name}}',
          'Knowledge Center',
          'Courses'
        ],
        trailRoutes: [
          '/knowledgecenter',
          '/knowledgecenter'
        ]
      }
    }
  },
  {
    path: 'knowledgecenter/courses/:id',
    component: WctPageComponent,
    data: {
      mock: 'courses/course-details',
      breadcrumb: {
        trail: [
          '{{IXS_name}}',
          'Knowledge Center',
          'Courses',
          '[[id]]'
        ],
        trailRoutes: [
          '/knowledgecenter',
          '/knowledgecenter',
          '/knowledgecenter/courses'
        ]
      }
    }
  },

  // **************************SUBSCRIPTION API**************************************//
  {
    path: 'integrations',
    component: WctPageComponent,
    data: {
      mock: 'integrations/integrations-list',
      breadcrumb: {
        trail: [
          '{{IXS_name}}'
        ]
      }
    }
  },
  {
    path: 'integrations/:name',
    component: WctPageComponent,
    data: {
      mock: 'integrations/integrations-bridge',
      breadcrumb: {
        trail: [
          '{{IXS_name}}'
        ]
      }
    }
  },
  {
    path: 'fullfilments',
    component: WctPageComponent,
    data: {
      mock: 'fullfilment/fullfilment-list',
      breadcrumb: {
        trail: [
          '{{IXS_name}}'
        ]
      }
    }
  },
  {
    path: 'fullfilments/create',
    component: WctPageComponent,
    data: {
      mock: 'fullfilment/fullfilment-create',
      breadcrumb: {
        trail: [
          'requests___entityName'
        ]
      }
    }
  },
  {
    path: 'fullfilments/import',
    component: WctPageComponent,
    data: {
      mock: 'fullfilment/fullfilment-import',
      breadcrumb: {
        trail: [
          'requests___entityName'
        ]
      }
    }
  },
  {
    path: 'fullfilments/edit/:id',
    component: WctPageComponent,
    data: {
      mock: 'fullfilment/fullfilment-edit',
      breadcrumb: {
        trail: [
          'requests___entityName'
        ]
      }
    }
  },
  {
    path: 'schedulers',
    component: WctPageComponent,
    data: {
      mock: 'scheduler/scheduler-list',
      breadcrumb: {
        trail: [
          '{{IXS_name}}'
        ]
      }
    }
  },
  {
    path: 'schedulers/create',
    component: WctPageComponent,
    data: {
      mock: 'scheduler/scheduler-create',
      breadcrumb: {
        trail: [
          'requests___entityName'
        ]
      }
    }
  },
  {
    path: 'schedulers/edit/:id',
    component: WctPageComponent,
    data: {
      mock: 'scheduler/scheduler-edit',
      breadcrumb: {
        trail: [
          'requests___entityName'
        ]
      }
    }
  },
  {
    path: 'schedulers/execution/:id',
    component: WctPageComponent,
    data: {
      mock: 'scheduler/scheduler-execution',
      breadcrumb: {
        trail: [
          'requests___entityName'
        ]
      }
    }
  },
  {
    path: 'channelParameters',
    component: WctPageComponent,
    data: {
      mock: 'channelParameter/channelParameter-list',
      breadcrumb: {
        trail: [
          '{{IXS_name}}'
        ]
      }
    }
  },
  {
    path: 'channelParameters/create',
    component: WctPageComponent,
    data: {
      mock: 'channelParameter/channelParameter-create',
      breadcrumb: {
        trail: [
          'requests___entityName'
        ]
      }
    }
  },
  {
    path: 'channelParameters/edit/:id',
    component: WctPageComponent,
    data: {
      mock: 'channelParameter/channelParameter-edit',
      breadcrumb: {
        trail: [
          'requests___entityName'
        ]
      }
    }
  },
  {
    path: 'tests',
    component: WctPageComponent,
    data: {
      mock: 'tests/tests-list',
      breadcrumb: {
        trail: [
          '{{IXS_name}}'
        ]
      }
    }
  },
  {
    path: 'tests/edit/:id',
    component: WctPageComponent,
    data: {
      mock: 'tests/tests-edit',
      breadcrumb: {
        trail: [
          'requests___entityName'
        ]
      }
    }
  },
  {
    path: 'asrtunning',
    component: WctPageComponent,
    data: {
      mock: 'asrtunning/asr-tunning-list',
      breadcrumb: {
        trail: [
          '{{IXS_name}}'
        ]
      }
    }
  },
  {
    path: 'verticals',
    component: WctPageComponent,
    data: {
      mock: 'verticals/integrations-list',
      breadcrumb: {
        trail: [
          '{{IXS_name}}'
        ]
      }
    }
  },

  {
    path: 'print',
    outlet: 'print',
    component: PrintLayoutComponent,
    children: [
      { path: 'invoice/:invoiceIds', component: InvoiceComponent }
    ]
  },
  {
    path: 'superheros/:superheroId/print',
    outlet: 'print',
    component: PrintLayoutComponent,
    children: [
      { path: 'payments/:paymentId', component: InvoiceComponent }
    ]
  },
  // TOURS
  {
    path: 'tours',
    component: WctPageComponent,
    data: {
      mock: 'tours/tours-list',
      breadcrumb: {
        trail: [
          '{{IXS_name}}'
        ]
      }
    }
  },
  {
    path: '**',
    redirectTo: ''
  }
];
