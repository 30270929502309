import {Component, OnInit, ElementRef, Renderer2, ViewChild} from '@angular/core';
// import { ModalComponent } from 'foundations-webct-palette/components/modalComponent/modal.component';
// import { ModalService } from 'foundations-webct-palette/components/modalComponent/modal.service';
import { ModalService } from './../../../foundations-webct-robot/robot/services/modal.service';
import { ComponentsService } from './../../../foundations-webct-robot/robot/services/components.service';

@Component({
  selector: 'app-wct-modal',
  templateUrl: './wct-modal.component.html'
  // styleUrls: ['./wct-modal.component.scss']
})

export class WctModalComponent implements OnInit {

  @ViewChild('modalBounds') el: ElementRef;

  public rootNode: HTMLElement;
  public draggableBounds: Object;
  public inBounds = true;

  public edge: EdgeProperties = {
    top: true,
    bottom: true,
    left: true,
    right: true
  };

  constructor(
    public modalService: ModalService,
    private _rd: Renderer2,
    private _components: ComponentsService) { }


  public getHtmlId = (type: string = 'modal') => this._components.getHtmlId(this.modalService.modalProps, type);

  public ngOnInit() {
    this.rootNode = document.getElementsByTagName('body')[0];
  }

  public ngDoCheck() {
    if (this.modalService.isModalShown && this.el) {
      this._rd.setStyle(this.el.nativeElement, 'z-index', -100);
      this._rd.setStyle(this.el.nativeElement, 'width', this.rootNode.clientWidth + 'px');
      this._rd.setStyle(this.el.nativeElement, 'height', this.rootNode.clientHeight + 'px');
    }
  }

  public showEdge(e) {
    this.edge = e;
  }

  public onStart(event) {
    document.getElementsByTagName('body')[0].classList.add('dragging-noselect');
  }

  public onStop(event) {
    document.getElementsByTagName('body')[0].classList.remove('dragging-noselect');
  }
}

interface EdgeProperties {
  top: boolean;
  bottom: boolean;
  left: boolean;
  right: boolean;
}

