import { Injectable } from '@angular/core';

import { PageService } from './../../../foundations-webct-robot/robot/pageComponent/page.service';
import { Utils } from './../../../foundations-webct-robot/robot/utils/utils.service';

@Injectable()
export class BreadcrumbService {

  private _breadcrumb: BreadcrumbDataType[];

  constructor(
    private _utils: Utils,
    private _pageService: PageService) {
  }

  public setBreadcrumb(value: BreadcrumbDataType[], dataRecs: Object | Object[] = null) {
    this._breadcrumb = [];
    for (let obj of value) {
      if (!obj.name)
        continue;
      this._breadcrumb.push({
        id: this._utils.replaceTagVars(obj.name, dataRecs),
        name: this._formatUrlName(this._utils.replaceTagVars(obj.name, dataRecs)),
        route: obj.route ? this._utils.replaceTagVars(obj.route, dataRecs) : null
      });
    }
  }

  public getBreadcrumb(): BreadcrumbDataType[] {
    return this._breadcrumb;
  }

  private _formatUrlName(name: string) {
    name = this._pageService.i18n(name);
    return name && name.length > 25 ? name.substring(0, 25) + '..' : name;
  }

}

export interface BreadcrumbDataType {
  name: string;
  id?: string;
  route?: string;
}
