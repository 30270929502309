import { CustomValidator } from '../../customValidators.service';
import { FormBuilder, Validators, FormGroup } from '@angular/forms';
import { TestService} from '../test.service';
import { CustomEventManagerService } from '../../customComponents/custom-event-manager.service';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Component, OnInit, Input } from '@angular/core';
// import { ModalService } from './../../foundations-webct2-palette/components/modalComponent/modal.service';
import { ModalService} from '../../foundations-webct-robot/robot/services/modal.service';

// import { NotificationService } from './../../foundations-webct2-palette/components/notificationComponent/notification.component';
import { WctNotificationService } from '../../foundations-webct2-palette/components/wct-notification/wct-notification.service';

@Component({
  selector: 'test-creation',
  templateUrl: './test-creation.component.html',
  styleUrls: ['./test-creation.component.scss']
})
export class TestCreationComponent implements OnInit {
  @Input() inputParameters;
  @Input() inputDataRecs;
  @Input() dataRecs;
  public name = "";
  public description = "";

  createIntentForm: FormGroup;
  duplicatedNameError = false;
  submitted = false;

  httpOptions = {
    headers: new HttpHeaders({
      'Accept': 'application/json; charset=utf-8',
      'Cache-Control': 'no-cache',
      'Authorization': 'Bearer ' + localStorage.token,
      'IXS': localStorage.IXS_id
    })
  };

  constructor(
    private modalService: ModalService,
    private testsService: TestService,
    private eventManager: CustomEventManagerService,
    private formBuilder: FormBuilder,
    private customValidator: CustomValidator,
    // private notificationService: NotificationService) { }
    private notificationService: WctNotificationService) { }

  ngOnInit() {
    this.createIntentForm = this.formBuilder.group({
      intentName: ['', [Validators.required, this.customValidator.preventSpecialCharacters]],
      intentDescription: ['']
    });
  }

  public validateName() {
    //this.duplicatedNameError = this.testsService.intentExists(this.name.trim());
    console.log("validateName");
  }

  public cancel(){
    this.modalService.closeModal();
  }

  public create(){

    if (!this.isValidForm() || this.submitted) {
      return;
    }
    this.submitted = true;

    this.testsService.createStep(this.name, this.description).then(
      (resp) => {
        this.notificationService.showSuccess(
          "Create Mock",
          "Mock successfully created!",
          { timeout: { success: 700 }, positionClass: { success: 'top-right' }, limit: { success: 1 } }
        );
        this.testsService.reloadProgram().then(
          response => {
            this.eventManager.sendNewIntentCreated();
            this.modalService.closeModal();
          }
        );
      },
      (resp) => {
        this.notificationService.showError(
          "Mock Error",
          "Mock  creation error!",
          { timeout: { error: 700 }, positionClass: { error: 'top-right' }, limit: { error: 1 } }
        );
        console.log(resp);
        this.submitted = false;
      }
    );
  }


  public isValidForm(){
    return !(this.createIntentForm.invalid || this.duplicatedNameError);
  }

}
