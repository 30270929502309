import { CustomValidator } from '../../customValidators.service';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { WctNotificationService } from '../../foundations-webct2-palette/components/wct-notification/wct-notification.service';
import { TestService, Intent } from '../test.service';
// import { HttpClient, HttpHeaders } from '@angular/common/http';
// import { GlobalVarsService } from './../../foundations-webct-robot/robot/utils/global-vars.service';
// import { ModalService } from './../../foundations-webct2-palette/components/modalComponent/modal.service';
// import { ModalService} from './../../foundations-webct-robot/robot/services/modal.service';
declare var require: any;
import '../../../assets/mode-traningPhrases';
import '../../../assets/theme-traningPhrases';
import { Component, Input, AfterViewInit, ViewChild, OnInit, OnDestroy, EventEmitter, Output, ElementRef } from '@angular/core';
import { JsonParams, newEvent } from '../../foundations-webct-robot/robot/classes/jsonParams.class';
// import { PageService } from './../../foundations-webct-robot/robot/pageComponent/page.service';
import { AceEditorComponent } from 'ng2-ace-editor';
import { CustomEventManagerService } from '../../customComponents/custom-event-manager.service';

import 'brace';
import 'brace/ext/searchbox';
import 'brace/ext/language_tools';

var UndoManager = require('brace').acequire("ace/undomanager").UndoManager;


@Component({
  selector: 'app-testSet-ExplainTest',
  templateUrl: 'testSet-ExplainTest.component.html',
  styleUrls: ['testSet-ExplainTest.component.css']
})
export class TestSetExplainTest implements OnInit, OnDestroy {


  @Input() public inputParameters: JsonParams;
  @Input() public dataRecs;


  private _filterArgument
  
  @Output() currentIntentChange = new EventEmitter<Intent>();

  @ViewChild('editor') aceEditor: AceEditorComponent;
  @Output() textChanged = new EventEmitter();

  //@Input() public currentIntent;

  private editor;

  public showInfo: boolean = true;

  public _currentIntent;

  public selectedTestSetDesc:string = ""


  //public newIntentNameValue: string = "";

  public newIntentDescriptionValue: string = "";
  private newVariableCreatedSubscription;

  private intentVariables;
  private programVariables;
  private newIntentCreatedSubscription;

  private variableColorsArray = [];

  /*   public intentName = "";
    public intentDescription = ""; */
  public isEditable = false;
  //public currentIntent;

  public intentNameForm: FormGroup;
  public duplicatedNameError: Boolean = false;

  public _selectedTestSet:any;

  public statusTestSetSelected:string ="";
  public dateTestSetSelected:string ="";
  

  public testsFails=[];

  value: string = "";
  ind: number=0;
  _ind: number=0;

  selectedError: string = "";
  infoError: string = "";
  qError: string = "";
  eError: string = "";
  rError: string = "";
  objectToSend = [];

  intentDetectionConfidence:number=0;
  intentCompentence:string="";
  step:string="";
  line:string="";
  code:string="";
  similarity:string="";
  mockErrorSelected:string="";
  intentDetection:string="";
  intentRanking :[]=[];
  entities :any;//[{'key':string, 'entity': any, 'value': any, 'confidence':any}];



  summary: string="";

 


  /*   httpOptions = {
      headers: new HttpHeaders({
        'Accept': 'application/json; charset=utf-8',
        'Authorization': 'Bearer ' + localStorage.token,
        'IXS': localStorage.IXS_id
      })
    }; */

  constructor(
    //private _modalService: ModalService,
    private eventManager: CustomEventManagerService,
    public testsService: TestService,
    //private globalVars: GlobalVarsService,
    private formBuilder: FormBuilder,
    public customValidator: CustomValidator,
    public notificationService: WctNotificationService) {
  }


  public ngOnInit() {

    

    // this.intentNameForm = this.formBuilder.group({
    //   newIntentNameValue: ['', [Validators.required, this.customValidator.preventSpecialCharacters]]
    // });



    // this.editor = this.aceEditor.getEditor();

    // this.newVariableCreatedSubscription = this.eventManager.newVariableCreated.subscribe(
    //   (teste) => {
    //     this._updateValue();
    //   }
    // );



    // if (!this.inputParameters) {
    //   this.inputParameters = new JsonParams();
    //   return;
    // }

    // this.value = this.inputParameters.value;

    // this.value = "";
    // this.inputParameters.checked = true;

    //console.log("this.currentTestSet.description: ", this._selectedTestSet.description)

    //this.editor.setValue(this._selectedTestSet.description, 1);

  }

  public setVars(){
    console.log("this.setVars");
    this.mockErrorSelected="";
  }

  ngOnDestroy(): void {
    if (this.newVariableCreatedSubscription) {
      this.newVariableCreatedSubscription.unsubscribe();
    }
  }

  public errorDetails(i:number){
    console.log("errorDetails: " + i);
    this.mockErrorSelected = "";

    this.ind = 1 + i;
    this.selectedError = "Error " + this.ind;
 
    this.qError=this._selectedTestSet.tests[0].results.fails[i].question;
    this.eError=this._selectedTestSet.tests[0].results.fails[i].expectedResponse;
    this.rError=this._selectedTestSet.tests[0].results.fails[i].response;


    this._ind=this.ind-1;


    this.intentDetectionConfidence=this._selectedTestSet.tests[0].results.fails[i].confidence;
    this.intentDetection=this._selectedTestSet.tests[0].results.fails[i].intent;
    this.intentCompentence=this._selectedTestSet.tests[0].results.fails[i].competence;
    this.step=this._selectedTestSet.tests[0].results.fails[i].step;
    this.line=""+this._selectedTestSet.tests[0].results.fails[i].line;
    this.similarity=this._selectedTestSet.tests[0].results.fails[i].similarity;
    this.intentRanking=this._selectedTestSet.tests[0].results.fails[i].intentRanking;
    var arrEntities=this._selectedTestSet.tests[0].results.fails[i].entities;

    this.code=this._selectedTestSet.tests[0].results.fails[i].code;

    this.testsService.getSpecificMock(this._selectedTestSet, this.step, this.line);

    if(this.entities){
      while(this.entities.length > 0) {
        this.entities.pop();
      }
    }

    let key="";
    let arr=[];
    if(arrEntities){
      for(let i=0; i<arrEntities.length; i++){
        key = arrEntities[i]["entity"] + "-" + arrEntities[i]["extractor"];

        if(!this.keyExist(key)){
          
          var obj = {'key':key, 'entity': arrEntities[i]["entity"], 'value': arrEntities[i]["value"], 'confidence': arrEntities[i]["confidence"]}
          if(this.entities){
            this.entities.push(obj)
          }else{
            var arrAux = [obj]
            this.entities=arrAux;
          }
        
        }

      }
    }
    

  }
  

  keyExist(key) {
    if(this.entities){
      for(let i=0; i<this.entities.length; i++){
        let obj:string = this.entities[i]["key"];
        if (obj === key){
          return true;
        }
      }
    }
    return false;
    
}



  @Input()
  set selectedTestSet(ts) {
    console.log("set selected", ts);

    this.testsFails=[];
    this.selectedError = "";
    
    let summaryAux: string="";
    let summaryRunningTests: string="";
    if (!ts)
      return;

    this._selectedTestSet = ts;
    this.selectedTestSetDesc = ts.description;
    this.statusTestSetSelected = ts.status;
    if(ts.date)
      this.dateTestSetSelected = ts.date.split("T")[0] + " " + ts.date.split("T")[1].split(".")[0];

    if(ts.tests){
    
      if(ts.tests.length>0){
        this.testsFails=ts.tests[0].results.fails;
      }

      
      
        // this.setEditMode("VIEW");
        // this.intentNameForm.get('newIntentNameValue').setValue(intent.name);
        // this.newIntentDescriptionValue = intent.description;

        // this.value = this.testsService.formatTrainingPhrases(intent.step);
        //this.reloadVariablesAutocompleter([intent.parameters, []]);
      
      if(ts.status=="executing"){
        summaryAux="VA: "+ ts.VA + " is already running this test scenario"+ ts.description+ "\nThe tests that will be executed are: ts.executeTests[0]";

        summaryRunningTests="";

      }else if(ts.status=="finished"){
        
        summaryAux= this.selectedTestSetDesc + " scenario was performed at " + this.dateTestSetSelected +  " by VA: "+ ts.VA + "\nThe tests that were performed are: " + ts.executeTests[0];

        summaryRunningTests = ts.tests.length + " conversation mocks were performed with " + ts.tests[ts.tests.length-1].dialogs + " dialogos.\n"
        summaryRunningTests = summaryRunningTests + "This execution have a " + ts.tests[0].successTax + " of success, a " + ts.tests[0].failTax + " of errors and a " + ts.tests[0].warningTax + " of warnings."
        
        //Verifica se tem Warnigs
        if(ts.tests[ts.tests.length-1].results.warnings){
          if (ts.tests[ts.tests.length-1].results.warnings.length>0){
            summaryRunningTests = summaryRunningTests + "\nYou should review the answers since they are not exactly the same, however the deviation from the cosine is not representative to signal as an error."
            summaryRunningTests = summaryRunningTests + "\nPlease pay attention to the response to intent: " + ts.tests[ts.tests.length-1].results.warnings[0].intent 
          
          }else{
            summaryRunningTests = summaryRunningTests + " All virtual assistants answers are configured as expected."
          }
        }
        
        //Verifica se tem erros
        if (ts.tests[ts.tests.length-1].fails>0){
          summaryRunningTests = summaryRunningTests + "\nYou have " + ts.tests[ts.tests.length-1].fails +  " errors."
          //summaryRunningTests = summaryRunningTests + "\nPlease pay attention to the response to intent: " + ts.tests[ts.tests.length-1].results.warnings[0].intent 
        
        }else{
          summaryRunningTests = summaryRunningTests + "The VA in tests looks like he's pretty smart. 0 errors well done!"
        }

      }else if(ts.status=="error"){
        summaryAux="This test scenario is configured to VA: "+ ts.VA + " but an error occurred in its execution." ;
    
    
      }else{
          summaryAux="This test scenario is configured to VA: "+ ts.VA +  
          "\nThe tests that are configured to be executed are:" + ts.executeTests[0] ;
      }
    }else{
      summaryAux="This test scenario is configured to VA: "+ ts.VA +  
      "\nThe tests that are configured to be executed are:" + ts.executeTests[0] ;
    }
    
    this.summary = summaryAux + "\n" + summaryRunningTests
    // this.editor.setValue(summaryAux + "\n" + summaryRunningTests, 1);
    
    // this.editor.session.setUndoManager(new UndoManager());

    //this.reloadVariablesAutocompleter([this.testsService.program.variables, []]);


  }

  public runTestSet(){
    this.testsService.executeTestSetId(this._selectedTestSet._id.$oid);
    this.statusTestSetSelected = "executing";

    //this.selectedTestSet(this._selectedTestSet);
    
    //http://app.grappleai.com/tests/execute/32423

  }

  public async viewMock(rowIndex){
    //protected async subscribe() {
      this.mockErrorSelected = this.testsService.errorMockTestSetSelected;
  
  }

  // @Input()
  // set filterArgument(token) {
  //   this._filterArgument = token;
  //   /*     console.info("FILTER ARGUMENT: ", this._filterArgument);
  //       if(this._filterArgument){
    
  //         this.editor.execCommand("find");
  //         this.editor.searchBox.hide();
  //         this.editor.searchBox.searchInput.value = this._filterArgument;
  //         this.editor.searchBox.$onChange();
    
  //       } */
  // }

  /*   @Input()
    set autoCompleteLists(vars){
      this.reloadVariablesAutocompleter(vars);
    } */

  /**
   * @deprecated ainda esta a ser usado no cancel
   * @param intent
   */
  // private loadTranigPhrases(intent) {

  //   this._currentIntent = intent.intent;

  //   this.intentNameForm.get('newIntentNameValue').setValue(this._currentIntent.name);
  //   //this.newIntentDescriptionValue = this._currentIntent.description;

  //   console.log("loadTranigPhrases: "+ intent)

  //   this.value = this.testsService.formatTrainingPhrases(this._currentIntent.step);
  //   //    this.reloadVariablesAutocompleter(intent.parameters);
  //   this.editor.setValue(this.value, -1);
  //   this.inputParameters.checked = true;
  //   this.editor.session.setUndoManager(new UndoManager());

  //   if (this.filterArgument) {
  //     this.editor.execCommand("find");
  //     this.editor.searchBox.searchInput.value = intent.filterArgument;
  //     this.editor.searchBox.$onChange();
  //     //this.editor.searchBox.hide();
  //   }

  //   //this._updateValue();
  // }

  // public onTextChange(e) {
  //   this._updateValue();
  // }






  // private _updateValue() {


  //   let lines = this.editor.session.bgTokenizer.lines;
  //   //setValue to webCT
  //   this.inputParameters.value = lines;
  //   console.log("this.inputParameters: " + JSON.stringify(this.editor.session.bgTokenizer.lines))
  //   this.editor.session.bgTokenizer.start(0);

  //   //_________________________________________________





  // }


  // private setReadOnly(notEditable: boolean) {
  //   this.isEditable = !notEditable;
  //   if (this.isEditable) {
  //     this.editor.setOptions({ readOnly: false, highlightActiveLine: true, highlightGutterLine: true });
  //     this.editor.renderer.$cursorLayer.element.style.display = "";
  //   } else {
  //     this.editor.setOptions({ readOnly: true, highlightActiveLine: false, highlightGutterLine: false });
  //     this.editor.renderer.$cursorLayer.element.style.display = "none";
  //   }
  // }

  // public validateName() {
  //   console.info(this._currentIntent.displayName);
  //   if (this.intentNameForm.get('newIntentNameValue').value.trim() === this._currentIntent.name) {
  //     this.duplicatedNameError = false;
  //   } else {
  //     this.duplicatedNameError = this.testsService.intentExists(this.intentNameForm.get('newIntentNameValue').value.trim());
  //   }

  // }

  // setEditMode(mode) {
  // }


  // startEdit() {
  //   this._updateValue();
  //   this.setReadOnly(false);
  //   this.setEditMode("EDIT_TRAINING_PHRASES");
  // }

  // cancelEdit() {
  //   this.loadTranigPhrases({ intent: this._currentIntent });
  //   this.setReadOnly(true);
  //   this.setEditMode("VIEW");
  // }

  

  // saveEdit() {
  //   console.log("this.inputParameters: " + JSON.stringify(this.editor.session.bgTokenizer.lines))
  //   //this.usedVariablesChange = this.editor.session.bgTokenizer.lines;
  //   var arr:any = this.testsService.cleanEmptyLines(this.editor.session.bgTokenizer.lines);

  //   console.log("cleanEmptyLines: " + JSON.stringify(arr))

  //   // this.testsService.saveTrainingPhrases(this.inputParameters.value, this._currentIntent.displayName).then(
  //   //   (resp) => {
  //   //     this.testsService.saveTrainingPhrasesVariableList(this.usedVariables, this._currentIntent.index, this._currentIntent.displayName).then(
  //   //       (resp) => {
  //   //         this.notificationService.showSuccess(
  //   //           "Save training phrases",
  //   //           "Training phrases successfully saved!",
  //   //           { timeout: { success: 700 }, positionClass: { success: 'top-right' }, limit: { success: 1 } }
  //   //         );

  //   //         if (this.testsService.program.model && this.testsService.program.model.status != "new") {
  //   //           this.testsService.setProgramDeprecated().then(
  //   //             (resp) => {
  //   //               this.testsService.reloadProgram().then(
  //   //                 (resp) => {
  //   //                   this.setReadOnly(true);
  //   //                   this.setEditMode("VIEW");
  //   //                 }
  //   //               );
  //   //             }
  //   //           );
  //   //         } else {
  //   //           this.testsService.reloadProgram().then(
  //   //             (resp) => {
  //   //               this.setReadOnly(true);
  //   //               this.setEditMode("VIEW");
  //   //             }
  //   //           );
  //   //         }
  //   //       }
  //   //     );
  //   //   },
  //   //   (resp) => {
  //   //     this.notificationService.showError(
  //   //       "Save training phrases",
  //   //       "An error occurred while saving training phrases!",
  //   //       { timeout: { error: 700 }, positionClass: { error: 'top-right' }, limit: { error: 1 } }
  //   //     );
  //   //     console.log(resp)
  //   //   }
  //   // );
  // }


  /**
   * createFirstIntent
   */
  // public createFirstIntent() {
  //   this.eventManager.sendCreateNewIntent();
  // }


  // @ViewChild("intentNameEditField") intentNameEditField: ElementRef;
  // public renameIntent() {

  //   //this.intentNameEditField.nativeElement.focus();
  // }



}