import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-bs-superhero-chat-element-init',
  templateUrl: './bs-superhero-chat-element-init.component.html',
  styleUrls: ['./bs-superhero-chat-element-init.component.scss']
})
export class BsSuperheroChatElementInitComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
