import { SafePipe } from './utils/safe.pipe';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { HttpModule } from '@angular/http';
import { HttpClientModule } from '@angular/common/http';
import { RouterModule } from '@angular/router';
import { CommonModule } from '@angular/common';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { NgxChartsModule } from '@swimlane/ngx-charts';
import { AgmCoreModule } from '@agm/core';
import { ToasterModule } from 'angular2-toaster/angular2-toaster';
import { TooltipModule, TimepickerModule } from 'ngx-bootstrap';
import { PaginationModule } from 'ngx-bootstrap/pagination';
import { ModalModule } from 'ngx-bootstrap/modal';
import { BsDatepickerModule, DatepickerConfig } from 'ngx-bootstrap/datepicker';
import { PopoverModule } from 'ngx-bootstrap/popover';
import { ProgressbarModule } from 'ngx-bootstrap/progressbar';
import { TreeModule } from 'angular-tree-component/dist/angular-tree-component';
import { SliderModule } from 'primeng/components/slider/slider';
import { LeafletModule } from '@asymmetrik/ngx-leaflet';
import { ColorPickerModule } from 'ngx-color-picker';
import { AngularDraggableModule } from 'angular2-draggable';
import { NguiAutoCompleteModule } from '@ngui/auto-complete/dist/auto-complete.module';
import { ReCaptchaModule } from 'angular2-recaptcha';
import { NgSelectModule } from '@ng-select/ng-select';

import { AceEditorModule } from 'ng2-ace-editor';
import { InfiniteScrollModule } from 'ngx-infinite-scroll';

/**
 * ROBOT
 */
import { RobotEngineModel } from './robotEngineModel';
import { PageService } from './pageComponent/page.service';
import { PageCustomValidatorService } from './pageComponent/page-custom-validations.service';
import { ObjectPipe } from './utils/object.pipe';
import { TruncatePipe } from './utils/truncate.pipe';
import { Utils } from './utils/utils.service';
import { OperationsService } from './utils/operations.service';
import { ConfigurationService } from './utils/configuration.service';
import { AuthzService } from './utils/authz.service';
import { PreventDefaultLinkDirective } from './utils/preventDefaultLink';
import { I18nPipe } from './utils/i18n.pipe';
import { CapitalizePipe } from './utils/capitalize.pipe';
import { MyIpDirective } from './utils/myIp.directive';
import { GlobalVarsService } from './utils/global-vars.service';
import { UtilsCustomService } from './utils/utils-webct-methods.service';

import { ApiService } from './services/api.service';
import { DateService } from './services/date.service';
import { ToggleElementsService } from './services/toggle-elements.service';
import { NavigationService } from './services/navigation.service';
import { ComponentsService } from './services/components.service';
import { FilterService } from './services/filter.service';
import { InputService } from './services/input.service';
import { VersionControlService } from './services/version-control.service';

@NgModule({
  imports: [ // import Angular's modules
    CommonModule,
    RouterModule,
    BrowserModule,
    BrowserAnimationsModule,
    FormsModule,
    NgxChartsModule,
    ReactiveFormsModule,
    PaginationModule.forRoot(),
    ModalModule.forRoot(),
    PopoverModule.forRoot(),
    ProgressbarModule.forRoot(),
    ToasterModule,
    HttpModule,
    HttpClientModule,
    TreeModule,
    SliderModule,
    BsDatepickerModule.forRoot(),
    TimepickerModule.forRoot(),
    TooltipModule.forRoot(),
    AgmCoreModule.forRoot(),
    LeafletModule.forRoot(),
    AngularDraggableModule,
    NguiAutoCompleteModule.forRoot(),
    ColorPickerModule,
    ReCaptchaModule,
    NgSelectModule,
    AceEditorModule,
    InfiniteScrollModule
  ],
  declarations: [
    PreventDefaultLinkDirective,
    ObjectPipe,
    TruncatePipe,
    I18nPipe,
    SafePipe,
    MyIpDirective,
    CapitalizePipe
  ],
  exports: [
    BrowserModule,
    RouterModule,
    BrowserAnimationsModule,
    FormsModule,
    NgxChartsModule,
    ReactiveFormsModule,
    PaginationModule,
    ModalModule,
    PopoverModule,
    ProgressbarModule,
    ToasterModule,
    HttpClientModule,
    TreeModule,
    SliderModule,
    BsDatepickerModule,
    TimepickerModule,
    TooltipModule,
    AgmCoreModule,
    CommonModule,
    PreventDefaultLinkDirective,
    ObjectPipe,
    TruncatePipe,
    I18nPipe,
    SafePipe,
    CapitalizePipe,
    LeafletModule,
    AngularDraggableModule,
    NguiAutoCompleteModule,
    ColorPickerModule,
    MyIpDirective,
    ReCaptchaModule,
    NgSelectModule,
    AceEditorModule,
    InfiniteScrollModule
  ],
  providers: [
    RobotEngineModel,
    ConfigurationService,
    AuthzService,
    Utils,
    PageService,
    PageCustomValidatorService,
    UtilsCustomService,
    OperationsService,
    GlobalVarsService,
    DatepickerConfig,
    ApiService,
    DateService,
    ToggleElementsService,
    NavigationService,
    ComponentsService,
    FilterService,
    InputService,
    VersionControlService
  ]
})
export class SharedModule {

}
