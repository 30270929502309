import { Component, Input, OnInit, OnDestroy } from '@angular/core';
import { FormControl } from '@angular/forms';
import { RobotEngineModel } from './../../../foundations-webct-robot/robot/robotEngineModel';
import { Utils } from './../../../foundations-webct-robot/robot/utils/utils.service';
import { PageService } from './../../../foundations-webct-robot/robot/pageComponent/page.service';
import { JsonParams } from './../../../foundations-webct-robot/robot/classes/jsonParams.class';
import { InputService } from './../../../foundations-webct-robot/robot/services/input.service';
import { ComponentsService } from './../../../foundations-webct-robot/robot/services/components.service';

@Component({
  selector: 'input-select',
  templateUrl: 'select.component.html'
})

export class InputSelectComponent implements OnInit, OnDestroy {

  @Input() public inputParameters: JsonParams;
  @Input() public inputDataRecs: Object;
  @Input() public dataRecs: Object;
  @Input() public control: FormControl;

  public elementsIds = {
    select: '',
    options: '',
    description: ''
  };
  public selectedIdIndex: number[] = [];
  public selectMultiple = false;
  public valueControl: string = null;

  private _componentId: string;
  private _subscription: any;

  constructor(
    private _robot: RobotEngineModel,
    private _utils: Utils,
    private _components: ComponentsService,
    private _pageService: PageService,
    private _inputService: InputService) {

    this._componentId = 'SELECT-' + this._utils.guid(4, '');
  }

  public ngOnInit() {

    this._startComponent();
    this._subscription = this.inputParameters.observe().subscribe(param => {
      this._inputService.loadValueListFromDataRecs(this.inputParameters, this.dataRecs, this.inputParameters.valueList, this._componentId);
    });

  }

  public ngOnDestroy() {
    if (this._subscription)
      this._subscription.unsubscribe();
  }

  public defineValue(initValue?: boolean) {

    if (this.selectMultiple) {
      this.inputParameters.value = [];
      for (let i in this.selectedIdIndex)
        this.inputParameters.value.push(this.findValue(this.selectedIdIndex[i]));

      this.valueControl = this.inputParameters.value.length > 0 ? '*'.repeat(this.inputParameters.value.length) : null;
    } else {
      this.inputParameters.value = this.findValue(this.selectedIdIndex[0]);
      this.valueControl = this.inputParameters.value != undefined ? (this.inputParameters.value || this.inputParameters.value.toString()) : null;
    }

    if (initValue)
      this._setOriginalValue();

    this._robot.findDynamicPropsDependencies(this.inputParameters.id, this.dataRecs);
    this._components.updatePageParameters(this.inputParameters.parameters);
  }

  public isSelected = (val: any): boolean => this.selectMultiple && this.inputParameters.value ? this.inputParameters.value.indexOf(val) >= 0 : this.inputParameters.value == val;
  public optionValue = (option: any) => typeof option == 'string' ? option : option.id ? option.text : option.value;
  public optionKey = (option: any) => typeof option == 'string' ? option : (option.id ? (typeof option.value != 'object' ? (option.value || option.id) : option.id) : option.key);

  private _startComponent() {

    this.elementsIds.select = this._components.getHtmlId(this.inputParameters);
    this.elementsIds.options = this._components.getHtmlId(this.inputParameters, 'select-option');
    if (this.inputParameters.description)
      this.elementsIds.description = this._components.getHtmlId(this.inputParameters, 'select-description')

    this.selectMultiple = this.inputParameters.subType === 'multiple';
    if (this.inputParameters.value) {
      if (this.selectMultiple) {
        this.inputParameters.value = typeof this.inputParameters.value == 'string' ? [this.inputParameters.value] : this.inputParameters.value;
      } else if (typeof this.inputParameters.value == 'object') {
        this.inputParameters.subType = 'multiple';
        this.selectMultiple = true;
      }
    }

    if (!this.control)
      this.control = new FormControl();

    this._setOriginalValue();

    if (this.inputParameters.key === 'reasonCode' || this.inputParameters.id === 'reasonCode') {
      this._loadReasonCodes(<string>this.inputParameters.pathToValue);
    } else {
      this._inputService.loadValueListFromDataRecs(this.inputParameters, this.dataRecs, this.inputParameters.valueList, this._componentId);
      this._selectInitialValue();
    }
  }

  private _selectInitialValue() {
    if (this.inputParameters.valueList) {
      if (this.selectMultiple) {
        this.selectedIdIndex = [];
        if (this.inputParameters.value && this.inputParameters.value.length > 0) {
          for (let i in this.inputParameters.value)
            this.selectedIdIndex.push(this.inputParameters.valueList.findIndex((obj) => { return (obj.id || obj.key) == this.inputParameters.value[i]; }));
        }
      } else {
        let currIndex = this.inputParameters.valueList.findIndex((obj) => { return (obj.id || obj.key) == this.inputParameters.value; });
        if (currIndex >= 0)
          this.selectedIdIndex = [currIndex];
        else if (this.inputParameters.validator && this.inputParameters.validator.required && this.inputParameters.validator.required.value && this.inputParameters.placeholder !== null)
          this.selectedIdIndex = [0];
      }
      this.defineValue(true);
    } else
      this.inputParameters.value = this.selectMultiple ? [] : '';
  }

  public findValue(index: number) {
    let option = this.inputParameters.valueList[index];
    return option ? (typeof option == 'string' ? option : (option.id ? (option.value || option.id) : option.key)) : '';
  }

  private _loadReasonCodes(pathToValue: string) {

    let resourceQueryString = pathToValue.split('?')[1].split('&');
    let resourceOptions = {};

    for (let p in resourceQueryString) {
      resourceOptions[resourceQueryString[p].split('=')[0]] = resourceQueryString[p].split('=')[1];
    }

    this._utils
      .GetAll(this._pageService.getUrlFromConfig(pathToValue))
      .subscribe((reasonGroup) => {
        if (reasonGroup[resourceOptions['grp']]) {
          this._utils
            .GetAll(location.origin + '/assets/reasonlist.json')
            .subscribe((reasonList) => {
              if (reasonList) {
                this.inputParameters.valueList = [];
                for (let id in reasonGroup[resourceOptions['grp']]) {
                  this.inputParameters.valueList.push({
                    key: reasonGroup[resourceOptions['grp']][id],
                    value: reasonList[reasonGroup[resourceOptions['grp']][id]]
                  });
                }
              }
              this._selectInitialValue();
            });
        }
      });
  }

  private _setOriginalValue() {
    if (this.inputParameters.value)
      this.inputParameters.originalValue = this._utils.cloneJsonParse(this.inputParameters.value);
  }
}
